<div
  [class.with-filters]="showFilters"
  class="filter-group-date-range-container"
>
  <div
    *ngIf="showTrendDateRange"
    class="date-range-container mt-x2"
  >
    <dpa-tooltip
      [tooltipText]="'COMMON_MESSAGES.DATE_RANGE_HELP_TEXT' | translate"
      [focusEnabled]="true"
      class="mr-x1"
    >
      <cds-icon shape="calendar"></cds-icon>
    </dpa-tooltip>
    <dpa-trend-date-range
      [trendDateRange]="filterTrendDateRange"
      [readonly]="isTrendDateRangeReadonly"
      [maxCustomRange]="maxCustomRange"
      [customTimePeriodHelpText]="customTimePeriodHelpText"
      (trendDateRangeUpdated)="onDateRangeChange($event)"
    ></dpa-trend-date-range>
  </div>

  <div
    *ngIf="showFilters"
    class="filter-container pt-x1"
  >
    <dpa-filter-tags
      *ngIf="filterTags?.length"
      [hasDivider]="true"
      [filterTags]="filterTags"
      [defaultSelectedTags]="defaultSelectedTags"
      [tagsDisabled]="!filterTagsClickable"
      [class.mr-x2]="filterTagsClickable"
      (onFilterChange)="onTagFilterChange($event)"
    ></dpa-filter-tags>
    <dpa-tooltip
      *ngIf="filterTags?.length && !filterTagsClickable"
      [tooltipText]="'COMMON_MESSAGES.FILTER_TAG_DISABLE_TEXT' | translate"
      [focusEnabled]="true"
      class="is-gray mr-x2"
    >
      <cds-icon
        shape="info-circle"
        size="20"
      ></cds-icon>
    </dpa-tooltip>
    <button
      (click)="setFilterSlideOverVisible(true)"
      class="btn btn-link m-x0 p-x0"
    >
      <cds-icon
        shape="filter"
        size="18"
        class="filter-icon"
      ></cds-icon>
      {{ 'COMMON_MESSAGES.ADVANCED_FILTERS' | translate }}
      <span
        *ngIf="filterCount > 0"
        class="ml-x1 badge badge-info"
      >
        {{ filterCount }}
      </span>
    </button>
  </div>
</div>

<dpa-slide-over
  *ngIf="filterSlideOverVisible"
  [isVisible]="filterSlideOverVisible"
  [size]="FILTER_SLIDE_OVER_SIZE"
  (hide)="setFilterSlideOverVisible(false)"
>
  <div class="slide-over">
    <div class="slide-over-header pl-x4 pr-x2">
      <h4 class="mt-0">{{ 'COMMON_MESSAGES.FILTERS' | translate }}</h4>
      <button class="btn btn-link btn-icon">
        <cds-icon
          (click)="setFilterSlideOverVisible(false)"
          shape="times"
          size="20"
        ></cds-icon>
      </button>
    </div>
    <div class="slide-over-content">
      <dpa-filter-group-date-range
        [editable]="editable"
        [columns]="filterColumns"
        [ruleGroup]="filterRuleGroup"
        [defaultRuleGroup]="defaultRuleGroup"
        [showFilters]="showFilters"
        [showTrendDateRange]="false"
        [showThreeColumnFilter]="true"
        [isCrossCategory]="isCrossCategory"
        [showIncludesAllText]="showIncludesAllText"
        [hideEmptyRules]="true"
        (onApply)="onFilterChange($event.ruleGroup)"
        class="mh-x4 mt-x4"
      ></dpa-filter-group-date-range>
    </div>
  </div>
</dpa-slide-over>
