/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DataExplorerTab
 *
 * @export
 * @enum {string}
 */
export enum DataExplorerTab {
  PREVIEW = 'preview',
  VISUALIZATION = 'visualization',
  SCHEMA = 'schema',
}
