<div
  *ngIf="customContentTemplate"
  class="title-wrapper mt-x4"
>
  <h3 class="mt-0">
    {{ 'COMMON_MESSAGES.SELECT_TEMPLATE' | translate }}
  </h3>
  <ng-template
    [ngTemplateOutlet]="customContentTemplate"
    class="custom-template-button"
  ></ng-template>
</div>

<!-- Search functionality: Start -->
<div class="search-input mt-x4">
  <dpa-local-search
    [placeHolderText]="searchPlaceholder || ('COMMON_ACTIONS.SEARCH' | translate)"
    (onSearch)="onQueryChanged($event)"
    iconClass="search"
  ></dpa-local-search>
  <dpa-type-ahead
    *ngIf="isCardView && orderByArray.length > 0"
    [placeholderText]="'WIDGET_CUSTOMIZE.WIDGET_CHART_ORDER_BY' | translate"
    [searchableItems]="orderByArray"
    [customFormatter]="orderByFormatter"
    [labelFormatter]="orderByFormatter"
    (selectedItemChange)="onOrderByChange($event)"
    class="order-by-input"
  ></dpa-type-ahead>
</div>
<!-- Search functionality: End -->

<div class="filters-wrapper mt-x4">
  <!-- Filters : Start -->
  <dpa-filter-tags
    *ngIf="filterTags?.length"
    [defaultSelectedTags]="[defaultSelectedTag]"
    [filterTags]="filterTags"
    (onFilterChange)="onFilterChange($event)"
  ></dpa-filter-tags>
  <!-- Filters : End -->

  <!-- Layout Switcher : Start -->
  <dpa-multi-layout-switcher
    [updateParams]="updateUrlLayoutParam"
    [availableLayouts]="availableLayouts"
    [selectedLayout]="selectedLayout"
    (selectedLayoutChange)="onLayoutChange($event)"
  ></dpa-multi-layout-switcher>
  <!-- Layout Switcher : End -->
</div>
<!-- Grid view -->
<div class="template-search-data-grid">
  <ng-container *ngIf="isCardView; else listView">
    <div class="clr-row">
      <div
        *ngFor="let row of response.results"
        class="clr-col-xl-4 clr-col-lg-4 clr-col-md-6"
      >
        <ng-template
          [ngTemplateOutlet]="cardTemplate"
          [ngTemplateOutletContext]="{
            row: row,
            styledString: styledStrings[row[searchOn]]
          }"
        ></ng-template>
      </div>
    </div>
    <dpa-data-grid
      [footerMode]="true"
      [pageSize]="pageSize"
      [loading]="isLoading"
      [response]="response"
      [noResultsText]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
      (pageChange)="onPageChange($event)"
      (sortChange)="onSortChange($event)"
    ></dpa-data-grid>
  </ng-container>
  <!-- List view -->
  <ng-template #listView>
    <div class="mt-x4">
      <dpa-data-grid
        *ngIf="dataGridColumns?.length"
        [loading]="isLoading"
        [response]="response"
        [sortOns]="sortOns"
        [selectable]="{ single: false, enabled: false }"
        [selectedRows]="selectedRows"
        [pageSize]="pagedRequest.size"
        [noResultsText]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
        [columnWidthByKey]="columnWidthByKey"
        [attr.aria-label]="tableName"
        (sortChange)="onSortChange($event)"
        (pageChange)="onPageChange($event)"
        class="valign-cells"
        tabindex="0"
      >
        <div
          *ngFor="let action of actions"
          dpaDataGridSingleRowAction
          [label]="action.label"
          [className]="action.className"
          [hidden]="action.isHidden"
          [disabled]="action.isDisabled"
          (click)="action.onClick($event)"
          (keyup.enter)="action.onClick($event)"
        ></div>
        <div
          *ngFor="let dataGridColumn of dataGridColumns"
          dpaDataGridColumn
          [field]="dataGridColumn.value"
          [sortBy]="dataGridColumn.value"
          [title]="dataGridColumn.label"
          [sortable]="true"
          [className]="dataGridColumn.className"
        >
          <ng-template
            dpaDataGridCell
            let-dataItem
          >
            <ng-template
              *ngIf="cellTemplatesByColumnValue[dataGridColumn.value]; else simpleValue"
              [ngTemplateOutlet]="cellTemplatesByColumnValue[dataGridColumn.value]"
              [ngTemplateOutletContext]="{ dataItem: dataItem, dataGridColumn: dataGridColumn }"
            ></ng-template>
            <ng-template #simpleValue>
              <div
                *ngIf="dataGridColumn.name === searchOn && styledStrings[dataItem[searchOn]]; else decoratedValue"
                [dpaInnerHtmlLight]="styledStrings[dataItem[searchOn]]"
                class="matching-text"
              ></div>
              <ng-template #decoratedValue>
                <dpa-decorated-value
                  *ngIf="!cellTemplatesByColumnValue[dataGridColumn.value]"
                  [value]="dataItem[dataGridColumn.value]"
                  [valueDecorator]="dataGridColumn.valueDecorator"
                ></dpa-decorated-value>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </dpa-data-grid>
    </div>
  </ng-template>
</div>
