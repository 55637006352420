/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { size } from 'lodash';

import { Column, ColumnIndex, CustomReport } from '@ws1c/intelligence-models';

/**
 * getReportColumnsFromNames
 * @param {string[]} customReportColumnNames
 * @param {ColumnIndex} columnsByName
 * @returns {Column[]}
 */
export function getReportColumnsFromNames(customReportColumnNames: string[], columnsByName: ColumnIndex): Column[] {
  if (!size(columnsByName)) {
    return [];
  }
  return customReportColumnNames?.map(
    (customReportColumnName: string) =>
      columnsByName[customReportColumnName] ??
      new Column({
        name: customReportColumnName,
        error: true,
      }),
  );
}

/**
 * Check whether custom report data is valid or not
 *
 * @export
 * @param {CustomReport} customReportDetails
 * @returns {boolean}
 */
export function isValidCustomReportData(customReportDetails: CustomReport): boolean {
  return !!(
    customReportDetails &&
    !!customReportDetails?.name &&
    customReportDetails.categoryId &&
    customReportDetails.columnNames &&
    customReportDetails.columnNames.length
  );
}

export const REPORT_COMMON_HELPERS = {
  getReportColumnsFromNames,
  isValidCustomReportData,
};
