/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { BreadCrumb, StickyHeaderComponent, StickyHeaderV2Component } from '@dpa/ui-common';
import { isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { PageService } from '@ws1c/intelligence-core/services';

/**
 * PageContextDirective
 * @export
 * @class PageContextDirective
 */
@Directive({
  selector: '[dpaPageContext]',
})
export class PageContextDirective implements OnInit, OnDestroy {
  @Input() public breadCrumbs: BreadCrumb[];
  @Input() public pageTitle: string;

  public sub: Subscription = new Subscription();

  /**
   * Constructor
   * @param {StickyHeaderComponent} [stickyHeaderComponent]
   * @param {StickyHeaderV2Component} [stickyHeaderV2Component]
   * @param {PageService} pageService
   * @memberOf PageContextDirective
   */
  constructor(
    @Optional() private stickyHeaderComponent: StickyHeaderComponent,
    @Optional() private stickyHeaderV2Component: StickyHeaderV2Component,
    private pageService: PageService,
  ) {}

  /**
   * ngOnInit
   * @memberof PageContextDirective
   */
  public ngOnInit() {
    const headerComponent: StickyHeaderComponent | StickyHeaderV2Component = this.stickyHeaderComponent || this.stickyHeaderV2Component;
    if (!this.breadCrumbs) {
      this.sub.add(
        this.pageService.breadCrumbs$.pipe(distinctUntilChanged(isEqual)).subscribe((breadCrumbs: BreadCrumb[]) => {
          headerComponent.changeDetectorRef?.detectChanges();
          headerComponent.breadCrumbs = breadCrumbs;
        }),
      );
    }
    if (!this.pageTitle) {
      this.sub.add(
        this.pageService.pageTitle$.pipe(distinctUntilChanged()).subscribe((pageTitle: string) => {
          headerComponent.changeDetectorRef?.detectChanges();
          headerComponent.pageTitle = pageTitle;
        }),
      );
    }
  }

  /**
   * ngOnDestroy
   * @memberof PageContextDirective
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
