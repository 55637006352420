/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { LookupConfig } from '@ws1c/intelligence-models/connection/lookup-config.model';
import { defaultValueConverterFactory, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { MetaFormFieldGroups } from './meta-form-field-groups.model';
import { MetaFormFieldPresentationType } from './meta-form-field-presentation-type.enum';

/**
 * DefaultValueType
 *
 * @export
 * @enum {string}
 */
export enum DefaultValueType {
  CUSTOM = 'custom',
  LOOKUP = 'lookup',
}

/**
 * ChoiceValue
 * @export
 * @class ChoiceValue
 */
@Serializable
export class ChoiceValue {
  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('value')
  public value: string = undefined;

  @JsonProperty('associated_display_fields')
  public associatedDisplayFields: string[] = [];

  @JsonProperty('associated_placeholder_value')
  public associatedPlaceholderValue: string = undefined;

  @JsonProperty({
    name: 'associated_presentation_type',
    customConverter: enumConverterFactory(MetaFormFieldPresentationType),
  })
  public associatedPresentationType: MetaFormFieldPresentationType = undefined;

  /**
   * Creates an instance of ChoiceValue
   * @param {Array<Partial<ChoiceValue>>} args
   * @memberof ChoiceValue
   */
  constructor(...args: Array<Partial<ChoiceValue>>) {
    Object.assign(this, ...args);
  }
}

/**
 * MetaFormField
 * @export
 * @class MetaFormField
 */
@Serializable
export class MetaFormField {
  /**
   * Matches dynamic values - eg ${device_friendly_name}
   * There is a group to capture the attribute inside the ${} - eg device_friendly_name
   *
   * @static
   * @memberof MetaFormField
   */
  public static dynamicValuesRegex = /\${([^${]+)}/gm;

  /**
   * Extracts dynamic value attributes
   *
   * @param {MetaFormField} field
   * @returns {Set<string>}
   * @memberof MetaFormField
   */
  public static extractDynamicValues(field: MetaFormField): Set<string> {
    const matches = new Set<string>();
    if (field.value && typeof field.value === 'string') {
      let match: RegExpExecArray;
      do {
        match = this.dynamicValuesRegex.exec(field.value);
        if (match && match[1]) {
          matches.add(match[1]);
        }
      } while (match);
    }

    return matches;
  }

  @JsonProperty('associated_field')
  public associatedField: string = undefined;

  @JsonProperty('associated_display_field')
  public associatedDisplayField: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('data_type')
  public dataType: string = undefined;

  @JsonProperty({
    name: 'presentation_type',
    customConverter: enumConverterFactory(MetaFormFieldPresentationType),
  })
  public presentationType: MetaFormFieldPresentationType = undefined;

  @JsonProperty({ name: 'choice_values', cls: ChoiceValue })
  public choiceValues: ChoiceValue[] = [];

  @JsonProperty('is_suggestion_supported')
  public isSuggestionSupported: boolean = undefined;

  @JsonProperty('suggestion_source')
  public suggestionSource: string = undefined;

  @JsonProperty('entity')
  public entity: string = undefined;

  @JsonProperty('classifications')
  public classifications: string[] = undefined;

  @JsonProperty({ name: 'groups', cls: MetaFormFieldGroups })
  public groups: MetaFormFieldGroups[] = undefined;

  @JsonProperty('description_available')
  public descriptionAvailable: boolean = undefined;

  @JsonProperty('required')
  public required: boolean = false;

  @JsonProperty({
    name: 'default_value',
    customConverter: defaultValueConverterFactory(),
  })
  public defaultValue: any = undefined;

  @JsonProperty('placeholder_value')
  public placeholderValue: string = undefined;

  @JsonProperty('template_allowed')
  public useTemplate: boolean = undefined;

  @JsonProperty('note')
  public note: string = undefined;

  @JsonProperty({ name: 'value', excludeToJson: true })
  public value?: any = undefined;

  @JsonProperty({ name: 'lookup_config', cls: LookupConfig })
  public lookupConfig?: LookupConfig = undefined;

  @JsonProperty('disabled')
  public disabled?: boolean = undefined;

  @JsonProperty('disabled_in_edit_mode')
  public disabledInEditMode?: boolean = undefined;

  public scope: string;

  /**
   * Creates an instance of MetaFormField
   *
   * @param {Array<Partial<MetaFormField>>} args
   * @memberof MetaFormField
   */
  constructor(...args: Array<Partial<MetaFormField>>) {
    Object.assign(this, ...args);
  }
}
