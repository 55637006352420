<dpa-template-search
  [templateType]="templateType"
  [templates]="templates$ | async"
  [tags]="tags"
  [selectedTag]="selectedTag"
  [customCardBlock]="customCardBlock"
  [templateTableColumns]="tableColumns"
  [actions]="actions"
  [showCustomContentTemplate]="showCustomButton"
  [bookmarksEnabled]="false"
  (toggleBookmark)="onToggleBookmark($event)"
></dpa-template-search>
<ng-template
  #customCardBlock
  let-template="template"
>
  <div class="card-block">
    <dpa-card-block-row>
      {{ 'COMMON_MESSAGES.LANGUAGE_NAME' | translate : { language: template.language } }}
    </dpa-card-block-row>
    <dpa-card-block-row>
      {{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate : { name: template.platform } }}
    </dpa-card-block-row>
  </div>
</ng-template>
