<div
  *ngIf="!disabled; else disabledInput"
  [ngSwitch]="getUiPresentationType()"
>
  <div *ngSwitchCase="MetaFormFieldPresentationType.TEXT_INPUT">
    <form [formGroup]="formGroup">
      <div [class.clr-error]="formGroup.invalid && formGroup.dirty">
        <input
          [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
          formControlName="ruleValue"
          type="text"
          class="clr-input standard-input"
        />
        <span
          *ngIf="formGroup.dirty && formGroup.get('ruleValue').hasError('minlength')"
          class="clr-subtext"
        >
          {{ 'ERROR_MESSAGES.MIN_LENGTH' | translate : { n: selectedCondition?.minLength } }}
        </span>
      </div>
    </form>
  </div>
  <div *ngSwitchCase="MetaFormFieldPresentationType.IPV4_INPUT">
    <dpa-ip-value-selector
      [selectedValues]="selectedValues"
      (onChange)="onChange($event)"
    ></dpa-ip-value-selector>
  </div>
  <div *ngSwitchCase="MetaFormFieldPresentationType.IPV4_LIST_INPUT">
    <dpa-ip-value-selector
      [selectedValues]="selectedValues"
      [listSelection]="true"
      (onChange)="onChange($event)"
    ></dpa-ip-value-selector>
  </div>
  <div *ngSwitchCase="MetaFormFieldPresentationType.IPV4_RANGE_INPUT">
    <dpa-ip-value-selector
      [selectedValues]="selectedValues"
      [rangeSelection]="true"
      (onChange)="onChange($event)"
    ></dpa-ip-value-selector>
  </div>
  <div *ngSwitchCase="MetaFormFieldPresentationType.SELECT">
    <dpa-type-ahead
      [placeholderText]="placeholderText"
      [selectedItem]="selectedItems[0]"
      [searchableItems]="getSearchableItems()"
      [keyBy]="unwrapValue"
      [customFormatter]="unwrapValue"
      [labelFormatter]="unwrapValue"
      [createCustomItem]="wrapValue"
      [dropdownAlignRight]="dropdownAlignRight"
      [subgroupConfig]="getFuzzySubgroupConfig()"
      [isSuggestionLoading]="isSuggestionLoading$ | async"
      (selectedItemChange)="onSelectedItemsChange(toArrayFormat($event))"
      (onQueryChange)="onQueryChange($event)"
    ></dpa-type-ahead>
  </div>
  <div *ngSwitchCase="MetaFormFieldPresentationType.MULTISELECT">
    <dpa-type-ahead-multi
      [placeholderText]="placeholderText"
      [noValueFoundText]="noValueFoundText"
      [selectedItems]="selectedItems"
      [searchableItems]="getSearchableItems()"
      [keyBy]="unwrapValue"
      [searchFormatter]="unwrapValue"
      [labelFormatter]="unwrapValue"
      [useCheckboxes]="false"
      [includeSelectedInResults]="false"
      [createCustomItem]="wrapValue"
      [subgroupConfig]="getFuzzySubgroupConfig()"
      [isSuggestionLoading]="isSuggestionLoading$ | async"
      [dropdownAlignRight]="dropdownAlignRight"
      [maxFilteredItemCount]="autoCompletedItems.length"
      [delimiterSupported]="delimiterSupported"
      (selectedItemsChange)="onSelectedItemsChange($event)"
      (onQueryChange)="onQueryChange($event)"
    ></dpa-type-ahead-multi>
  </div>
  <div *ngSwitchCase="MetaFormFieldPresentationType.WITHIN">
    <dpa-relative-time-picker
      [relativeTime]="startValue"
      [dropdownAlignRight]="dropdownAlignRight"
      (relativeTimeChange)="onChange(toArrayFormat($event))"
    ></dpa-relative-time-picker>
  </div>
  <div *ngSwitchDefault>
    <dpa-organization-group-selector
      *ngIf="column.isTreeField()"
      [selectedValues]="selectedValues"
      [singleSelection]="filterRule.condition === FILTER_CONDITION.contains"
      [orgHierarchy]="orgHierachy$ | async"
      [dropdownAlignRight]="dropdownAlignRight"
      (onDone)="onOrganizationGroupSelection($event)"
    ></dpa-organization-group-selector>
    <div *ngIf="column.dataType === DATA_TYPE[DATA_TYPE.BOOLEAN]">
      <dpa-type-ahead
        [placeholderText]="placeholderText"
        [selectedItem]="selectedValue"
        [searchableItems]="booleanItems"
        (selectedItemChange)="onChange($event)"
      ></dpa-type-ahead>
    </div>
    <input
      *ngIf="column.dataType === DATA_TYPE[DATA_TYPE.STRING]"
      [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
      [ngModel]="selectedValue"
      (ngModelChange)="onChange($event)"
      type="text"
      class="clr-input standard-input"
    />
    <div
      *ngIf="isNumber(column.dataType)"
      class="input-container"
    >
      <ng-container *ngIf="filterRule.condition !== FILTER_CONDITION.includes; else multiNumberSelector">
        <input
          [step]="getSupportedStep(column.dataType)"
          [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
          [ngModel]="startValue"
          (ngModelChange)="onChange($event)"
          (keydown)="onKeyDownForCheckingInputForIntegerDataType($event)"
          (paste)="onPasteForCheckingInputForIntegerDataType($event)"
          type="number"
          class="clr-input standard-input"
        />
        <ng-container *ngIf="filterRule.condition === FILTER_CONDITION.between || filterRule.condition === FILTER_CONDITION.notBetween">
          <span class="p2">&amp;</span>
          <input
            [step]="getSupportedStep(column.dataType)"
            [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
            [ngModel]="endValue"
            (ngModelChange)="onChange([startValue, $event])"
            type="number"
            class="clr-input standard-input"
          />
        </ng-container>
      </ng-container>
      <ng-template #multiNumberSelector>
        <dpa-multi-number-selector
          [selectedValues]="selectedValues"
          (onChange)="onChange($event)"
          (keydown)="onKeyDownForCheckingInputForIntegerDataType($event)"
          (paste)="onPasteForCheckingInputForIntegerDataType($event)"
          class="standard-input"
        ></dpa-multi-number-selector>
      </ng-template>
    </div>
    <section
      *ngIf="column.isDateTimeField()"
      class="input-container"
    >
      <dpa-date-time-picker
        [date]="getDateFromTimestamp(startDate)"
        [title]="getDateTimePickerTitle('start')"
        [pickerType]="DATE_TIME_PICKER_TYPE.DATE_TIME"
        (onSelectDate)="onStartDateChange($event)"
      ></dpa-date-time-picker>

      <ng-container *ngIf="[FILTER_CONDITION.between, FILTER_CONDITION.notBetween].includes(filterRule.condition)">
        <span class="p2">&amp;</span>
        <dpa-date-time-picker
          [date]="getDateFromTimestamp(endDate)"
          [title]="getDateTimePickerTitle('end')"
          [pickerType]="DATE_TIME_PICKER_TYPE.DATE_TIME"
          [popupAlignRight]="true"
          (onSelectDate)="onEndDateChange($event)"
        ></dpa-date-time-picker>
      </ng-container>
    </section>
  </div>
  <ng-container *ngTemplateOutlet="maxLengthError"></ng-container>
</div>
<ng-template #disabledInput>
  <input
    [disabled]="true"
    [placeholder]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
    type="text"
    class="clr-input standard-input"
  />
</ng-template>
<ng-template #maxLengthError>
  <div class="clr-error">
    <span
      *ngIf="isQueryMaxInvalid"
      class="clr-subtext"
    >
      {{ 'ERROR_MESSAGES.MAX_LENGTH' | translate : { n: MAX_TEXT_LENGTH } }}
    </span>
    <span
      *ngIf="isQueryMinInvalid"
      class="clr-subtext"
    >
      {{ 'ERROR_MESSAGES.MIN_LENGTH' | translate : { n: selectedCondition.minLength } }}
    </span>
  </div>
</ng-template>
