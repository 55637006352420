<div class="container">
  <clr-dropdown
    *ngFor="let facetCount of facetCounts; trackBy: trackByFacetValue"
    [clrCloseMenuOnItemClick]="false"
  >
    <ng-container *ngLet="!!facetCount.subFacetCounts?.length as hasSubFacets">
      <button
        *ngLet="selectedTagsMap.get(facetCount.fieldValue)?.size as selectedCount"
        clrDropdownTrigger
        [class.label-info]="isTagSelected(facetCount.fieldValue)"
        (click)="!hasSubFacets && toggleTag(facetCount.fieldValue)"
        class="label clickable filter-tag top-level"
      >
        <span>{{ facetCount.fieldValue }}</span>
        <span *ngIf="selectedCount">: {{ 'COMMON_MESSAGES.COUNT_SELECTED' | translate : { count: selectedCount } }}</span>
        <cds-icon
          *ngIf="isTagSelected(facetCount.fieldValue); else angle"
          (click)="clearAllSubTags(facetCount.fieldValue, $event)"
          shape="times"
          size="14"
        ></cds-icon>
        <ng-template #angle>
          <cds-icon
            *ngIf="hasSubFacets"
            shape="angle"
            direction="down"
          ></cds-icon>
        </ng-template>
      </button>
      <ng-container *ngIf="hasSubFacets">
        <clr-dropdown-menu *clrIfOpen>
          <span
            *ngFor="let subFacetCount of facetCount.subFacetCounts; trackBy: trackByFacetValue"
            clrDropdownItem
            [class.label-info]="isTagSelected(facetCount.fieldValue, subFacetCount.fieldValue)"
            (click)="toggleSubTag(facetCount.fieldValue, subFacetCount.fieldValue)"
            class="label clickable filter-tag"
          >
            <span>{{ subFacetCount.fieldValue }}</span>
            <cds-icon
              *ngIf="isTagSelected(facetCount.fieldValue, subFacetCount.fieldValue)"
              shape="times"
              size="14"
            ></cds-icon>
          </span>
          <div class="dropdown-options">
            <button
              (click)="selectAllSubTags(facetCount.fieldValue)"
              class="btn btn-link"
            >
              {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_SELECT_ALL' | translate }}
            </button>
            <button
              [disabled]="!isTagSelected(facetCount.fieldValue)"
              (click)="clearAllSubTags(facetCount.fieldValue)"
              class="btn btn-link"
            >
              {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
            </button>
          </div>
        </clr-dropdown-menu>
      </ng-container>
    </ng-container>
  </clr-dropdown>
</div>
