<ng-container *ngIf="riskIndicators$ | async as indicatorList; else loading">
  <table
    *ngIf="indicatorList.length; else noIndicators"
    [attr.aria-label]="'STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS_TABLE' | translate"
    class="table table-noborder table-vertical mv-x1"
  >
    <tbody>
      <tr *ngFor="let data of indicatorList">
        <th scope="col">
          <cds-icon
            shape="warning-standard"
            size="14"
            class="is-yellow-light-midtone"
          ></cds-icon>
        </th>
        <td>
          <dpa-risk-score-tooltip
            [scoreName]="data[getFQN(COLUMN_NAMES.score_type_name)]"
            [scoreType]="data[getFQN(COLUMN_NAMES.score_type)]"
          >
            <span class="p2">{{ data[getFQN(COLUMN_NAMES.score_type_name)] || data[getFQN(COLUMN_NAMES.score_type)] }}:</span>
          </dpa-risk-score-tooltip>
          <ng-container
            *ngIf="getScoreMetaData(data[getFQN(COLUMN_NAMES.score_type)], data[getFQN(COLUMN_NAMES.score_meta_data)]) as metaDataList"
          >
            <ng-container *ngIf="isNestedMetaData(data[getFQN(COLUMN_NAMES.score_type)], metaDataList[0].name); else indicatorDetails">
              <!-- STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_SUMMARY.CARBONBLACKALERTS -->
              <!-- STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_SUMMARY.PERSISTENTCRITICALCVES -->
              <!-- STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_SUMMARY.RISKYSETTINGS -->
              <span
                [dpaInnerHtmlLight]="
                  'STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_SUMMARY.' + data[getFQN(COLUMN_NAMES.score_type)].toUpperCase()
                    | translate : { count: metaDataList.length }
                "
                class="ml-x1"
              ></span>
              <ul class="list mt-x2">
                <!-- STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_SUMMARY.CARBONBLACKALERTS.* -->
                <!-- STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_SUMMARY.PERSISTENTCRITICALCVES.* -->
                <!-- STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM.RISKYSETTINGS.* -->
                <li
                  *ngFor="let metaDataItem of metaDataList; trackBy: metaDataTracker"
                  [dpaInnerHtmlLight]="
                    'STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM.' + metaDataItem.name.toUpperCase()
                      | translate : metaDataItem.meta_data
                  "
                ></li>
              </ul>
            </ng-container>
            <ng-template #indicatorDetails>
              <!--
                STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.APPCOLLECTOR
                STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.COMPDOWNLOAD
                STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.EXCESSIVECRITICALCVES
                STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.LAGGUPDATE
                STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.RAREAPPCOLLECTOR
                STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.UNUSUALAPP
              -->
              <div
                [dpaInnerHtmlLight]="
                  'STANDARD_DASHBOARD.USER_RISK.RISK_INDICATOR_DETAILS.' + data[getFQN(COLUMN_NAMES.score_type)].toUpperCase()
                    | translate : metaDataList[0].meta_data
                "
              ></div>
              <ng-container *ngIf="metaDataList[0].meta_data.nested_details">
                <ul class="list mt-x2">
                  <li
                    *ngFor="let metaDataItem of metaDataList[0].meta_data.nested_details; trackBy: metaDataTracker"
                    [dpaInnerHtmlLight]="
                      'STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM.' +
                        metaDataList[0].name.toUpperCase() +
                        '.' +
                        metaDataItem.name.toUpperCase() | translate : metaDataItem
                    "
                  ></li>
                </ul>
                <dpa-tooltip
                  *ngIf="metaDataList[0].meta_data.item_count > metaDataList[0].meta_data.nested_details.length"
                  [tooltipText]="'STANDARD_DASHBOARD.USER_RISK.MORE_ITEMS_TOOLTIP' | translate"
                >
                  <div class="more-items mt-x2">
                    {{
                      'STANDARD_DASHBOARD.USER_RISK.MORE_ITEMS'
                        | translate : { more: metaDataList[0].meta_data.item_count - metaDataList[0].meta_data.nested_details.length }
                    }}
                  </div>
                </dpa-tooltip>
              </ng-container>
            </ng-template>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<ng-template #loading>
  <div class="spinner-container">
    <div class="spinner spinner-sm"></div>
  </div>
</ng-template>
<ng-template #noIndicators>
  <em class="p3">
    {{ 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate }}
  </em>
</ng-template>
