/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import { AdminRoleType } from './admin-role-type.enum';
import { enumConverterFactory } from './converters';

import { dateFormatConverter } from './converters/date-format.converter';
import { RoleName } from './role-name.enum';
import { RoleScopeSet } from './role-scope-set.model';
import { UserDetails } from './user-details.model';

/**
 * AccountRole
 * @export
 * @class AccountRole
 */
@Serializable
export class AccountRole {
  @JsonProperty('id')
  public roleId: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public _label: string = undefined;

  @JsonProperty('description')
  public _description: string = undefined;

  @JsonProperty('description_text')
  public i18nDescription: string = undefined;

  @JsonProperty('service_type')
  public serviceType: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'role_type', customConverter: enumConverterFactory(AdminRoleType) })
  public roleType: AdminRoleType = undefined;

  @JsonProperty('assigned_accounts_count')
  public assignedAccountsCount: number = undefined;

  @JsonProperty('dap_compliant')
  public dapCompliant: boolean = undefined;

  @JsonProperty({ name: 'scope_sets', cls: RoleScopeSet })
  public scopeSet: RoleScopeSet[] = undefined;

  @JsonProperty('external_prerequisite_role')
  public externalPrerequisiteRole: boolean = undefined;

  /**
   * Creates an instance of AccountRole.
   * @param {Array<Partial<AccountRole>>} args - object initialization arguments
   * @memberof AccountRole
   */
  constructor(...args: Array<Partial<AccountRole>>) {
    Object.assign(this, ...args);
  }

  /**
   * getter for description
   * @readonly
   * @type {string}
   * @memberof AccountRole
   */
  public get description(): string {
    return this._description ?? this.i18nDescription;
  }

  /**
   * setter for description
   * @readonly
   * @type {string}
   * @memberof AccountRole
   */
  public set description(description: string) {
    this._description = description;
  }

  /**
   * getter for label
   * @readonly
   * @type {string}
   * @memberof AccountRole
   */
  public get label(): string {
    return this._label || this.name;
  }

  /**
   * setter for label
   * @memberof AccountRole
   */
  public set label(label: string) {
    this._label = label;
  }

  /**
   * getNameLowercase
   * @returns {string}
   * @memberof AccountRole
   */
  public getNameLowercase(): string {
    return this.name.toLowerCase();
  }
}

/**
 * accountRolesConverter
 * @export
 * @type {CustomConverter}
 */
export const accountRolesConverter: CustomConverter = {
  fromJson(data: object[] = []): AccountRole[] {
    const roles: AccountRole[] = data.map((role: AccountRole) => deserialize(AccountRole, role));
    sortRoles(roles);
    return roles;
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * sortRoles
 * @export
 * @param {AccountRole[]} roles
 */
export function sortRoles(roles: AccountRole[]) {
  roles.sort((a: AccountRole, b: AccountRole) => {
    return RoleName[a.name] - RoleName[b.name];
  });
}
