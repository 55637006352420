/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { omit } from 'lodash-es';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DownloadService, Endpoint, HttpService } from '@ws1c/intelligence-common';
import {
  GenericSearchRequest,
  IntegrationActionsSearchResponse,
  IntegrationDetailView,
  IntegrationHealthReport,
  IntegrationObjectsCounts,
  IntegrationObjectsSearchResponse,
  IntegrationObjectsType,
  IntegrationReprovisioningStatusResponse,
  IntegrationReprovisionStatus,
} from '@ws1c/intelligence-models';

/**
 * Provide access to Integration API data
 * @export
 * @class IntegrationService
 */
@Injectable()
export class IntegrationService {
  /**
   * Creates an instance of IntegrationService.
   * @param {DownloadService} downloadService
   * @param {HttpService} http
   * @memberof IntegrationService
   */
  constructor(private downloadService: DownloadService, private http: HttpService) {}

  /**
   * Deauthorizes integration for a given integration id
   *
   * @param {string} id - integration id
   * @returns {Observable<boolean>}
   * @memberof IntegrationService
   */
  public deAuthorizeById(id: string): Observable<boolean> {
    return this.http.put(Endpoint.INTEGRATION_ID_DISABLE(id), null).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getFullUrl
   * @param {string} route
   * @returns {string}
   * @memberof IntegrationService
   */
  public getFullUrl(route: string): string {
    return this.http.getFullUrl(route);
  }

  /**
   * getReauthorizeRedirectUrl
   *
   * @param {string} id
   * @returns {string}
   * @memberof IntegrationService
   */
  public getReauthorizeRedirectUrl(id: string): string {
    return this.getFullUrl(Endpoint.IAM_INGEGRATION_ID_REAUTHORIZE(id));
  }

  /**
   * getRedirectUrl
   *
   * @param {string} integration
   * @returns {string}
   * @memberof IntegrationService
   */
  public getRedirectUrl(integration: string): string {
    return this.getFullUrl(Endpoint.IAM_SETUP(integration));
  }

  /**
   * downloadCredential
   *
   * @param {string} integration
   * @returns {Observable<GenericObject>}
   * @memberof IntegrationService
   */
  public downloadCredential(integration: string): Observable<GenericObject> {
    return this.http
      .post(Endpoint.INTEGRATION_DOWNLOAD_CREDENTIAL, {
        integration,
      })
      .pipe(
        map((response: GenericObject) => {
          // Requested to remove created_by for download by Rekha
          response = omit(response, ['data.oauth_client.created_by']);
          this.downloadService.downloadTextFile(JSON.stringify(response), `${integration}.credentials.json`);
          return response.data;
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * deactivateCredential
   *
   * @param {string} integration
   * @param {string} sourceSystemId
   * @returns {Observable<boolean>}
   * @memberof IntegrationService
   */
  public deactivateCredential(integration: string, sourceSystemId: string): Observable<boolean> {
    return this.http.put(Endpoint.INTEGRATION_DEACTIVATE_CREDENTIAL(integration, sourceSystemId), {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * reactivateCredential
   *
   * @param {string} integration
   * @param {string} sourceSystemId
   * @returns {Observable<GenericObject>}
   * @memberof IntegrationService
   */
  public reactivateCredential(integration: string, sourceSystemId: string): Observable<GenericObject> {
    return this.http.put(Endpoint.INTEGRATION_REACTIVATE_CREDENTIAL(integration, sourceSystemId), {}).pipe(
      map((response: GenericObject) => {
        // Requested to remove created_by for download by Rekha
        response = omit(response, ['data.oauth_client.created_by']);
        this.downloadService.downloadTextFile(JSON.stringify(response), `${integration}.credentials.json`);
        return response.data;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Does integration opt in for a given integration id
   *
   * @param {string} id - integration id
   * @returns {Observable<boolean>}
   * @memberof IntegrationService
   */
  public optIntoIntegrationById(id: string): Observable<boolean> {
    return this.http.put(Endpoint.INTEGRATION_ID_OPT_IN(id), null).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Does integration opt out for a given integration id
   *
   * @param {string} id - integration id
   * @returns {Observable<boolean>}
   * @memberof IntegrationService
   */
  public optOutOfIntegrationById(id: string): Observable<boolean> {
    return this.http.put(Endpoint.INTEGRATION_ID_OPT_OUT(id), null).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccessIntegrationReprovisioningStatus
   * Get integration reprovisioning status of tenants for an org
   * @returns {Observable<IntegrationReprovisionStatus[]>}
   * @memberof IntegrationService
   */
  public getAccessIntegrationReprovisioningStatus(): Observable<IntegrationReprovisionStatus[]> {
    return this.http.get(Endpoint.REPROVISION_STATUS).pipe(
      map((response: GenericObject) => deserialize(IntegrationReprovisioningStatusResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get integration health status
   * @param {IntegrationDetailView} integrationDetailView
   * @returns {Observable<IntegrationHealthReport>}
   * @memberof IntegrationService
   */
  public getIntegrationHealthStatus(integrationDetailView: IntegrationDetailView): Observable<IntegrationHealthReport> {
    return this.http.get(Endpoint.INTEGRATIONS_HEALTH(integrationDetailView.integration)).pipe(
      map((response: GenericObject) => deserialize(IntegrationHealthReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get integration tenant health status
   * @param {IntegrationDetailView} integrationDetailView
   * @returns {Observable<IntegrationHealthReport>}
   * @memberof IntegrationService
   */
  public getIntegrationTenantHealthStatus(integrationDetailView: IntegrationDetailView): Observable<IntegrationHealthReport> {
    return this.http.get(Endpoint.INTEGRATIONS_TENANT_HEALTH(integrationDetailView.integration, integrationDetailView.tenantId)).pipe(
      map((response: GenericObject) => deserialize(IntegrationHealthReport, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get gem integration tenant health status
   * @param {IntegrationDetailView} integrationDetailView
   * @returns {Observable<IntegrationHealthReport>}
   * @memberof IntegrationService
   */
  public getGemIntegrationTenantHealthStatus(integrationDetailView: IntegrationDetailView): Observable<IntegrationHealthReport> {
    return this.http
      .get(
        Endpoint.INTEGRATIONS_GEM_TENANT_HEALTH(
          integrationDetailView.integration,
          integrationDetailView.sourceSystemId,
          integrationDetailView.tenantId,
        ),
      )
      .pipe(
        map((response: GenericObject) => deserialize(IntegrationHealthReport, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Get integration objects counts
   * @param {string} integration
   * @returns {Observable<IntegrationObjectsCounts>}
   * @memberof IntegrationService
   */
  public getIntegrationObjectsCounts(integration: string): Observable<IntegrationObjectsCounts> {
    return this.http.get(Endpoint.INTEGRATIONS_OBJECTS_COUNTS(integration)).pipe(
      map((response: GenericObject) => deserialize(IntegrationObjectsCounts, response.data.object_count)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get integration objects
   * @param {string} integration
   * @param {IntegrationObjectsType} integrationObject
   * @param {GenericSearchRequest} request
   * @returns {Observable<IntegrationObjectsSearchResponse>}
   * @memberof IntegrationService
   */
  public getIntegrationObjects(
    integration: string,
    integrationObject: IntegrationObjectsType,
    request: GenericSearchRequest,
  ): Observable<IntegrationObjectsSearchResponse> {
    return this.http.post(Endpoint.INTEGRATIONS_OBJECTS(integration, integrationObject), request).pipe(
      map((response: GenericObject) => deserialize(IntegrationObjectsSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get integration actions
   * @param {string} integration
   * @param {GenericSearchRequest} request
   * @returns {Observable<IntegrationActionsSearchResponse>}
   * @memberof IntegrationService
   */
  public getIntegrationActions(integration: string, request: GenericSearchRequest): Observable<IntegrationActionsSearchResponse> {
    return this.http.post(Endpoint.INTEGRATIONS_ACTIONS(integration), request).pipe(
      map((response: GenericObject) => deserialize(IntegrationActionsSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}
