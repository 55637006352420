/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { QuickFilterService } from '@ws1c/intelligence-core/services';
import { QuickFilterActions } from '@ws1c/intelligence-core/store/quick-filter/quick-filter.actions';
import { Tag } from '@ws1c/intelligence-models';

/**
 * QuickFilterEffects
 * @export
 * @class QuickFilterEffects
 */
@Injectable()
export class QuickFilterEffects {
  public getTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickFilterActions.getTags),
      switchMap(({ quickFilterTagType }: ReturnType<typeof QuickFilterActions.getTags>) =>
        this.quickFilterService.getTags(quickFilterTagType).pipe(
          map((tags: Tag[]) => QuickFilterActions.setTags({ tags })),
          catchError(() => of(QuickFilterActions.setTags({ tags: [] }))),
        ),
      ),
    ),
  );

  /**
   * Creates an instance of QuickFilterEffects.
   * @param {Actions} actions$
   * @param {QuickFilterService} quickFilterService
   * @memberof QuickFilterEffects
   */
  constructor(private actions$: Actions, private quickFilterService: QuickFilterService) {}
}
