/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * ThresholdAttribute
 * @export
 * @enum {string}
 */
export enum ThresholdAttribute {
  AVG_APP_LATENCY_MILLIS_EXPERIENCESCORE_MOBILE_APP = 'AVG_APP_LATENCY_MILLIS_EXPERIENCESCORE_MOBILE_APP',
  APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP = 'APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP',
  APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP_MACOS = 'APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP_MACOS',
  APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS = 'APP_CPU_USAGE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS',
  APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP = 'APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_APP',
  APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_SESSION = 'APP_CPU_USAGE_HORIZON_EXPERIENCESCORE_SESSION',
  APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP = 'APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP',
  APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS = 'APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS',
  APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS = 'APP_CRASH_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS',
  APP_CRASH_RATE_EXPERIENCESCORE_MOBILE_APP = 'APP_CRASH_RATE_EXPERIENCESCORE_MOBILE_APP',
  APP_CRASH_RATE_HORIZON_EXPERIENCESCORE_APP = 'APP_CRASH_RATE_HORIZON_EXPERIENCESCORE_APP',
  APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP = 'APP_GPU_USAGE_EXPERIENCESCORE_DESKTOP_APP',
  APP_HANDLED_EXCEPTIONS_EXPERIENCESCORE_MOBILE_APP = 'APP_HANDLED_EXCEPTIONS_EXPERIENCESCORE_MOBILE_APP',
  APP_HANG_COUNT_EXPERIENCESCORE_DESKTOP_APP = 'APP_HANG_COUNT_EXPERIENCESCORE_DESKTOP_APP',
  APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP = 'APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP',
  APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP = 'APP_HANG_RATE_HORIZON_EXPERIENCESCORE_APP',
  APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS = 'APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_MACOS',
  APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS = 'APP_HANG_RATE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS',
  APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP = 'APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP',
  APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP_MACOS = 'APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP_MACOS',
  APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS = 'APP_MEMORY_USAGE_EXPERIENCESCORE_DESKTOP_APP_WINDOWS',
  APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP = 'APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_APP',
  APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_SESSION = 'APP_MEMORY_USAGE_HORIZON_EXPERIENCESCORE_SESSION',
  APP_NET_ERROR_RATE_EXPERIENCESCORE_MOBILE_APP = 'APP_NET_ERROR_RATE_EXPERIENCESCORE_MOBILE_APP',
  APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP = 'APP_UI_UNRESPONSIVE_RATE_EXPERIENCESCORE_DESKTOP_APP',
  AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP = 'AVERAGE_APP_UI_UNRESPONSIVE_DURATION_EXPERIENCESCORE_DESKTOP_APP',
  // eslint-disable-next-line max-len
  AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP = 'AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE_EXPERIENCESCORE_DESKTOP_APP',
  AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE = 'AVERAGE_BOOT_TIME_MILLIS_EXPERIENCESCORE_DEVICE',
  AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE = 'AVERAGE_SHUTDOWN_TIME_MILLIS_EXPERIENCESCORE_DEVICE',
  BATTERY_HEALTH_EXPERIENCESCORE_DEVICE = 'BATTERY_HEALTH_EXPERIENCESCORE_DEVICE',
  CPU_HEALTH_EXPERIENCESCORE_DEVICE = 'CPU_HEALTH_EXPERIENCESCORE_DEVICE',
  CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION = 'CPU_PERCENT_HORIZON_EXPERIENCESCORE_SESSION',
  CRASH_COUNT_EXPERIENCESCORE_DEVICE = 'CRASH_COUNT_EXPERIENCESCORE_DEVICE',
  CRASH_COUNT_EXPERIENCESCORE_DEVICE_WINDOWS = 'CRASH_COUNT_EXPERIENCESCORE_DEVICE_WINDOWS',
  CRASH_COUNT_EXPERIENCESCORE_DEVICE_MACOS = 'CRASH_COUNT_EXPERIENCESCORE_DEVICE_MACOS',
  DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE = 'DEVICE_TEMPERATURE_EXPERIENCESCORE_DEVICE',
  DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION = 'DISK_IOPS_HORIZON_EXPERIENCESCORE_SESSION',
  DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'DISK_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION = 'DOT11_SIGNAL_QUALITY_HORIZON_EXPERIENCESCORE_SESSION',
  DOWNLOAD_SPEED_EXPERIENCESCORE_DEVICE = 'DOWNLOAD_SPEED_EXPERIENCESCORE_DEVICE',
  GPU_HEALTH_EXPERIENCESCORE_DEVICE = 'GPU_HEALTH_EXPERIENCESCORE_DEVICE',
  LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'LAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  LAN_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION = 'LAN_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION',
  LOGON_DURATION_HORIZON_EXPERIENCESCORE_SESSION = 'LOGON_DURATION_HORIZON_EXPERIENCESCORE_SESSION',
  MEMORY_HEALTH_EXPERIENCESCORE_DEVICE = 'MEMORY_HEALTH_EXPERIENCESCORE_DEVICE',
  MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION = 'MEMORY_PERCENT_HORIZON_EXPERIENCESCORE_SESSION',
  PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION = 'PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION',
  PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'PROTOCOL_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  UPLOAD_SPEED_EXPERIENCESCORE_DEVICE = 'UPLOAD_SPEED_EXPERIENCESCORE_DEVICE',
  USERS_WITH_POOR_SCORE_PERCENT_EXPERIENCESCORE_ORG = 'USERS_WITH_POOR_SCORE_PERCENT_EXPERIENCESCORE_ORG',
  USERS_WITH_POOR_SCORE_PERCENT_HORIZON_EXPERIENCESCORE_ORG = 'USERS_WITH_POOR_SCORE_PERCENT_HORIZON_EXPERIENCESCORE_ORG',
  WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION = 'WAN_AVERAGE_LATENCY_HORIZON_EXPERIENCESCORE_SESSION',
  WAN_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION = 'WAN_PACKET_LOSS_RATE_HORIZON_EXPERIENCESCORE_SESSION',
  WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION = 'WIFI_SIGNAL_STRENGTH_HORIZON_EXPERIENCESCORE_SESSION',
  WIFI_STRENGTH_EXPERIENCESCORE_DEVICE = 'WIFI_STRENGTH_EXPERIENCESCORE_DEVICE',
  // Horizon Infra Monitoring Thresholds
  CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'CONNECTION_COUNT_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'CPU_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'CPU_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  // eslint-disable-next-line max-len
  DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  // eslint-disable-next-line max-len
  DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'DAYS_BEFORE_CERTIFICATE_EXPIRATION_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'MEMORY_USAGE_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'MEMORY_USAGE_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
  SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'SESSION_COUNT_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER = 'STATUS_HORIZON_EXPERIENCESCORE_CONNECTION_SERVER',
  STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE = 'STATUS_HORIZON_EXPERIENCESCORE_UAG_APPLIANCE',
}
