/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable, serialize } from '@dpa/ui-common';

import { DeemPlaybookComponentDetails } from '@ws1c/intelligence-models';
import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { Trend } from '@ws1c/intelligence-models/dashboard/trend.model';
import { PreviewReportContentRequest } from '@ws1c/intelligence-models/preview-report-content-request.model';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';
import { DeemPlaybookStepItemType } from './deem-playbook-step-item-type.enum';

/**
 * componentDetailsConverter
 * @type {CustomConverter}
 */
const componentDetailsConverter: CustomConverter = {
  fromJson(data: GenericObject, instance: DeemPlaybookStepItem): DeemPlaybookComponentDetails {
    if (instance?.type === DeemPlaybookStepItemType.WIDGET) {
      return deserialize(Trend, data);
    }
    if (instance?.type === DeemPlaybookStepItemType.REPORT) {
      return deserialize(PreviewReportContentRequest, data);
    }
    return undefined;
  },
  toJson(data: DeemPlaybookComponentDetails): GenericObject {
    return serialize(data);
  },
};

/**
 * DeemPlaybookStepItem
 * @export
 * @class DeemPlaybookStepItem
 * @extends {UserTouchDetails}
 */
@Serializable
export class DeemPlaybookStepItem extends UserTouchDetails {
  @JsonProperty({ name: 'component_type', customConverter: enumConverterFactory(DeemPlaybookStepItemType) })
  public type: DeemPlaybookStepItemType = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('use_incident_filter')
  public useIncidentFilter: boolean = undefined;

  @JsonProperty({ name: 'component_details', customConverter: componentDetailsConverter })
  public componentDetails: DeemPlaybookComponentDetails = undefined;

  @JsonProperty('url')
  public url: string = undefined;

  @JsonProperty('display_text')
  public displayText: string = undefined;

  @JsonProperty('embedded_iframe')
  public embeddedIframe: string = undefined;

  /**
   * constructor
   * @param {Partial<DeemPlaybookStepItem>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStepItem>>) {
    super();
    Object.assign(this, ...args);
  }
}
