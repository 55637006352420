/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { flatten, values } from 'lodash-es';

import { Device } from '@ws1c/intelligence-models/device.model';
import { Integration } from '@ws1c/intelligence-models/integration.model';

/**
 * deviceIndexFlattener
 * @export
 * @type {CustomConverter}
 */
export const deviceIndexFlattener: CustomConverter = {
  fromJson(data: object): Device[] {
    const flattenedData = flatten(values(data));
    const devices: Device[] = [];
    flattenedData.forEach((trend: GenericObject) => {
      const integration = Object.keys(trend.trend_definition.entities_by_integration)[0];
      switch (integration) {
        case Integration.EMPLOYEE_EXPERIENCE:
          trend.trend_results.forEach((result: GenericObject) => {
            if (result.counters[0].result.value) {
              const [{ value: id }, { value: platform }, { value: name }, { value: model }, { value: serialNumber }] =
                result.bucketing_attributes;
              devices.push(new Device({ id, platform, name, model, serialNumber, integration }));
            }
          });
          break;
        case Integration.APTELIGENT:
          trend.trend_results.forEach((result: GenericObject) => {
            if (result.counters[0].result.value) {
              const [{ value: id }, { value: platform }, { value: name }, { value: model }] = result.bucketing_attributes;
              devices.push(new Device({ id, platform, name, model, integration }));
            }
          });
          break;
      }
    });
    return devices;
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * DeviceSearchResponse
 * @export
 * @class DeviceSearchResponse
 */
@Serializable
export class DeviceSearchResponse {
  @JsonProperty({ name: 'data', customConverter: deviceIndexFlattener })
  public data: Device[] = undefined;
}
