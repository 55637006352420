<ng-container *ngIf="loading; else chartTemplate">
  <dpa-timeout-loader [skeletonClassName]="chartTypeClassName"></dpa-timeout-loader>
</ng-container>
<ng-template #chartTemplate>
  <ng-container *ngIf="isDataPresent(); else noData">
    <ng-container [ngSwitch]="activeChartType">
      <dpa-bubble-chart
        *ngSwitchCase="CHART_TYPE.BUBBLE"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-bubble-chart>
      <dpa-unstacked-line-chart
        *ngSwitchCase="CHART_TYPE.LINE"
        [ngxChart]="ngxChart"
        [overlayChart]="overlayChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [isRanged]="isRangedData"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="!isInvertMode"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        [showTimeline]="showTimeline"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [yAxisTicks]="yAxisTicks"
        [markers]="markers"
        [markerTooltipTemplate]="markerTooltipTemplate"
        [yScaleMax]="yScaleMax"
        [yScaleMin]="yScaleMin"
        [trimXAxisTicks]="trimXAxisTicks"
        [focussedSequence]="focussedSequence"
        [overlayTooltipTemplate]="overlayTooltip"
        (onViewDetails)="onDrilldown($event)"
        (onMarkerClick)="handleMarkerClick($event)"
      ></dpa-unstacked-line-chart>
      <dpa-unstacked-line-chart
        *ngSwitchCase="CHART_TYPE.LINE_SPARK"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [isSpark]="true"
        [showYAxis]="!isInvertMode"
        [rotateXAxisTicks]="rotateXAxisTicks || ngxChart?.trendMode === TREND_MODE[TREND_MODE.SNAPSHOT]"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        [showTimeline]="showTimeline"
        [markers]="markers"
        [markerTooltipTemplate]="markerTooltipTemplate"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-unstacked-line-chart>
      <dpa-line-chart
        *ngSwitchCase="CHART_TYPE.AREA"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showTimeline]="showTimeline"
        [chartCurveType]="chartCurveType"
        [markers]="markers"
        [markerTooltipTemplate]="markerTooltipTemplate"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-line-chart>
      <dpa-vertical-chart
        *ngIf="activeChartType === CHART_TYPE.VERTICAL || activeChartType === CHART_TYPE.VERTICAL_GROUP"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [overlayChart]="overlayChart"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showXAxisLabel]="showXAxisLabel"
        [rotateXAxisTicks]="rotateXAxisTicks || ngxChart?.trendMode === TREND_MODE[TREND_MODE.SNAPSHOT]"
        [showDataLabel]="showDataLabel"
        [yScaleMax]="yScaleMax"
        [focussedSequence]="focussedSequence"
        [chartType]="activeChartType"
        [overlayTooltipTemplate]="overlayTooltip"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-vertical-chart>
      <dpa-horizontal-chart
        *ngIf="activeChartType === CHART_TYPE.HORIZONTAL || activeChartType === CHART_TYPE.HORIZONTAL_GROUP"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showYAxisLabel]="showYAxisLabel"
        [showDataLabel]="showDataLabel"
        [xAxisLabel]="xAxisLabel"
        [chartType]="activeChartType"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-horizontal-chart>
      <dpa-donut-chart
        *ngSwitchCase="CHART_TYPE.DONUT"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [stackedTooltip]="stackedTooltip || percentStackedToolTip"
        [activeSeries]="activeSeries"
        [showAllAvailableLabels]="showAllAvailableLabels"
        [showLabels]="showLabels"
        [totalHeaderKey]="totalHeaderKey"
        [totalHeaderValue]="totalHeaderValue"
        [totalHeaderClass]="totalHeaderClass"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-donut-chart>
      <dpa-metric-chart
        *ngSwitchCase="CHART_TYPE.METRIC"
        [ngxChart]="ngxChart"
      ></dpa-metric-chart>
      <dpa-table-chart
        *ngSwitchCase="CHART_TYPE.TABLE"
        [ngxChart]="ngxChart"
        [tableColumnNames]="tableColumnNames"
        [tableColumnLabelsByName]="tableColumnLabelsByName"
        [tableCellTemplatesByName]="tableCellTemplatesByName"
        [showDetailView]="showDetailView"
        [detailHeaderTemplate]="detailHeaderTemplate"
        [detailBodyTemplate]="detailBodyTemplate"
        [selectable]="selectable"
        [enablePagination]="enablePagination"
        [tableSettings]="tableSettings"
        [expandedRowTemplate]="tableExpandedRowTemplate"
        [mergeColumns]="mergeColumns"
        [showTableFilter]="showTableFilter"
        [isCountersClickable]="isCountersClickable"
        [columnWidthByKey]="columnWidthByKey"
        (selectedChange)="onSelectedChange($event)"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-table-chart>
      <dpa-heat-map-chart
        *ngSwitchCase="CHART_TYPE.HEAT_MAP"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-heat-map-chart>
      <dpa-tree-map-chart
        *ngSwitchCase="CHART_TYPE.TREE_MAP"
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [labelFormatting]="labelFormatting"
        [valueFormatting]="valueFormatting"
        [showSameSizeCell]="showSameSizeCell"
        (onViewDetails)="onDrilldown($event)"
      ></dpa-tree-map-chart>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngIf="!loading">
  <dpa-legend
    *ngIf="isLegendVisible; else noBucketingColor"
    [ngxChart]="ngxChart"
    [focusedSeries]="focusedSeries"
    [activeSeries]="activeSeries"
    [isExploreMode]="isExploreMode"
    [availableColumns]="availableColumns"
    [colorizedColumn]="colorizedColumn"
    (clearFocusedSeries)="clearFocusedSeries()"
    (selectedColumnChange)="onSelectColorizedColumn($event)"
    (selectSeries)="onSelectSeries($event)"
    (activateSeries)="onActivateSeries($event)"
    (deactivateSeries)="onDeactivateSeries($event)"
  ></dpa-legend>
  <ng-template #noBucketingColor>
    <dpa-legend-item
      *ngIf="overlayChart && showLegend"
      [selected]="true"
      [color]="ngxChart?.noBucketingColor"
      [name]="ngxChart?.labels?.counter"
      class="overlay-legend"
    ></dpa-legend-item>
  </ng-template>
  <ng-container *ngIf="overlayChart && showLegend">
    <div class="overlay-legend-title bold pl-x1">{{ 'WIDGET_CUSTOMIZE.OVERLAY_LEGEND' | translate }}</div>
    <dpa-legend
      *ngIf="isOverlayLegendVisible; else overlayNoBucketingColor"
      [ngxChart]="overlayChart"
      [isExploreMode]="false"
    ></dpa-legend>
    <ng-template #overlayNoBucketingColor>
      <dpa-legend-item
        [selected]="true"
        [color]="overlayChart?.noBucketingColor"
        [name]="overlayChart?.labels?.counter"
        class="overlay-legend"
      ></dpa-legend-item>
    </ng-template>
  </ng-container>
</ng-container>
<ng-template
  #defaultStackedToolTip
  let-ngxModel="ngxModel"
  let-tooltip="tooltip"
>
  <div class="tool-tip-container">
    <div *ngIf="tooltip.rollingDateBucket">
      {{
        'COMMON_MESSAGES.START_TO_END_TIME'
          | translate : { start: tooltip.rollingDateBucket.startDateStr, end: tooltip.rollingDateBucket.endDateStr }
      }}
    </div>
    <div
      *ngFor="let filter of tooltip.filters"
      class="key-value-pair"
    >
      <div class="key-label">
        <span>{{ filter.keyLabel }}</span>
        <cds-icon
          class="filter-arrow ml-x1"
          size="18"
          shape="arrow"
          direction="right"
        ></cds-icon>
      </div>
      <div class="value-label pl-x2">
        {{ filter.value }}
      </div>
    </div>
    <div
      *ngIf="tooltip.tableGroupedByLabel"
      class="key-value-pair"
    >
      <div class="key-label">{{ 'WIDGET_CUSTOMIZE.GROUPED_BY' | translate }}:</div>
      <div class="value-label pl-x2">
        {{ tooltip.tableGroupedByLabel }}
      </div>
    </div>
    <div class="sub-group-container">
      <div
        *ngFor="let keyValuePair of tooltip.tableData | slice : 0 : maxTooltipItems"
        class="key-value-pair"
      >
        <span class="key-label">{{ $any(keyValuePair).keyLabel }}:</span>
        <span class="value-label pl-x2">
          {{ $any(keyValuePair).value }}
        </span>
      </div>
      <div *ngIf="tooltipItemsOverMax(tooltip.tableData) as remainingItems">
        <em>{{ 'WIDGET_CUSTOMIZE.CLICK_TO_VIEW_N_MORE' | translate : { n: remainingItems } }}</em>
      </div>
    </div>

    <div class="key-value-pair">
      <span class="key-label">{{ 'COMMON_MESSAGES.TOTAL' | translate }}:</span>
      <span
        *ngIf="isRangedData"
        class="value-label pl-x2"
      >
        {{ ngxChart.labels?.counterFormatter(tooltip.counterValue) }}
      </span>
      <span
        *ngIf="!isRangedData"
        class="value-label pl-x2"
      >
        {{ tooltip.counterFormattedValue }}
      </span>
    </div>
    <div
      *ngIf="isRangedData"
      class="key-value-pair"
    >
      <span class="key-label">{{ 'COMMON_MESSAGES.RANGE' | translate }}:</span>
      <span class="value-label pl-x2">
        {{ tooltip.counterFormattedValue }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <div class="no-data-container">
    <dpa-no-search-results
      [message]="noDataMessage || 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
      [tooltipText]="noDataTooltip"
    ></dpa-no-search-results>
  </div>
</ng-template>

<ng-template
  #seriesTooltip
  let-tooltipItems="tooltipItems"
  let-usingFakeGroupBy="usingFakeGroupBy"
>
  <div class="series-tooltip-container">
    <div *ngIf="tooltipItems && tooltipItems[0]?.name">
      {{ tooltipItems[0].name }}
    </div>
    <div
      *ngFor="let tooltipItem of sortFilterTooltipItems(tooltipItems) | slice : 0 : maxTooltipItems"
      class="tooltip-item"
    >
      <div
        *ngIf="showSeriesNames && !usingFakeGroupBy"
        class="series-label"
      >
        <span
          [style.background-color]="$any(tooltipItem).color"
          class="tooltip-item-color"
        ></span>
        <span>{{ $any(tooltipItem).series }}:</span>
      </div>
      <span class="value-label pl-x2">
        <span *ngIf="isRangedData">{{ 'COMMON_MESSAGES.TOTAL' | translate }}:</span>
        {{ ngxChart.labels?.counterFormatter($any(tooltipItem).value) }}
        <div *ngIf="isRangedData">
          <ng-container>
            {{ 'COMMON_MESSAGES.RANGE' | translate }}:
            {{ ngxChart.labels?.counterFormatter($any(tooltipItem).min) }}
            -
            {{ ngxChart.labels?.counterFormatter($any(tooltipItem).max) }}
          </ng-container>
        </div>
      </span>
    </div>
    <div *ngIf="tooltipItemsOverMax(tooltipItems) as remainingItems">
      {{ 'COMMON_MESSAGES.N_MORE_DOT_DOT' | translate : { n: remainingItems } }}
    </div>
    <div
      *ngIf="tooltipItems?.length > 1 && !hideSeriesTooltipSubtotal"
      class="key-value-pair"
    >
      <span class="key-label">
        {{ 'COMMON_MESSAGES.SUBTOTAL' | translate }}
      </span>
      <span class="value-label pl-x2">
        {{ ngxChart.labels?.totalCountFormatter(tooltipItems) }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template
  #percentStackedToolTip
  let-ngxModel="ngxModel"
  let-tooltip="tooltip"
  let-total="total"
>
  <div class="tool-tip-container">
    <div *ngIf="tooltip.rollingDateBucket">
      {{
        'COMMON_MESSAGES.START_TO_END_TIME'
          | translate : { start: tooltip.rollingDateBucket.startDateStr, end: tooltip.rollingDateBucket.endDateStr }
      }}
    </div>
    <div
      *ngFor="let filter of tooltip.filters"
      class="key-value-pair"
    >
      <div class="key-label">
        <span>{{ filter.keyLabel }}</span>
        <cds-icon
          class="filter-arrow ml-x1"
          size="18"
          shape="arrow"
          direction="right"
        ></cds-icon>
      </div>
      <div class="value-label pl-x2">
        {{ filter.value }}
        <span>({{ tooltip.counterValue / total | percent : '1.0' }})</span>
      </div>
    </div>
    <div
      *ngIf="tooltip.tableGroupedByLabel"
      class="key-value-pair"
    >
      <div class="key-label">{{ 'WIDGET_CUSTOMIZE.GROUPED_BY' | translate }}:</div>
      <div class="value-label pl-x2">
        {{ tooltip.tableGroupedByLabel }}
      </div>
    </div>
    <div class="sub-group-container">
      <div
        *ngFor="let keyValuePair of tooltip.tableData | slice : 0 : maxTooltipItems"
        class="key-value-pair"
      >
        <span class="key-label">{{ $any(keyValuePair).keyLabel }}:</span>
        <span class="value-label pl-x2">
          {{ $any(keyValuePair).value }}
        </span>
      </div>
      <div *ngIf="tooltipItemsOverMax(tooltip.tableData) as remainingItems">
        <em>{{ 'WIDGET_CUSTOMIZE.CLICK_TO_VIEW_N_MORE' | translate : { n: remainingItems } }}</em>
      </div>
    </div>

    <div class="key-value-pair">
      <span class="key-label">
        {{ 'COMMON_MESSAGES.SUBTOTAL' | translate }}
      </span>
      <span class="value-label pl-x2">
        {{
          'COMMON_MESSAGES.SUB_TOTAL_OF_GRAND_TOTAL' | translate : { subTotal: tooltip.counterValue | number, grandTotal: total | number }
        }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template
  #overlayTooltip
  let-tooltipItems="tooltipItems"
  let-seriesName="seriesName"
>
  <div class="series-tooltip-container overlay-tooltip-container">
    <div *ngIf="seriesName">{{ 'COMMON_MESSAGES.DATE' | translate }} &rightarrow; {{ seriesName }}</div>
    <div
      *ngFor="let tooltipItem of tooltipItems; trackBy: trackByName"
      class="overlay-tooltip"
    >
      <div
        *ngIf="tooltipItem?.series?.length === 1; else groupByTemplate"
        class="tooltip-item"
      >
        <ng-container *ngLet="tooltipItem?.series[0] as seriesItem">
          <div class="series-label">
            <span
              [style.background-color]="seriesItem.color"
              class="tooltip-item-color"
            ></span>
            <span class="main-label">{{ tooltipItem.name }}:</span>
          </div>
          <span class="value-label pl-x2">
            {{ seriesItem.value }}
          </span>
        </ng-container>
      </div>
      <ng-template #groupByTemplate>
        <div class="main-label">{{ tooltipItem.name }}</div>
        <div class="tooltip-item">
          <div class="series-label">
            <span>{{ 'COMMON_MESSAGES.TOTAL' | translate }}:</span>
          </div>
          <span class="value-label pl-x2">
            {{ tooltipItem.total }}
          </span>
        </div>
        <div
          *ngFor="let seriesItem of tooltipItem.series | slice : 0 : maxTooltipItems; trackBy: trackByName"
          class="tooltip-item"
        >
          <div class="series-label">
            <span
              [style.background-color]="$any(seriesItem).color"
              class="tooltip-item-color"
            ></span>
            <span>{{ $any(seriesItem).name }}:</span>
          </div>
          <span class="value-label pl-x2">
            {{ $any(seriesItem).value }}
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
