/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';
import { each, flatten, get, isEmpty, isEqual, isNull, isString, isUndefined, reduce } from 'lodash-es';

import { ActionData, AutomationAction } from '@ws1c/intelligence-models/automation/automation-action.model';
import { ActionTemplate } from '@ws1c/intelligence-models/connection/action-template.model';
import { AutomationRunHistoryStatus } from '@ws1c/intelligence-models/connector/automation-run-history-status.interface';
import { ConnectorActionSection } from '@ws1c/intelligence-models/connector/connector-action-section.model';
import { JsonSchemaMetadata } from '@ws1c/intelligence-models/connector/json-schema-metadata.model';
import { JsonSchemaNode } from '@ws1c/intelligence-models/connector/json-schema-node.interface';
import { JsonSchemaType } from '@ws1c/intelligence-models/connector/json-schema-type.enum';
import { TrendResult } from '@ws1c/intelligence-models/dashboard/trend-result.model';
import { Trend } from '@ws1c/intelligence-models/dashboard/trend.model';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { MetaFormFieldPresentationType } from '@ws1c/intelligence-models/meta-form/meta-form-field-presentation-type.enum';
import { MetaFormField } from '@ws1c/intelligence-models/meta-form/meta-form-field.model';
import { MetaFormSection } from '@ws1c/intelligence-models/meta-form/meta-form-section.model';
import { MetaFormType } from '@ws1c/intelligence-models/meta-form/meta-form-type.enum';
import { MetaForm } from '@ws1c/intelligence-models/meta-form/meta-form.model';
import { FieldsMetaForm, SectionsMetaForm } from '@ws1c/intelligence-models/meta-form/meta-form.types';

export let helpers; // eslint-disable-line prefer-const

/**
 * isTemplateValue
 *
 * @param {string} value
 * @returns {boolean}
 */
function isTemplateValue(value: string): boolean {
  return isString(value) && /^\$\{.+\}$/.test(value);
}

/**
 * isNumberDataType
 *
 * @export
 * @param {string} dataType
 * @returns {boolean}
 */
export function isNumberDataType(dataType: string): boolean {
  return [DataType.INTEGER, DataType.LONG, DataType.FLOAT, DataType.DOUBLE].includes(DataType[dataType]);
}

/**
 * isIntegerDataType
 *
 * @export
 * @param {string} dataType
 * @returns {boolean}
 */
export function isIntegerDataType(dataType: string): boolean {
  return [DataType.INTEGER, DataType.LONG].includes(DataType[dataType]);
}

/**
 * isDataTypeValueValid
 *
 * @export
 * @param {MetaFormField} field
 * @param {any} value
 * @returns {boolean}
 */
export function isDataTypeValueValid(field: MetaFormField, value: any): boolean {
  const { dataType, useTemplate } = field;
  if (dataType?.toUpperCase() === DataType[DataType.BOOLEAN]) {
    // any value is allowed, undefined will be converted to false
    return true;
  }
  if (isNumberDataType(dataType)) {
    if (isNull(value) || value === '') {
      return false;
    }
    const isNumeric = !isNaN(value);
    if (!isNumeric && useTemplate) {
      return isTemplateValue(value);
    }
    return isNumeric;
  }
  if ([DataType[DataType.STRINGLIST], DataType[DataType.NUMBERLIST]].includes(dataType)) {
    return !!value?.length;
  }
  return !!value;
}

/**
 * isValueAllowed
 *
 * @export
 * @param {MetaFormField} metaFormField
 * @param {any} value
 * @returns {boolean}
 */
export function isValueAllowed(metaFormField: MetaFormField, value: any): boolean {
  if (!metaFormField.required) {
    return true;
  }
  // assume there is a valid default value
  if (metaFormField.presentationType === MetaFormFieldPresentationType.HIDDEN) {
    return true;
  }
  return isDataTypeValueValid(metaFormField, value);
}

/**
 * isSchemaValueAllowed
 *
 * @export
 * @param {ConnectorActionSection} section
 * @param {JsonSchemaNode} currentField
 * @param {string} fieldKey
 * @param {any} value
 * @returns {boolean}
 */
export function isSchemaValueAllowed(section: ConnectorActionSection, currentField: JsonSchemaNode, fieldKey: string, value: any): boolean {
  if (!section.schema.required?.includes(fieldKey)) {
    return true;
  }
  const fieldMetadata = section.metadata?.find((metadata: JsonSchemaMetadata) => metadata.scope === currentField.$anchor);
  if (currentField.type === JsonSchemaType.ARRAY) {
    return currentField.items[0].required.every((requiredField: string) => value?.[0][requiredField]);
  }
  if (fieldMetadata?.presentationType === MetaFormFieldPresentationType.HIDDEN) {
    return true;
  }
  return isDataTypeValueValid(
    new MetaFormField({
      dataType: currentField.type,
      useTemplate: fieldMetadata?.templateAllowed,
    }),
    value,
  );
}

/**
 * convertMetaFormFields
 *
 * @export
 * @param {AutomationAction} automationAction
 * @param {MetaFormField[]} [fields=[]]
 * @returns {MetaFormField[]}
 */
export function convertMetaFormFields(automationAction: AutomationAction, fields: MetaFormField[] = []): MetaFormField[] {
  return fields.reduce((list: MetaFormField[], field: MetaFormField) => {
    const value = automationAction?.actionData?.settings[field.name];
    if (isDataTypeValueValid(field, value)) {
      list.push({
        ...field,
        value,
      } as MetaFormField);
    }
    return list;
  }, []);
}

/**
 * convertMetaFormFieldsForTesting
 *
 * @export
 * @param {AutomationAction} automationAction
 * @param {MetaFormField[]} [fields=[]]
 * @returns {Array<Partial<MetaFormField>>}
 */
export function convertMetaFormFieldsForTesting(
  automationAction: AutomationAction,
  fields: MetaFormField[] = [],
): Array<Partial<MetaFormField>> {
  return convertMetaFormFields(automationAction, fields).map(
    (field: MetaFormField) =>
      ({
        name: field.name,
        value: field.value,
      } as Partial<MetaFormField>),
  );
}

/**
 * convertMetaFormSections
 *
 * @export
 * @param {AutomationAction} automationAction
 * @param {MetaFormSection[]} sections
 * @returns {Record<string, MetaFormField[]>}
 */
export function convertMetaFormSections(automationAction: AutomationAction, sections: MetaFormSection[]): Record<string, MetaFormField[]> {
  return sections.reduce((acc, section) => {
    acc[section.type] = section.fields.reduce((list: MetaFormField[], field: MetaFormField) => {
      const sectionData = get(automationAction, `actionData.settingsBySection.${section.type}`, {});
      const key = field.scope?.split('/').splice(2)?.join('.') ?? field.name;
      const value = get(sectionData, key);
      if (isDataTypeValueValid(field, value)) {
        list.push({
          ...field,
          value,
        });
      }
      return list;
    }, []);
    return acc;
  }, {});
}

/**
 * convertMetaFormSectionsForTesting
 *
 * @export
 * @param {AutomationAction} automationAction
 * @param {MetaFormSection[]} [sections=[]]
 * @returns {Record<string, MetaFormField[]>}
 */
export function convertMetaFormSectionsForTesting(
  automationAction: AutomationAction,
  sections: MetaFormSection[] = [],
): Record<string, MetaFormField[]> {
  const convertedSections = convertMetaFormSections(automationAction, sections);
  return Object.keys(convertedSections).reduce((result: GenericObject, sectionKey: string) => {
    result[sectionKey] = convertedSections[sectionKey].map(
      (field): Pick<MetaFormField, 'name' | 'value'> => ({
        name: field.name,
        value: field.value,
      }),
    );
    return result;
  }, {});
}

/**
 * getMetaFormSettingsFromFormGroup
 *
 * @export
 * @param {UntypedFormGroup} formGroup
 * @returns {(Pick<ActionData, 'settings' | 'settingsBySection'>)}
 */
export function getMetaFormSettingsFromFormGroup(formGroup: UntypedFormGroup): Pick<ActionData, 'settings' | 'settingsBySection'> {
  const rawValue = formGroup.getRawValue();
  return helpers.packSettings(get(rawValue, MetaFormType.FIELDS, {}), get(rawValue, MetaFormType.SECTIONS));
}

/**
 * getSettingsFromFormsBySectionType
 *
 * @export
 * @param {Record<string, UntypedFormGroup>} formsBySectionType
 * @returns {(Pick<ActionData, 'settingsBySection'>)}
 */
export function getSettingsFromFormsBySectionType(
  formsBySectionType: Record<string, UntypedFormGroup>,
): Pick<ActionData, 'settingsBySection'> {
  const settingsBySection = {};
  Object.keys(formsBySectionType).forEach((sectionType: string) => {
    settingsBySection[sectionType] = getValidRawValues(formsBySectionType[sectionType].getRawValue());
  });
  return {
    settingsBySection,
  };
}

/**
 * getMergedFormForSectionType
 * @export
 * @param {Record<string, UntypedFormGroup>} formsBySectionType
 * @param {string} sectionType
 * @param {UntypedFormGroup} form
 * @returns {UntypedFormGroup}
 */
export function getMergedFormForSectionType(
  formsBySectionType: Record<string, UntypedFormGroup>,
  sectionType: string,
  form: UntypedFormGroup,
): UntypedFormGroup {
  if (formsBySectionType[sectionType] && !isEqual(formsBySectionType[sectionType].value, form.value)) {
    // Merge forms of same sectionType
    const controls: Record<string, UntypedFormControl> = {};
    addFormControls(controls, formsBySectionType[sectionType].controls);
    addFormControls(controls, form.controls);
    return new UntypedFormGroup(controls);
  }
  return form;
}

/**
 * addFormControls
 * @param {Record<string, UntypedFormControl>} controls
 * @param {GenericObject} existingFormControls
 */
function addFormControls(controls: Record<string, UntypedFormControl>, existingFormControls: GenericObject) {
  each(existingFormControls, (control: UntypedFormControl, key: string) => {
    controls[key] = new UntypedFormControl(control.value, { validators: control.validator });
  });
}

/**
 * getValidRawValues
 * @param {GenericObject} formRawValue
 * @returns {GenericObject}
 */
function getValidRawValues(formRawValue: GenericObject): GenericObject {
  const validRawValues = {};
  each(formRawValue, (value: any, key: string) => {
    if (typeof value === 'string' || value === null) {
      validRawValues[key] = value || undefined;
      return;
    }
    validRawValues[key] = value;
  });
  return validRawValues;
}

/**
 * getFlattenConnectorActionSections
 *
 * @export
 * @param {Record<string, ConnectorActionSection[]>} sections
 * @returns {ConnectorActionSection[]}
 */
export function getFlattenConnectorActionSections(sections: Record<string, ConnectorActionSection[]>): ConnectorActionSection[] {
  return flatten(Object.values(sections ?? {}));
}

/**
 * getDynamicFormValuesFromAutomationAction
 *
 * @export
 * @param {AutomationAction} action
 * @returns {GenericObject}
 */
export function getDynamicFormValuesFromAutomationAction(action: AutomationAction): GenericObject {
  if (!action) {
    return {};
  }
  const { actionData } = action;

  let formValues = {
    ...actionData?.settings,
  };
  if (actionData?.settingsBySection) {
    Object.keys(actionData.settingsBySection).forEach((sectionType: string) => {
      formValues = {
        ...formValues,
        ...actionData.settingsBySection[sectionType],
      };
    });
  }
  return formValues;
}

/**
 * packSettings
 *
 * @export
 * @param {any} settings
 * @param {(any | undefined)} settingsBySection
 * @returns {(Pick<ActionData, 'settings' | 'settingsBySection'>)}
 */
export function packSettings(settings: any, settingsBySection: any | undefined): Pick<ActionData, 'settings' | 'settingsBySection'> {
  if (isEmpty(settingsBySection)) {
    return {
      settings,
    } as Pick<ActionData, 'settings' | 'settingsBySection'>;
  }
  return {
    settings,
    settingsBySection,
  };
}

/**
 * getMetaFormSettingsFromTemplate
 *
 * @export
 * @param {ActionTemplate} template
 * @returns {(Pick<ActionData, 'settings' | 'settingsBySection'>)}
 */
export function getMetaFormSettingsFromTemplate(template: ActionTemplate): Pick<ActionData, 'settings' | 'settingsBySection'> {
  const settings = reduce(
    template.fields,
    (data: GenericObject, field: MetaFormField): GenericObject => {
      if (!isUndefined(field.defaultValue)) {
        data[field.name] = field.defaultValue;
      }
      return data;
    },
    {},
  );

  const settingsBySection =
    hasSections(template) &&
    template.sections.reduce((fieldsBySection: GenericObject, section: MetaFormSection) => {
      fieldsBySection[section.type] = reduce(
        section.fields,
        (data: GenericObject, field: MetaFormField) => {
          if (!isUndefined(field.defaultValue)) {
            data[field.name] = field.defaultValue;
          }
          return data;
        },
        {},
      );
      return fieldsBySection;
    }, {});

  return packSettings(settings, settingsBySection);
}

/**
 * hasSections - check that the sections property exists and has data
 *
 * @export
 * @param {(ActionTemplate | FieldsMetaForm | MetaForm | SectionsMetaForm | undefined)} template
 * @returns {template is SectionsMetaForm}
 */
export function hasSections(
  template: ActionTemplate | FieldsMetaForm | MetaForm | SectionsMetaForm | undefined,
): template is SectionsMetaForm {
  return get(template, 'sections', []).length > 0;
}

/**
 * hasFields - check that the fields property exists and has data
 *
 * @export
 * @param {(ActionTemplate | FieldsMetaForm | MetaForm | SectionsMetaForm | undefined)} template
 * @returns {template is FieldsMetaForm}
 */
export function hasFields(template: ActionTemplate | FieldsMetaForm | MetaForm | SectionsMetaForm | undefined): template is FieldsMetaForm {
  return get(template, 'fields', []).length > 0 || !hasSections(template);
}

/**
 * getRunHistoryForId
 *
 * @export
 * @param {string} automationId
 * @param {Trend} trend
 * @returns {AutomationRunHistoryStatus}
 */
export function getRunHistoryForId(automationId: string, trend: Trend): AutomationRunHistoryStatus | undefined {
  if (!trend?.trendResults?.length) {
    return;
  }

  const trendResultsForAutomation: TrendResult[] = trend.trendResults.filter((trendResult: TrendResult) => {
    return trendResult.bucketingAttributes[0].value === automationId;
  });

  const result: AutomationRunHistoryStatus = trendResultsForAutomation.reduce(
    (acc: AutomationRunHistoryStatus, trendResult: TrendResult) => {
      acc[trendResult.bucketingAttributes[1].value] = trendResult.getFirstCounterValue();
      return acc;
    },
    {},
  );

  return isEmpty(result) ? null : result;
}

helpers = {
  convertMetaFormFields,
  convertMetaFormFieldsForTesting,
  convertMetaFormSections,
  convertMetaFormSectionsForTesting,
  getDynamicFormValuesFromAutomationAction,
  getFlattenConnectorActionSections,
  getMetaFormSettingsFromFormGroup,
  getMetaFormSettingsFromTemplate,
  getRunHistoryForId,
  getSettingsFromFormsBySectionType,
  getMergedFormForSectionType,
  hasFields,
  hasSections,
  isDataTypeValueValid,
  isNumberDataType,
  isSchemaValueAllowed,
  isIntegerDataType,
  isValueAllowed,
  packSettings,
};
