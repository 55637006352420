<input
  [placeholder]="selectedValues.length === 0 ? ('COMMON_ACTIONS.ENTER_VALUE' | translate) : ''"
  [ngModel]="query"
  (ngModelChange)="searchValues($event)"
  (focus)="onInputFocus()"
  (blur)="onInputBlur()"
  class="clr-input tree-filter-input"
  type="text"
  autocomplete="off"
/>

<div
  *ngIf="isOpen && orgHierarchy"
  [class.align-right]="dropdownAlignRight"
  (mousedown)="$event.preventDefault()"
  (click)="$event.stopPropagation()"
  (keyup.enter)="$event.stopPropagation()"
  class="tree-dropdown"
>
  <div
    *ngIf="getDisplayRoot()"
    class="treeview-item-container"
  >
    <clr-alert
      *ngIf="this.query && this.query.length < MIN_CHAR_FOR_SEARCH; else displayTree"
      clrAlertType="info"
      [clrAlertClosable]="false"
    >
      <clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ 'COMMON_MESSAGES.MIN_CHAR_SEARCH_INFO' | translate : { minChars: MIN_CHAR_FOR_SEARCH } }}
        </span>
      </clr-alert-item>
    </clr-alert>
    <ng-template #displayTree>
      <div
        *ngTemplateOutlet="
          itemTemplate;
          context: { item: getDisplayRoot(), onItemSelectedChange: onItemSelectedChange, selectedValuesIndex: selectedValuesIndex }
        "
      ></div>
    </ng-template>
  </div>
  <div
    *ngIf="!getDisplayRoot()"
    class="no-results-container"
  >
    <div class="no-results-message">
      {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_NO_VALUE_FOUND' | translate }}
    </div>
  </div>
</div>

<ul
  *ngIf="selectedValues.length > 0 && showSelectedOrgs"
  class="list-unstyled"
>
  <li *ngFor="let selectedValue of selectedValues.slice(0, 3)">
    <div>
      <span class="label">
        <span>{{ treeNodesById[selectedValue]?.text }}</span>
        <a
          (click)="removeSelectedValue(selectedValue)"
          class="badge badge-clear"
          aria-label="Close"
          aria-hidden="true"
        >
          <cds-icon shape="times"></cds-icon>
        </a>
      </span>
    </div>
  </li>
  <li *ngIf="selectedValues.length > 3">
    <div class="signpost-item">
      <div class="trigger-item">
        <clr-signpost>
          <button
            clrSignpostTrigger
            class="btn label"
          >
            {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_ITEMS_MORE' | translate : { count: selectedValues.length - 3 } }}
          </button>
          <clr-signpost-content
            *clrIfOpen
            [clrPosition]="SIGNPOST_POSITION"
          >
            <ul>
              <li
                *ngFor="let selectedValue of selectedValues.slice(3)"
                class="label"
              >
                <span>{{ treeNodesById[selectedValue]?.text }}</span>
                <a
                  (click)="removeSelectedValue(selectedValue)"
                  class="badge badge-clear"
                  aria-label="Close"
                  aria-hidden="true"
                >
                  <cds-icon shape="times"></cds-icon>
                </a>
              </li>
            </ul>
          </clr-signpost-content>
        </clr-signpost>
      </div>
    </div>
  </li>
</ul>

<ng-template
  #itemTemplate
  let-item="item"
  let-onItemSelectedChange="onItemSelectedChange"
  let-selectedValuesIndex="selectedValuesIndex"
>
  <div class="form-group tree-row">
    <cds-icon
      [class.visible]="item.children.length"
      [class.collapsed]="!item.expanded"
      (click)="item.expanded = !item.expanded"
      class="caret-icon clickable"
      shape="angle"
      aria-hidden="true"
    ></cds-icon>
    <div
      (click)="toggleNodeSelected(item)"
      (keyup.enter)="toggleNodeSelected(item)"
      class="checkbox-container clickable"
    >
      <input
        [ngModel]="!!selectedValuesIndex[item.id]"
        class="tree-row-checkbox"
        type="checkbox"
      />
      <span class="tree-row-label">
        {{ item.text }}
      </span>
    </div>
  </div>
  <div
    *ngIf="item.expanded"
    class="treeview-child-container"
  >
    <ng-container *ngFor="let child of item.children">
      <div
        *ngTemplateOutlet="
          itemTemplate;
          context: { item: child, onItemSelectedChange: onItemSelectedChange, selectedValuesIndex: selectedValuesIndex }
        "
      ></div>
    </ng-container>
  </div>
</ng-template>
