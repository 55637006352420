/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

/**
 * Org Metric Status
 *
 * @export
 * @enum {number}
 */
export enum OrgMetricStatus {
  OK,
  WARN,
  INFO,
  ERROR,
}
