/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GenericObject, LabelValue } from '@dpa/ui-common';

// eslint-disable-next-line max-len
import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';

/**
 * RadioFormFieldComponent
 * @export
 * @class RadioFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-radio-form-field',
  templateUrl: 'radio-form-field.component.html',
  styleUrls: ['radio-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioFormFieldComponent extends BaseFormFieldComponent {
  @Input() public enumList?: LabelValue[];

  /**
   * onRadioChange
   * @param {GenericObject} event
   * @memberof RadioFormFieldComponent
   */
  public onRadioChange(event: GenericObject) {
    const value: any = event.target?.value ?? '';
    if (value) {
      this.formControl.patchValue(value === 'true' || value === 'false' ? JSON.parse(value) : value);
    }
  }
}
