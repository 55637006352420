/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@ws1c/intelligence-common/constants';
import { CookiesService } from './cookies.service';

/**
 * Wrapper for HttpClient Service
 * Inject CookiesService to be able to access API_URL from cookies
 *
 * @export
 * @class HttpService
 * @extends {HttpClient}
 */
@Injectable()
export class HttpService extends HttpClient {
  /**
   * Creates an instance of HttpService.
   * @param {HttpHandler} httpHandler
   * @param {CookiesService} cookiesService
   * @memberof HttpService
   */
  constructor(protected httpHandler: HttpHandler, private cookiesService: CookiesService) {
    super(httpHandler);
  }

  /**
   * Performs a request with `get` http method.
   *
   * @param {string} url
   * @param {any} [options]
   * @returns {Observable<any>}
   * @memberof HttpService
   */
  public get(url: string, options?: any): Observable<any> {
    return super.get(this.getFullUrl(url), options);
  }

  /**
   * Performs a request with `post` http method.
   *
   * @param {string} url
   * @param {*} body
   * @param {any} [options]
   * @returns {Observable<any>}
   * @memberof HttpService
   */
  public post(url: string, body: any, options?: any): Observable<any> {
    return super.post(this.getFullUrl(url), body, options);
  }

  /**
   * Performs a request with `put` http method.
   *
   * @param {string} url
   * @param {*} body
   * @param {any} [options]
   * @returns {Observable<any>}
   * @memberof HttpService
   */
  public put(url: string, body: any, options?: any): Observable<any> {
    return super.put(this.getFullUrl(url), body, options);
  }

  /**
   * Performs a request with `patch` http method.
   *
   * @param {string} url
   * @param {*} body
   * @param {any} [options]
   * @returns {Observable<any>}
   * @memberof HttpService
   */
  public patch(url: string, body: any, options?: any): Observable<any> {
    return super.patch(this.getFullUrl(url), body, options);
  }

  /**
   * Performs a request with `delete` http method.
   *
   * @param {string} url
   * @param {any} [options]
   * @returns {Observable<any>}
   * @memberof HttpService
   */
  public delete(url: string, options?: any): Observable<any> {
    return super.delete(this.getFullUrl(url), options);
  }

  /**
   * Build API url.
   *
   * @param {string} url
   * @returns {string}
   * @memberof HttpService
   */
  public getFullUrl(url: string): string {
    const apiUrl = this.cookiesService.getCookie(AppConfig.API_URL);
    return apiUrl ? apiUrl + url : url;
  }
}
