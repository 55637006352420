/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { BaseTemplateSearchComponent } from '@ws1c/intelligence-core/components/template-search/base-template-search.component';
import { ConnectorCommonActions, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store';
import { Column, COLUMN_NAMES, DataType, Template, TEMPLATE_TYPE } from '@ws1c/intelligence-models';

/**
 * WorkflowTemplateSearchComponent
 * @export
 * @class WorkflowTemplateSearchComponent
 * @extends {BaseTemplateSearchComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-workflow-template-search',
  templateUrl: 'workflow-template-search.component.html',
  styleUrls: ['workflow-template-search.component.scss'],
})
export class WorkflowTemplateSearchComponent extends BaseTemplateSearchComponent implements OnInit {
  public isAutomationEnabled$: Observable<boolean>;

  /**
   * Creates an instance of WorkflowTemplateSearchComponent.
   * @param {Store} store
   * @param {I18NService} i18nService
   * @memberof WorkflowTemplateSearchComponent
   */
  constructor(public store: Store, public i18nService: I18NService) {
    super(store, i18nService, TEMPLATE_TYPE.AUTOMATIONS);
    this.isAutomationEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isAutomationEnabled);
    this.tableColumns = [
      new Column({
        name: COLUMN_NAMES.byName.label,
        label: this.i18nService.translate('TEMPLATE.WORKFLOW_NAME'),
        dataType: DataType[DataType.STRING],
        className: 'dpa-dg-col-lg',
      }),
      new Column({
        name: COLUMN_NAMES.byName.description,
        label: this.i18nService.translate('TEMPLATE.WORKFLOW_DESCRIPTION'),
        dataType: DataType[DataType.STRING],
        className: '',
      }),
      new Column({
        name: COLUMN_NAMES.byName.actions,
        label: this.i18nService.translate('COMMON_MESSAGES.TAGS'),
        className: 'clr-justify-content-end dpa-dg-col-lg',
      }),
    ];
  }

  /**
   * ngOnInit
   * @memberof WidgetTemplateSearchComponent
   */
  public ngOnInit() {
    super.ngOnInit();
    this.store.dispatch(ConnectorCommonActions.loadAllManagedConnectors());
    this.sub.add(
      this.isAutomationEnabled$.subscribe((isAutomationEnabled: boolean) => {
        this.actions = this.customActions ?? [
          {
            label: this.i18nService.translate('COMMON_ACTIONS.PREVIEW'),
            onClick: this.onPreviewTemplate.bind(this),
          },
          {
            label: this.i18nService.translate('COMMON_ACTIONS.START'),
            isDisabled: (template: Template) => template.isStartActionDisabled,
            isHidden: () => !isAutomationEnabled,
            onClick: this.onSelectTemplate.bind(this),
          },
        ];
      }),
    );
  }
}
