/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, Self } from '@angular/core';

import { PanelComponent } from '@ws1c/intelligence-common/components';

/**
 * PanelsComponent
 * @export
 * @class PanelsComponent
 */
@Component({
  selector: 'dpa-panels',
  templateUrl: 'panels.component.html',
  styleUrls: ['panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      // Use `EnclosingPanelsComponent` custom provider to avoid circular reference between panels and panel components
      provide: 'EnclosingPanelsComponent',
      useFactory: () => {
        return {
          onPanelExpand: (_panel: PanelComponent) => {},
        };
      },
    },
  ],
})
export class PanelsComponent {
  @Input() public closeOthers = false;
  @Output() public activePanel = new EventEmitter<PanelComponent>();

  /**
   * Creates an instance of PanelsComponent.
   * @param {{ onPanelExpand: (panel: PanelComponent) => void }} panelsComponent
   * @memberof PanelsComponent
   */
  constructor(@Self() @Inject('EnclosingPanelsComponent') public panelsComponent: { onPanelExpand: (panel: PanelComponent) => void }) {
    // override onPanelExpand method on the provider
    this.panelsComponent.onPanelExpand = (panel: PanelComponent) => {
      this.onPanelExpand(panel);
    };
  }

  /**
   * onPanelExpand
   * @param {PanelComponent} panel
   * @memberOf PanelsComponent
   */
  public onPanelExpand(panel: PanelComponent) {
    if (!this.closeOthers) {
      return;
    }

    this.activePanel.emit(panel);
  }
}
