/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Inject, InjectionToken, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { AppConfig, I18NService } from '@ws1c/intelligence-common';
import { IntegrationMetaSelectors, TemplateCommonActions, TemplateCommonSelectors } from '@ws1c/intelligence-core/store';
import {
  Column,
  COLUMN_NAMES,
  IntegrationCategories,
  IntroType,
  Tag,
  TAGS_TYPE,
  Template,
  TEMPLATE_TYPE,
  TemplateAction,
  TemplateBookmark,
} from '@ws1c/intelligence-models';

const TYPE = new InjectionToken<string>('TYPE');

/**
 * BaseTemplateSearchComponent
 * @export
 * @abstract
 * @class BaseTemplateSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  template: '',
})
export abstract class BaseTemplateSearchComponent implements OnInit, OnDestroy {
  @Input() public showCustomButton?: boolean = false;
  @Input() public showOnlyCustomTemplates?: boolean;
  @Input() public customActions?: TemplateAction[];
  @Input() public customDropdownActions?: TemplateAction[];
  @Output() public clickCustomTemplate: EventEmitter<void> = new EventEmitter<void>();
  @Output() public previewTemplate: EventEmitter<Template> = new EventEmitter<Template>();
  @Output() public selectTemplate: EventEmitter<Template> = new EventEmitter<Template>();

  public isTemplatesLoading$: Observable<boolean>;
  public isBookmarkingInProgress$: Observable<boolean>;
  public templates$: Observable<Template[]>;
  public sub: Subscription = new Subscription();
  public tags: Tag[];

  public actions: TemplateAction[] = [];
  public dropdownActions: TemplateAction[] = [];
  public tableColumns: Column[] = [];
  public additionalTags: Tag[] = [];

  public readonly TRIAL_DAYS = AppConfig.TRIAL_DAYS;
  public readonly IntroType = IntroType;

  /**
   * Creates an instance of BaseTemplateSearchComponent.
   * @param {Store} store
   * @param {I18NService} i18nService
   * @param {TEMPLATE_TYPE} templateType
   * @memberof BaseTemplateSearchComponent
   */
  constructor(public store: Store, public i18nService: I18NService, @Inject(TYPE) public templateType: TEMPLATE_TYPE) {
    this.isTemplatesLoading$ = this.store.select(TemplateCommonSelectors.isTemplatesLoading(this.templateType));
    this.isBookmarkingInProgress$ = this.store.select(TemplateCommonSelectors.isTemplateBookmarkingInProgress);
  }

  /**
   * ngOnInit
   * @memberof BaseTemplateSearchComponent
   */
  public ngOnInit() {
    this.actions = this.customActions ?? this.actions;
    this.dropdownActions = this.customDropdownActions ?? this.dropdownActions;
    this.templates$ = this.store.select(TemplateCommonSelectors.getVisibleTemplates(this.templateType, this.showOnlyCustomTemplates));
    this.store.dispatch(TemplateCommonActions.loadTemplates({ templateType: this.templateType }));
    this.store.dispatch(TemplateCommonActions.getTags());
    this.sub.add(
      combineLatest([
        this.store.select(IntegrationMetaSelectors.getTemplateIntegrationCategories(this.templateType)),
        this.store.select(TemplateCommonSelectors.getVisibleTemplateTags(this.templateType)),
      ]).subscribe(([integrationCategories, tags]: [IntegrationCategories[], Tag[]]) => {
        this.tags = [...tags, ...this.getIntegrationTag(integrationCategories), ...this.additionalTags];
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof BaseTemplateSearchComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * onPreviewTemplate
   * @param {Template} template
   * @memberof BaseTemplateSearchComponent
   */
  public onPreviewTemplate(template: Template) {
    this.previewTemplate.emit(template);
  }

  /**
   * onSelectTemplate
   * @param {Template} template
   * @memberof BaseTemplateSearchComponent
   */
  public onSelectTemplate(template: Template) {
    this.selectTemplate.emit(template);
  }

  /**
   * onClickCustomTemplate
   * @memberof BaseTemplateSearchComponent
   */
  public onClickCustomTemplate() {
    this.clickCustomTemplate.emit();
  }

  /**
   * onToggleBookmark
   * @param {TemplateBookmark} templateBookmark
   * @memberof BaseTemplateSearchComponent
   */
  public onToggleBookmark(templateBookmark: TemplateBookmark) {
    this.store.dispatch(TemplateCommonActions.toggleTemplateBookmark({ templateType: this.templateType, templateBookmark }));
  }

  /**
   * getIntegrationTag
   * @param {IntegrationCategories[]} integrationCategories
   * @returns {Tag[]}
   * @memberof BaseTemplateSearchComponent
   */
  public getIntegrationTag(integrationCategories: IntegrationCategories[]): Tag[] {
    const subtags = integrationCategories?.map((item: IntegrationCategories) => item.integration);
    if (!subtags?.length) {
      return [];
    }
    return [
      new Tag({
        name: COLUMN_NAMES.byName.integration,
        label: this.i18nService.translate('SETTINGS.INTEGRATION'),
        type: TAGS_TYPE.INTEGRATION,
        subtags,
      }),
    ];
  }
}
