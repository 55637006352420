/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GenericObject, PagedRequest, PagedResponse, SortOn } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { isEmpty, isEqual, uniqueId } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { TableChartConfig } from '@ws1c/dashboard-common/const';
import { CoreAppState, DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import { ChartDrilldownEvent, Column, DataType, LocalDataGridSettings, NgxChart, NgxTrendResultFlattener } from '@ws1c/intelligence-models';

/**
 * TableChartComponent
 * @export
 * @class TableChartComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-table-chart',
  templateUrl: 'table-chart.component.html',
  styleUrls: ['table-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableChartComponent implements OnChanges, OnInit {
  @Input() public ngxChart: NgxChart;
  @Input() public tableColumnNames: string[];
  @Input() public tableColumnLabelsByName: Record<string, string> = {};
  @Input() public tableCellTemplatesByName: Record<string, TemplateRef<any>> = {};
  @Input() public showDetailView?: boolean = false;
  @Input() public detailHeaderTemplate?: TemplateRef<any>;
  @Input() public detailBodyTemplate?: TemplateRef<any>;
  @Input() public expandedRowTemplate?: TemplateRef<any>;
  @Input() public selectable?: { enabled: boolean; single: boolean } = TableChartConfig.selectableDefault;
  @Input() public enablePagination?: boolean = true;
  @Input() public tableSettings?: Partial<LocalDataGridSettings> = {};
  @Input() public mergeColumns?: Record<string, string[]>;
  @Input() public showTableFilter?: boolean = true;
  @Input() public isCountersClickable?: boolean = true;
  @Input() public columnWidthByKey?: { [key: string]: number } = {};
  @Output() public onViewDetails = new EventEmitter<ChartDrilldownEvent>();
  @Output() public selectedChange: EventEmitter<GenericObject[]> = new EventEmitter();

  @ViewChild('counterTemplate', { static: true }) public counterTemplate: TemplateRef<any>;

  public availableColumns: Column[];
  public tableData: PagedResponse;
  public formattersByColumnName: Record<string, (trendResultValue, flatTrendResult) => string>;
  public filterSearchKeys: string[];
  public cellTemplatesByColumnValue: Record<string, TemplateRef<any>> = {};

  // Used to retrieve and store localDataGridSettings in dashboard state
  public settingsId: string = uniqueId();
  public localDataGridSettings$: Observable<LocalDataGridSettings>;

  public readonly COUNTER_KEY = NgxTrendResultFlattener.COUNTER_KEY;
  public readonly DATATYPE = DataType;
  private columnsToRemove: Set<string>;
  private mergedColumns: string[];
  private counterKeysMap: Record<string, string>;

  /**
   * constructor
   * @memberof TableChartComponent
   * @param {Store<CoreAppState>} store
   */
  constructor(private store: Store<CoreAppState>) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TableChartComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.ngxChart || changes.tableColumnLabelsByName) {
      this.setTableData(this.ngxChart);
    }
    if (changes.tableCellTemplatesByName) {
      this.setCellTemplatesByColumnValue();
    }
    if (!isEmpty(changes.tableSettings)) {
      this.onLocalDataGridSettingsChange(this.getDefaultLocalDataGridSettings());
    }
  }

  /**
   * ngOnInit
   * @memberof TableChartComponent
   */
  public ngOnInit() {
    this.setCellTemplatesByColumnValue();

    // depends on this.tableSettings, need to wait for init
    this.localDataGridSettings$ = this.store.select(DashboardSelectors.getLocalDataGridSettingsById).pipe(
      map((localDataGridSettingsById: Record<string, LocalDataGridSettings>) => {
        return localDataGridSettingsById[this.settingsId] || this.getDefaultLocalDataGridSettings();
      }),
      distinctUntilChanged(isEqual),
    );
  }

  /**
   * getDefaultLocalDataGridSettings
   * @returns {LocalDataGridSettings}
   * @memberof TableChartComponent
   */
  public getDefaultLocalDataGridSettings(): LocalDataGridSettings {
    return new LocalDataGridSettings({
      filter: '',
      sortOns: [],
      pagedRequest: new PagedRequest({
        from: 0,
        size: 25,
      }),
      ...this.tableSettings,
    });
  }

  /**
   * setCellTemplatesByColumnValue
   * @memberof TableChartComponent
   */
  public setCellTemplatesByColumnValue() {
    this.cellTemplatesByColumnValue = {
      [NgxTrendResultFlattener.COUNTER_KEY]: this.counterTemplate,
      ...this.tableCellTemplatesByName,
    };
  }

  /**
   * setTableData
   * @param {NgxChart} ngxChart
   * @memberof TableChartComponent
   */
  public setTableData(ngxChart: NgxChart) {
    this.counterKeysMap = this.ngxChart.getCounterKeysMap();
    const columnNamesWithDateKey = this.getTableColumnNames(ngxChart);
    this.availableColumns = columnNamesWithDateKey
      .map((columnName: string) => {
        // Swap out the date groupBy with the startMilli groupBy for non-string sorting
        // formattersByColumnName will swap back to the formatted date value for displaying and filtering
        const columnNameWithReplacedDateKey =
          columnName === NgxTrendResultFlattener.DATE_KEY ? NgxTrendResultFlattener.START_DRILLDOWN_KEY : columnName;

        const columnLabel = this.tableColumnLabelsByName[columnName] || this.ngxChart.labels.byFlatKey[columnName];
        return new Column({
          name: columnNameWithReplacedDateKey,
          label: columnLabel,
          dataType: this.getDataType(this.ngxChart.indices.dataTypesByBucketingAttributeKey[columnName]),
          dataUnit: this.ngxChart.indices.dataUnitsByBucketingAttributeKey[columnName],
        });
      })
      .filter(Boolean);
    this.tableData = {
      results: this.parseColumnResults(),
    } as PagedResponse;
    this.formattersByColumnName = {
      [NgxTrendResultFlattener.START_DRILLDOWN_KEY]: (val: any, flatTrendResult: any) => {
        return flatTrendResult[NgxTrendResultFlattener.START_STR] === flatTrendResult[NgxTrendResultFlattener.END_STR]
          ? flatTrendResult[NgxTrendResultFlattener.DATE_KEY]
          : `${flatTrendResult[NgxTrendResultFlattener.START_STR]} - ${flatTrendResult[NgxTrendResultFlattener.END_STR]}`;
      },
    };
    this.filterSearchKeys = this.availableColumns.map((column: Column) => column.name);
  }

  /**
   * getTableColumnNames
   * @param {NgxChart} ngxChart
   * @returns {string[]}
   * @memberof TableChartComponent
   */
  public getTableColumnNames(ngxChart: NgxChart): string[] {
    const result = this.tableColumnNames || [...this.ngxChart.groupBys, ...ngxChart.getCounterKeys()];
    if (!this.mergeColumns) {
      return result;
    }
    // Columns to be added
    this.mergedColumns = Object.keys(this.mergeColumns);
    // Columns to remove
    this.columnsToRemove = new Set(
      this.mergedColumns.reduce((acc: string[], mergedColumnName: string) => {
        return acc.concat(this.mergeColumns[mergedColumnName].map(this.getColumnNameOrCounterKey));
      }, []),
    );
    return result.filter((columnName: string) => !this.columnsToRemove.has(columnName)).concat(this.mergedColumns);
  }

  /**
   * parseColumnResults
   * @returns {GenericObject[]}
   * @memberof TableChartComponent
   */
  public parseColumnResults(): GenericObject[] {
    if (!this.mergeColumns) {
      return this.ngxChart.trendResultFlattener.results;
    }
    const results = this.ngxChart.trendResultFlattener.results;
    for (const result of results) {
      // copy the data from its original column to the merged column
      // note that original data remains so the end result may double in size
      this.mergedColumns.forEach((mergedColumnName: string) => {
        result[mergedColumnName] = this.mergeColumns[mergedColumnName].reduce((acc: GenericObject, columnName: string) => {
          acc[columnName] = result[this.getColumnNameOrCounterKey(columnName)];
          return acc;
        }, {});
      });
    }
    return results;
  }

  /**
   * getColumnNameOrCounterKey
   * @param {string} columnName
   * @returns {string}
   * @memberof TableChartComponent
   */
  public getColumnNameOrCounterKey = (columnName: string): string => {
    return this.counterKeysMap[columnName] ?? columnName;
  };

  /**
   * onQueryChange
   * @param {string} filter
   * @memberof TableChartComponent
   */
  public onQueryChange(filter: string) {
    this.store.dispatch(
      DashboardActions.patchLocalDataGridSettings({
        settingId: this.settingsId,
        localDataGridSettingsPatch: {
          filter,
          pagedRequest: this.getDefaultLocalDataGridSettings().pagedRequest,
        },
      }),
    );
  }

  /**
   * onLocalDataGridSettingsChange
   * @param {LocalDataGridSettings} localDataGridSettings
   * @memberof TableChartComponent
   */
  public onLocalDataGridSettingsChange(localDataGridSettings: LocalDataGridSettings) {
    if (localDataGridSettings?.sortOns?.length && this.mergedColumns?.length) {
      localDataGridSettings = new LocalDataGridSettings({
        ...localDataGridSettings,
        sortOns: localDataGridSettings.sortOns.map((sortOn: SortOn) => {
          if (this.mergedColumns.includes(sortOn.by)) {
            return new SortOn({
              ...sortOn,
              by: this.getColumnNameOrCounterKey(this.mergeColumns[sortOn.by][0]),
            });
          }
          return sortOn;
        }),
      });
    }

    this.store.dispatch(
      DashboardActions.patchLocalDataGridSettings({
        settingId: this.settingsId,
        localDataGridSettingsPatch: localDataGridSettings,
      }),
    );
  }

  /**
   * onSelectionChange
   * @param {GenericObject[]} selected
   * @memberof TableChartComponent
   */
  public onSelectionChange(selected: GenericObject[]) {
    this.selectedChange.emit(selected);
  }

  /**
   * viewDetails
   * @param {any} dataItem
   * @memberof TableChartComponent
   */
  public viewDetails(dataItem: any) {
    const groupByValues = this.ngxChart.groupBys.map((groupBy: string) => dataItem[groupBy]);
    const drilldownEvent = this.ngxChart.drilldownEventBuilder.getEventFromGroupByValues(groupByValues);
    this.onViewDetails.emit(drilldownEvent);
  }

  /**
   * returns string if dataType is DATETIME else returns the dataType
   *
   * @private
   * @param {string} dataType
   * @returns {string}
   * @memberof TableChartComponent
   */
  private getDataType(dataType: string): string {
    return dataType === DataType[DataType.DATETIME] ? DataType[DataType.DATE_AS_STRING] : dataType;
  }
}
