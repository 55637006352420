/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { FullPageElementService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store';
import { ContentType } from '@ws1c/intelligence-common/models';
import { UserPreferenceActions, UserPreferenceAssetsSelectors } from '@ws1c/intelligence-core';
import { UIPreference } from '@ws1c/intelligence-models';

/**
 * CookiePolicyBannerComponent
 *
 * @export
 * @class CookiePolicyBannerComponent
 */
@Component({
  selector: 'dpa-cookie-policy-banner',
  templateUrl: 'cookie-policy-banner.component.html',
  styleUrls: ['cookie-policy-banner.component.scss'],
})
export class CookiePolicyBannerComponent {
  public vmwarePrivacyPolicyUrl$: Observable<string>;
  public readonly ContentType = ContentType;

  /**
   * @param {Store<MerlotState>} store
   * @param {FullPageElementService} fullPageElementService
   * @memberof CookiePolicyBannerComponent
   */
  constructor(private store: Store<MerlotState>, private fullPageElementService: FullPageElementService) {
    this.vmwarePrivacyPolicyUrl$ = this.store.select(UserPreferenceAssetsSelectors.getVmwarePrivacyPolicyUrl);
  }

  /**
   * navigateToCookiePolicyPage
   * @memberof CookiePolicyBannerComponent
   */
  public navigateToCookiePolicyPage() {
    this.updateIsCookiePolicyBannerVisited();
    this.store.dispatch(UserPreferenceActions.navigateToCookiePolicy());
  }

  /**
   * updateIsCookiePolicyBannerVisited
   * @memberof CookiePolicyBannerComponent
   */
  public updateIsCookiePolicyBannerVisited() {
    this.store.dispatch(
      UserPreferenceActions.updateUISettings({
        uiSettings: {
          [UIPreference.IS_UI_TELEMETRY_BANNER_VISITED]: true,
        },
      }),
    );
  }

  /**
   * dismissAlert
   * @memberof CookiePolicyBannerComponent
   */
  public dismissAlert() {
    this.fullPageElementService.resizeContainer();
    this.store.dispatch(
      UserPreferenceActions.updateUISettings({
        uiSettings: {
          [UIPreference.IS_UI_TELEMETRY_BANNER_CLOSED]: true,
        },
      }),
    );
  }
}
