/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

export const ROUTE_SEGMENTS = {
  ACCESS_DENIED: 'access-denied',
  ACCEPT_EULA: 'accept-eula',
  ACCESS_RESTRICTED: 'access-restricted',
  CLOUD_ACCOUNT_RESTRICTED: 'cloud-account-restricted',
  COMPLIANCE: 'compliance',
  COOKIE_POLICY: 'cookie-policy',
  INTEGRATIONS: 'integrations',
  INTRO: 'intro',
  LOGIN: 'login',
  MARKETPLACE: 'marketplace',
  NOTIFICATION: 'notification',
  ONBOARDING: 'onboarding',
  ORGANIZATION: 'organization',
  ORG_DISABLED: 'org-disabled',
  PRODUCT_UPGRADE: 'product-upgrade',
  PROFILE: 'profile',
  RECENT_ACTIVITY: 'recent-activity',
  REPORT_SETTINGS: 'report-settings',
  RESOURCE_NOT_FOUND: 'resource-not-found',
  SETTINGS: 'settings',
  SHARE: 'share',
  TENANTS: 'tenants',
  TRIAL_ENDED: 'trial-ended',
  WORKFLOW_CONNECTORS: 'workflow-connectors',
  WORKSPACE: 'workspace',
};

export const ROUTE_NAMES = {
  ADMIN_MANAGEMENT: {
    HOME: 'settings/admin-management',
  },
  AUTOMATION: {
    AUTOMATION_WORKFLOW: 'workspace/automation/workflow',
    HOME: 'workspace/automation',
    INTRO: 'workspace/automation/intro',
    WORKFLOW: 'workflow',
    OVERVIEW: (id: string) => `workspace/automation/workflow/${id}/overview`,
    POTENTIAL_IMPACT: (id: string) => `workspace/automation/workflow/${id}/potential-impact`,
    RUN_HISTORY: (id: string) => `workspace/automation/workflow/${id}/run-history`,
  },
  GLOBAL_ORCHESTRATOR: {
    ADD_WORKFLOW: 'workspace/automation/global-orchestrator/add',
    AUTOMATION_WORKFLOW: 'workspace/automation/global-orchestrator',
  },
  COMMON: {
    ADD: 'add',
    EDIT: 'edit',
    LIST: 'list',
    SHARE: 'share',
  },
  WORKSPACE: {
    HOME: 'workspace',
    DATA_EXPLORER: 'data-explorer',
    MY_WORKSPACE: 'workspace/home',
    CUSTOMIZE: 'workspace/home/customize',
    GETTING_STARTED: 'workspace/home/getting-started',
  },
  MARKETPLACE: {
    AUTOMATIONS: 'automations',
    CVE: 'cve',
    DASHBOARDS: 'dashboards',
    DATA_SOURCES: 'data-sources',
    DEEM: 'deem',
    DESKTOP: 'desktop',
    DEEM_V2: 'deemv2',
    EXPLORE: 'explore',
    FRONTLINE: 'frontline',
    UEM_FREESTYLE_WORKFLOWS: 'freestyle-workflows',
    HOME: 'marketplace',
    INTEGRATIONS: 'integrations',
    MOBILE: 'mobile',
    REPORTS: 'reports',
    SCRIPTS: 'scripts',
    SENSORS: 'sensors',
    SOLUTIONS: 'solutions',
    TEMPLATES: 'templates',
    THIRD_PARTY: 'third-party',
    WIDGETS: 'widgets',
    WORKFLOW_CONNECTORS: 'workflow-connectors',
    WORKSPACE_ONE: 'workspace-one',
    TEMPLATES_TYPE: (type: string) => `/marketplace/templates/${type}`,
    TEMPLATES_DASHBOARDS: '/marketplace/templates/dashboards',
    TEMPLATES_WIDGETS: '/marketplace/templates/widgets',
    SOLUTIONS_DEEM: '/marketplace/solutions/deem',
    SOLUTIONS_CVE: '/marketplace/solutions/cve',
    DEEM_DESKTOP_LEARN_MORE: 'marketplace/solutions/deem/desktop',
    DEEM_FRONTLINE_LEARN_MORE: 'marketplace/solutions/deem/frontline',
    DEEM_MOBILE_LEARN_MORE: 'marketplace/solutions/deem/mobile',
    DEEM_VIRTUAL_LEARN_MORE: 'marketplace/solutions/deem/virtual',
    VIRTUAL: 'virtual',
  },
  INTEGRATIONS: {
    HOME: 'integrations',
    INBOUND_CONNECTORS: 'integrations/inbound',
    OUTBOUND_CONNECTORS: 'integrations/outbound',
    WORKFLOW_CONNECTORS: 'integrations/workflow-connectors',
    WORKFLOW_CONNECTORS_DETAILS: (id: string) => `integrations/workflow-connectors/${id}`,
    WORKFLOW_CONNECTORS_CONFIGURE_ACTION: (connectorId: string, actionId: string) => {
      return `integrations/workflow-connectors/${connectorId}/configure/${actionId}`;
    },
    OUTBOUND_CONNECTORS_USAGE: (id: string) => `integrations/outbound/${id}/usage`,
    INBOUND_CONNECTORS_VIEW: 'integrations/inbound/view',
    VIEW_INBOUND_CONNECTOR_STATUS: 'integrations/inbound/view/status',
    VIEW_INBOUND_CONNECTOR_USAGE: 'integrations/inbound/view/usage',
    VIEW_INBOUND_CONNECTOR_ACTIONS: 'integrations/inbound/view/actions',
    VIEW_TENANT_URL_CONFIG: 'integrations/inbound/view/intelligence-onboarding/tenant-url-configuration',
  },
  ONBOARDING_PAGE: {
    HOME: 'onboarding',
    TRIAL_QUESTIONNAIRE: 'onboarding/intelligence-onboarding/trial-questionnaire',
    TENANT_URL_CONFIGURATION_SETUP: 'onboarding/intelligence-onboarding/tenant-url-configuration/setup',
  },
  REPORT: {
    HOME: 'workspace/custom-report',
    ADD_REPORT: 'workspace/custom-report/add',
    EDIT_REPORT: 'workspace/custom-report/edit',
    ADD_REPORT_V2: 'add-report',
    EDIT_REPORT_V2: 'edit-report',
    ADD_TEMPLATE: 'workspace/custom-report/add-template',
    EDIT_TEMPLATE: 'workspace/custom-report/edit-template',
    INTRO: 'workspace/custom-report/intro',
    LIST: 'workspace/custom-report/list',
    MANAGE_TEMPLATES: 'workspace/custom-report/manage-templates',
    DETAILS: (id: string) => `/${ROUTE_NAMES.REPORT.LIST}/${id}`,
    OVERVIEW: (id: string) => `${ROUTE_NAMES.REPORT.DETAILS(id)}/overview`,
    RECENT_ACTIVITY: 'workspace/custom-report/recent-activity',
    REPORT_SETTINGS: 'workspace/custom-report/report-settings',
  },
  DASHBOARD: {
    ADD_WIDGET(rootUrl: string, id: string) {
      return `${rootUrl}/dashboard/list/${id}/add-widget`;
    },
    EDIT_WIDGET(rootUrl: string, dashboardId: string, widgetId: string) {
      return `${rootUrl}/dashboard/list/${dashboardId}/${widgetId}/edit`;
    },
    APP: 'workspace/dashboard/app',
    APP_DETAILS: 'workspace/dashboard/app/details',
    APP_DETAILS_IDM: 'workspace/dashboard/app/details/idm',
    APP_WIDGET_DETAILS: 'workspace/dashboard/app/widget-details',
    APP_DEEM_DETAILS: 'workspace/dashboard/app/deem-details',
    APP_INTELLIGENCE_SDK: 'workspace/dashboard/app/intelligence-sdk',
    APP_MOBILE_PRODUCTIVITY_APPS: 'workspace/dashboard/app/mobile-productivity-apps',
    APP_USER_FLOWS_DETAILS: 'workspace/dashboard/app/user-flows-details',
    CARBON_BLACK: 'workspace/dashboard/carbon-black',
    CARBON_BLACK_DETAILS: 'workspace/dashboard/carbon-black/widget-details',
    CUSTOM_WIDGET_DETAILS: (dashboardId: string) => `workspace/dashboard/list/${dashboardId}/widget-details`,
    DASHBOARD_CUSTOM_WIDGET: 'workspace/dashboard/list/widget-details',
    DEVICES: 'workspace/dashboard/devices',
    DEVICES_PROFILE: 'workspace/dashboard/devices/profile',
    DEVICES_WIDGET_DETAILS: 'workspace/dashboard/devices/widget-details',
    DESKTOP_ERRORS: 'workspace/dashboard/devices/desktop/system_errors',
    DESKTOP_ERROR_DETAILS: 'workspace/dashboard/devices/desktop/system_errors/error_details',
    DESKTOP_OVERVIEW: 'workspace/dashboard/devices/desktop/overview',
    DESKTOP_BOOT_SHUTDOWN: 'workspace/dashboard/devices/desktop/boot_shutdown',
    DESKTOP_PERFORMANCE: 'workspace/dashboard/devices/desktop/device_performance',
    DESKTOP_NETWORK: 'workspace/dashboard/devices/desktop/network',
    DESKTOP_NETWORK_SIGNAL: 'workspace/dashboard/devices/desktop/network/signal',
    DESKTOP_NETWORK_SSID: 'workspace/dashboard/devices/desktop/network/signal/ssid',
    HOME: 'workspace/dashboard',
    HORIZON_MONITORING: 'workspace/dashboard/horizon-monitoring',
    HORIZON_MONITORING_POOL_OVERVIEW: 'workspace/dashboard/horizon-monitoring/pool-overview',
    HORIZON_MONITORING_CONNECTIONS: 'workspace/dashboard/horizon-monitoring/connections',
    HORIZON_MONITORING_SESSIONS: 'workspace/dashboard/horizon-monitoring/sessions',
    HORIZON_MONITORING_USERS: 'workspace/dashboard/horizon-monitoring/users',
    HORIZON_MONITORING_UTILIZATION: 'workspace/dashboard/horizon-monitoring/utilization',
    HORIZON_MONITORING_UTILIZATION_PRIVATE_DATA_CENTER: 'Private Data Center',
    HORIZON_MONITORING_UTILIZATION_AZURE: 'workspace/dashboard/horizon-monitoring/utilization/Microsoft Azure',
    HORIZON_MONITORING_WIDGET_DETAILS: 'workspace/dashboard/horizon-monitoring/widget-details',
    HORIZON_MONITORING_TITAN: 'workspace/dashboard/horizon-monitoring-titan',
    HORIZON_MONITORING_TITAN_CONNECTIONS: 'workspace/dashboard/horizon-monitoring-titan/connections',
    HORIZON_MONITORING_TITAN_COUNTER_DETAILS: 'workspace/dashboard/horizon-monitoring-titan/counter-details',
    HORIZON_MONITORING_TITAN_POOL_TEMPLATE_OVERVIEW: 'workspace/dashboard/horizon-monitoring-titan/pool-template-overview',
    HORIZON_MONITORING_TITAN_SESSIONS: 'workspace/dashboard/horizon-monitoring-titan/sessions',
    HORIZON_MONITORING_TITAN_USERS: 'workspace/dashboard/horizon-monitoring-titan/users',
    HORIZON_MONITORING_TITAN_UTILIZATION: 'workspace/dashboard/horizon-monitoring-titan/utilization',
    HORIZON_MONITORING_TITAN_UTILIZATION_AZURE: 'workspace/dashboard/horizon-monitoring-titan/utilization/Microsoft Azure',
    HORIZON_MONITORING_TITAN_WIDGET_DETAILS: 'workspace/dashboard/horizon-monitoring-titan/widget-details',
    INTELLIGENCE_SDK: 'intelligence-sdk',
    INTRO: 'workspace/dashboard/intro',
    LOGIN_RISK: 'workspace/dashboard/login-risk',
    LOGIN_RISK_DETAILS: 'workspace/dashboard/login-risk/widget-details',
    MOBILE_CONTRIBUTOR_DETAIL(appId: string, platform: string, tab: string): string {
      return `/workspace/dashboard/app/details/${appId}/platform/${platform}/${tab}`;
    },
    MOBILE_OVERVIEW: 'workspace/dashboard/devices/mobile/overview',
    MOBILE_PRODUCTIVITY_APPS: 'mobile-productivity-apps',
    MY_DASHBOARDS: 'workspace/dashboard/list',
    NETWORK_INSIGHTS_DETAILS: 'NETWORK_INSIGHTS_DETAILS',
    OS: 'workspace/dashboard/enrollment',
    OS_DETAILS: 'workspace/dashboard/enrollment/widget-details',
    OVERVIEW(id: string) {
      return `${ROUTE_NAMES.DASHBOARD.MY_DASHBOARDS}/${id}`;
    },
    SECURITY: 'workspace/dashboard/security',
    SECURITY_CVE(platform: string): string {
      return `workspace/dashboard/security/SECURITY_VULNERABILITIES_SUMMARY/${platform}`;
    },
    SECURITY_WIDGET: 'workspace/dashboard/security/widget-details',
    SECURITY_CVE_WIDGET: 'workspace/dashboard/security/cve/widget-details',
    SECURITY_DEVICE_WIDGET: 'workspace/dashboard/security/device/widget-details',
    UAG_TUNNEL: 'workspace/dashboard/uag-tunnel',
    UAG_TUNNEL_DETAILS: 'workspace/dashboard/uag-tunnel/widget-details',
    USER_PROFILE: 'workspace/dashboard/user-profile',
    USER_RISK: 'workspace/dashboard/user-risk',
    USER_RISK_DETAILS: 'workspace/dashboard/user-risk/widget-details',
    WIDGET: 'workspace/dashboard/widget',
    WIDGET_DETAILS: 'workspace/dashboard/widget-details',
    WORKSPACE_ONE_MTD: 'workspace/dashboard/workspace-one-mtd',
    WORKSPACE_ONE_MTD_DETAILS: 'workspace/dashboard/workspace-one-mtd/widget-details',
  },
  SETTINGS: {
    ADMIN_ACCOUNT: 'settings/administrators/account',
    ADMIN_ACCOUNT_ADD: 'settings/administrators/account/add',
    ADMIN_ACCOUNT_GROUP: 'settings/administrators/account-group',
    ADMIN_ACCOUNT_GROUP_ADD: 'settings/administrators/account-group/add',
    ADMIN_INVITE: 'settings/admin-management/admins-invitation/invite',
    ADMIN_LEARN_MORE: 'settings/administrators/learnmore',
    ADMIN_ROLES: 'settings/administrators/admin-roles',
    ADMIN_USER_IDENTITY_MANAGEMENT: 'settings/administrators/user-identity-management',
    DATA_ACCESS_POLICY: 'settings/data-access-policy',
    DATA_ACCESS_POLICY_ADD: 'settings/data-access-policy/add',
    DATA_ACCESS_POLICY_LIST: 'settings/data-access-policy/list',
    END_USER_MANAGEMENT: 'settings/end-user-management',
    END_USER_MANAGEMENT_NOTIFICATION_REDIRECTS: 'settings/end-user-management/notification-redirects',
    HOME: 'settings',
    SERVICE_ACCOUNT: 'settings/service-account',
    SYSTEM_LIMITS: 'settings/system-limits',
    SERVICE_ACCOUNT_ADD: 'settings/service-account/add',
    DATA_ACCESS_POLICY_EDIT(id) {
      return `settings/data-access-policy/${id}/edit`;
    },
  },
  ACCEPT_EULA: 'accept-eula',
  ACCESS_DENIED: 'access-denied',
  ACCESS_RESTRICTED: 'access-restricted',
  CLOUD_ACCOUNT_RESTRICTED: 'cloud-account-restricted',
  LOGIN: 'login',
  NOTIFICATION: {
    HOME: 'notification',
    CENTER: 'notification/center',
    SETTINGS: 'notification/settings',
  },
  COOKIE_POLICY: 'cookie-policy',
  ORGANIZATION: 'organization',
  PRODUCT_UPGRADE: 'product-upgrade',
  RESOURCE_NOT_FOUND: 'resource-not-found',
  SOLUTIONS: 'solutions',
  SOLUTIONS_CVE: 'workspace/solutions/cve/dashboard',
  SOLUTIONS_CVE_SETUP: 'workspace/solutions/cve/setup',
  SOLUTIONS_CVE_HOME: 'workspace/solutions/cve',
  SOLUTIONS_CVE_SETTINGS: 'workspace/solutions/cve/settings',
  SOLUTIONS_CVE_LEARN_MORE: 'workspace/solutions/cve/learnmore',
  SOLUTIONS_CVE_WIDGET_DETAILS: 'workspace/solutions/cve/dashboard/widget-details',
  SOLUTIONS_DEEM: 'workspace/solutions/deem/summary',
  SOLUTIONS_DEEM_HOME: 'workspace/solutions/deem',
  SOLUTIONS_DEEM_HUB_NOTIFICATIONS: 'workspace/solutions/deem/hub-notifications',
  SOLUTIONS_DEEM_ORGANIZATION_OVERVIEW: 'workspace/solutions/deem/organization-overview',
  SOLUTIONS_DEEM_SETUP: 'workspace/solutions/deem/setup',
  SOLUTIONS_DEEM_SETUP_DESKTOP: 'workspace/solutions/deem/setup/desktop-experience',
  SOLUTIONS_DEEM_SETUP_MOBILE: 'workspace/solutions/deem/setup/mobile-experience',
  SOLUTIONS_DEEM_SETUP_MOBILE_APP: 'workspace/solutions/deem/setup/mobile-app',
  SOLUTIONS_DEEM_SETUP_FRONTLINE: 'workspace/solutions/deem/setup/frontline',
  SOLUTIONS_DEEM_INCIDENTS: 'workspace/solutions/deem/incidents',
  SOLUTIONS_DEEM_INCIDENTS_ID: (id: string) => `workspace/solutions/deem/incidents/${id}`,
  SOLUTIONS_DEEM_INCIDENTS_RCA: (id: string) => `workspace/solutions/deem/incidents/${id}/rca`,
  SOLUTIONS_DEEM_INCIDENTS_WORKFLOW: (id: string) => `workspace/solutions/deem/incidents/${id}/workflow`,
  SOLUTIONS_DEEM_INSIGHTS: 'workspace/solutions/deem/insights',
  SOLUTIONS_DEEM_INSIGHTS_ID: (id: string) => `workspace/solutions/deem/insights/${id}`,
  SOLUTIONS_DEEM_SETTINGS: 'workspace/solutions/deem/settings',
  SOLUTIONS_DEEM_SETTINGS_DATASOURCE: 'workspace/solutions/deem/settings/datasource',
  SOLUTIONS_DEEM_SETTINGS_THRESHOLD: 'workspace/solutions/deem/settings/ux-score',
  SOLUTIONS_DEEM_SUMMARY_CONTRIBUTOR_ERROR_DETAIL: (
    prefixUrl: string,
    subDashboardId: string,
    platform: string,
    type: string,
    module: string,
    error: string,
    modulePath: string,
  ) =>
    // eslint-disable-next-line max-len
    `${prefixUrl}/${subDashboardId}/${platform}/contri/${type}/module/${module}/error/${error}/modulePath/${modulePath}`,
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL: 'workspace/solutions/deem/summary/physical',
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL_DESKTOP_APPS: 'workspace/solutions/deem/summary/physical/desktop-apps/all',
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL_MOBILE_APPS: 'workspace/solutions/deem/summary/physical/mobile-apps/all',
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL_USERS: 'workspace/solutions/deem/summary/physical/users',
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL_USER: 'workspace/solutions/deem/summary/physical/users/all/user',
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL_DEVICE_DETAIL: (subDashboardId: string, platform: string) =>
    `workspace/solutions/deem/summary/physical/${subDashboardId}/${platform}/device`,
  SOLUTIONS_DEEM_SUMMARY_PHYSICAL_CONTRIBUTOR: (subDashboardId: string, platform: string, type: string) =>
    `workspace/solutions/deem/summary/physical/${subDashboardId}/${platform}/contributor/${type}`,
  SOLUTIONS_DEEM_SUMMARY_VIRTUAL_V2: 'workspace/solutions/deem/summary/virtual-v2',
  SOLUTIONS_DEEM_SUMMARY_VIRTUAL_LOGON_EXPERIENCE: 'workspace/solutions/deem/summary/virtual/logon-experience',
  SOLUTIONS_DEEM_SUMMARY_VIRTUAL_CONTRIBUTOR: (subDashboardId: string, platform: string, type: string) =>
    `workspace/solutions/deem/summary/virtual-v2/${subDashboardId}/${platform}/contributor/${type}`,
  SOLUTIONS_DEEM_WIDGET_DETAILS: 'workspace/solutions/deem/summary/widget-details',
  SOLUTIONS_DEEM_PLAYBOOKS: 'workspace/solutions/deem/playbooks',
  SOLUTIONS_DEEM_SURVEYS: 'workspace/solutions/deem/surveys',
  SOLUTIONS_DEEM_SURVEYS_ID: (id: string) => `workspace/solutions/deem/surveys/${id}`,
  SOLUTIONS_DEEM_SURVEYS_WIDGET_DETAILS: 'workspace/solutions/deem/surveys/widget-details',
  SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING: 'workspace/solutions/deem/synthetic-url-monitoring',
  SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_DETAIL: (id: string) => `workspace/solutions/deem/synthetic-url-monitoring/list/${id}`,
  SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_ADD_TEST: 'workspace/solutions/deem/synthetic-url-monitoring/add',
  SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_UPDATE_TEST: (id: string) => `workspace/solutions/deem/synthetic-url-monitoring/update/${id}`,
  SOLUTIONS_DEEM_CREATE_SURVEY: 'workspace/solutions/deem/create-survey',
  SOLUTIONS_DEEM_LEARN_MORE: 'workspace/solutions/deem/learnmore',
  SOLUTIONS_DEEM_SAM: 'workspace/solutions/deem/sam',
  SOLUTIONS_DEEM_ZOOM_CONTRIBUTORS_VIEW: (contributor: string) =>
    `workspace/solutions/deem/summary/physical/app-integrations/zoom/contributors/${contributor}`,
  SOLUTIONS_DEEM_ZOOM_MEETING_DETAIL_VIEW: (id: string) => `workspace/solutions/deem/summary/physical/app-integrations/zoom/meeting/${id}`,
  SOLUTIONS_DEEM_ZOOM_USER_DETAIL_VIEW: (id: string) => `workspace/solutions/deem/summary/physical/app-integrations/zoom/user/${id}`,
  TRIAL_ENDED: 'trial-ended',
  PROFILE: 'profile',
};
