/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { I18NService, RouterExtensions } from '@ws1c/intelligence-common/services';
import { LayoutType } from '@ws1c/intelligence-models';

/**
 * LayoutSwitchIcon
 *
 * @export
 * @interface LayoutSwitchIcon
 */
export interface LayoutSwitchIcon {
  label: string;
  icon: string;
  id: string;
}

/**
 * MultiLayoutSwitcherComponent
 *
 * @export
 * @class MultiLayoutSwitcherComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-multi-layout-switcher',
  templateUrl: 'multi-layout-switcher.component.html',
  styleUrls: ['multi-layout-switcher.component.scss'],
})
export class MultiLayoutSwitcherComponent implements OnInit {
  @Input() public selectedLayout: LayoutType;
  @Input() public availableLayouts: LayoutType[];
  @Input() public updateParams?: boolean = true;
  @Output() public selectedLayoutChange: EventEmitter<LayoutType> = new EventEmitter<LayoutType>();

  public layoutSwitches: Record<LayoutType, LayoutSwitchIcon>;
  public availableSwitches: LayoutSwitchIcon[];

  /**
   * Creates an instance of MultiLayoutSwitcherComponent.
   * @param {RouterExtensions} router
   * @param {ActivatedRoute} activatedRoute
   * @param {I18NService} i18nService
   * @memberof MultiLayoutSwitcherComponent
   */
  constructor(private router: RouterExtensions, private activatedRoute: ActivatedRoute, private i18nService: I18NService) {
    this.layoutSwitches = {
      [LayoutType.SPLIT_VIEW]: {
        label: this.i18nService.translate('WORKFLOWS.ARIA_LABEL_LAYOUT_SELECT_SPLIT_VIEW'),
        icon: 'dpa-selector-automation-split-view-list',
        id: LayoutType.SPLIT_VIEW,
      },
      [LayoutType.CARD_VIEW]: {
        label: this.i18nService.translate('WORKFLOWS.ARIA_LABEL_LAYOUT_SELECT_CARD_VIEW'),
        icon: 'dpa-selector-automation-split-view-card',
        id: LayoutType.CARD_VIEW,
      },
      [LayoutType.GRID_VIEW]: {
        label: this.i18nService.translate('WORKFLOWS.ARIA_LABEL_LAYOUT_SELECT_GRID_VIEW'),
        icon: 'dpa-selector-automation-split-view-grid',
        id: LayoutType.GRID_VIEW,
      },
    };
  }

  /**
   * ngOnInit
   *
   * @memberof MultiLayoutSwitcherComponent
   */
  public ngOnInit() {
    this.changeLayout(this.selectedLayout);
  }

  /**
   * changeLayout
   *
   * @param {LayoutType} layoutType
   * @memberof MultiLayoutSwitcherComponent
   */
  public changeLayout(layoutType: LayoutType) {
    this.selectedLayoutChange.emit(layoutType);
    if (this.updateParams) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          layout: layoutType,
        },
        replaceUrl: true,
      });
    }
  }
}
