/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GenericObject, RangeSliderBoundary, RangeSliderComponent } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { get, maxBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CoreAppState, DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import {
  Trend,
  TrendResult,
  WidgetDataRequest,
  WidgetPreference,
  WidgetPreferences,
  WidgetRangeFilter,
  WidgetRangeType,
} from '@ws1c/intelligence-models';

/**
 * WidgetEditRangeDialogComponent
 * @export
 * @class WidgetEditRangeDialogComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-widget-edit-range-dialog',
  templateUrl: 'widget-edit-range-dialog.component.html',
  styleUrls: ['widget-edit-range-dialog.component.scss'],
})
export class WidgetEditRangeDialogComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(RangeSliderComponent) public rangeSlider: RangeSliderComponent;

  @Input() public isModalOpen?: boolean = true;
  @Input() public dashboardId: string;
  @Input() public chartData: Trend;
  @Output() public isModalOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public updateDataRange: EventEmitter<WidgetRangeFilter> = new EventEmitter<WidgetRangeFilter>();

  public readonly RANGE_TYPE = WidgetRangeType;
  public readonly SLIDER_COLOR_CONFIG = {
    low: '#DDDDDD',
    mid: '#0079B8',
    high: '#DDDDDD',
  };

  public maxDataValue$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public groupByLabel$: Observable<string>;

  public widgetPreference: WidgetPreference;
  public widgetId: string;
  public editRangeFormGroup: UntypedFormGroup;
  public maxDataValue: number;
  public min: number = 0;
  public max: number;
  public step: number = 1;
  public boundaries: Record<RangeSliderBoundary, number>;
  public sub: Subscription = new Subscription();

  /**
   * Creates an instance of WidgetEditRangeDialogComponent.
   * @param {UntypedFormBuilder} fb
   * @param {Store<CoreAppState>} store
   * @memberof WidgetEditRangeDialogComponent
   */
  constructor(private fb: UntypedFormBuilder, private store: Store<CoreAppState>) {
    this.editRangeFormGroup = this.fb.group({
      rangeType: [this.RANGE_TYPE.COUNT, [Validators.required]],
    });
    this.groupByLabel$ = this.store.select(DashboardSelectors.getWidgetEditRangeGroupByLabel);
  }

  /**
   * ngOnInit
   * @memberof WidgetEditRangeDialogComponent
   */
  public ngOnInit() {
    this.editRangeFormGroup.valueChanges.subscribe(({ rangeType }: GenericObject) => {
      const maxValue = rangeType === this.RANGE_TYPE.PERCENTAGE ? 100 : this.maxDataValue;
      this.updateRangeSlider(0, maxValue, 0, maxValue);
    });
    this.sub.add(
      this.store
        .select(DashboardSelectors.getEditRangeWidgetId)
        .pipe(
          filter((widgetId) => !!widgetId),
          switchMap((widgetId: string) => {
            this.widgetId = widgetId;
            return this.store.select(DashboardSelectors.getWidgetDataByIdState(widgetId));
          }),
          map((widgetDataRequest: WidgetDataRequest) => {
            return widgetDataRequest?.result;
          }),
          filter((trend: Trend) => !!trend),
          map((trend: Trend) => {
            this.chartData = trend;
            this.maxDataValue = this.getMaxDataValue();
            return this.maxDataValue;
          }),
        )
        .subscribe((maxValue: number) => {
          this.maxDataValue$.next(maxValue);
        }),
    );
    this.sub.add(
      combineLatest([this.store.select(DashboardSelectors.getWidgetAttributePreferencesForEditRange), this.maxDataValue$]).subscribe(
        ([widgetPreference, maxDataValue]: [WidgetPreference, number]) => {
          this.widgetPreference = widgetPreference;
          if (widgetPreference?.rangeFilter) {
            this.editRangeFormGroup.patchValue({
              rangeType: widgetPreference.rangeFilter.type,
            });
          }
          this.updateRangeSlider(
            0,
            widgetPreference?.rangeFilter?.type === this.RANGE_TYPE.PERCENTAGE ? 100 : maxDataValue,
            widgetPreference?.rangeFilter?.min || 0,
            widgetPreference?.rangeFilter?.max,
          );
        },
      ),
    );
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof WidgetEditRangeDialogComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.chartData?.currentValue) {
      this.maxDataValue = this.getMaxDataValue();
      this.maxDataValue$.next(this.maxDataValue);
    }
  }

  /**
   * ngOnDestroy
   * @memberof WidgetEditRangeDialogComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * closeModal
   * @memberof WidgetEditRangeDialogComponent
   */
  public closeModal() {
    this.store.dispatch(
      DashboardActions.openEditRangeDialog({
        isOpen: false,
      }),
    );
  }

  /**
   * setSelectedRange
   * @param {Record<RangeSliderBoundary, number>} range
   * @memberof WidgetEditRangeDialogComponent
   */
  public setSelectedRange(range: Record<RangeSliderBoundary, number>) {
    this.boundaries = { ...range };
  }

  /**
   * isSaveButtonDisabled
   * @returns {boolean}
   * @memberof WidgetEditRangeDialogComponent
   */
  public isSaveButtonDisabled(): boolean {
    return !this.editRangeFormGroup || this.editRangeFormGroup.invalid;
  }

  /**
   * saveRange
   * @memberof WidgetEditRangeDialogComponent
   */
  public saveRange() {
    const widgetAttributePreferences = [
      new WidgetPreference({
        ...this.widgetPreference,
        attributeName: this.chartData?.trendDefinition?.bucketingAttributes?.[0],
        rangeFilter: new WidgetRangeFilter({
          type: this.editRangeFormGroup.value.rangeType,
          min: this.boundaries[RangeSliderBoundary.LOW_BOUNDARY],
          max: this.boundaries[RangeSliderBoundary.HIGH_BOUNDARY],
        }),
      }),
    ];
    const widgetPreferences = new WidgetPreferences({
      widgetAttributePreferences,
    });
    this.store.dispatch(
      DashboardActions.setWidgetRangeFilter({
        dashboardId: this.dashboardId,
        widgetId: this.widgetId,
        widgetPreferences,
      }),
    );
    this.closeModal();
  }

  /**
   * updateRangeSlider
   * @private
   * @param {number} min
   * @param {number} max
   * @param {number} low
   * @param {number} high
   * @memberof WidgetEditRangeDialogComponent
   */
  private updateRangeSlider(min: number, max: number, low: number, high: number) {
    this.min = min;
    this.max = max;

    const range: Record<RangeSliderBoundary, number> = {
      [RangeSliderBoundary.LOW_BOUNDARY]: low ?? 0,
      [RangeSliderBoundary.HIGH_BOUNDARY]: high <= this.max ? high : this.max,
    };
    if (!this.rangeSlider) {
      this.boundaries = range;
    } else {
      setTimeout(() => {
        this.rangeSlider?.formGroup.patchValue(range);
      });
    }
  }

  /**
   * getMaxDataValue
   * @returns {number}
   * @memberof WidgetEditRangeDialogComponent
   */
  private getMaxDataValue(): number {
    const maxValue: number = Math.ceil(
      get(
        maxBy(this.chartData.trendResults, (trendResult: TrendResult) => trendResult.counters[0].result.value),
        'counters[0].result.value',
      ),
    );

    // range slider configuration should allow more than one step. e.g. if min is 0, step is 1, max must be more than or equal to 2.
    return this.min + this.step < maxValue ? maxValue : maxValue + this.step;
  }
}
