<clr-alert
  [clrAlertAppLevel]="true"
  [clrAlertType]="'info'"
  (clrAlertClosedChange)="dismissAlert()"
>
  <clr-alert-item>
    <span
      [innerHTML]="
        'UI_TELEMETRY.COOKIE_POLICY_BANNER_TEXT'
          | translate
            : {
                privacyPolicyUrl: vmwarePrivacyPolicyUrl$ | async
              }
          | safe : ContentType.HTML
      "
      class="alert-text"
    ></span>
    <div class="alert-actions">
      <button
        (click)="navigateToCookiePolicyPage()"
        class="btn btn-primary-outline btn-sm cookie-policy-btn"
      >
        {{ 'UI_TELEMETRY.COOKIE_USAGE_LABEL' | translate }}
      </button>
    </div>
  </clr-alert-item>
</clr-alert>
