/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * OAuthPartnerType
 * @export
 * @enum {string}
 */
export enum OAuthPartnerType {
  ZOOM = 'ZOOM',
}

/**
 * OAuthPartnerTypeDisplay
 * @export
 * @enum {string}
 */
export enum OAuthPartnerTypeDisplay {
  ZOOM = 'Zoom',
}

/**
 * OAuthPartnerConnectionModalType
 * @export
 * @enum {number}
 */
export enum OAuthPartnerConnectionModalType {
  NONE,
  AUTHORIZE,
  REAUTHORIZE,
  DEAUTHORIZE,
}
