<ng-container *ngIf="tagNames.length && (tagNames.length === 1 || tagNames.length < maxNumTags); else moreTemplate">
  <ng-container *ngFor="let key of tagNames">
    <!-- possible key for getTooltipText(key)
      WIDGET_TEMPLATE.HISTORICAL
      WIDGET_TEMPLATE.SNAPSHOT
    -->
    <dpa-tooltip-tag
      [labelText]="tagLabelsByName[key]"
      [tooltipText]="getTooltipText(key)"
    ></dpa-tooltip-tag>
  </ng-container>
</ng-container>

<ng-template #moreTemplate>
  <clr-dropdown *ngIf="tagNames.length">
    <button
      clrDropdownTrigger
      class="ph-0"
    >
      <span class="label">
        {{ 'WIDGET_TEMPLATE.NUM_OF_TAGS' | translate : { num: tagNames.length } }}
      </span>
    </button>
    <clr-dropdown-menu
      *clrIfOpen
      [clrPosition]="isMinimized ? CLARITY_TOOLTIP_POSITION.BOTTOM_RIGHT : CLARITY_TOOLTIP_POSITION.TOP_RIGHT"
    >
      <span *ngFor="let key of tagNames">
        <!-- possible key for getTooltipText(key)
          WIDGET_TEMPLATE.HISTORICAL
          WIDGET_TEMPLATE.SNAPSHOT
        -->
        <dpa-tooltip-tag
          clrDropdownItem
          [labelText]="tagLabelsByName[key]"
          [tooltipText]="getTooltipText(key)"
        ></dpa-tooltip-tag>
      </span>
    </clr-dropdown-menu>
  </clr-dropdown>
</ng-template>
