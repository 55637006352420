/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DateTimeFormat, GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { CoreAppState, DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import { COLUMN_NAMES, DashboardConfig, Entity, getFQNFunction, Integration } from '@ws1c/intelligence-models';

/**
 * RiskIndicatorDetailsComponent
 * @export
 * @class RiskIndicatorDetailsComponent
 */
@Component({
  selector: 'dpa-risk-indicator-details',
  templateUrl: 'risk-indicator-details.component.html',
  styleUrls: ['risk-indicator-details.component.scss'],
})
export class RiskIndicatorDetailsComponent implements OnInit, OnChanges {
  @Input() public guid: string;
  @Input() public indicators: string[];
  @Input() public scoreCalculatedAt: number;

  public riskIndicators$: Observable<GenericObject[]>;
  public getFQN = getFQNFunction(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE);

  public readonly COLUMN_NAMES = COLUMN_NAMES.byName;
  public readonly DASHBOARD_CONFIG = DashboardConfig;
  public readonly DATE_KEY_STRING: string = '_date';
  public readonly NESTED_DETAILS_KEY_STRING: string = 'nested_details';

  /**
   * constructor
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18NService
   * @memberof RiskIndicatorDetailsComponent
   */
  constructor(private store: Store<CoreAppState>, private i18NService: I18NService) {}

  /**
   * ngOnInit
   * @memberof RiskIndicatorDetailsComponent
   */
  public ngOnInit() {
    this.riskIndicators$ = this.store
      .select(DashboardSelectors.getRiskIndicatorDetailsByTimestampByGuid(this.guid, this.scoreCalculatedAt))
      .pipe(
        filter((riskIndicatorsByType: GenericObject) => !!riskIndicatorsByType),
        map((riskIndicatorsByType: GenericObject) => {
          return this.indicators?.map((scoreType: any) => riskIndicatorsByType[scoreType]).filter(Boolean);
        }),
      );
  }

  /**
   * ngOnChanges
   * @memberof RiskIndicatorDetailsComponent
   */
  public ngOnChanges() {
    this.store.dispatch(
      DashboardActions.loadRiskIndicatorDetails({
        guid: this.guid,
        indicators: this.indicators,
        timestamp: this.scoreCalculatedAt,
      }),
    );
  }

  /**
   * metaDataTracker
   * @param {number} _index
   * @param {any} metaData
   * @returns {string}
   * @memberof RiskIndicatorDetailsComponent
   */
  public metaDataTracker(_index: number, metaData: any): string {
    return metaData.name;
  }

  /**
   * isNestedMetaData
   * @param {string} scoreType
   * @param {string} metaDataName
   * @returns {boolean}
   * @memberof RiskIndicatorDetailsComponent
   */
  public isNestedMetaData(scoreType: string, metaDataName: string): boolean {
    const nameParts = metaDataName.split('.');
    return nameParts.length === 2 && nameParts[0] === scoreType;
  }

  /**
   * getScoreMetaData
   * @param {string} scoreType
   * @param {string[]} metaDataList
   * @returns {any[]}
   * @memberof RiskIndicatorDetailsComponent
   */
  public getScoreMetaData(scoreType: string, metaDataList: string[]): any[] {
    if (!metaDataList.length) {
      return null;
    }
    return metaDataList.map((metaDataJson: string) => {
      const metaData = JSON.parse(metaDataJson);
      // Convert an Array into a list of values separated with spaces for better display (for example, the "app_list")
      Object.keys(metaData.meta_data).forEach((key: string) => {
        const value = metaData.meta_data[key];
        if (Array.isArray(value)) {
          if (key.match(this.NESTED_DETAILS_KEY_STRING)) {
            metaData.meta_data[key] = this.formatNestedDetailsAttribute(metaData.meta_data[key]);
          } else {
            metaData.meta_data[key] = value.join(', ');
          }
        }
        // Convert date value to local date format
        if (key.match(this.DATE_KEY_STRING) && Date.parse(value)) {
          metaData.meta_data[key] = this.formatDate(value);
        }
      });
      // Normalize the data by adding a "value" property for nested risk score meta data
      // This allows reuse of the following i18n key in the template: STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM.RISKYSETTINGS
      if (this.isNestedMetaData(scoreType, metaData.name)) {
        const settingType = metaData.name.split('.')[1];
        const originalValue = metaData.meta_data[settingType];

        /**
         * Possible values for labelKey
         *
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_ANTIVIRUS_STATUS_0
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_ANTIVIRUS_STATUS_1
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_ANTIVIRUS_STATUS_2
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_ANTIVIRUS_STATUS_3
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_ANTIVIRUS_STATUS_4
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_AUTO_UPDATE_STATUS_0
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_AUTO_UPDATE_STATUS_1
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_AUTO_UPDATE_STATUS_2
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_AUTO_UPDATE_STATUS_3
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_AUTO_UPDATE_STATUS_4
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_AUTO_UPDATE_STATUS_5
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_FIREWALL_STATUS_0
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_FIREWALL_STATUS_1
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_FIREWALL_STATUS_2
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_FIREWALL_STATUS_3
         * STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.DEVICE_FIREWALL_STATUS_4
         */
        const labelKey = `STANDARD_DASHBOARD.USER_RISK.SCORE_TYPE_META_DATA_ITEM_VALUES.${settingType.toUpperCase()}_${originalValue}`;

        const label = this.i18NService.translate(labelKey);
        Object.assign(metaData.meta_data, {
          originalValue,
          // If the label does not exist, i18NService returns labelKey it was given
          // In this case, use the originalValue
          value: label === labelKey ? originalValue : label,
        });
      }
      return metaData;
    });
  }

  /**
   * @param {GenericObject[]} arrObject
   * @returns {GenericObject[]}
   * @memberof RiskIndicatorDetailsComponent
   */
  public formatNestedDetailsAttribute(arrObject: GenericObject[]): GenericObject[] {
    arrObject.forEach((object: GenericObject) => {
      const formattedObject = object;
      Object.keys(formattedObject).forEach((id: string) => {
        const val = formattedObject[id];
        if (id.match(this.DATE_KEY_STRING) && Date.parse(val)) {
          formattedObject[id] = this.formatDate(val);
        }
      });
    });
    return arrObject;
  }

  /**
   * @param {string} date
   * @returns {string}
   * @memberof RiskIndicatorDetailsComponent
   */
  public formatDate(date: string): string {
    return moment(date).format(DateTimeFormat.MOMENT_DATE_FORMAT);
  }
}
