/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { values } from 'lodash-es';

import { I18NService } from '@ws1c/intelligence-common';
import {
  COLUMN_NAMES,
  DashboardConfig,
  DrilldownWidgetConfig,
  Entity,
  getEntityFromFQN,
  HorizonDeploymentType,
  HorizonHealthLevel,
  HorizonPodType,
  HorizonSessionStatus,
  StandardDashboardType,
  StandardWidgetSubtype,
} from '@ws1c/intelligence-models';

/**
 * HorizonDashboardConfig
 * @exports
 * @class HorizonDashboardConfig
 */
export class HorizonDashboardConfig {
  public static readonly statusColors = [
    {
      name: HorizonSessionStatus.ACTIVE,
      value: DashboardConfig.poorNeutralGoodColors[0].value,
    },
    {
      name: HorizonSessionStatus.IDLE,
      value: DashboardConfig.poorNeutralGoodColors[1].value,
    },
    {
      name: HorizonSessionStatus.DISCONNECTED,
      value: DashboardConfig.poorNeutralGoodColors[2].value,
    },
  ];

  public static readonly performanceLevelNames = {
    cpuAndMemory: {
      [HorizonHealthLevel.CRITICAL]: ['>=95%'],
      [HorizonHealthLevel.WARNING]: ['80% - 95%'],
      [HorizonHealthLevel.NORMAL]: ['60% - 80%', '40% - 60%', '20% - 40%', '<20%'],
    },
    diskIops: {
      [HorizonHealthLevel.CRITICAL]: ['>=100'],
      [HorizonHealthLevel.WARNING]: ['80 - 100'],
      [HorizonHealthLevel.NORMAL]: ['50 - 80', '30 - 50', '<30'],
    },
    diskLatency: {
      [HorizonHealthLevel.CRITICAL]: ['>=100 ms'],
      [HorizonHealthLevel.WARNING]: ['60 - 100 ms'],
      [HorizonHealthLevel.NORMAL]: ['40 - 60 ms', '20 - 40 ms', '<20 ms'],
    },
    logonDuration: {
      [HorizonHealthLevel.CRITICAL]: ['>=240 sec'],
      [HorizonHealthLevel.WARNING]: ['120 - 240 sec'],
      [HorizonHealthLevel.NORMAL]: ['60 - 120 sec', '30 - 60 sec', '15 - 30 sec', '<15 sec'],
    },
    protocolLatency: {
      [HorizonHealthLevel.CRITICAL]: ['>=250 ms'],
      [HorizonHealthLevel.WARNING]: ['200 - 250 ms'],
      [HorizonHealthLevel.NORMAL]: ['150 - 200 ms', '100 - 150 ms', '<100 ms'],
    },
  };

  public static readonly performanceLevelColors = HorizonDashboardConfig.getPerformanceLevelNames(HorizonHealthLevel.CRITICAL)
    .map((name: string) => ({
      name,
      value: DashboardConfig.colors.red,
    }))
    .concat(
      HorizonDashboardConfig.getPerformanceLevelNames(HorizonHealthLevel.WARNING).map((name: string) => ({
        name,
        value: DashboardConfig.colors.orange,
      })),
    )
    .concat(
      HorizonDashboardConfig.getPerformanceLevelNames(HorizonHealthLevel.NORMAL).map((name: string) => ({
        name,
        value: DashboardConfig.colors.babyBlue,
      })),
    );

  /**
   * healthLevelToBadgeClassMap
   * @static
   * @memberof HorizonDashboardConfig
   */
  public static readonly healthLevelToBadgeClassMap = {
    [HorizonHealthLevel.CRITICAL]: 'badge-red',
    [HorizonHealthLevel.WARNING]: 'badge-orange',
    [HorizonHealthLevel.NORMAL]: 'badge-success',
  };

  public static readonly treeMapLabel: Record<string, string> = {
    LOCATION: 'STANDARD_DASHBOARD.HORIZON.LOCATIONS',
    DEPLOYMENT: 'STANDARD_DASHBOARD.HORIZON.DEPLOYMENTS',
    POD: 'STANDARD_DASHBOARD.HORIZON.PODS',
    POOL: 'STANDARD_DASHBOARD.HORIZON.POOLS',
  };

  public static readonly treeMapTitanLabel: Record<string, string> = {
    SITES: 'STANDARD_DASHBOARD.HORIZON.SITES',
    EDGES: 'STANDARD_DASHBOARD.HORIZON.EDGES',
    POOL: 'STANDARD_DASHBOARD.HORIZON.POOLS',
  };

  public static readonly treeMapListViewConfig: DrilldownWidgetConfig[] = [
    {
      drilldownEventPath: 'selectedBuckets[0].bucketName',
      drilldownEventValue: 'horizon.pod.pod_name',
      widgetId: StandardWidgetSubtype.HORIZON_POOL_LIST_BY_POD_NAME,
    },
    {
      drilldownEventPath: 'selectedCounter',
      drilldownEventValue: HorizonDashboardConfig.treeMapLabel.POOL,
      widgetId: StandardWidgetSubtype.HORIZON_POOL_LIST_BY_POD_NAME,
    },
  ];

  public static readonly treeMapListViewTitanConfig: DrilldownWidgetConfig[] = [
    {
      drilldownEventPath: 'selectedBuckets[0].bucketName',
      drilldownEventValue: 'horizon.edge_titan.edge_name',
      widgetId: StandardWidgetSubtype.HORIZON_POOL_TEMPLATE_LIST_BY_EDGE_NAME,
    },
    {
      drilldownEventPath: 'selectedCounter',
      drilldownEventValue: HorizonDashboardConfig.treeMapTitanLabel.POOL,
      widgetId: StandardWidgetSubtype.HORIZON_POOL_TEMPLATE_LIST_BY_EDGE_NAME,
    },
  ];

  public static readonly horizonDeploymentTypeToPodType: Record<string, string> = {
    [HorizonDeploymentType.AZURE]: HorizonPodType.AZURE,
    [HorizonDeploymentType.AVS]: HorizonPodType.VMWARE_SDDC,
    [HorizonDeploymentType.AWS]: HorizonPodType.VMWARE_SDDC,
    [HorizonDeploymentType.DELL_EMC]: HorizonPodType.VMWARE_SDDC,
    [HorizonDeploymentType.GCVE]: HorizonPodType.VMWARE_SDDC,
    [HorizonDeploymentType.OCVS]: HorizonPodType.VMWARE_SDDC,
    [HorizonDeploymentType.PRIVATE_DATA_CENTER]: HorizonPodType.VMWARE_SDDC,
  };

  public static readonly horizonDashboardTypeToPodIdColumn: Record<string, string> = {
    [StandardDashboardType.HORIZON_SESSIONS]: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id,
    [StandardDashboardType.HORIZON_SESSIONS_HISTORICAL]: COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pod_id,
    [StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC]: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id,
    [StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC_HISTORICAL]: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_event_pod_id,
    [StandardDashboardType.HORIZON_UTILIZATION_AZURE]: COLUMN_NAMES.byFullyQualifiedName.horizon_pod_pod_id,
    [StandardDashboardType.HORIZON_TITAN_SESSIONS_HISTORICAL]: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_id,
  };

  public static readonly horizonDashboardTypeToPoolIdColumn: Record<string, string> = {
    [StandardDashboardType.HORIZON_SESSIONS_HISTORICAL]: COLUMN_NAMES.byFullyQualifiedName.horizon_session_historical_pool_id,
  };

  // For both Titan & V1
  public static readonly dashboardTypeListByDeploymentType = {
    [HorizonDeploymentType.AZURE]: {
      TITAN: [StandardDashboardType.HORIZON_TITAN_UTILIZATION],
      V1: [StandardDashboardType.HORIZON_UTILIZATION_AZURE, StandardDashboardType.HORIZON_UTILIZATION_AZURE_HISTORICAL],
    },
    DEFAULT: {
      TITAN: [StandardDashboardType.HORIZON_TITAN_UTILIZATION],
      V1: [StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC, StandardDashboardType.HORIZON_UTILIZATION_VMWARE_SDDC_HISTORICAL],
    },
  };

  /**
   * getDrilldownWidgetConfig
   * @static
   * @param {I18NService} i18nService
   * @returns {DrilldownWidgetConfig[]}
   * @memberof HorizonDashboardConfig
   */
  public static getDrilldownWidgetConfig(i18nService: I18NService): DrilldownWidgetConfig[] {
    return [
      {
        drilldownEventPath: '',
        drilldownEventValue: '',
        widgetId: StandardWidgetSubtype.__HORIZON_TOTAL_TOPO_COUNT,
        showSameSizeCell: true,
        labelFormatting: (c: GenericObject) => {
          return values(HorizonDashboardConfig.treeMapLabel).reduce((label: string, localeKey: string) => {
            return label.replace(localeKey, i18nService.translate(localeKey));
          }, c.label);
        },
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapLabel.LOCATION,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.LOCATIONS'),
        widgetId: StandardWidgetSubtype.HORIZON_DEPLOYMENT_COUNT_BY_LOCATION_NAME,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_DEPLOYMENTS', { count }),
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapLabel.DEPLOYMENT,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.DEPLOYMENTS'),
        widgetId: StandardWidgetSubtype.HORIZON_POD_COUNT_BY_DEPLOYMENT_TYPE,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_PODS', { count }),
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapLabel.POD,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.PODS'),
        widgetId: StandardWidgetSubtype.HORIZON_POOL_COUNT_BY_POD_NAME,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapLabel.POOL,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.POOLS'),
        widgetId: StandardWidgetSubtype.HORIZON_TOTAL_POOL_COUNT,
        isEnd: true,
        labelFormatting: () => i18nService.translate('STANDARD_DASHBOARD.HORIZON.TOTAL_POOLS'),
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
      {
        drilldownEventPath: 'selectedBuckets[0].bucketName',
        drilldownEventValue: 'horizon.pod.location',
        widgetId: StandardWidgetSubtype.HORIZON_POD_COUNT_BY_DEPLOYMENT_TYPE,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_PODS', { count }),
      },
      {
        drilldownEventPath: 'selectedBuckets[0].bucketName',
        drilldownEventValue: 'horizon.pod._deployment_type',
        widgetId: StandardWidgetSubtype.HORIZON_POOL_COUNT_BY_POD_NAME,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
      {
        drilldownEventPath: 'selectedBuckets[0].bucketName',
        drilldownEventValue: 'horizon.pod.pod_name',
        widgetId: StandardWidgetSubtype.HORIZON_POOL_COUNT_BY_POD_NAME,
        isEnd: true,
        labelFormatting: () => '',
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
    ];
  }

  /**
   * getDrilldownWidgetTitanConfig
   * @static
   * @param {I18NService} i18nService
   * @returns {*}  {DrilldownWidgetConfig[]}
   * @memberof HorizonDashboardConfig
   */
  public static getDrilldownWidgetTitanConfig(i18nService: I18NService): DrilldownWidgetConfig[] {
    return [
      {
        drilldownEventPath: '',
        drilldownEventValue: '',
        widgetId: StandardWidgetSubtype.__HORIZON_TOTAL_TOPO_COUNT_TITAN,
        showSameSizeCell: true,
        labelFormatting: (c: GenericObject) => {
          return values(HorizonDashboardConfig.treeMapTitanLabel).reduce((label: string, localeKey: string) => {
            return label.replace(localeKey, i18nService.translate(localeKey));
          }, c.label);
        },
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapTitanLabel.SITES,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.SITES'),
        widgetId: StandardWidgetSubtype.HORIZON_EDGE_COUNT_BY_SITE_NAME,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_EDGES', { count }),
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapTitanLabel.EDGES,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.EDGES'),
        widgetId: StandardWidgetSubtype.HORIZON_POOL_TEMPLATE_COUNT_BY_EDGE_NAME,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
      {
        drilldownEventPath: 'selectedCounter',
        drilldownEventValue: HorizonDashboardConfig.treeMapTitanLabel.POOL,
        drilldownKey: i18nService.translate('STANDARD_DASHBOARD.HORIZON.POOLS'),
        widgetId: StandardWidgetSubtype.HORIZON_TOTAL_POOL_TEMPLATE_COUNT,
        isEnd: true,
        labelFormatting: () => i18nService.translate('STANDARD_DASHBOARD.HORIZON.TOTAL_POOLS'),
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
      {
        drilldownEventPath: 'selectedBuckets[0].bucketName',
        drilldownEventValue: COLUMN_NAMES.byFullyQualifiedName.horizon_site_titan_site_name,
        widgetId: StandardWidgetSubtype.HORIZON_POOL_TEMPLATE_COUNT_BY_EDGE_NAME,
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
      {
        drilldownEventPath: 'selectedBuckets[0].bucketName',
        drilldownEventValue: COLUMN_NAMES.byFullyQualifiedName.horizon_edge_titan_edge_name,
        widgetId: StandardWidgetSubtype.HORIZON_POOL_TEMPLATE_COUNT_BY_EDGE_NAME,
        isEnd: true,
        labelFormatting: () => '',
        valueFormatting: (count: string) => i18nService.translate('STANDARD_DASHBOARD.HORIZON.N_POOLS', { count }),
      },
    ];
  }

  /**
   * getPerformanceLevelNames
   * @static
   * @param {string} level
   * @returns {string[]}
   * @memberof HorizonDashboardConfig
   */
  public static getPerformanceLevelNames(level: string): string[] {
    return Object.keys(HorizonDashboardConfig.performanceLevelNames).reduce(
      (levelNames: string[], measureKey: string) => [...levelNames, ...HorizonDashboardConfig.performanceLevelNames[measureKey][level]],
      [],
    );
  }

  /**
   * statusImpactFactorClassMap
   * @static
   * @param {Number} val
   * @param {string} colName
   * @returns {string}
   * @memberof HorizonDashboardConfig
   */
  public static statusImpactFactorClassMap(val: number, colName: string): string {
    const entity = getEntityFromFQN(colName);
    if (entity === Entity.SESSION_SNAPSHOT || entity === Entity.SESSION_SNAPSHOT_TITAN) {
      return HorizonDashboardConfig.getImpactFactorClass(val, 240, 120);
    }
    if (entity === Entity.VM || entity === Entity.VM_TITAN) {
      switch (colName) {
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_cpu_percent:
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_memory_percent:
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_cpu_percent_processor_time:
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_memory_percent:
          return HorizonDashboardConfig.getImpactFactorClass(val, 95, 80);
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_disk_iops:
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_disk_iops:
          return HorizonDashboardConfig.getImpactFactorClass(val, 100, 80);
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_disk_latency:
        case COLUMN_NAMES.byFullyQualifiedName.horizon_vm_titan_disk_latency:
          return HorizonDashboardConfig.getImpactFactorClass(val, 100, 60);
        default:
          return '';
      }
    }
    return '';
  }

  /**
   * getImpactFactorClass
   * @static
   * @param {Number} target
   * @param {Number} threshold1
   * @param {Number} threshold2
   * @returns {string}
   * @memberof HorizonDashboardConfig
   */
  public static getImpactFactorClass(target: number, threshold1: number, threshold2: number): string {
    if (target >= threshold1) {
      return 'font-red';
    }
    if (target >= threshold2) {
      return 'font-orange';
    }
    return '';
  }
}
