/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfig, Endpoint, GenericResponse, GraphqlService, HttpCache, HttpService } from '@ws1c/intelligence-common';
import { CONNECTORS_SEARCH } from '@ws1c/intelligence-core/services/connector-search.graphql';
import { CONNECTORS_USAGE } from '@ws1c/intelligence-core/services/connector-usage.graphql';
import {
  AllManagedConnectorsResponse,
  AutomationRunHistoryResponse,
  Connector,
  ConnectorConfig,
  ConnectorConfigData,
  ConnectorConfigUpdateParams,
  ConnectorCreateParams,
  ConnectorsAllActionsRequest,
  ConnectorsAllActionsResponse,
  ConnectorSchema,
  ConnectorsSearchResponse,
  ConnectorsWithActionsResponse,
  ConnectorUpdateParams,
  GenericSearchRequest,
  ManagedConnectorsSearchResponse,
  SourceObjectType,
} from '@ws1c/intelligence-models';

/**
 * ConnectorCommonService
 *
 * @export
 * @class ConnectorCommonService
 */
@Injectable({
  providedIn: 'root',
})
export class ConnectorCommonService {
  /**
   * @param {HttpService} httpService
   * @param {GraphqlService} graphqlService
   * @memberof ConnectorCommonService
   */
  constructor(private httpService: HttpService, private graphqlService: GraphqlService) {}

  /**
   * searchConnectors
   * @param {GenericSearchRequest} request
   * @returns {Observable<ConnectorsSearchResponse>}
   * @memberOf ConnectorCommonService
   */
  public searchConnectors(request: GenericSearchRequest): Observable<ConnectorsSearchResponse> {
    return this.graphqlService
      .query(CONNECTORS_SEARCH, {
        connectorInput: request,
      })
      .pipe(
        map((response: GenericObject) => {
          response = {
            ...response?.connectorViews?.paged_response,
            results: response?.connectorViews?.connector_views,
          };
          return deserialize(ConnectorsSearchResponse, response);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   * searchActions
   * @param {ConnectorsAllActionsRequest} request
   * @param {SourceObjectType} sourceObjectType
   * @returns {Observable<ConnectorsAllActionsResponse>}
   */
  public searchActions(request: ConnectorsAllActionsRequest, sourceObjectType: SourceObjectType): Observable<ConnectorsAllActionsResponse> {
    const params = sourceObjectType ? new HttpParams().set(AppConfig.QUERY_PARAM_SOURCE_OBJECT_TYPE, sourceObjectType) : {};

    let context;
    if (!request?.searchTerms?.length && !sourceObjectType) {
      context = HttpCache.getContext(HttpCache.BUCKETS.CONNECTORS, HttpCache.KEYS.ALL_CONNECTOR_ACTIONS);
    }

    return this.httpService
      .post(Endpoint.CONNECTOR_ACTIONS, request, {
        params,
        context,
      })
      .pipe(
        map((response: GenericResponse) => deserialize(ConnectorsAllActionsResponse, response)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * getConnectorConfigSchema
   * @param {string} configSchemaId
   * @returns {Observable<ConnectorSchema>}
   */
  public getConnectorConfigSchema(configSchemaId: string): Observable<ConnectorSchema> {
    return this.httpService.get(Endpoint.CONNECTORS_SCHEMAS_ID(configSchemaId)).pipe(
      map((response: GenericResponse) => deserialize(ConnectorSchema, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateConnector
   * @param {string} id
   * @param {ConnectorUpdateParams} params
   * @returns {Observable<Connector>}
   * @memberof ConnectorCommonService
   */
  @HttpCache.clear(HttpCache.BUCKETS.CONNECTORS)
  public updateConnector(id: string, params: ConnectorUpdateParams): Observable<Connector> {
    return this.httpService.patch(Endpoint.CONNECTORS_ID(id), params).pipe(
      map((response: GenericResponse) => deserialize(Connector, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * createCustomConnector
   * @param {ConnectorCreateParams} params
   * @returns {Observable<Connector>}
   * @memberof ConnectorCommonService
   */
  @HttpCache.clear(HttpCache.BUCKETS.CONNECTORS)
  public createCustomConnector(params: ConnectorCreateParams): Observable<Connector> {
    return this.httpService.post(Endpoint.CONNECTORS, params).pipe(
      map((response: GenericResponse) => deserialize(Connector, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteConnector
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof ConnectorCommonService
   */
  @HttpCache.clear(HttpCache.BUCKETS.CONNECTORS)
  public deleteConnector(id: string): Observable<boolean> {
    return this.httpService.delete(Endpoint.CONNECTORS_ID(id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteConnectorConfig
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof ConnectorCommonService
   */
  @HttpCache.clear(HttpCache.BUCKETS.CONNECTORS)
  public deleteConnectorConfig(id: string): Observable<boolean> {
    return this.httpService.delete(Endpoint.CONNECTORS_CONFIG_ID(id)).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getConnectorConfig
   * @param {string} id
   * @returns {Observable<ConnectorConfig>}
   * @memberof ConnectorCommonService
   */
  public getConnectorConfig(id: string): Observable<ConnectorConfig> {
    return this.httpService.get(Endpoint.CONNECTORS_CONFIG_ID(id)).pipe(
      map((response: GenericResponse) => deserialize(ConnectorConfig, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateConnectorConfig
   * @param {string} id
   * @param {ConnectorConfigUpdateParams} params
   * @returns {Observable<boolean>}
   * @memberof ConnectorCommonService
   */
  @HttpCache.clear(HttpCache.BUCKETS.CONNECTORS)
  public updateConnectorConfig(id: string, params: ConnectorConfigUpdateParams): Observable<boolean> {
    return this.httpService.post(Endpoint.CONNECTORS_CONFIG_DATA_ID(id), params).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * createConnectorConfig
   * @param {string} id
   * @param {ConnectorConfigData} configData
   * @returns {Observable<ConnectorConfig>}
   * @memberof ConnectorCommonService
   */
  @HttpCache.clear(HttpCache.BUCKETS.CONNECTORS)
  public createConnectorConfig(id: string, configData: ConnectorConfigData): Observable<ConnectorConfig> {
    return this.httpService.post(Endpoint.CONNECTORS_ID_CONFIG(id), configData).pipe(
      map((response: GenericResponse) => deserialize(ConnectorConfig, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * loadManagedConnectors
   * @param {GenericSearchRequest} request
   * @returns {Observable<ManagedConnectorsSearchResponse>}
   * @memberOf ConnectorCommonService
   */
  public loadManagedConnectors(request: GenericSearchRequest): Observable<ManagedConnectorsSearchResponse> {
    return this.httpService.post(Endpoint.CONNECTORS_REPOSITORY_SEARCH, request).pipe(
      map((response: GenericResponse) => deserialize(ManagedConnectorsSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * loadAllManagedConnectors
   * @returns {Observable<ConnectorsSearchResponse>}
   * @memberOf ConnectorCommonService
   */
  public loadAllManagedConnectors(): Observable<AllManagedConnectorsResponse> {
    return this.httpService.get(Endpoint.CONNECTORS_REPOSITORY).pipe(
      map((response: GenericResponse) => {
        return deserialize(AllManagedConnectorsResponse, response);
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * listConnectorsWithActions
   * @param {ConnectorsAllActionsRequest} request
   * @param {SourceObjectType} sourceObjectType
   * @returns {Observable<ConnectorsWithActionsResponse>}
   * @memberof ConnectorCommonService
   */
  public listConnectorsWithActions(request: ConnectorsAllActionsRequest, sourceObjectType): Observable<ConnectorsWithActionsResponse> {
    const params = sourceObjectType ? new HttpParams().set(AppConfig.QUERY_PARAM_SOURCE_OBJECT_TYPE, sourceObjectType) : {};

    let context;
    if (!request?.searchTerms?.length && !sourceObjectType) {
      context = HttpCache.getContext(HttpCache.BUCKETS.CONNECTORS, HttpCache.KEYS.ALL_CONNECTORS_WITH_ACTIONS);
    }

    return this.httpService.post(Endpoint.CONNECTORS_WITH_ACTIONS, request, { params, context }).pipe(
      map((response: GenericResponse) => deserialize(ConnectorsWithActionsResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getConnectorUsage
   * @param {string} id
   * @param {GenericSearchRequest} request
   * @returns {Observable<AutomationRunHistoryResponse>}
   * @memberof ConnectorCommonService
   */
  public getConnectorUsage(id: string, request: GenericSearchRequest): Observable<AutomationRunHistoryResponse> {
    return this.graphqlService
      .query(CONNECTORS_USAGE, {
        pagedSearchRequestInput: request,
        connectorId: id,
      })
      .pipe(
        map((response: GenericObject) => {
          response = {
            ...response?.automationRunHistory?.paged_response,
            results: response?.automationRunHistory?.automation_run_history_views,
          };
          return deserialize(AutomationRunHistoryResponse, response);
        }),
        catchError(requestErrorHandler),
      );
  }
}
