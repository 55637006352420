/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { isUndefined } from 'lodash-es';

import { ALERT_BANNER_TYPE, AlertBannerTarget } from '@ws1c/intelligence-models';
import { AlertBannerActions } from './alert-banner.actions';
import { AlertBanner, AlertBannerState, initialAlertBannerState } from './alert-banner.state';

const AUTO_DISMISS_DELAY_TIME = 5000;

const _reducer: ActionReducer<AlertBannerState> = createReducer(
  initialAlertBannerState,
  on(
    AlertBannerActions.dismissAlertBanner,
    (state: AlertBannerState, action: ReturnType<typeof AlertBannerActions.dismissAlertBanner>): AlertBannerState => ({
      ...state,
      [action.target]: {
        visible: false,
      },
    }),
  ),
  on(
    AlertBannerActions.showAlertBanner,
    (state: AlertBannerState, action: ReturnType<typeof AlertBannerActions.showAlertBanner>): AlertBannerState => {
      const alertType = action.alertType || ALERT_BANNER_TYPE.INFO;
      const autoDismiss: boolean = isUndefined(action.autoDismiss) ? alertType !== ALERT_BANNER_TYPE.DANGER : action.autoDismiss;
      const target: AlertBannerTarget = isUndefined(action.target) ? AlertBannerTarget.PAGE : action.target;
      return {
        ...state,
        [target]: {
          visible: true,
          alertType,
          autoDismiss,
          hideAfter: action.hideAfter || AUTO_DISMISS_DELAY_TIME,
          message: action.message,
          actionLink: action.actionLink || '',
          actionText: action.actionText || '',
          alertItems: action.alertItems,
        } as AlertBanner,
      };
    },
  ),
);

/**
 * alertBannerState
 *
 * @export
 * @param {AlertBannerState} state
 * @param {Action} action
 * @returns {AlertBannerState}
 */
export function alertBannerState(state: AlertBannerState, action: Action) {
  return _reducer(state, action);
}
