/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { ClrCommonStringsService } from '@clr/angular';
import { DEFAULT_LOCALE, GenericObject, I18N_LOCALES } from '@dpa/ui-common';
import { TranslateLoader } from '@ngx-translate/core';
import { merge } from 'lodash-es';
import { forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoint } from '@ws1c/intelligence-common/constants';
import { loadBundle as loadIntelligenceCoreTranslations } from '@ws1c/intelligence-core/i18n';

/**
 * Custom Loader to load I18N Resources.
 *
 * @export
 * @class I18nResourcesLoader
 * @implements {TranslateLoader}
 */
export class I18nResourcesLoader implements TranslateLoader {
  /**
   * constructor
   * @param {HttpClient} http
   * @param {ClrCommonStringsService} commonStringsService
   * @memberof I18nResourcesLoader
   */
  constructor(private http: HttpClient, private commonStringsService: ClrCommonStringsService) {}

  /**
   * getTranslation
   * A custom loader that fetches the required language JSON file
   * @param {string} locale
   * @returns {Observable<any>}
   * @memberof I18nResourcesLoader
   */
  public getTranslation(locale: string): Observable<any> {
    const localeToLoad = I18N_LOCALES[locale] ? locale : DEFAULT_LOCALE;
    const getCommonLocale = this.http.get(Endpoint.ASSETS_I18N(localeToLoad, Date.now()));
    const getClarityLocale = this.http.get(Endpoint.ASSETS_I18N_CLARITY(localeToLoad, Date.now()));
    const coreTranslationsPromise = this.getIntelligenceCoreTranslations(localeToLoad);
    return forkJoin([getCommonLocale, getClarityLocale, from(coreTranslationsPromise)]).pipe(
      map(([commonLocaleJson, clarityLocaleJson, coreTranslations]: GenericObject[]) => {
        const translations = {
          ...merge({}, commonLocaleJson, coreTranslations),
          ...clarityLocaleJson,
        };
        this.commonStringsService.localize(translations.CLARITY);
        return translations;
      }),
    );
  }

  /**
   * getIntelligenceCoreTranslations
   * @param {string} locale
   * @returns {Promise<GenericObject>}
   * @memberof I18nResourcesLoader
   */
  private getIntelligenceCoreTranslations(locale: string): Promise<GenericObject> {
    return loadIntelligenceCoreTranslations(locale);
  }
}
