/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import {
  CustomReportPreviewSearchResponse,
  Device,
  EntityThresholds,
  PreviewReportContentRequest,
  RuleGroup,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * @export
 * @interface DeviceProfileState
 */
export interface DeviceProfileState {
  device: Device;
  id: string;
  filterRuleGroup: RuleGroup;
  riskScore: string;
  trendDateRange: TrendDateRange;
  thresholdsV2: EntityThresholds;
  ediResponse: CustomReportPreviewSearchResponse;
  propertiesResponse: CustomReportPreviewSearchResponse;
  deviceEventsResponse: CustomReportPreviewSearchResponse;
  deviceEventsRequest: PreviewReportContentRequest;
  performance: {
    app: Record<string, Trend>;
    destination: Record<string, Trend>;
    network: Record<string, Trend>;
    syntheticUrlMonitoring: Record<string, Trend>;
    zoomSummaryRequest: PreviewReportContentRequest;
    zoomSummaryResponse: CustomReportPreviewSearchResponse;
  };
  deviceCrumbListsIndex: GenericObject;
  deviceHealth: string;
  deviceHealthResponse: Record<string, CustomReportPreviewSearchResponse>;
  isSlideOverVisible: boolean;
}

/**
 * @export
 * @type {DeviceProfileState}
 */
export const initialDeviceProfileState: DeviceProfileState = {
  device: undefined,
  id: undefined,
  filterRuleGroup: undefined,
  riskScore: undefined,
  trendDateRange: undefined,
  thresholdsV2: undefined,
  ediResponse: undefined,
  propertiesResponse: undefined,
  deviceEventsResponse: undefined,
  deviceEventsRequest: undefined,
  performance: {
    app: {},
    destination: {},
    network: {},
    syntheticUrlMonitoring: {},
    zoomSummaryRequest: undefined,
    zoomSummaryResponse: undefined,
  },
  deviceCrumbListsIndex: {},
  deviceHealth: undefined,
  deviceHealthResponse: undefined,
  isSlideOverVisible: false,
};
