/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { CopyService, RuleGroupOperator, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { values } from 'lodash-es';
import { Subscription } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import {
  CoreAppState,
  IntegrationMetaSelectors,
  OrgSelectors,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import {
  Column,
  ColumnIndex,
  ColumnToggleFilter,
  FilterRule,
  OrgTreeNode,
  QueryBuilder,
  RuleGroup,
  RuleStatus,
} from '@ws1c/intelligence-models';

interface RuleInfo {
  name: string;
  condition: string;
  translatedValue: string;
  ruleText: string;
  valueCount: number;
}

/**
 * FilterGroupTextViewComponent
 * @export
 * @class FilterGroupTextViewComponent
 */
@Component({
  selector: 'dpa-filter-group-text-view',
  templateUrl: 'filter-group-text-view.component.html',
  styleUrls: ['filter-group-text-view.component.scss'],
})
export class FilterGroupTextViewComponent implements OnDestroy, OnChanges {
  @Input() public ruleGroup: RuleGroup;
  @Input() public readOnly?: boolean = false;
  @Input() public allColumnsByName: ColumnIndex;
  @Input() public isInlineFilter?: boolean = false;
  @Input() public showIncludesAllText?: boolean = false;
  @Input() public hideEmptyRules?: boolean = false;
  @Input() public showLess?: boolean = false;
  @Input() public showEdit?: boolean = false;

  @Output() public focusFilterGroupRule = new EventEmitter<FilterRule>();
  @Output() public edit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public filteredRules: FilterRule[] = [];
  public columnsToShowIncludesAll: Column[] = [];
  public orgHierachy: OrgTreeNode;
  public RULE_STATUS: any = RuleStatus;
  public subs: Subscription[];
  public ruleTextCache = new Map<FilterRule, RuleInfo>();
  public columnToggleFilterMap: Record<string, ColumnToggleFilter> = {};
  public isAttributeSelectorV2Enabled: boolean = false;

  public readonly RuleGroupOperator = RuleGroupOperator;
  public readonly SHOW_VALUES;
  public readonly HIDE_VALUES;

  /**
   * Creates an instance of FilterGroupTextViewComponent.
   * @param {CopyService} copyService
   * @param {I18NService} i18nService
   * @param {Store<CoreAppState>} store
   * @memberof FilterGroupTextViewComponent
   */
  constructor(private copyService: CopyService, private i18nService: I18NService, private store: Store<CoreAppState>) {
    this.subs = [
      this.store.select(OrgSelectors.getOrgHierarchy).subscribe((orgHierachy) => {
        this.orgHierachy = orgHierachy;
        this.computeRuleTextForAllRules(this.ruleGroup);
      }),

      this.store
        .select(IntegrationMetaSelectors.getRawAndNormalizedMapping)
        .subscribe((columnToggleFilterMap: Record<string, ColumnToggleFilter>) => {
          this.columnToggleFilterMap = columnToggleFilterMap;
        }),

      this.store
        .select(UserPreferenceFeatureControlsSelectors.isAttributeSelectorV2Enabled)
        .subscribe((isAttributeSelectorV2Enabled: boolean) => {
          this.isAttributeSelectorV2Enabled = isAttributeSelectorV2Enabled;
        }),
    ];

    const i18nValues = this.i18nService.translate('COMMON_MESSAGES.VALUES');
    this.SHOW_VALUES = `${this.i18nService.translate('COMMON_MESSAGES.SHOW')} ${i18nValues}`;
    this.HIDE_VALUES = `${this.i18nService.translate('COMMON_MESSAGES.HIDE')} ${i18nValues}`;
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterGroupTextViewComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (this.ruleGroup && (changes.allColumnsByName || changes.ruleGroup || changes.showIncludesAllText)) {
      this.columnsToShowIncludesAll = this.showIncludesAllText
        ? this.getColumnsToShowIncludesAll(this.allColumnsByName, this.ruleGroup)
        : [];
      this.computeRuleTextForAllRules(this.ruleGroup);
    }
  }

  /**
   * ngOnDestroy
   * @memberof FilterGroupTextViewComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * getColumnsToShowIncludesAll
   * @param {ColumnIndex} columnsByName
   * @param {RuleGroup} ruleGroup
   * @returns {Column[]}
   */
  public getColumnsToShowIncludesAll(columnsByName: ColumnIndex, ruleGroup: RuleGroup): Column[] {
    const filterRuleAttributes: Set<string> = this.computeAllFilterRuleAttributes(ruleGroup);

    // for FQFN check for column.attributeName
    return values(columnsByName).filter((column: Column) => !filterRuleAttributes.has(column.attributeName));
  }

  /**
   * Sets index of filter to focus
   * @param {FilterRule} rule
   * @param {Event} $event
   * @memberof FilterGroupTextViewComponent
   */
  public onFilterRuleClick(rule: FilterRule, $event: Event) {
    $event.preventDefault();
    this.focusFilterGroupRule.emit(rule);
  }

  /**
   * computeRuleTextVersion
   * @param {FilterRule} rule
   * @memberof FilterGroupTextViewComponent
   */
  public computeRuleTextVersion(rule: FilterRule) {
    const { name, condition, value } = rule.getNormalizedRule(this.allColumnsByName, this.orgHierachy);
    const translatedValue = typeof value === 'string' ? value : this.i18nService.translate(value.resource, value.data);

    const valueCount = Array.isArray(rule.data) ? rule.data.length : 1;
    const ruleText = `${name} ${condition} ${translatedValue}`;

    this.ruleTextCache.set(rule, {
      name,
      condition,
      translatedValue,
      ruleText,
      valueCount,
    });
  }

  /**
   * computeRuleTextForAllRules
   * @param {RuleGroup} ruleGroup
   * @memberof FilterGroupTextViewComponent
   */
  public computeRuleTextForAllRules(ruleGroup: RuleGroup) {
    if (!ruleGroup?.rules) {
      return;
    }
    ruleGroup.rules.forEach((ruleOrRuleGroup: (typeof ruleGroup.rules)[number]) => {
      if (RuleGroup.isRuleGroup(ruleOrRuleGroup)) {
        this.computeRuleTextForAllRules(ruleOrRuleGroup);
        return;
      }
      this.computeRuleTextVersion(ruleOrRuleGroup);
    });
  }

  /**
   * getRuleTextVersion
   * @param {FilterRule} rule
   * @returns {RuleInfo}
   * @memberof FilterGroupTextViewComponent
   */
  public getRuleInfo(rule): RuleInfo {
    return this.ruleTextCache.get(rule) ?? ({} as RuleInfo);
  }

  /**
   * getRuleStatus
   * @param {FilterRule} rule
   * @returns {RuleStatus} RuleStatus enum value
   * @memberof FilterGroupTextViewComponent
   */
  public getRuleStatus(rule: FilterRule): RuleStatus {
    return rule.getStatus(this.allColumnsByName);
  }

  /**
   * toggleRuleView
   * @memberof FilterGroupTextViewComponent
   */
  public toggleRuleView() {
    this.showLess = !this.showLess;
  }

  /**
   * copyRules
   * @memberof FilterGroupTextViewComponent
   */
  public copyRules() {
    const queryBuilder = new QueryBuilder(this.ruleGroup);
    this.copyService.copyToClipboard(queryBuilder.getQueryString());
  }

  /**
   * isArrayCondition
   * @param {string} operator
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public isArrayCondition(operator): boolean {
    return QueryBuilder.isMultiSelect(operator);
  }

  /**
   * displayRule
   * @param {FilterRule} rule
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public displayRule(rule: FilterRule): boolean {
    if (!this.hideEmptyRules) {
      return true;
    } else {
      return !!rule?.attribute && !!rule?.data;
    }
  }

  /**
   * displayRuleOperator
   * @param {FilterRule | RuleGroup} rule
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public displayRuleOperator(rule: FilterRule | RuleGroup): boolean {
    if (RuleGroup.isRuleGroup(rule)) {
      return true;
    } else {
      return this.displayRule(rule);
    }
  }

  /**
   * isRuleGroup
   * @param {FilterRule | RuleGroup} rule
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public isRuleGroup(rule: FilterRule | RuleGroup): rule is RuleGroup {
    return RuleGroup.isRuleGroup(rule);
  }

  /**
   * toggleEdit
   * @memberof FilterGroupTextViewComponent
   */
  public toggleEdit(): void {
    this.showEdit = !this.showEdit;
    this.edit.emit(this.showEdit);
  }

  /**
   * showRuleSet
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public showRuleSet(): boolean {
    const hasRules = this.hasRules();
    return hasRules || (!hasRules && !this.hideEmptyRules);
  }

  /**
   * hasRules
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public hasRules(): boolean {
    return this.ruleGroup?.rules?.length && this.ruleGroup?.rules.some((rule: FilterRule) => !!rule.attribute && !!rule.data);
  }

  /**
   * showRowNumber
   * @param {number} ruleIndex
   * @returns {boolean}
   * @memberof FilterGroupTextViewComponent
   */
  public showRowNumber(ruleIndex: number): boolean {
    return (ruleIndex === 0 && !!this.columnsToShowIncludesAll?.length) || ruleIndex > 0;
  }

  /**
   * computeAllFilterRuleAttributes
   * @param {RuleGroup} ruleGroup
   * @returns {Set<string>}
   * @memberof FilterGroupTextViewComponent
   */
  private computeAllFilterRuleAttributes(ruleGroup: RuleGroup): Set<string> {
    const resultSet = new Set<string>();
    for (const rule of ruleGroup.rules) {
      if (this.isRuleGroup(rule)) {
        for (const attr of this.computeAllFilterRuleAttributes(rule)) {
          resultSet.add(attr);
        }
      } else {
        if (rule.data) {
          resultSet.add(rule.attribute);
        }
      }
    }
    return resultSet;
  }
}
