/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatureControl } from '@ws1c/intelligence-common';
import { featureControlGuard } from '@ws1c/intelligence-core/guards';
import { NotificationComponent } from './notification.component';

const routes: Routes = [
  {
    path: '',
    component: NotificationComponent,
    canActivate: [featureControlGuard],
    data: {
      featureControl: [FeatureControl.NOTIFICATION_ENABLED],
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'center',
      },
      {
        path: 'center',
        loadChildren: () =>
          import('./components/pages/notification-center/notification-center.module').then((route) => route.NotificationCenterModule),
      },
      {
        path: 'settings',
        canActivate: [featureControlGuard],
        data: {
          featureControl: [FeatureControl.NOTIFICATION_RULES_ENABLED],
        },
        loadChildren: () =>
          import('./components/pages/notification-settings/notification-settings.module').then((route) => route.NotificationSettingsModule),
      },
    ],
  },
];

/**
 * NotificationRoutingModule
 *
 * @export
 * @class NotificationRoutingModule
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutingModule {}
