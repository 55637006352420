/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

export const DEEM_ROUTE_SEGMENTS = {
  ADD: 'add',
  APP_CONTRIBUTOR_ERROR: ':subDashboardId/:platform/contri/:type/module/:module/error/:error/modulePath/:modulePath',
  APP_INTEGRATIONS_ZOOM: 'app-integrations/zoom',
  CREATE_SURVEY: 'create-survey',
  HORIZON_APPS_ALL: 'horizon-apps/all',
  HORIZON_APP_DETAIL: 'horizon-apps/all/:appName/app-detail',
  HORIZON_SESSIONS_ALL: 'horizon-sessions/all',
  HORIZON_SESSIONS_LOGON_EXPERIENCE: 'horizon-sessions/logon-experience',
  HUB_NOTIFICATIONS: 'hub-notifications',
  INCIDENTS: 'incidents',
  INSIGHTS: 'insights',
  INSIGHT_ID_PARAM: ':insightId',
  LIST: 'list',
  MANAGEMENT_COMPONENTS_ALL: 'management-components/all',
  NETWORK_PROTOCOL: 'horizon-sessions/network-protocol',
  ORGANIZATION_ALL: 'organization/all',
  ORGANIZATION_OVERVIEW: 'organization-overview',
  OVERVIEW: 'overview',
  PLAYBOOKS: 'playbooks',
  SAM: 'sam',
  SETTINGS: 'settings',
  SETUP: 'setup',
  SUMMARY: 'summary',
  SURVEYS: 'surveys',
  SYNTHETIC_URL_MONITORING: 'synthetic-url-monitoring',
  UPDATE: 'update',
  USER_DETAIL_V2: 'horizon-sessions/logon-experience/user-detail',
  VIRTUAL_V2: 'virtual-v2',
  VM_PERFORMANCE: 'horizon-sessions/vm-performance',
  VIRTUAL_CONTRIBUTOR: ':subDashboardId/:platform/contributor/:type',
  VIRTUAL_SESSION_DETAILS: ':subDashboardId/:platform/session-details/overview',
  PATH_ID: ':id',
  ZOOM_CONTRIBUTORS: 'app-integrations/zoom/contributors/:contributor',
  ZOOM_MEETING_ID: 'app-integrations/zoom/meeting/:id',
  ZOOM_USER_ID: 'app-integrations/zoom/user/:id',
} as const;

export const DEEM_ROUTE_NAMES = {
  APP_INTEGRATIONS: '/workspace/solutions/deem/summary/physical/app-integrations',
  APP_INTEGRATIONS_ALL: 'app-integrations/all',
  DEEM: 'deem',
  DEEM_PLAYBOOKS: '/workspace/solutions/deem/playbooks',
  DESKTOP_APPS_ALL: 'desktop-apps/all',
  DESKTOP_APPS_MACOS: 'desktop-apps/macos',
  DESKTOP_APPS_WINDOWS: 'desktop-apps/windows',
  DEVICES_ALL: 'devices/all',
  DEVICES_MACOS: 'devices/macos',
  DEVICES_WINDOWS: 'devices/windows',
  HORIZON_APPS_ALL: 'horizon-apps/all',
  HORIZON_SESSIONS_ALL: 'horizon-sessions/all',
  HORIZON_SESSIONS_LOGON_EXPERIENCE: 'horizon-sessions/logon-experience',
  MANAGEMENT_COMPONENTS_ALL: 'management-components/all',
  MOBILE_APPS_ALL: 'mobile-apps/all',
  MOBILE_APPS_ANDROID: 'mobile-apps/android',
  MOBILE_APPS_IOS: 'mobile-apps/ios',
  NETWORK_PROTOCOL: 'horizon-sessions/network-protocol',
  SETUP: 'setup',
  SUMMARY_PHYSICAL: '/workspace/solutions/deem/summary/physical',
  SUMMARY_PHYSICAL_ORGANIZATION: '/workspace/solutions/deem/summary/physical/organization/all',
  SUMMARY_PHYSICAL_ORGANIZATION_PREFIX: '/workspace/solutions/deem/summary/physical/organization',
  SUMMARY_PHYSICAL_DEVICES: '/workspace/solutions/deem/summary/physical/devices/all',
  SUMMARY_PHYSICAL_DEVICES_PREFIX: '/workspace/solutions/deem/summary/physical/devices',
  SUMMARY_PHYSICAL_DEVICES_MACOS: '/workspace/solutions/deem/summary/physical/devices/macos',
  SUMMARY_PHYSICAL_DEVICES_WINDOWS: '/workspace/solutions/deem/summary/physical/devices/windows',
  SUMMARY_PHYSICAL_DESKTOP_APPS: '/workspace/solutions/deem/summary/physical/desktop-apps/all',
  SUMMARY_PHYSICAL_DESKTOP_APPS_PREFIX: '/workspace/solutions/deem/summary/physical/desktop-apps',
  SUMMARY_PHYSICAL_DESKTOP_APPS_MACOS: '/workspace/solutions/deem/summary/physical/desktop-apps/macos',
  SUMMARY_PHYSICAL_DESKTOP_APPS_WINDOWS: '/workspace/solutions/deem/summary/physical/desktop-apps/windows',
  SUMMARY_PHYSICAL_MOBILE_APPS: '/workspace/solutions/deem/summary/physical/mobile-apps/all',
  SUMMARY_PHYSICAL_MOBILE_APPS_PREFIX: '/workspace/solutions/deem/summary/physical/mobile-apps',
  SUMMARY_PHYSICAL_MOBILE_APPS_ANDROID: '/workspace/solutions/deem/summary/physical/mobile-apps/android',
  SUMMARY_PHYSICAL_MOBILE_APPS_IOS: '/workspace/solutions/deem/summary/physical/mobile-apps/ios',
  SUMMARY_PHYSICAL_USERS: '/workspace/solutions/deem/summary/physical/users/all',
  SUMMARY_PHYSICAL_USERS_PREFIX: '/workspace/solutions/deem/summary/physical/users',
  SUMMARY_PHYSICAL_APP_DETAIL: (subDashboardId: string, platform: string, appName: string) =>
    `/workspace/solutions/deem/summary/physical/${subDashboardId}/${platform}/${appName}/app-detail`,
  SUMMARY_PHYSICAL_CONTRITOR: (subDashboardId: string, platform: string, attribute: string) =>
    `/workspace/solutions/deem/summary/physical/${subDashboardId}/${platform}/contributor/${attribute}`,
  SUMMARY_PHYSICAL_HORIZON_APP_DETAIL: (appName: string) =>
    `/workspace/solutions/deem/summary/virtual-v2/horizon-apps/all/${appName}/app-detail`,
  SUMMARY_VIRTUAL_MANAGEMENT_COMPONENTS: '/workspace/solutions/deem/summary/virtual-v2/management-components/all',
  SUMMARY_VIRTUAL_HORIZON_APPS: '/workspace/solutions/deem/summary/virtual-v2/horizon-apps/all',
  SUMMARY_VIRTUAL_HORIZON_APPS_PREFIX: '/workspace/solutions/deem/summary/virtual-v2/horizon-apps',
  SUMMARY_VIRTUAL_HORIZON_SESSIONS: '/workspace/solutions/deem/summary/virtual-v2/horizon-sessions/all',
  SUMMARY_VIRTUAL_HORIZON_SESSIONS_PREFIX: '/workspace/solutions/deem/summary/virtual-v2/horizon-sessions',
  SUMMARY_VIRTUAL_HORIZON_SESSIONS_LOGON_EXPERIENCE: '/workspace/solutions/deem/summary/virtual-v2/horizon-sessions/logon-experience',
  SUMMARY_VIRTUAL_HORIZON_SESSIONS_NETWORK_PROTOCOL: '/workspace/solutions/deem/summary/virtual-v2/horizon-sessions/network-protocol',
  SUMMARY_VIRTUAL_HORIZON_SESSIONS_VM_PERFORMANCE: '/workspace/solutions/deem/summary/virtual-v2/horizon-sessions/vm-performance',
  SUMMARY_VIRTUAL_ORGANIZATION_PREFIX: '/workspace/solutions/deem/summary/virtual-v2/organization',
  SUMMARY_VIRTUAL_USER_DETAIL: '/workspace/solutions/deem/summary/virtual/logon-experience/user-detail',
  SOLUTIONS_DEEM_SUMMARY_VIRTUAL_CONTRIBUTOR: (subDashboardId: string, platform: string, type: string) =>
    `/workspace/solutions/deem/summary/virtual-v2/${subDashboardId}/${platform}/contributor/${type}`,
  SUMMARY_VIRTUAL_SESSION_DETAILS_DASHBOARD: (subDashboardId: string, platform: string) =>
    `/workspace/solutions/deem/summary/virtual-v2/${subDashboardId}/${platform}/session-details/overview`,
  SUMMARY_VIRTUAL_USER_DETAIL_V2: '/workspace/solutions/deem/summary/virtual-v2/horizon-sessions/logon-experience/user-detail',
  SUMMARY_VIRTUAL_V2: '/workspace/solutions/deem/summary/virtual-v2',
  SETTINGS_FULL_PATH: '/workspace/solutions/deem/settings',
  SETTINGS_DATA_SOURCE: '/workspace/solutions/deem/settings/datasource',
  SETTINGS_THRESHOLDS: '/workspace/solutions/deem/settings/ux-score',
  USERS_ALL: 'users/all',
  HUB_NOTIFICATION_OVERVIEW: '/workspace/solutions/deem/hub-notifications/overview',
  HUB_NOTIFICATION_LIST: '/workspace/solutions/deem/hub-notifications/list',
  LEARNMORE: 'learnmore',
  ORGANIZATION_ALL: 'organization/all',
  OVERVIEW: 'overview',
  TOTAL_ANALYTICS: 'total-analytics',
  UNIQUE_ANALYTICS: 'unique-analytics',
  CREATE_SURVEY_FQ: 'workspace/solutions/deem/create-survey',
  NEW: 'new',
  RESULTS: 'results',
  ACTIVITY: 'activity',
  // aligned to ROUTE_NAMES.WORKFLOW
  AUTOMATIONS: 'workflow',
  COMMENTS: 'comments',
  AUDIT_LOG: 'audit-log',
  DEEM_HOME: `workspace/solutions/deem/summary`,
  INCIDENT_ID: (incidentId: string) => `workspace/solutions/deem/incidents/${incidentId}`,
  INCIDENT_DASHBOARD_ADD: 'dashboard/add',
  INCIDENT_DASHBOARD_LIST: 'dashboard/list',
  INCIDENT_DASHBOARDS: (incidentId: string) => `workspace/solutions/deem/incidents/${incidentId}/overview`,
  INSIGHT_ID: (insightId: string) => `/workspace/solutions/deem/insights/${insightId}`,
  RCA: 'rca',
  RCA_ID: (incidentId: string) => `workspace/solutions/deem/incidents/${incidentId}/rca`,
  MOBILE_APP: 'mobile-app',
  FRONTLINE: 'frontline',
  DESKTOP_EXPERIENCE: 'desktop-experience',
  MOBILE_EXPERIENCE: 'mobile-experience',
  SYNTHETIC_URL_MONITORING_URL_TEST_DETAIL: (testId: string) => `/workspace/solutions/deem/synthetic-url-monitoring/list/${testId}`,
  VM_PERFORMANCE: 'horizon-sessions/vm-performance',
  ZOOM: '/workspace/solutions/deem/summary/physical/app-integrations/zoom',
  SAM_PORTFOLIO: 'portfolio',
  SAM_DISCOVER: 'discover',
  SAM_OPTIMIZE: 'optimize',
  SAM_COMPARE: 'compare',
};
