/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { REMEDIATION_ROUTE_REGEX } from '@dpa-components/compliance/compliance.routes';
import { Endpoint, EndpointV2 } from '@ws1c/intelligence-common';
import { AuthService } from '@ws1c/intelligence-core';
import { LOAD_STATE, ROUTE_SEGMENTS } from '@ws1c/intelligence-models';

export type ShouldReloadFn = (error: HttpErrorResponse) => boolean;

/**
 * Handle Errors (401, 403, 504)
 *
 * @export
 * @class HttpAuthenticationInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HttpAuthenticationInterceptor implements HttpInterceptor {
  public static readonly UNAUTHORIZED_ERROR: number = 401;
  public static readonly FORBIDDEN_ERROR: number = 403;
  public static readonly GATEWAY_TIMEOUT_ERROR: number = 504;

  public static readonly AUTHENTICATION_ERRORS: number[] = [
    HttpAuthenticationInterceptor.UNAUTHORIZED_ERROR,
    HttpAuthenticationInterceptor.FORBIDDEN_ERROR,
    HttpAuthenticationInterceptor.GATEWAY_TIMEOUT_ERROR,
  ];

  public static readonly SERVICE_LIMIT_ERROR_PREFIX: string = 'SERVICE-LIMIT-MAX';

  /**
   * Creates an instance of HttpAuthenticationInterceptor.
   * @param {AuthService} authService
   * @param {Router} router
   * @memberof HttpAuthenticationInterceptor
   */
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Redirect to access denied or login page when we hit {AUTHENTICATION_ERRORS}
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpAuthenticationInterceptor
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!this.shouldReloadFn(error) || !HttpAuthenticationInterceptor.AUTHENTICATION_ERRORS.includes(error.status)) {
          return throwError(() => error);
        }
        if (error.status === HttpAuthenticationInterceptor.UNAUTHORIZED_ERROR) {
          this.authService.setPreferenceLoadState(LOAD_STATE.FAILURE);
        }
        if (error.status === HttpAuthenticationInterceptor.FORBIDDEN_ERROR) {
          this.authService.setAccessError(error);
        }
        this.router.navigate([`/${ROUTE_SEGMENTS.ACCESS_DENIED}`]);
        return EMPTY;
      }),
    );
  }

  /**
   * shouldReloadFn
   *
   * @private
   * @type {ShouldReloadFn}
   * @memberof HttpAuthenticationInterceptor
   */
  private shouldReloadFn: ShouldReloadFn = (error: HttpErrorResponse) => {
    // Ignore SERVICE_LIMIT_EXCEEDED_ERROR_CODES
    const payload = error.error;
    const errorCode = payload && payload.errors ? payload.errors[0].code : '';
    if (errorCode && errorCode.startsWith(HttpAuthenticationInterceptor.SERVICE_LIMIT_ERROR_PREFIX)) {
      return false;
    }
    // Ignore all errors on compliance URLs
    if (REMEDIATION_ROUTE_REGEX.test(this.router.url)) {
      return false;
    }
    // Ignore error on our bootstrap UI APIs and Automation Test Action API
    const endpointWhitelist = [
      // v1/org/xxx
      Endpoint.INTEGRATED_SERVICES,
      Endpoint.ORG_PREFERENCES,
      // v1/iam/xxx
      Endpoint.SESSION_SCOPES,
      // v1/userpreference
      Endpoint.USER_PREFERENCE,
      // v2/services/actions/lookup
      EndpointV2.AUTOMATION_ACTIONS_LOOKUP,
      // ui/xxx
      Endpoint.UI_ROOT,
      Endpoint.UI_ORG,
      Endpoint.UI_USER_ACCOUNT,
    ];
    return !endpointWhitelist.some((path: string) => error.url.includes(path));
  };
}
