/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Endpoint, HttpService } from '@ws1c/intelligence-common';
import { AccountService } from '@ws1c/intelligence-core';
import {
  Account,
  AccountSearchResponse,
  DataAccessPolicy,
  DataAccessPolicyAssignPreview,
  DataAccessPolicyAssignPreviewResponse,
  DataAccessPolicyBulkAssignRequest,
  DataAccessPolicyDeleteRequest,
  DataAccessPolicySearchResponse,
  GenericSearchRequest,
  IdentifiersRequest,
} from '@ws1c/intelligence-models';

/**
 * Provides REST Interface for data access policy API's
 *
 * @export
 * @class DataAccessPolicyService
 */
@Injectable()
export class DataAccessPolicyService {
  /**
   * Creates an instance of DataAccessPolicyService.
   * @param {HttpService} httpService
   * @param {AccountService} accountService
   * @memberof DataAccessPolicyService
   */
  constructor(private httpService: HttpService, private accountService: AccountService) {}

  /**
   * Get list of data access policy
   * @param {GenericSearchRequest} searchRequest
   * @returns {Observable<DataAccessPolicySearchResponse>}
   * @memberof DataAccessPolicyService
   */
  public getDataAccessPolicy(searchRequest: GenericSearchRequest): Observable<DataAccessPolicySearchResponse> {
    return this.httpService.post(Endpoint.DATA_ACCESS_POLICY_SEARCH, searchRequest).pipe(
      map((response: any) => deserialize(DataAccessPolicySearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * addDataAccessPolicy
   * @param {DataAccessPolicy} dataAccessPolicy
   * @returns {Observable<DataAccessPolicy>}
   * @memberof DataAccessPolicyService
   */
  public addDataAccessPolicy(dataAccessPolicy: DataAccessPolicy): Observable<DataAccessPolicy> {
    return this.httpService.post(Endpoint.DATA_ACCESS_POLICY, dataAccessPolicy).pipe(
      map((response: any) => deserialize(DataAccessPolicy, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * updateDataAccessPolicy
   * @param {DataAccessPolicy} dataAccessPolicy
   * @returns {Observable<DataAccessPolicy>}
   * @memberof DataAccessPolicyService
   */
  public updateDataAccessPolicy(dataAccessPolicy: DataAccessPolicy): Observable<DataAccessPolicy> {
    return this.httpService.put(Endpoint.DATA_ACCESS_POLICY, dataAccessPolicy).pipe(
      map((response: any) => deserialize(DataAccessPolicy, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * assignUsers
   * @param {DataAccessPolicyBulkAssignRequest} bulkAssignRequest
   * @returns {Observable<boolean>}
   * @memberof DataAccessPolicyService
   */
  public assignUsers(bulkAssignRequest: DataAccessPolicyBulkAssignRequest): Observable<boolean> {
    return this.httpService.put(Endpoint.DATA_ACCESS_POLICY_ASSIGN_USERS, bulkAssignRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAssignUsersPreview
   * @param {DataAccessPolicyBulkAssignRequest} bulkAssignRequest
   * @returns {Observable<DataAccessPolicyAssignPreview>}
   * @memberof DataAccessPolicyService
   */
  public getAssignUsersPreview(bulkAssignRequest: DataAccessPolicyBulkAssignRequest): Observable<DataAccessPolicyAssignPreview> {
    return this.httpService.put(Endpoint.DATA_ACCESS_POLICY_ASSIGN_USERS_PREVIEW, bulkAssignRequest).pipe(
      map((response: any) => deserialize(DataAccessPolicyAssignPreviewResponse, response).preview),
      catchError(requestErrorHandler),
    );
  }

  /**
   * unAssignUsers
   * @param {DataAccessPolicyBulkAssignRequest} bulkAssignRequest
   * @returns {Observable<boolean>}
   * @memberof DataAccessPolicyService
   */
  public unAssignUsers(bulkAssignRequest: DataAccessPolicyBulkAssignRequest): Observable<boolean> {
    return this.httpService.put(Endpoint.DATA_ACCESS_POLICY_UNASSIGN_USERS, bulkAssignRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAssignedUsers
   * @param {string} policyId
   * @returns {Observable<AccountSearchResponse>}
   * @memberof DataAccessPolicyService
   */
  public getAssignedUsers(policyId: string): Observable<AccountSearchResponse> {
    return this.getDapAccounts(policyId).pipe(
      mergeMap((accountSearchResponse: AccountSearchResponse) => {
        if (isEmpty(accountSearchResponse.results)) {
          return of(accountSearchResponse);
        }
        return this.accountService
          .getAccountsById(
            new IdentifiersRequest({
              identifiers: accountSearchResponse.results.map((account: Account) => account.id),
            }),
          )
          .pipe(
            map((accounts: Account[]) => {
              accountSearchResponse.results.forEach((account: Account) => {
                const user = accounts.find((accountInfo: Account) => accountInfo.id === account.id);
                Object.assign(account, {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  directory: user.directory,
                });
              });
              return accountSearchResponse;
            }),
            catchError(requestErrorHandler),
          );
      }),
    );
  }

  /**
   * deleteDataAccessPolicy
   * @param {DataAccessPolicy[]} dataAccessPolicies
   * @returns {Observable<DataAccessPolicySearchResponse>}
   * @memberof DataAccessPolicyService
   */
  public deleteDataAccessPolicy(dataAccessPolicies: DataAccessPolicy[]): Observable<DataAccessPolicySearchResponse> {
    const dataAccessPolicyDeleteRequest = new DataAccessPolicyDeleteRequest({
      policyIds: dataAccessPolicies.map((policy: DataAccessPolicy) => policy.id),
    });
    return this.httpService.delete(Endpoint.DATA_ACCESS_POLICY, { body: dataAccessPolicyDeleteRequest }).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get data access policy by id
   * @param {string} policyId
   * @returns {Observable<DataAccessPolicy>}
   * @memberof DataAccessPolicyService
   */
  public getDataAccessPolicyById(policyId: string): Observable<DataAccessPolicy> {
    return this.httpService.get(Endpoint.DATA_ACCESS_POLICY_ID(policyId)).pipe(
      map((response: any) => deserialize(DataAccessPolicy, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getDapAccounts
   * @private
   * @param {string} policyId
   * @returns {Observable<AccountSearchResponse>}
   * @memberof DataAccessPolicyService
   */
  private getDapAccounts(policyId: string): Observable<AccountSearchResponse> {
    return this.httpService
      .post(
        Endpoint.DATA_ACCESS_POLICY_GET_ASSIGNED_USERS(policyId),
        new GenericSearchRequest({
          size: 1000,
        }),
      )
      .pipe(
        map((response: any) => deserialize(AccountSearchResponse, response.data)),
        catchError(requestErrorHandler),
      );
  }
}
