/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * RawThresholdAttribute
 * @export
 * @enum {string}
 */
export enum RawThresholdAttribute {
  BOOT_TIME = 'boot_time',
  LOGIN_TIME = 'login_time',
  SHUTDOWN_TIME = 'shutdown_time',
  APP_CPU_USAGE = 'app_cpu_usage',
  APP_CRASH_RATE = 'app_crash_rate',
  APP_GPU_USAGE = 'app_gpu_usage',
  APP_HANG_RATE = 'app_hang_rate',
  APP_HANDLED_EXCEPTIONS = 'app_handled_exceptions',
  APP_NET_ERROR_RATE = 'app_net_error_rate',
  APP_MEMORY_USAGE = 'app_memory_usage',
  AVERAGE_APP_UI_UNRESPONSIVE_DURATION = 'average_app_ui_unresponsive_duration',
  AVERAGE_APP_UI_UNRESPONSIVE_EVENTS_PER_DEVICE = 'average_app_ui_unresponsive_events_per_device',
  APP_UI_UNRESPONSIVE_RATE = 'app_ui_unresponsive_rate',
  AVG_APP_LATENCY_MILLIS = 'avg_app_latency_millis',
  CRASH_COUNT = 'crash_count',
  AVERAGE_BOOT_TIME_MILLIS = 'average_boot_time_millis',
  AVERAGE_SHUTDOWN_TIME_MILLIS = 'average_shutdown_time_millis',
  BATTERY_HEALTH = 'battery_health',
  USERS_WITH_POOR_SCORE_PERCENT = 'users_with_poor_score_percent',
  CPU_HEALTH = 'cpu_health',
  CPU_PERCENT = 'cpu_percent',
  DAYS_BEFORE_CERTIFICATE_EXPIRATION = 'days_before_certificate_expiration',
  DEVICE_TEMPERATURE = 'device_temperature',
  DISK_IOPS = 'disk_iops',
  DISK_LATENCY = 'disk_latency',
  GPU_HEALTH = 'gpu_health',
  LOGON_DURATION = 'logon_duration',
  MEMORY_HEALTH = 'memory_health',
  MEMORY_PERCENT = 'memory_percent',
  PACKET_LOSS_RATE = 'packet_loss_rate',
  PROTOCOL_LATENCY = 'protocol_latency',
  DOT11_SIGNAL_QUALITY = 'dot11_signal_quality',
  WIFI_STRENGTH = 'wifi_strength',
  DOWNLOAD_SPEED = 'download_speed',
  UPLOAD_SPEED = 'upload_speed',
  LAN_AVERAGE_LATENCY = 'lan_average_latency',
  WAN_AVERAGE_LATENCY = 'wan_average_latency',
  LAN_PACKET_LOSS_RATE = 'lan_packet_loss_rate',
  WAN_PACKET_LOSS_RATE = 'wan_packet_loss_rate',
}
