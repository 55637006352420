/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { RouterExtensions } from '@ws1c/intelligence-common';
import { BookmarksActions, BookmarksSelectors } from '@ws1c/intelligence-core';
import { Bookmark, NavigationMenuGroup } from '@ws1c/intelligence-models';

/**
 * Bookmarks Menu Component
 *
 * @export
 * @class BookmarksMenuComponent
 */
@Component({
  selector: 'dpa-bookmarks-menu',
  templateUrl: 'bookmarks-menu.component.html',
  styleUrls: ['bookmarks-menu.component.scss'],
})
export class BookmarksMenuComponent {
  public activeBookmarks$: Observable<Bookmark[]>;
  public sub: Subscription = new Subscription();

  public readonly NavigationMenuGroup = NavigationMenuGroup;

  /**
   * Initializes BookmarksMenuComponent instance
   * @param {RouterExtensions} router - RouterExtensions instance
   * @param {Store} store
   * @memberof BookmarksMenuComponent
   */
  constructor(private router: RouterExtensions, private store: Store) {
    this.activeBookmarks$ = this.store.select(BookmarksSelectors.getBookmarks);
  }

  /**
   * Handler for bookmark menu item click.
   * @param {string} path - bookmark path
   * @memberof BookmarksMenuComponent
   */
  public onBookmarkClick(path: string) {
    this.router.navigateByUrl(path);
  }

  /**
   * Handler for remove bookmark button click.
   * @param {Event} $event - click event object
   * @param {Bookmark} bookmark - Holds bookmark object which needs to be removed
   * @memberof BookmarksMenuComponent
   */
  public onRemoveBookmark($event: Event, bookmark: Bookmark) {
    $event.stopPropagation();
    $event.preventDefault();
    this.store.dispatch(BookmarksActions.removeBookmark({ bookmark }));
  }
}
