/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { getURLAttributes, UrlAttributes } from '@ws1c/intelligence-common';
import { OrgSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import { AvailabilityStatus, LOAD_STATE } from '@ws1c/intelligence-models';
import { TenantReprovisionConfig } from '@ws1c/intelligence-onboarding/const/tenant-reprovision-config';
import {
  IntegrationsState,
  TenantNameAvailabilityStatus,
  TenantUrlConfigurationState,
  TenantUrlUpdateStatus,
  TenantUsersDetail,
} from '@ws1c/intelligence-onboarding/models';

/**
 * TenantUrlConfigurationSelectors
 * @export
 * @class TenantUrlConfigurationSelectors
 */
export class TenantUrlConfigurationSelectors {
  /**
   * Selects the Tenant Url Configuration state slice from Integrations store
   * @static
   * @type {(state: IntegrationsState) => TenantUrlConfigurationState}
   * @returns {TenantUrlConfigurationState}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static selectTenantUrlConfigurationState = (state) => state.integrations.tenantUrlConfiguration;

  /**
   * activeTenantIdSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, string>>}
   * @returns {string}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static activeTenantIdSelector: MemoizedSelector<IntegrationsState, string> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.activeTenant?.id,
  );

  /**
   * activeTenantUrlSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, string>>}
   * @returns {string}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static activeTenantUrlSelector: MemoizedSelector<IntegrationsState, string> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.activeTenant?.tenantHost,
  );

  /**
   * activeTenantUrlAttributesSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, UrlAttributes>}
   * @returns {UrlAttributes}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static activeTenantUrlAttributesSelector: MemoizedSelector<IntegrationsState, UrlAttributes> = createSelector(
    TenantUrlConfigurationSelectors.activeTenantUrlSelector,
    getURLAttributes,
  );

  /**
   * isOnboardingMode
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isOnboardingMode: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.isOnboardingMode,
  );

  /**
   * isRefreshingTenantUrlUpdateStatusSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isRefreshingTenantUrlUpdateStatusSelector: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.isRefreshingTenantUrlUpdateStatus,
  );

  /**
   * isTenantRenameStarting
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantRenameStarting: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    OrgSelectors.isAccessTenantReprovisionStepCompleted,
    (state: TenantUrlConfigurationState, isAccessTenantReprovisionStepCompleted: boolean) => {
      const tenantRenameStartState: LOAD_STATE = state.tenantRenameStartState;
      return (
        tenantRenameStartState === LOAD_STATE.IN_FLIGHT ||
        (tenantRenameStartState === LOAD_STATE.SUCCESS && !isAccessTenantReprovisionStepCompleted)
      );
    },
  );

  /**
   * isTenantRenameStarted
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantRenameStarted: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    OrgSelectors.isAccessTenantReprovisionStepCompleted,
    (state: TenantUrlConfigurationState, isAccessTenantReprovisionStepCompleted: boolean) => {
      return state.tenantRenameStartState === LOAD_STATE.SUCCESS && isAccessTenantReprovisionStepCompleted;
    },
  );

  /**
   * isTenantActivelyUsed
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantActivelyUsed: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => {
      return state.tenantUsersDetail?.usersCount >= TenantReprovisionConfig.MINIMUM_TENANT_USERS_COUNT;
    },
  );

  /**
   * isTenantReprovisionCancelModalOpen
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantReprovisionCancelModalOpen: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.isTenantReprovisionCancelModalOpen,
  );

  /**
   * isTenantReprovisionResultModalOpen
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantReprovisionResultModalOpen: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.isTenantReprovisionResultModalOpen,
  );

  /**
   * isTenantUrlUpdateModalOpenSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantUrlUpdateModalOpenSelector: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.isTenantUrlUpdateModalOpen,
  );

  /**
   * tenantNameAvailabilityLoadStateSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, LOAD_STATE>}
   * @returns {LOAD_STATE}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static tenantNameAvailabilityLoadStateSelector: MemoizedSelector<IntegrationsState, LOAD_STATE> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.tenantNameAvailabilityLoadState,
  );

  /**
   * tenantNameAvailabilityStatus
   * @static
   * @type {MemoizedSelector<IntegrationsState, TenantNameAvailabilityStatus>}
   * @returns {TenantNameAvailabilityStatus}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static tenantNameAvailabilityStatus: MemoizedSelector<IntegrationsState, TenantNameAvailabilityStatus> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.tenantNameAvailabilityStatus,
  );

  /**
   * isTenantNameAvailable
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantNameAvailable: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.tenantNameAvailabilityStatus,
    (tenantNameAvailabilityStatus: TenantNameAvailabilityStatus) => {
      return tenantNameAvailabilityStatus?.status === AvailabilityStatus.AVAILABLE;
    },
  );

  /**
   * newTenantNameSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, string>>}
   * @returns {string}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static newTenantNameSelector: MemoizedSelector<IntegrationsState, string> = createSelector(
    TenantUrlConfigurationSelectors.tenantNameAvailabilityStatus,
    (tenantNameAvailabilityStatus: TenantNameAvailabilityStatus) => {
      return tenantNameAvailabilityStatus.status === AvailabilityStatus.AVAILABLE ? tenantNameAvailabilityStatus.name : '';
    },
  );

  /**
   * newTenantUrlSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, string>>}
   * @returns {string}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static newTenantUrlSelector: MemoizedSelector<IntegrationsState, string> = createSelector(
    TenantUrlConfigurationSelectors.newTenantNameSelector,
    TenantUrlConfigurationSelectors.activeTenantUrlAttributesSelector,
    (newTenantName: string, activeTenantUrlAttributes: UrlAttributes) => {
      return newTenantName ? `${activeTenantUrlAttributes.protocol}${newTenantName}${activeTenantUrlAttributes.domainName}` : '';
    },
  );

  /**
   * tenantUrlStatusLoadState
   * @static
   * @type {MemoizedSelector<IntegrationsState, LOAD_STATE>}
   * @returns {LOAD_STATE}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static tenantUrlStatusLoadState: MemoizedSelector<IntegrationsState, LOAD_STATE> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.tenantUrlStatusLoadState,
  );

  /**
   * isFetchingTenantUrlStatus
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isFetchingTenantUrlStatus: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.tenantUrlStatusLoadState,
    UserPreferenceSelectors.isLoadingUserPreference,
    (tenantUrlStatusLoadState: LOAD_STATE, isLoadingUserPreference: boolean) =>
      tenantUrlStatusLoadState === LOAD_STATE.IN_FLIGHT || isLoadingUserPreference,
  );

  /**
   * tenantUrlUpdateStatus
   * @static
   * @type {MemoizedSelector<IntegrationsState, TenantUrlUpdateStatus>}
   * @returns {TenantUrlUpdateStatus}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static tenantUrlUpdateStatus: MemoizedSelector<IntegrationsState, TenantUrlUpdateStatus> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.tenantUrlUpdateStatus,
  );

  /**
   * tenantUrlUpdateStateSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, LOAD_STATE>}
   * @returns {LOAD_STATE}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static tenantUrlUpdateStateSelector: MemoizedSelector<IntegrationsState, LOAD_STATE> = createSelector(
    TenantUrlConfigurationSelectors.tenantUrlUpdateStatus,
    (tenantUrlUpdateStatus: TenantUrlUpdateStatus) => {
      return TenantReprovisionConfig.TENANT_UPDATE_STATUS_TO_LOAD_STATE_MAP[tenantUrlUpdateStatus.status];
    },
  );

  /**
   * isTenantUrlUpdateComplete
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantUrlUpdateComplete: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.isFetchingTenantUrlStatus,
    TenantUrlConfigurationSelectors.tenantUrlUpdateStateSelector,
    (isFetchingTenantUrlStatus: boolean, tenantUrlUpdateState: LOAD_STATE) => {
      return !isFetchingTenantUrlStatus && [LOAD_STATE.SUCCESS, LOAD_STATE.FAILURE].includes(tenantUrlUpdateState);
    },
  );

  /**
   * tenantUsersDetail
   * @static
   * @type {MemoizedSelector<IntegrationsState, TenantUsersDetail>}
   * @returns {TenantUsersDetail}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static tenantUsersDetail: MemoizedSelector<IntegrationsState, TenantUsersDetail> = createSelector(
    TenantUrlConfigurationSelectors.selectTenantUrlConfigurationState,
    (state: TenantUrlConfigurationState) => state.tenantUsersDetail,
  );

  /**
   * isTenantUrlUpdateInProgressSelector
   * @static
   * @type {MemoizedSelector<IntegrationsState, boolean>}
   * @returns {boolean}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static isTenantUrlUpdateInProgressSelector: MemoizedSelector<IntegrationsState, boolean> = createSelector(
    TenantUrlConfigurationSelectors.tenantUrlUpdateStateSelector,
    (tenantUrlUpdateState: LOAD_STATE) => tenantUrlUpdateState === LOAD_STATE.IN_FLIGHT,
  );

  /**
   * resultantTenantUrl
   * @static
   * @type {MemoizedSelector<IntegrationsState, string>}
   * @returns {string}
   * @memberof TenantUrlConfigurationSelectors
   */
  public static resultantTenantUrl: MemoizedSelector<IntegrationsState, string> = createSelector(
    TenantUrlConfigurationSelectors.tenantUrlUpdateStateSelector,
    TenantUrlConfigurationSelectors.activeTenantUrlSelector,
    TenantUrlConfigurationSelectors.newTenantUrlSelector,
    (tenantUrlUpdateState: LOAD_STATE, activeTenantUrl: string, newTenantUrl: string) => {
      return tenantUrlUpdateState === LOAD_STATE.SUCCESS ? newTenantUrl : activeTenantUrl;
    },
  );
}
