/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChronoUnit, DataDecorator, MetricCardSize, PagedRequest, SortOn, TrendSpan } from '@dpa/ui-common';

import { AppPlatform, COLUMN_NAMES, StandardDashboardType, StandardWidgetSubtype, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * SamActionParams
 * @exports
 * @interface SamActionParams
 */
export interface SamActionParams {
  foreground?: string;
  id?: string;
  measure?: string;
  name?: string;
  pagedRequest?: PagedRequest;
  platform?: string;
  query?: string;
  sortOns?: SortOn[];
  startMillis?: number;
  tab?: string;
  trendDateRange?: TrendDateRange;
}

/**
 * DeemSamConfig
 * @export
 * @class DeemSamConfig
 */
export class DeemSamConfig {
  public static iconByTab = {
    portfolio: 'applications',
    discover: 'bar-chart',
    optimize: 'sync',
    compare: 'users',
  };
  public static readonly ForegroundActivity = {
    DAY_14: { queryString: '14 days', value: 14 },
    DAY_30: { queryString: '30 days', value: 30 },
    DAY_45: { queryString: '45 days', value: 45 },
    DAY_60: { queryString: '60 days', value: 60 },
    DAY_90: { queryString: '90 days', value: 90 },
    DAY_120: { queryString: '120 days', value: 120 },
  };
  public static readonly SAM_ROUTE_NAMES = {
    COMPARE: 'compare',
    COMPARE_SLIDER: 'compare_slider',
    DETAIL: 'detail/:name/:id',
    DISCOVER: 'discover',
    OPTIMIZE: 'optimize',
    PORTFOLIO: 'portfolio',
  };
  public static readonly COMPARE_MAX_LIMIT: number = 3;
  public static readonly measure = {
    APP_TOTAL: 'app_total',
    ACTIVE_APP_TOTAL: 'active_app_total',
    INACTIVE_APP_TOTAL: 'inactive_app_total',
  };
  public static readonly qualifiedCountMeasure = [this.measure.ACTIVE_APP_TOTAL, this.measure.INACTIVE_APP_TOTAL];
  public static readonly platformAll = 'All';
  public static readonly platforms = [this.platformAll, AppPlatform.APPLE_MACOS, AppPlatform.WINDOWS_DESKTOP];
  public static readonly nameMapByMeasure = {
    [DeemSamConfig.measure.APP_TOTAL]: 'DEEM_SAM.TOTAL_APPS',
    [DeemSamConfig.measure.ACTIVE_APP_TOTAL]: 'DEEM_SAM.TOTAL_ACTIVE_APPS',
    [DeemSamConfig.measure.INACTIVE_APP_TOTAL]: 'DEEM_SAM.TOTAL_INACTIVE_APPS',
  };
  public static readonly defaultPeriod: TrendDateRange = new TrendDateRange({
    trendSpan: new TrendSpan({ duration: 120, unit: ChronoUnit[ChronoUnit.DAYS] }),
    samplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
  });
  public static readonly nonForegroundActivities = Object.values(this.ForegroundActivity).map((activity) => activity.queryString);
  public static readonly periodsIndex: number[] = Object.values(this.ForegroundActivity).map((activity) => activity.value);
  public static readonly QUERY_PARAM_COMPARE_FILTER = 'product_ids';
  public static readonly QUERY_PARAM_FOREGROUND_FILTER = 'foreground';
  public static readonly QUERY_PARAM_ID_FILTER = 'id';
  public static readonly QUERY_PARAM_NAME_FILTER = 'name';
  public static readonly QUERY_PARAM_PLATFORM_FILTER = '_device_platform';
  public static readonly QUERY_PARAM_TIME_FILTER = 'product_create_date';
  public static readonly portfolioMapByMeasure = {
    [this.measure.APP_TOTAL]: [
      {
        title: 'DEEM_SAM.TOTAL_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
      {
        title: 'DEEM_SAM.MANAGED_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_MANAGED_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
      {
        title: 'DEEM_SAM.UNMANAGED_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_UNMANAGED_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
    ],
    [this.measure.ACTIVE_APP_TOTAL]: [
      {
        title: 'DEEM_SAM.TOTAL_ACTIVE_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.ACTIVE_APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_ACTIVE_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
      {
        title: 'DEEM_SAM.MANAGED_ACTIVE_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.ACTIVE_APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_ACTIVE_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_MANAGED_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
      {
        title: 'DEEM_SAM.UNMANAGED_ACTIVE_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.ACTIVE_APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_ACTIVE_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_UNMANAGED_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
    ],
    [this.measure.INACTIVE_APP_TOTAL]: [
      {
        title: 'DEEM_SAM.TOTAL_INACTIVE_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.INACTIVE_APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_INACTIVE_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
      {
        title: 'DEEM_SAM.MANAGED_INACTIVE_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.INACTIVE_APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_INACTIVE_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_MANAGED_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
      {
        title: 'DEEM_SAM.UNMANAGED_INACTIVE_APPS',
        info: '',
        trendTitle: 'DEEM_SAM.INACTIVE_APPS_COUNT_TREND',
        trendHelp: '',
        type: StandardDashboardType.DEEM_SAM_PORTFOLIO_INACTIVE_APP_TOTAL,
        subType: StandardWidgetSubtype.DEEM_SAM_PORTFOLIO_UNMANAGED_APPS,
        format: DataDecorator.DEFAULT,
        size: MetricCardSize.LG,
      },
    ],
  };
  public static readonly detailMapByMeasure = {
    [this.measure.APP_TOTAL]: StandardDashboardType.DEEM_SAM_PRODUCT_DETAIL_APP_TOTAL,
    [this.measure.ACTIVE_APP_TOTAL]: StandardDashboardType.DEEM_SAM_PRODUCT_DETAIL_ACTIVE_APP_TOTAL,
    [this.measure.INACTIVE_APP_TOTAL]: StandardDashboardType.DEEM_SAM_PRODUCT_DETAIL_INACTIVE_APP_TOTAL,
  };
  public static readonly optimizeMapByMeasure = {
    [this.measure.APP_TOTAL]: StandardDashboardType.DEEM_SAM_OPTIMIZE_APP_TOTAL,
    [this.measure.ACTIVE_APP_TOTAL]: StandardDashboardType.DEEM_SAM_OPTIMIZE_ACTIVE_APP_TOTAL,
    [this.measure.INACTIVE_APP_TOTAL]: StandardDashboardType.DEEM_SAM_OPTIMIZE_INACTIVE_APP_TOTAL,
  };
  public static readonly compareMapByMeasure = {
    [this.measure.APP_TOTAL]: StandardDashboardType.DEEM_SAM_COMPARE_APP_TOTAL,
    [this.measure.ACTIVE_APP_TOTAL]: StandardDashboardType.DEEM_SAM_COMPARE_ACTIVE_APP_TOTAL,
    [this.measure.INACTIVE_APP_TOTAL]: StandardDashboardType.DEEM_SAM_COMPARE_INACTIVE_APP_TOTAL,
  };
  public static readonly measureFilterColumnMap = {
    [this.measure.APP_TOTAL]: COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_total_product_device_count,
    [this.measure.ACTIVE_APP_TOTAL]: COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_active_product_device_count,
    [this.measure.INACTIVE_APP_TOTAL]: COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_inactive_product_device_count,
  };
  public static readonly portfolioPreviewFields = [
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_product_id,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_product_create_date,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_product_name,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_foreground_activity_lookback_interval,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_device_platform_list,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_managed_status_list,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_is_product_total,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_avg_foreground_time,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_total_foreground_time,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_total_product_device_count,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_active_product_device_count,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_inactive_product_device_count,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_active_product_device_percentage,
    COLUMN_NAMES.byFullyQualifiedName.internal_product_catalog_metrics_inactive_product_device_percentage,
  ];
  public static readonly productPreviewFields = [
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_event_created_time,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid,
  ];
}
