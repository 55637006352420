/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NgxSingleData } from './ngx-chart-data-builder.model';

/**
 * NgxChartOtherGrouper
 * @export
 * @class NgxChartOtherGrouper
 */
export class NgxChartOtherGrouper {
  public otherSeries: NgxSingleData[];
  public readonly maxOthersThreshold: number = 0.75;
  public readonly minOthersSize: number = 5;

  /**
   * constructor
   * @param {NgxSingleData} rootNode
   * @param {any}           translators
   */
  constructor(public rootNode: NgxSingleData, public translators: any) {}

  /**
   * applyOtherGrouping
   * @memberof NgxChartOtherGrouper
   */
  public applyOtherGrouping() {
    if (!this.rootNode || !this.rootNode.series) {
      return;
    }

    // Donut chart series will be ordered by value instead of alphabetically
    this.rootNode.series.sort((series1: NgxSingleData, series2: NgxSingleData) => {
      return series2.value - series1.value;
    });

    const otherStartIndex = this.getOtherStartIndex(this.rootNode);
    if (this.rootNode.series.length - otherStartIndex < this.minOthersSize) {
      return;
    }
    this.otherSeries = this.rootNode.series.splice(otherStartIndex);
    const otherGroup = this.combineOtherSeries(this.otherSeries);
    this.rootNode.series.push(otherGroup);
  }

  /**
   * getOtherStartIndex
   * @param  {NgxSingleData} node
   * @returns {number}
   * @memberof NgxChartOtherGrouper
   */
  public getOtherStartIndex(node: NgxSingleData): number {
    const total = node.series.reduce((sum: number, childNode: NgxSingleData) => {
      return sum + childNode.value;
    }, 0);
    let runningSum = 0;
    let otherStartIndex;
    for (let i = 0; i < node.series.length; i++) {
      runningSum += node.series[i].value;
      if (runningSum / total > this.maxOthersThreshold) {
        otherStartIndex = i + 1;
        break;
      }
    }
    return otherStartIndex;
  }

  /**
   * combineOtherSeries
   * @param  {NgxSingleData[]} nodes
   * @returns {NgxSingleData}
   * @memberof NgxChartOtherGrouper
   */
  public combineOtherSeries(nodes: NgxSingleData[]): NgxSingleData {
    const otherTotal = nodes.reduce((sum: number, node: NgxSingleData) => {
      return sum + node.value;
    }, 0);
    return {
      name: this.translators.OTHERS(),
      value: otherTotal,
      series: [],
    } as NgxSingleData;
  }
}
