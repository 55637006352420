/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * ZoomMeetingQuality
 * @export
 * @enum {string}
 */
export enum ZoomMeetingQuality {
  GOOD = 'good',
  FAIR = 'fair',
  POOR = 'poor',
  BAD = 'bad',
}

/**
 * ZoomContributor
 * @export
 * @enum {string}
 */
export enum ZoomContributor {
  AUDIO_INPUT = 'Audio Input',
  AUDIO_OUTPUT = 'Audio Output',
  AUDIO_QUALITY = 'Audio Quality',
  SCREEN_SHARE_INPUT = 'Screen Share Input',
  SCREEN_SHARE_OUTPUT = 'Screen Share Output',
  SCREEN_SHARE_QUALITY = 'Screen Share Quality',
  VIDEO_INPUT = 'Video Input',
  VIDEO_OUTPUT = 'Video Output',
  VIDEO_QUALITY = 'Video Quality',
}

/**
 * ZoomNetworkTab
 * @export
 * @enum {string}
 */
export enum ZoomNetworkTab {
  BITRATE = 'BITRATE',
  JITTER = 'JITTER',
  LATENCY = 'LATENCY',
  AVG_PACKET_LOSS = 'AVG_PACKET_LOSS',
  MAX_PACKET_LOSS = 'MAX_PACKET_LOSS',
}
