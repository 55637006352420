<dpa-modal
  [modalOpen]="isOpen"
  [modalSize]="ModalSize.MD"
  (onModalClose)="modalClose.emit()"
>
  <dpa-modal-title *ngIf="isLoading; else deleteModalTitle">
    {{ 'CUSTOM_CONNECTION.DELETE_CONNECTOR' | translate }}
  </dpa-modal-title>
  <ng-template #deleteModalTitle>
    <dpa-modal-title>
      <dpa-ellipsis-with-tooltip>
        {{
          connectorUsageCount
            ? ('CUSTOM_CONNECTION.CANNOT_DELETE_CONNECTOR' | translate : { connector: connector?.name })
            : ('CUSTOM_CONNECTION.DELETE_CONNECTION' | translate : { connector: connector?.name })
        }}
      </dpa-ellipsis-with-tooltip>
    </dpa-modal-title>
  </ng-template>

  <div
    *ngIf="isLoading; else deleteModalContent"
    class="modal-body"
  >
    <div class="spinner-container">
      <clr-spinner clrMedium></clr-spinner>
    </div>
  </div>
  <ng-template #deleteModalContent>
    <div class="modal-body">
      <dpa-alert-banner [target]="AlertBannerTarget.MODAL"></dpa-alert-banner>
      <clr-alert
        *ngIf="connectorUsageCount"
        clrAlertType="warning"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <div class="alert-text">
            {{ 'CUSTOM_CONNECTION.COUNT_WORKFLOWS_IMPACTED' | translate : { count: connectorUsageCount | number } }}
          </div>
        </clr-alert-item>
      </clr-alert>
      <div class="mt-x4 pl-x4">
        <ng-container *ngIf="!connectorUsageCount; else toRemoveActions">
          {{ 'CUSTOM_CONNECTION.DELETE_CUSTOM_CONNECTION_CONFIRMATION' | translate }}
        </ng-container>
        <ng-template #toRemoveActions>
          {{ 'CUSTOM_CONNECTION.TO_DELETE_THE_CONNECTOR' | translate }}
          <ol>
            <li>{{ 'CUSTOM_CONNECTION.REMOVE_CONNECTOR_ACTIONS_FROM_WORKFLOWS' | translate }}</li>
            <li>{{ 'CUSTOM_CONNECTION.UPDATE_THE_WORKFLOW_WITH_A_NEW_ACTION' | translate }}</li>
            <li>{{ 'CUSTOM_CONNECTION.TEST_TO_VALIDATE_THE_UPDATES_TO_ALL_WORKFLOWS' | translate }}</li>
          </ol>
        </ng-template>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="modalClose.emit()"
        type="button"
        class="btn btn-outline"
      >
        {{ 'COMMON_ACTIONS.CLOSE' | translate }}
      </button>
      <button
        *ngIf="!connectorUsageCount; else viewAllWorkflows"
        [clrLoading]="isLoading"
        (click)="delete.emit()"
        type="button"
        class="btn btn-danger"
      >
        {{ 'COMMON_ACTIONS.DELETE' | translate }}
      </button>
      <ng-template #viewAllWorkflows>
        <button
          [clrLoading]="isLoading"
          (click)="viewWorkflows.emit()"
          type="button"
          class="btn btn-primary"
        >
          {{ 'CUSTOM_CONNECTION.VIEW_WORKFLOWS' | translate }}
        </button>
      </ng-template>
    </div>
  </ng-template>
</dpa-modal>
