/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import moment from 'moment-timezone';

import { Device } from '@ws1c/intelligence-models/device.model';

/**
 * Deep clone for nested json object
 *
 * @export
 * @param {*} json
 * @returns {*}
 */
export function deepClone(json: any): any {
  return JSON.parse(JSON.stringify(json));
}

/**
 * Utility function to create a class instance and extend it
 * @param {class} instanceClass
 * @param {any} data
 * @returns {any}
 */
export function createInstance(instanceClass, data) {
  const instance = new instanceClass();
  return Object.assign(instance, data);
}

/**
 * getTimezoneAbbreviation
 *
 * @export
 * @returns {string}
 */
export function getTimezoneAbbreviation(): string {
  return moment.tz(moment.tz.guess()).format('z');
}

/**
 * getTimezoneOffsetString
 *
 * @export
 * @returns {string}
 */
export function getTimezoneOffsetString(): string {
  return moment.tz(moment.tz.guess()).format('UTCZ');
}

/**
 * isPlatformSupported
 * @param {Device} device
 * @param {string[]} platforms
 * @export
 * @returns {boolean}
 */
export function isPlatformSupported(device: Device, platforms: string[]): boolean {
  return platforms.includes(device?.platform);
}
