/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SortOn } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';

import {
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  DeviceCrumbListLocator,
  DevicesDashboardConfig,
  Entity,
  FilterRule,
  getFQN,
  getFQNFromMaskedName,
  Integration,
  PreviewReportContentRequest,
  QueryBuilder,
  RuleGroup,
  TrendMode,
} from '@ws1c/intelligence-models';

export const helper = {
  getDeviceCrumbListMultiRequest,
  loadDeviceHealthStatusRequest,
  loadDeviceRiskScoreRequest,
  loadDeviceHealthRequest,
  getAppsCrumbListRequest,
  getDevicesCrumbListRequest,
  getDevicePerformanceCrumbListRequest,
  getDisplayCrumbListRequest,
  getNetworkCrumbListRequest,
  getOsUpdatesCrumbListRequest,
  getServicesCrumbListRequest,
  getUserActionsCrumbListRequest,
  getDevicePropertiesRequest,
  getDesktopEdiRequest,
  getMobileUemEdiRequest,
  getMobileSdkEdiRequest,
  mergeResponseResults,
};

/**
 * loadDeviceHealthStatusRequest
 * @param {string} deviceId
 * @param {number} start
 * @param {number} end
 * @returns {PreviewReportContentRequest}
 */
export function loadDeviceHealthStatusRequest(deviceId: string, start: number, end: number): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    filter: `${getFQN(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DEVICE, COLUMN_NAMES.byName.device_guid)} = '${deviceId}'`,
    fields: [
      getFQN(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DEVICE, COLUMN_NAMES.byName._device_overall_score),
      getFQN(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DEVICE, COLUMN_NAMES.byName.adp_modified_at),
    ],
    entitiesByIntegration: {
      [Integration.INTELLIGENCE]: [Entity.EXPERIENCESCORE_DEVICE],
    },
    startDateMillis: start,
    endDateMillis: end,
    offset: 0,
    pageSize: 1,
    sortOns: [
      new SortOn({
        by: getFQN(Integration.INTELLIGENCE, Entity.EXPERIENCESCORE_DEVICE, COLUMN_NAMES.byName.adp_modified_at),
        reverse: true,
      }),
    ],
  });
}

/**
 * loadDeviceRiskScoreRequest
 * @param {string} deviceId
 * @param {number} start
 * @param {number} end
 * @returns {PreviewReportContentRequest}
 */
export function loadDeviceRiskScoreRequest(deviceId: string, start: number, end: number): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    filter: `${getFQN(Integration.AIRWATCH, Entity.DEVICE_SUMMARY_RISK_SCORE, COLUMN_NAMES.byName._airwatch_device_guid)} = '${deviceId}'`,
    fields: [
      getFQN(Integration.AIRWATCH, Entity.DEVICE_SUMMARY_RISK_SCORE, COLUMN_NAMES.byName.score_severity),
      getFQN(Integration.AIRWATCH, Entity.DEVICE_SUMMARY_RISK_SCORE, COLUMN_NAMES.byName.adp_modified_at),
    ],
    entitiesByIntegration: {
      [Integration.AIRWATCH]: [Entity.DEVICE_SUMMARY_RISK_SCORE],
    },
    startDateMillis: start,
    endDateMillis: end,
    offset: 0,
    pageSize: 1,
    sortOns: [
      Object.assign(new SortOn(), {
        by: getFQN(Integration.AIRWATCH, Entity.DEVICE_SUMMARY_RISK_SCORE, COLUMN_NAMES.byName.adp_modified_at),
        reverse: true,
      }),
    ],
  });
}

/**
 * loadDeviceHealthRequest
 * @param {string} deviceId
 * @param {number} start
 * @param {number} end
 * @returns {PreviewReportContentRequest}
 */
export function loadDeviceHealthRequest(deviceId: string, start: number, end: number): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    filter: `internal.experiencescore_device._airwatch_device_guid = '${deviceId}'`,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_boot_time_millis,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_average_shutdown_time_millis,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_crashes,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_battery_health,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_cpu_health,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_gpu_health,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_memory_health,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_wifi_strength,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_download_speed,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_upload_speed,
    ],
    entitiesByIntegration: {
      [Integration.INTELLIGENCE]: [Entity.EXPERIENCESCORE_DEVICE],
    },
    startDateMillis: start,
    endDateMillis: end,
    offset: 0,
    pageSize: 1,
    sortOns: [
      Object.assign(new SortOn(), {
        by: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_adp_modified_at,
        reverse: true,
      }),
    ],
    integration: Integration.INTELLIGENCE,
    entity: Entity.EXPERIENCESCORE_DEVICE,
    trendMode: TrendMode.HISTORICAL,
  });
}

/**
 * getDeviceCrumbListMultiRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {RuleGroup} ruleGroup
 * @returns {PreviewReportContentRequest[]}
 */
export function getDeviceCrumbListMultiRequest(
  crumbListLocator: DeviceCrumbListLocator,
  ruleGroup: RuleGroup,
): PreviewReportContentRequest[] {
  const deviceFilterStr = QueryBuilder.queryStringFromFilterRules([
    ...((ruleGroup?.rules as FilterRule[]) ?? []),
    ...FilterRule.listFromKeyValue({
      ['integration.entity._airwatch_device_guid']: crumbListLocator.deviceUuid,
    }),
  ]);
  const mostRecentSortOn = Object.assign(new SortOn(), {
    by: 'integration.entity.event_timestamp',
    reverse: true,
  });
  return [
    getAppsCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getDevicesCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getDevicePerformanceCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getDisplayCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getNetworkCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getOsUpdatesCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getServicesCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
    getUserActionsCrumbListRequest(crumbListLocator, deviceFilterStr, mostRecentSortOn),
  ];
}

/**
 * getAppsCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getAppsCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.APPS, mostRecentSortOn.by),
  });
  const extraFilter = QueryBuilder.queryStringFromFilterRules([
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_status,
      condition: FilterRule.FILTER_CONDITION.includes,
      data: DevicesDashboardConfig.completeStatus,
    }),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.APPS],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: [getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.APPS, deviceFilterStr), extraFilter].join(
      ` ${FilterRule.FILTER_CONDITION.and}`,
    ),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_module,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_code,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_offset,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_data,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_agent_version,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getDevicesCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getDevicesCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICES, mostRecentSortOn.by),
  });
  const extraFilter = QueryBuilder.queryStringFromFilterRules([
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_status,
      condition: FilterRule.FILTER_CONDITION.includes,
      data: DevicesDashboardConfig.completeStatus,
    }),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.DEVICES],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: [getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICES, deviceFilterStr), extraFilter].join(
      ` ${FilterRule.FILTER_CONDITION.and}`,
    ),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_reason,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getDevicePerformanceCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getDevicePerformanceCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICE_PERFORMANCE, mostRecentSortOn.by),
  });
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.DEVICE_PERFORMANCE],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICE_PERFORMANCE, deviceFilterStr),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_user,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_make,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_model,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_processor_totaltime,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_gpu_usage0,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_usage_percent,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getDisplayCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getDisplayCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.DISPLAY, mostRecentSortOn.by),
  });
  const extraFilter = QueryBuilder.queryStringFromFilterRules([
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_display_event_status,
      condition: FilterRule.FILTER_CONDITION.includes,
      data: DevicesDashboardConfig.completeStatus,
    }),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.DISPLAY],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: [getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.DISPLAY, deviceFilterStr), extraFilter].join(
      ` ${FilterRule.FILTER_CONDITION.and}`,
    ),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_display_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_display_event_timestamp,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getOsUpdatesCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getOsUpdatesCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.OS_UPDATES, mostRecentSortOn.by),
  });
  const extraFilter = QueryBuilder.queryStringFromFilterRules([
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_status,
      condition: FilterRule.FILTER_CONDITION.includes,
      data: DevicesDashboardConfig.completeStatus,
    }),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.OS_UPDATES],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: [getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.OS_UPDATES, deviceFilterStr), extraFilter].join(
      ` ${FilterRule.FILTER_CONDITION.and}`,
    ),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_title,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_duration_millis,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getServicesCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getServicesCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.SERVICES, mostRecentSortOn.by),
  });
  const extraFilter = QueryBuilder.queryStringFromFilterRules([
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_status,
      condition: FilterRule.FILTER_CONDITION.includes,
      data: DevicesDashboardConfig.completeStatus,
    }),
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_image_name,
      condition: FilterRule.FILTER_CONDITION.isNotNull,
      data: [],
    }),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.SERVICES],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: [getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.SERVICES, deviceFilterStr), extraFilter].join(
      ` ${FilterRule.FILTER_CONDITION.and}`,
    ),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_service_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_image_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_duration_millis,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getUserActionsCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getUserActionsCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.USER_ACTIONS, mostRecentSortOn.by),
  });
  const extraFilter = QueryBuilder.queryStringFromFilterRules([
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_status,
      condition: FilterRule.FILTER_CONDITION.includes,
      data: DevicesDashboardConfig.completeStatus,
    }),
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_logon_type,
      condition: FilterRule.FILTER_CONDITION.notIncludes,
      data: DevicesDashboardConfig.logonExclusionTypes,
    }),
    new FilterRule({
      attribute: COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_user,
      condition: FilterRule.FILTER_CONDITION.isNotNull,
      data: [],
    }),
  ]);
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.USER_ACTIONS],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: [getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.USER_ACTIONS, deviceFilterStr), extraFilter].join(
      ` ${FilterRule.FILTER_CONDITION.and}`,
    ),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_status,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_user,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_logon_type,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_sid,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_duration_millis,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getNetworkCrumbListRequest
 * @param {DeviceCrumbListLocator} crumbListLocator
 * @param {string} deviceFilterStr
 * @param {SortOn} mostRecentSortOn
 * @returns {PreviewReportContentRequest}
 */
export function getNetworkCrumbListRequest(
  crumbListLocator: DeviceCrumbListLocator,
  deviceFilterStr: string,
  mostRecentSortOn: SortOn,
): PreviewReportContentRequest {
  const sortOn = Object.assign(new SortOn(), mostRecentSortOn, {
    by: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.NET_EVENT, mostRecentSortOn.by),
  });
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.NET_EVENT],
    },
    startDateMillis: crumbListLocator.startTime,
    endDateMillis: crumbListLocator.endTime,
    filter: getFQNFromMaskedName(Integration.EMPLOYEE_EXPERIENCE, Entity.NET_EVENT, deviceFilterStr),
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_friendly_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_ssid,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_wifi_signal_range,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_type,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_physical_address,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_mode,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_rssi,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_frequency,
    ],
    pageSize: 50,
    offset: 0,
    sortOns: [sortOn],
  });
}

/**
 * getDevicePropertiesRequest
 * @param {string} id
 * @returns {PreviewReportContentRequest}
 */
export function getDevicePropertiesRequest(id: string): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    filter: `${COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid} = '${id}'`,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_user_name,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_enrollment_status,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tag_name_list,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_serial_number,

      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_is_compromised,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_windows_bitlocker_encryption_method,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_windows_bitlocker_status,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_tpm_chip,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_recovery_key_escrowed,
    ],
    entitiesByIntegration: {
      [Integration.AIRWATCH]: [Entity.DEVICE],
    },
    pageSize: 1,
    offset: 0,
  });
}

/**
 * getDesktopEdiRequest
 * @param {string} deviceId
 * @returns {PreviewReportContentRequest}
 */
export function getDesktopEdiRequest(deviceId: string): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    entity: Entity.ASSET_INFO,
    entitiesByIntegration: {
      [Integration.EMPLOYEE_EXPERIENCE]: [Entity.ASSET_INFO],
    },
    filter: `${COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_airwatch_device_guid} = '${deviceId}'`,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_processor,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_physical_memory_bytes,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_bios_version,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_bios_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_system_type,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_time_zone,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_device_os_version_friendly_name,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_os_version,
      COLUMN_NAMES.byFullyQualifiedName.employee_experience_asset_info_agent_version,
    ],
    sortOns: [],
    offset: 0,
    pageSize: 1,
  });
}

/**
 * getMobileUemEdiRequest
 * @param {string} deviceId
 * @returns {PreviewReportContentRequest}
 */
export function getMobileUemEdiRequest(deviceId: string): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    entity: Entity.DEVICE,
    entitiesByIntegration: {
      [Integration.AIRWATCH]: [Entity.DEVICE],
    },
    filter: `${COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid} = '${deviceId}'`,
    fields: DevicesDashboardConfig.uemDeviceProperties,
    sortOns: [],
    offset: 0,
    pageSize: 1,
  });
}

/**
 * getMobileSdkEdiRequest
 * @param {string} deviceId
 * @returns {PreviewReportContentRequest[]}
 */
export function getMobileSdkEdiRequest(deviceId: string): PreviewReportContentRequest[] {
  return [
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [Entity.DEVICE],
      },
      filter: `${COLUMN_NAMES.byFullyQualifiedName.apteligent_device_airwatch_device_guid} = '${deviceId}'`,
      fields: DevicesDashboardConfig.sdkDeviceProperties,
      sortOns: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.apteligent_device_adp_modified_at,
          reverse: true,
        }),
      ],
      offset: 0,
      pageSize: 1,
    }),
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [Entity.BATTERY],
      },
      filter: `${COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_airwatch_device_guid} = '${deviceId}'`,
      fields: DevicesDashboardConfig.sdkDeviceBatteryProperties,
      sortOns: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.apteligent_battery_adp_modified_at,
          reverse: true,
        }),
      ],
      offset: 0,
      pageSize: 1,
    }),
    new PreviewReportContentRequest({
      entitiesByIntegration: {
        [Integration.APTELIGENT]: [Entity.NETWORK],
      },
      filter: `${COLUMN_NAMES.byFullyQualifiedName.apteligent_network_airwatch_device_guid} = '${deviceId}'`,
      fields: DevicesDashboardConfig.sdkDeviceNetworkProperties,
      sortOns: [
        new SortOn({
          by: COLUMN_NAMES.byFullyQualifiedName.apteligent_network_adp_modified_at,
          reverse: true,
        }),
      ],
      offset: 0,
      pageSize: 1,
    }),
  ];
}

/**
 * mergeResponseResults
 * @param {CustomReportPreviewSearchResponse} firstResponse
 * @param {CustomReportPreviewSearchResponse[]} secondResponses
 * @returns {CustomReportPreviewSearchResponse}
 */
export function mergeResponseResults(
  firstResponse: CustomReportPreviewSearchResponse,
  secondResponses: CustomReportPreviewSearchResponse[],
): CustomReportPreviewSearchResponse {
  const fields = {
    ...firstResponse.results?.[0],
    ...secondResponses[0].results?.[0],
    ...secondResponses[1].results?.[0],
    ...secondResponses[2].results?.[0],
  };
  const merged = new CustomReportPreviewSearchResponse({
    ...firstResponse,
    results: isEmpty(fields) ? [] : [fields],
  });
  return merged;
}
