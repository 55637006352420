/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationDashboardType
 * @export
 * @enum {string}
 */
export enum AutomationDashboardType {
  // For Automation Dashboard
  AUTOMATION_RUN_HISTORY = 'AUTOMATION_RUN_HISTORY',
}

/**
 * AutomationWidgetSubtype
 * @export
 * @enum {string}
 */
export enum AutomationWidgetSubtype {
  // For Automation Dashboard
  AUTOMATION_RUN_HISTORY_SUMMARY = 'AUTOMATION_RUN_HISTORY_SUMMARY',
  AUTOMATION_RUN_HISTORY_CHART = 'AUTOMATION_RUN_HISTORY_CHART',
  WORKFLOW_RUN_HISTORY_FOR_EXECUTION_ID = 'WORKFLOW_RUN_HISTORY_FOR_EXECUTION_ID',
}
