/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { each } from 'lodash-es';

// eslint-disable-next-line max-len
import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';
import { UserAdminAccount } from '@ws1c/intelligence-models';

/**
 * EmailMultiFormFieldComponent
 * @export
 * @class EmailMultiFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-email-multi-form-field',
  templateUrl: 'email-multi-form-field.component.html',
  styleUrls: ['email-multi-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailMultiFormFieldComponent extends BaseFormFieldComponent {
  public selectedUsers: UserAdminAccount[] = [];

  /**
   * getSelectedUsers
   * @returns {UserAdminAccount[]}
   * @memberof EmailMultiFormFieldComponent
   */
  public getSelectedUsers(): UserAdminAccount[] {
    if (!this.selectedUsers) {
      this.selectedUsers = [];
    }
    each(this.formControl?.value ?? {}, (email: string) => {
      if (!this.selectedUsers.some((user: UserAdminAccount) => user.email === email)) {
        this.selectedUsers.push(
          new UserAdminAccount({
            email,
          }),
        );
      }
    });
    return this.selectedUsers;
  }

  /**
   * onSelectedUsersChange
   * @param {UserAdminAccount[]} users
   * @memberof EmailMultiFormFieldComponent
   */
  public onSelectedUsersChange(users: UserAdminAccount[]) {
    this.formControl.patchValue(users.map((user: UserAdminAccount) => user.email));
  }
}
