/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { IntegratedServicesService } from '@ws1c/intelligence-core/services/integrated-services.service';
import { IntegratedServicesActions } from '@ws1c/intelligence-core/store/integrated-services';
import { IntegratedServicesResponse } from '@ws1c/intelligence-models';

/**
 * IntegratedServicesEffects
 *
 * @export
 * @class IntegratedServicesEffects
 */
@Injectable()
export class IntegratedServicesEffects {
  /**
   * getIntegratedServices$
   * @type {Observable<Action>}
   * @memberof IntegratedServicesEffects
   */
  public getIntegratedServices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegratedServicesActions.getIntegratedServices),
      startWith(IntegratedServicesActions.getIntegratedServices),
      switchMap(() =>
        this.integratedServicesService.getIntegratedServices().pipe(
          map((integratedServicesData: IntegratedServicesResponse) =>
            IntegratedServicesActions.getIntegratedServicesSuccess({ integratedServicesData }),
          ),
          catchError((error: WebError) => of(IntegratedServicesActions.getIntegratedServicesFailure({ error }))),
        ),
      ),
    ),
  );

  /**
   * Creates an instance of IntegratedServicesEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {IntegratedServicesService} integratedServicesService - Instance of IntegratedServicesService
   */
  constructor(private actions$: Actions, private integratedServicesService: IntegratedServicesService) {}
}
