<div class="ml-x1 mt-x6 mb-x2 start-trial-header">
  {{ 'TRIAL_BANNER.START_N_DAY_FREE_TRIAL' | translate : { count: TRIAL_DAYS } }}
</div>
<div class="clr-row">
  <div class="clr-col-md-6 clr-col-sm-12">
    <form
      [formGroup]="trialForm"
      class="clr-form clr-form-compact"
    >
      <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
      <p class="form-top-header mt-x1">
        {{ 'TRIAL_BANNER.USER_ACCOUNT_DETAILS' | translate }}
      </p>
      <!-- form control: firstName -->
      <div class="clr-form-control clr-row">
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialFirstName"
        >
          {{ 'COMMON_MESSAGES.FIRST_NAME' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('firstName') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialFirstName"
              formControlName="firstName"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('firstName')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: firstName -->
      <!-- form control: lastName -->
      <div class="clr-form-control clr-row">
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialLastName"
        >
          {{ 'COMMON_MESSAGES.LAST_NAME' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('lastName') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialLastName"
              formControlName="lastName"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('lastName')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: lastName -->
      <!-- form control: emailAddress -->
      <div class="clr-form-control clr-row">
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialEmail"
        >
          {{ 'COMMON_MESSAGES.EMAIL' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('emailAddress') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialEmail"
              formControlName="emailAddress"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('emailAddress')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: emailAddress -->
      <!-- form control: title -->
      <div class="clr-form-control clr-row">
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialTitle"
        >
          {{ 'COMMON_MESSAGES.TITLE' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('title') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialTitle"
              formControlName="title"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('title')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: title -->
      <!-- form control: companyName -->
      <div class="clr-form-control clr-row">
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialCompany"
        >
          {{ 'TRIAL_BANNER.COMPANY' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('companyName') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialCompany"
              formControlName="companyName"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('companyName')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: companyName -->
      <!-- form control: postalAddress -->
      <div
        formGroupName="postalAddress"
        class="clr-form-control clr-row"
      >
        <label
          class="clr-control-label clr-col-sm-5"
          for="trialAddress"
        >
          {{ 'TRIAL_BANNER.ADDRESS' | translate }}
        </label>
        <div class="clr-control-container">
          <div class="clr-input-wrapper">
            <input
              class="clr-input"
              type="text"
              id="trialAddress"
              formControlName="address"
            />
          </div>
        </div>
      </div>
      <!-- end of form control: postalAddress -->
      <!-- form control: city-->
      <div
        class="clr-form-control clr-row"
        formGroupName="postalAddress"
      >
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialCity"
        >
          {{ 'TRIAL_BANNER.CITY' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('postalAddress.city') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialCity"
              formControlName="city"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('postalAddress.city')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: city -->
      <!-- form control: state-->
      <div
        class="clr-form-control clr-row"
        formGroupName="postalAddress"
      >
        <label
          class="clr-control-label clr-col-sm-5"
          for="trialState"
        >
          {{ 'TRIAL_BANNER.STATE_PROVINCE' | translate }}
        </label>
        <div class="clr-control-container">
          <div class="clr-input-wrapper">
            <input
              class="clr-input"
              type="text"
              id="trialState"
              formControlName="state"
            />
          </div>
        </div>
      </div>
      <!-- end of form control: state -->
      <!-- form control: postalCode -->
      <div
        class="clr-form-control clr-row"
        formGroupName="postalAddress"
      >
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialPostalCode"
        >
          {{ 'TRIAL_BANNER.ZIPCODE_POSTAL_CODE' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('postalAddress.postalCode') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialPostalCode"
              formControlName="postalCode"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('postalAddress.postalCode')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: postalCode -->
      <!-- form control: country -->
      <div
        class="clr-form-control clr-row"
        formGroupName="postalAddress"
      >
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialCountry"
        >
          {{ 'TRIAL_BANNER.COUNTRY' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('postalAddress.country') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialCountry"
              formControlName="country"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('postalAddress.country')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: country -->
      <!-- form control: phoneNumber -->
      <div class="clr-form-control clr-row">
        <label
          class="clr-control-label clr-col-sm-5 clr-required-mark"
          for="trialPhone"
        >
          {{ 'TRIAL_BANNER.PHONE' | translate }}
        </label>
        <div
          [ngClass]="{ 'clr-error': showRequiredWarning('phoneNumber') }"
          class="clr-control-container"
        >
          <div class="clr-input-wrapper">
            <input
              required
              class="clr-input"
              type="text"
              id="trialPhone"
              formControlName="phoneNumber"
            />
            <dpa-tooltip
              *ngIf="showRequiredWarning('phoneNumber')"
              [attr.aria-label]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              [tooltipText]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
              class="tooltip tooltip-xs tooltip-top-left"
              role="tooltip"
              aria-haspopup="true"
              tabindex="0"
            >
              <cds-icon
                class="clr-validate-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </div>
      <!-- end of form control: phoneNumber -->
    </form>
    <p class="p5 ml-x1">
      <strong>{{ 'TRIAL_BANNER.NOTE' | translate }}:</strong>
      {{ 'TRIAL_BANNER.BY_ACCEPTING_THIS_TRIAL' | translate }}
    </p>
  </div>
  <div class="clr-col-md-6 bg-image intro-modal-trial-form"></div>
</div>
