<ng-container [ngSwitch]="resource?.resourceType">
  <div *ngSwitchCase="MarketplaceResourceType.WIDGET_TEMPLATE">
    <dpa-chart-type
      [chartType]="metadata.chartType"
      [trendMode]="metadata.trendMode"
    ></dpa-chart-type>
    <ng-template
      [ngTemplateOutlet]="usageCountTemplate"
      [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
    ></ng-template>
  </div>
  <div *ngSwitchCase="MarketplaceResourceType.REPORT_TEMPLATE">
    <dpa-from-now
      [formatString]="'CONNECTIONS.MODIFIED_WHEN'"
      [timestamp]="resource?.modifiedAt"
    ></dpa-from-now>
    <div *ngIf="metadata.reportType">
      {{ 'COMMON_MESSAGES.DATA_TYPE' | translate }}:
      {{ 'REPORT_BUILDER.REPORT_TYPE_' + metadata.reportType | translate }}
    </div>
    <ng-template
      [ngTemplateOutlet]="usageCountTemplate"
      [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
    ></ng-template>
  </div>
  <div *ngSwitchCase="MarketplaceResourceType.AUTOMATION_TEMPLATE">
    <dpa-from-now
      [formatString]="'CONNECTIONS.MODIFIED_WHEN'"
      [timestamp]="resource?.modifiedAt"
    ></dpa-from-now>
    <ng-template
      [ngTemplateOutlet]="usageCountTemplate"
      [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
    ></ng-template>
  </div>
  <div *ngSwitchCase="MarketplaceResourceType.SCRIPT_TEMPLATE">
    <div>{{ 'COMMON_MESSAGES.LANGUAGE_NAME' | translate : { language: metadata.language } }}</div>
    <div>{{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate : { name: metadata.platform } }}</div>
    <ng-template
      [ngTemplateOutlet]="usageCountTemplate"
      [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
    ></ng-template>
  </div>
  <div *ngSwitchCase="MarketplaceResourceType.SENSOR_TEMPLATE">
    <div>{{ 'COMMON_MESSAGES.LANGUAGE_NAME' | translate : { language: metadata.language } }}</div>
    <div>{{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate : { name: metadata.platform } }}</div>
    <ng-template
      [ngTemplateOutlet]="usageCountTemplate"
      [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
    ></ng-template>
  </div>
  <div *ngSwitchCase="MarketplaceResourceType.UEM_FREESTYLE_WORKFLOW_TEMPLATE">
    <div>{{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate : { name: metadata.platform } }}</div>
  </div>
</ng-container>
<ng-template
  #usageCountTemplate
  let-usageCount="usageCount"
>
  <div
    *ngIf="usageCount"
    [innerHTML]="'MARKETPLACE.USED_COUNT_MESSAGE' | translate : { count: usageCount | number }"
  ></div>
</ng-template>
