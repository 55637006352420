<div
  [class.compact]="isCompact"
  [class.btn-icon]="isCompact"
  [attr.role]="isCompact ? 'radiogroup' : 'dropdown'"
  class="btn-group"
>
  <div
    *ngIf="!isCompact"
    class="chart-selection-container"
  >
    <div class="toggle-container">
      <ng-container
        [ngTemplateOutlet]="chartIcon"
        [ngTemplateOutletContext]="{
          chartType: selectedChartType,
          chartLabel: 'WIDGET_CUSTOMIZE.WIDGET_CHART_' + selectedChartType | translate,
          isSelected: true,
          clickHandler: toggleList,
      }"
      ></ng-container>
      <cds-icon
        shape="angle"
        direction="down"
      ></cds-icon>
    </div>

    <ul
      *ngIf="showChartTypesList"
      class="list-unstyled chart-selection-list"
    >
      <li *ngFor="let chartType of chartTypes">
        <ng-container
          [ngTemplateOutlet]="chartIcon"
          [ngTemplateOutletContext]="{
            chartType: chartType,
            chartLabel: 'WIDGET_CUSTOMIZE.WIDGET_CHART_' + chartType | translate,
            isSelected: chartType === selectedChartType,
            clickHandler: selectChartType
          }"
        ></ng-container>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="isCompact">
    <ng-container
      *ngFor="let chartType of chartTypes"
      [ngTemplateOutlet]="chartIcon"
      [ngTemplateOutletContext]="{
        chartType: chartType,
        chartLabel: 'WIDGET_CUSTOMIZE.WIDGET_CHART_' + chartType | translate,
        isSelected: chartType === selectedChartType,
        clickHandler: selectChartType
      }"
    ></ng-container>
  </ng-container>
</div>

<ng-template
  #chartIcon
  let-chartType="chartType"
  let-isSelected="isSelected"
  let-chartLabel="chartLabel"
  let-clickHandler="clickHandler"
>
  <dpa-tooltip
    [attr.aria-label]="chartLabel"
    [attr.aria-checked]="isSelected"
    [tooltipText]="chartLabel"
    [focusEnabled]="true"
    class="tooltip"
    role="radio"
  >
    <button
      [class.btn-primary]="isSelected"
      [class.btn-outline]="!isSelected"
      [disabled]="allowedChartTypes && !allowedChartTypes.includes(chartType)"
      (click)="clickHandler(chartType, $event)"
      class="btn btn-small chart-button"
    >
      <div class="button-label">
        <cds-icon
          [class.active]="!isCompact && isSelected"
          [class.selected-chart]="isSelected"
          [attr.shape]="chartType | dpaIconName : '' : '-chart'"
          [attr.size]="!isCompact ? '16' : '24'"
          class="chart-type"
        ></cds-icon>
        <div
          *ngIf="!isCompact"
          class="ml-x1"
        >
          {{ chartLabel }}
        </div>
      </div>
    </button>
  </dpa-tooltip>
</ng-template>
