/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';

import { MapService } from '@ws1c/intelligence-common/services';
import { DpaMap, DpaMapCoordinates } from '@ws1c/intelligence-models';

/**
 * SimpleMapCardComponent
 *
 * @export
 * @class SimpleMapCardComponent
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-simple-map-card',
  templateUrl: 'simple-map-card.component.html',
  styleUrls: ['simple-map-card.component.scss'],
})
export class SimpleMapCardComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public cardTitle: string;
  @Input() public coordinates: DpaMapCoordinates;
  @Input() public zoom?: number;
  @Input() public popup?: string;

  @ViewChild('map') public mapElement;

  private map: DpaMap = null;

  /**
   * constructor
   * @param {MapService} mapService
   * @memberof SimpleMapCardComponent
   */
  constructor(private mapService: MapService) {}

  /**
   * ngAfterViewInit
   *
   * @memberof SimpleMapCardComponent
   */
  public ngAfterViewInit() {
    this.initializeMap(); // initialize map + add marker icon
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof SimpleMapCardComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.coordinates?.currentValue && this.map) {
      this.map.remove();
      this.initializeMap();
    }
  }

  /**
   * ngOnDestroy
   *
   * @memberof SimpleMapCardComponent
   */
  public ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }

  /**
   * initializeMap
   *
   * @private
   * @memberof SimpleMapCardComponent
   */
  private initializeMap() {
    const mapElement: HTMLElement = this.mapElement.nativeElement;

    this.map = this.mapService.createMap(mapElement, this.coordinates, this.zoom, this.popup);
  }
}
