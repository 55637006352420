/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { WS1BrokerService } from '@dpa-shared-merlot/services/ws1-broker.service';
import { WS1BrokerActions } from '@dpa-shared-merlot/store';
import { Ws1BrokerAllDirectories } from '@ws1c/intelligence-models';

/**
 * Handles side effects for ws1 broker UI
 * @export
 * @class WS1BrokerEffects
 */
@Injectable()
export class WS1BrokerEffects {
  /**
   * getBrokerDirectories
   * @type {Observable<Action>}
   * @memberof WS1BrokerEffects
   */
  public getBrokerDirectories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(WS1BrokerActions.getBrokerDirectories),
      switchMap(({ integrationId }) => {
        return this.ws1BrokerService.getBrokerDirectories(integrationId).pipe(
          map((ws1BrokerDirectories: Ws1BrokerAllDirectories) => {
            return WS1BrokerActions.getBrokerDirectoriesSuccess({ ws1BrokerDirectories });
          }),
          catchError(() => of(WS1BrokerActions.getBrokerDirectoriesFailure())),
        );
      }),
    ),
  );

  /**
   * Creates an instance of WS1BrokerEffects
   * @param {Actions} actions$
   * @param {WS1BrokerService} ws1BrokerService
   * @memberof WS1BrokerEffects
   */
  constructor(private actions$: Actions, private ws1BrokerService: WS1BrokerService) {}
}
