/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Insight Action Type
 * @enum {string}
 */
export enum InsightActionType {
  DISMISS = 'DISMISS',
  PIN_TOGGLE = 'PIN_TOGGLE',
  VIEW = 'VIEW',
  CREATE_INCIDENT = 'CREATE_INCIDENT',
}
