/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTimeFormat, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store';
import { DashboardActions } from '@ws1c/intelligence-core/store/dashboard/dashboard.actions';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { ActiveWidgetDialogMode } from '@ws1c/intelligence-core/store/dashboard/dashboard.state';
import { AggregationWidget, AlertBannerTarget, DashboardView, WidgetDataset } from '@ws1c/intelligence-models';

/**
 * Active Widget Modal
 * @export
 * @class ActiveWidgetModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-active-widget-modal',
  templateUrl: 'active-widget-modal.component.html',
  styleUrls: ['active-widget-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveWidgetModalComponent implements OnInit, OnDestroy {
  public activeWidget: AggregationWidget;
  public activeWidgetDialogMode$: Observable<ActiveWidgetDialogMode>;
  public duplicateWidgetDialogOpen$: Observable<boolean>;
  public deleteWidgetDialogOpen$: Observable<boolean>;
  public renameWidgetDialogOpen$: Observable<boolean>;
  public infoWidgetDialogOpen$: Observable<boolean>;
  public editThemeWidgetModalOpen$: Observable<boolean>;
  public categoryLabel$: Observable<string>;
  public dashboard: DashboardView;
  public AlertBannerTarget = AlertBannerTarget;
  public DateTimeFormat = DateTimeFormat;

  public duplicateWidgetForm: UntypedFormGroup;
  public renameWidgetForm: UntypedFormGroup;

  private sub: Subscription;

  /**
   * Creates an instance of ActiveWidgetModalComponent.
   * @param {Store<CoreAppState>} store
   * @param {UntypedFormBuilder} fb
   * @param {I18NService} i18nService
   * @memberof ActiveWidgetModalComponent
   */
  constructor(private store: Store<CoreAppState>, private fb: UntypedFormBuilder, private i18nService: I18NService) {
    this.duplicateWidgetForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    });
    this.renameWidgetForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    });
    this.categoryLabel$ = this.store.select(DashboardSelectors.getWidgetCategoryLabel);
    this.activeWidgetDialogMode$ = this.store.select(DashboardSelectors.getActiveWidgetDialogModeState);
    this.deleteWidgetDialogOpen$ = this.activeWidgetDialogMode$.pipe(
      map((activeWidgetDialogMode: ActiveWidgetDialogMode) => {
        return activeWidgetDialogMode === ActiveWidgetDialogMode.DELETE;
      }),
    );
    this.duplicateWidgetDialogOpen$ = this.activeWidgetDialogMode$.pipe(
      map((activeWidgetDialogMode: ActiveWidgetDialogMode) => {
        return activeWidgetDialogMode === ActiveWidgetDialogMode.DUPLICATE;
      }),
    );
    this.renameWidgetDialogOpen$ = this.activeWidgetDialogMode$.pipe(
      map((activeWidgetDialogMode: ActiveWidgetDialogMode) => {
        return activeWidgetDialogMode === ActiveWidgetDialogMode.RENAME;
      }),
    );
    this.infoWidgetDialogOpen$ = this.activeWidgetDialogMode$.pipe(
      map((activeWidgetDialogMode: ActiveWidgetDialogMode) => {
        return activeWidgetDialogMode === ActiveWidgetDialogMode.INFO;
      }),
    );
    this.editThemeWidgetModalOpen$ = combineLatest([
      this.activeWidgetDialogMode$,
      this.store.select(DashboardSelectors.getActiveWidget),
    ]).pipe(
      map(([activeWidgetDialogMode, widget]: [ActiveWidgetDialogMode, AggregationWidget]) => {
        return activeWidgetDialogMode === ActiveWidgetDialogMode.EDIT_THEME && widget?.id === this.activeWidget.id;
      }),
    );
  }

  /**
   *
   * @memberof ActiveWidgetModalComponent
   */
  public ngOnInit() {
    this.sub = this.store
      .select(DashboardSelectors.getActiveWidgetDialogModeState)
      .pipe(
        withLatestFrom(
          this.store.select(DashboardSelectors.getActiveWidgetState),
          this.store.select(DashboardSelectors.getActiveDashboardState),
        ),
      )
      .subscribe(([dialogMode, activeWidget, dashboard]: [ActiveWidgetDialogMode, AggregationWidget, DashboardView]) => {
        if (!activeWidget) {
          return;
        }
        this.dashboard = dashboard;
        this.activeWidget = activeWidget;
        switch (dialogMode) {
          case ActiveWidgetDialogMode.RENAME:
            this.renameWidgetForm.patchValue({
              name: activeWidget && activeWidget.name,
              description: activeWidget && activeWidget.description,
            });
            this.duplicateWidgetForm.reset();
            break;
          case ActiveWidgetDialogMode.DUPLICATE:
            const copiedName = this.i18nService.translate('COMMON_MESSAGES.COPY_OF', { name: activeWidget && activeWidget.name });
            this.duplicateWidgetForm.patchValue({
              name: copiedName,
              description: activeWidget && activeWidget.description,
            });
            this.renameWidgetForm.reset();
            break;
        }
      });
  }

  /**
   * ngOnDestroy
   * @memberof ActiveWidgetModalComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.sub);
  }

  /**
   * Trigger Confirm Rename Widget Action
   *
   * @param {AggregationWidget} widget
   * @param {string} renameName
   * @param {string} renameDescription
   * @memberof ActiveWidgetModalComponent
   */
  public confirmRenameWidget(widget: AggregationWidget, renameName: string, renameDescription: string) {
    const renameWidget = Object.assign({}, widget, {
      name: renameName,
      description: renameDescription,
    }) as AggregationWidget;
    this.store.dispatch(DashboardActions.confirmRenameWidget({ widget: renameWidget }));
  }

  /**
   * Trigger Confirm Duplicate Widget Action
   *
   * @param {AggregationWidget} widget
   * @param {string} duplicateName
   * @memberof ActiveWidgetModalComponent
   */
  public confirmDuplicateWidget(widget: AggregationWidget, duplicateName: string) {
    const duplicateWidget: AggregationWidget = new AggregationWidget({
      ...widget,
      name: duplicateName,
      id: null,
    });
    if (duplicateWidget.widgetDatasets) {
      widget.widgetDatasets.forEach((widgetDataset: WidgetDataset) => {
        widgetDataset.widgetId = undefined;
      });
    }
    this.store.dispatch(DashboardActions.confirmDuplicateWidget({ widget: duplicateWidget }));
  }

  /**
   * @memberof ActiveWidgetModalComponent
   */
  public confirmDeleteWidget() {
    this.store.dispatch(DashboardActions.confirmDeleteWidget());
  }

  /**
   * @memberof ActiveWidgetModalComponent
   */
  public closeModal() {
    this.store.dispatch(DashboardActions.setActiveWidgetDialogMode({ mode: ActiveWidgetDialogMode.CLOSE }));
  }
}
