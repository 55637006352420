<div class="legend-header">
  <div
    *ngIf="!isExploreMode"
    class="legend-group-by"
  >
    {{ ngxChart?.colorizedAttributeLabel }}
  </div>
  <dpa-key-selector-search
    *ngIf="isExploreMode"
    [showReset]="false"
    [showColumnsFromInput]="true"
    [allColumns]="availableColumns"
    [selectedColumn]="colorizedColumn"
    [placeholderTextKey]="'DASHBOARD_ACTIONS.SELECT_A_GROUP_BY'"
    (columnChange)="selectedColumnChange.emit($event)"
    class="chart-group-type-ahead"
  ></dpa-key-selector-search>
  <div class="legend-group-by-count">({{ ngxChart?.colorizedAttributeValues?.length }})</div>
  <div
    *ngIf="focusedSeries?.seriesNames.length"
    class="label ml-x2"
  >
    {{ 'COMMON_MESSAGES.COUNT_SELECTED' | translate : { count: focusedSeries.seriesNames.length } }}
    <cds-icon
      (click)="clearFocusedSeries.emit()"
      class="close-icon clickable"
      shape="times"
    ></cds-icon>
  </div>
</div>
<dpa-legend-horizontal
  [ngxChart]="ngxChart"
  [focusedSeries]="focusedSeries ? focusedSeries.seriesNames : []"
  [activeSeries]="activeSeries"
  (selectSeries)="selectSeries.emit($event)"
  (activateSeries)="activateSeries.emit($event)"
  (deactivateSeries)="deactivateSeries.emit($event)"
  class="horizontal-legend"
></dpa-legend-horizontal>
