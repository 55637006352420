/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, TemplateRef } from '@angular/core';
import { some } from 'lodash-es';

import { AppConfig } from '@ws1c/intelligence-common/constants';
import { AccordionWizardConfig, AccordionWizardOverviewStep } from '@ws1c/intelligence-common/models';
import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';

/**
 * AccordionWizardOverviewPageComponent
 * @export
 * @class AccordionWizardOverviewPageComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-accordion-wizard-overview-page',
  templateUrl: 'accordion-wizard-overview-page.component.html',
  styleUrls: ['accordion-wizard-overview-page.component.scss'],
})
export class AccordionWizardOverviewPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public overviewSteps: AccordionWizardOverviewStep[];
  @Input() public accordionWizardConfigsById: Record<string, AccordionWizardConfig> = {};
  @Input() public imageClass: string;
  @Input() public title: string;
  @Input() public description: string;
  @Input() public introTitle: string;
  @Input() public introDescription: string;
  @Input() public introContentTemplate?: TemplateRef<any>;
  @Input() public successRoute: string;
  @Input() public finishButtonText?: string;
  @Input() public isReadOnlyMode?: boolean = false;
  @Input() public isPrerequisitesComplete?: boolean = true;
  @Output() public finishClick: EventEmitter<void> = new EventEmitter<void>();

  public overviewStepsView: AccordionWizardOverviewStep[];
  public isLaunchDisabled: boolean;

  /**
   * constructor
   * @param {I18NService} i18nService
   * @param {Renderer2} renderer
   * @memberof AccordionWizardOverviewPageComponent
   */
  constructor(private i18nService: I18NService, public renderer: Renderer2) {}

  /**
   * ngOnInit
   * @memberof AccordionWizardOverviewPageComponent
   */
  public ngOnInit() {
    /**
     * Adding dpa-wizard-open class to the body
     * in order to hide GlobalHeaderSearchComponent
     */
    this.renderer.addClass(document.body, AppConfig.WIZARD_OPEN_CLASS);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes [description]
   * @memberof AccordionWizardOverviewPageComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.overviewSteps || changes.accordionWizardConfigsById) {
      this.setOverviewStepsView();
    }
  }

  /**
   * ngOnDestroy
   * @memberof AccordionWizardOverviewPageComponent
   */
  public ngOnDestroy() {
    this.renderer.removeClass(document.body, AppConfig.WIZARD_OPEN_CLASS);
  }

  /**
   * setOverviewStepsView
   * @memberof AccordionWizardOverviewPageComponent
   */
  public setOverviewStepsView() {
    this.overviewStepsView = this.overviewSteps.map(
      (accordionWizardOverviewStep: AccordionWizardOverviewStep, overviewStepIndex: number) => {
        return {
          ...accordionWizardOverviewStep,
          pristine: this.isOverviewStepPristine(overviewStepIndex),
          disabled: this.isOverviewStepDisabled(overviewStepIndex),
          incomplete: this.isOverviewStepIncomplete(overviewStepIndex),
          progressText: this.getOverviewProgessText(overviewStepIndex),
        };
      },
    );
    this.isLaunchDisabled =
      some(this.overviewStepsView, (step: AccordionWizardOverviewStep) => step.incomplete) || !this.isPrerequisitesComplete;
  }

  /**
   * isOverviewStepPristine
   * pristine means the corresponding accordionWizardConfig hasn't been initialized yet
   * @param {number} overviewStepIndex
   * @returns {boolean}
   * @memberof AccordionWizardOverviewPageComponent
   */
  public isOverviewStepPristine(overviewStepIndex: number): boolean {
    const overviewStep = this.overviewSteps[overviewStepIndex];
    return !this.accordionWizardConfigsById[overviewStep.accordionWizardId];
  }

  /**
   * isOverviewStepDisabled
   * @param {number} overviewStepIndex
   * @returns {boolean}
   * @memberof AccordionWizardOverviewPageComponent
   */
  public isOverviewStepDisabled(overviewStepIndex: number): boolean {
    if (!overviewStepIndex) {
      return false;
    }
    const previousOverviewStep = this.overviewSteps[overviewStepIndex - 1];
    const previousAccordionWizardConfig = this.accordionWizardConfigsById[previousOverviewStep.accordionWizardId];
    return !previousAccordionWizardConfig || !previousAccordionWizardConfig.isFinished;
  }

  /**
   * isOverviewStepIncomplete
   * @param {number} overviewStepIndex
   * @returns {boolean}
   * @memberof AccordionWizardOverviewPageComponent
   */
  public isOverviewStepIncomplete(overviewStepIndex: number): boolean {
    const overviewStep = this.overviewSteps[overviewStepIndex];
    const accordionWizardConfig = this.accordionWizardConfigsById[overviewStep.accordionWizardId];
    return !accordionWizardConfig || !accordionWizardConfig.isFinished;
  }

  /**
   * getOverviewProgessText
   * @param {number} overviewStepIndex
   * @returns {string}
   * @memberof AccordionWizardOverviewPageComponent
   */
  public getOverviewProgessText(overviewStepIndex: number): string {
    const overviewStep = this.overviewSteps[overviewStepIndex];
    const accordionWizardConfig = this.accordionWizardConfigsById[overviewStep.accordionWizardId];
    if (!accordionWizardConfig) {
      return;
    }
    if (this.isOverviewStepIncomplete(overviewStepIndex)) {
      return this.i18nService.translate('COMMON_MESSAGES.IN_PROGRESS');
    }
    if (!this.isOverviewStepPristine(overviewStepIndex)) {
      return this.i18nService.translate('COMMON_MESSAGES.COMPLETED');
    }
  }

  /**
   * isEditDisabled
   * @param {AccordionWizardOverviewStep} overviewStep
   * @returns {boolean}
   * @memberof AccordionWizardOverviewPageComponent
   */
  public isEditDisabled(overviewStep: AccordionWizardOverviewStep): boolean {
    return overviewStep.disabled || this.isReadOnlyMode || !this.isPrerequisitesComplete;
  }
}
