/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserService } from '@dpa-shared-merlot/services';
import { UserActions } from '@dpa-shared-merlot/store';

/**
 * Handles side effects for User Status
 * @export
 * @class UserEffects
 */
@Injectable()
export class UserEffects {
  public renewSession: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.renewSession),
      switchMap(() => {
        return this.userService.renewSession().pipe(
          map(() => UserActions.renewSessionSuccess()),
          catchError(() => of(UserActions.renewSessionFailure())),
        );
      }),
    ),
  );

  /**
   * silentLogout$
   * @type {Observable<Action>}
   * @memberof UserEffects
   */
  public silentLogout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.silentLogout),
      switchMap(() => {
        return this.userService.silentLogout().pipe(
          map(() => UserActions.silentLogoutSuccess()),
          catchError(() => of(UserActions.silentLogoutFailure())),
        );
      }),
    ),
  );

  /**
   * logout$
   * @type {Observable<Action>}
   * @memberof UserEffects
   */
  public logout$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(() => this.userService.logout()),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of UserEffects.
   * @param {Actions} actions$
   * @param {UserService} userService
   * @memberof UserEffects
   */
  constructor(private actions$: Actions, private userService: UserService) {}
}
