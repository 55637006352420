/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { GridsterItem } from 'angular-gridster2';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { AggregationWidgetChartType } from './dashboard.enum';
import { Trend } from './trend.model';
import { WidgetDataset } from './widget-dataset.model';
import { WidgetPosition } from './widget-position.model';
import { WidgetPreference } from './widget-preferences.model';
import { WidgetSequence } from './widget-sequence.enum';

/**
 * Aggregation Widget Model Object
 *
 * @export
 * @class AggregationWidget
 */
@Serializable
export class AggregationWidget {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('dashboard_id')
  public dashboardId: string = undefined;

  @JsonProperty({ name: 'dashboard_name', excludeToJson: true })
  public dashboardName: string = undefined;

  @JsonProperty('template_id')
  public templateId: string = undefined;

  @JsonProperty({ name: 'top_left', cls: WidgetPosition })
  public topLeft: WidgetPosition = undefined;

  @JsonProperty({ name: 'bottom_right', cls: WidgetPosition })
  public bottomRight: WidgetPosition = undefined;

  @JsonProperty({ name: 'trend', cls: Trend })
  public _trend: Trend = undefined;

  @JsonProperty('data_projection_fields')
  public _widgetColumns: string[] = undefined;

  @JsonProperty('chart_type')
  public _chartType: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'user_widget_attribute_preferences', cls: WidgetPreference })
  public widgetAttributePreferences: WidgetPreference[] = undefined;

  @JsonProperty({ name: 'widget_datasets', cls: WidgetDataset })
  public widgetDatasets: WidgetDataset[] = [];

  @JsonProperty('source_template_id')
  public sourceTemplateId: string = undefined;

  /**
   * Creates an instance of AggregationWidget.
   * @param {Array<Partial<AggregationWidget>>} args
   * @memberof AggregationWidget
   */
  constructor(...args: Array<Partial<AggregationWidget>>) {
    Object.assign(this, ...args);
  }

  /**
   * gridConfig
   * @readonly
   * @type {GridsterItem}
   */
  public get gridConfig(): GridsterItem {
    const minItemCols = this.chartType === AggregationWidgetChartType[AggregationWidgetChartType.METRIC] ? 1 : 2;
    return {
      x: this.topLeft.columnNumber,
      y: this.topLeft.rowNumber,
      rows: this.bottomRight.rowNumber - this.topLeft.rowNumber,
      cols: this.bottomRight.columnNumber - this.topLeft.columnNumber,
      minItemRows: 1,
      minItemCols,
      widget: this,
    };
  }

  /**
   * trendMode
   * @readonly
   * @type {string}
   * @memberof AggregationWidget
   */
  public get trendMode(): string {
    return this.trend?.trendDefinition?.trendMode;
  }

  /**
   * categoryId
   * @readonly
   * @type {string}
   * @memberof AggregationWidget
   */
  public get categoryId(): string {
    return this.trend?.trendDefinition?.categoryId;
  }

  /**
   * overlayCategoryId
   * @readonly
   * @type {string}
   * @memberof AggregationWidget
   */
  public get overlayCategoryId(): string {
    return this.overlayWidget?.categoryId;
  }

  /**
   *
   *
   * @readonly
   * @type {Trend}
   * @memberof AggregationWidget
   */
  public get trend(): Trend {
    return this._trend ?? this.mainWidget?.trend;
  }

  /**
   * setter for _trend
   * @memberof AggregationWidget
   */
  public set trend(trend: Trend) {
    this._trend = trend;
  }

  /**
   * widgetColumns
   * @readonly
   * @type {string[]}
   * @memberof AggregationWidget
   */
  public get widgetColumns(): string[] {
    return this.mainWidget?.widgetColumns ?? this._widgetColumns;
  }

  /**
   * setter for _widgetColumns
   * @memberof AggregationWidget
   */
  public set widgetColumns(widgetColumns: string[]) {
    this._widgetColumns = widgetColumns;
  }

  /**
   * overlayWidgetColumns
   * @readonly
   * @type {string[]}
   * @memberof AggregationWidget
   */
  public get overlayWidgetColumns(): string[] {
    return this.overlayWidget?.widgetColumns;
  }

  /**
   * chartType
   * @readonly
   * @type {string}
   * @memberof AggregationWidget
   */
  public get chartType(): string {
    return this._chartType ?? this.mainWidget?.chartType;
  }

  /**
   * setter for _chartType
   * @memberof AggregationWidget
   */
  public set chartType(chartType: string) {
    this._chartType = chartType;
  }

  /**
   * overlayChartType
   * @readonly
   * @type {string}
   * @memberof AggregationWidget
   */
  public get overlayChartType(): string {
    return this.overlayWidget?.chartType;
  }

  /**
   * overlayTrend
   * @readonly
   * @type {Trend}
   * @memberof AggregationWidget
   */
  public get overlayTrend(): Trend {
    return this.overlayWidget?.trend;
  }

  /**
   * mainWidget
   * @readonly
   * @type {WidgetDataset}
   * @memberof AggregationWidget
   */
  public get mainWidget(): WidgetDataset {
    return this.widgetDatasets?.find((widgetDataSet: WidgetDataset) => widgetDataSet.overlaySequence === WidgetSequence.MAIN);
  }

  /**
   * overlayWidget
   * @readonly
   * @type {WidgetDataset}
   * @memberof AggregationWidget
   */
  public get overlayWidget(): WidgetDataset {
    return this.widgetDatasets?.find((widgetDataSet: WidgetDataset) => widgetDataSet.overlaySequence === WidgetSequence.OVERLAY);
  }

  /**
   * mainWidgetId
   * @readonly
   * @returns {string}
   * @memberof AggregationWidget
   */
  public get mainWidgetId(): string {
    return this.mainWidget?.id ?? this.id;
  }

  /**
   * getter for overlayId
   * @returns {string}
   * @memberof AggregationWidget
   */
  public get overlayId(): string {
    return this.overlayWidget?.id;
  }

  /**
   * getter for overlayId
   * @returns {boolean}
   * @memberof AggregationWidget
   */
  public get hasOverlay(): boolean {
    return this.widgetDatasets?.length > 1;
  }

  /**
   * isValid
   * @readonly
   * @type {boolean}
   * @memberof AggregationWidget
   */
  public get isValid(): boolean {
    return this.mainWidget?.trend?.trendDefinition?.counterDefinitions.length > 0;
  }
}
