/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { DeemVirtualMgtComponentType } from '@ws1c/deem-solution/models/deem-virtual-v2/deem-virtual-mgt-component-type.enum';
// eslint-disable-next-line max-len
import { DeemVirtualMgtComponentsWidgetProps } from '@ws1c/deem-solution/models/deem-virtual-v2/deem-virtual-mgt-components-widget-props.interface';
import { COLUMN_NAMES, NgxTrendResultFlattener, StandardWidgetSubtype } from '@ws1c/intelligence-models';

/**
 * DeemHorizonInfrastructureConfig
 * @export
 * @class DeemHorizonInfrastructureConfig
 */
export class DeemHorizonInfrastructureConfig {
  public static readonly tableColumnLabelsByComponentType = {
    [DeemVirtualMgtComponentType.CONNECTION_SERVER]: {
      [NgxTrendResultFlattener.getCounterKey(5)]: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_NAME',
      [NgxTrendResultFlattener.getCounterKey(6)]: 'DEEM_VIRTUAL_SUMMARY.BUILD_NUMBER',
      [COLUMN_NAMES.byName.overall_score]: 'DEEM_VIRTUAL_SUMMARY.OVERALL_SCORE',
      [COLUMN_NAMES.byName.status_score]: 'DEEM_VIRTUAL_SUMMARY.CONNECTION_SERVER_STATUS',
      [COLUMN_NAMES.byName.cpu_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.CPU_USAGE',
      [COLUMN_NAMES.byName.memory_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.MEMORY_USAGE',
      [COLUMN_NAMES.byName.num_sessions_score]: 'DEEM_COMMON_MESSAGES.SESSIONS',
    },
    [DeemVirtualMgtComponentType.UAG_APPLIANCE]: {
      [NgxTrendResultFlattener.getCounterKey(4)]: 'DEEM_VIRTUAL_SUMMARY.UAG_APPLIANCE_NAME',
      [NgxTrendResultFlattener.getCounterKey(5)]: 'DEEM_VIRTUAL_SUMMARY.FQDN',
      [NgxTrendResultFlattener.getCounterKey(6)]: 'DEEM_COMMON_MESSAGES.TYPE',
      [COLUMN_NAMES.byName.overall_score]: 'DEEM_VIRTUAL_SUMMARY.OVERALL_SCORE',
      [COLUMN_NAMES.byName.connection_count_score]: 'STANDARD_DASHBOARD.HORIZON.CONNECTIONS',
      [COLUMN_NAMES.byName.cpu_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.CPU_USAGE',
      [COLUMN_NAMES.byName.memory_utilization_score]: 'DEEM_VIRTUAL_SUMMARY.MEMORY_USAGE',
    },
  };

  public static readonly tabsConfigByComponentType: Record<DeemVirtualMgtComponentType, DeemVirtualMgtComponentsWidgetProps> = {
    [DeemVirtualMgtComponentType.CONNECTION_SERVER]: {
      visibleWidgetSubtypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_AVG_CPU_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_AVG_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_AVG_SESSION_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_PERFORMANCE_CPU_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_PERFORMANCE_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_PERFORMANCE_SESSION_COUNT,
        StandardWidgetSubtype._DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
      ],
      configs: [
        {
          label: 'DEEM_VIRTUAL_SUMMARY.CPU',
          avgWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_AVG_CPU_USAGE,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_PERFORMANCE_CPU_USAGE,
          tableWidgetId: StandardWidgetSubtype._DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.status_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.num_sessions_score,
          ],
        },
        {
          label: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
          avgWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_AVG_MEMORY_USAGE,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_PERFORMANCE_MEMORY_USAGE,
          tableWidgetId: StandardWidgetSubtype._DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.status_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.num_sessions_score,
          ],
        },
        {
          label: 'DEEM_COMMON_MESSAGES.SESSIONS',
          avgWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_AVG_SESSION_COUNT,
          isAvgPercent: false,
          performanceWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_CONNECTION_SERVER_PERFORMANCE_SESSION_COUNT,
          tableWidgetId: StandardWidgetSubtype._DEEM_VIRTUAL_CONNECTION_SERVER_LIST_BY_OVERALL_STATUS_CPU_MEMORY_NUMSESSION_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.status_score,
            COLUMN_NAMES.byName.num_sessions_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
          ],
        },
      ],
    },
    [DeemVirtualMgtComponentType.UAG_APPLIANCE]: {
      visibleWidgetSubtypes: [
        StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_AVG_CPU_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_AVG_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_AVG_CONNECTION_COUNT,
        StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_PERFORMANCE_CPU_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_PERFORMANCE_MEMORY_USAGE,
        StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_PERFORMANCE_CONNECTION_COUNT,
        StandardWidgetSubtype._DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
      ],
      configs: [
        {
          label: 'DEEM_VIRTUAL_SUMMARY.CPU',
          avgWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_AVG_CPU_USAGE,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_PERFORMANCE_CPU_USAGE,
          tableWidgetId: StandardWidgetSubtype._DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(4),
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.connection_count_score,
          ],
        },
        {
          label: 'DEEM_VIRTUAL_SUMMARY.MEMORY',
          avgWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_AVG_MEMORY_USAGE,
          isAvgPercent: true,
          performanceWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_PERFORMANCE_MEMORY_USAGE,
          tableWidgetId: StandardWidgetSubtype._DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(4),
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.memory_utilization_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.connection_count_score,
          ],
        },
        {
          label: 'STANDARD_DASHBOARD.HORIZON.CONNECTIONS',
          avgWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_AVG_CONNECTION_COUNT,
          isAvgPercent: false,
          performanceWidgetId: StandardWidgetSubtype.DEEM_VIRTUAL_UAG_APPLIANCE_PERFORMANCE_CONNECTION_COUNT,
          tableWidgetId: StandardWidgetSubtype._DEEM_VIRTUAL_UAG_APPLIANCE_LIST_BY_OVERALL_CONNECTIONCOUNT_CPU_MEMORY_SCORE,
          tableColumnNames: [
            NgxTrendResultFlattener.getCounterKey(4),
            NgxTrendResultFlattener.getCounterKey(5),
            NgxTrendResultFlattener.getCounterKey(6),
            COLUMN_NAMES.byName.overall_score,
            COLUMN_NAMES.byName.connection_count_score,
            COLUMN_NAMES.byName.cpu_utilization_score,
            COLUMN_NAMES.byName.memory_utilization_score,
          ],
        },
      ],
    },
  };
}
