/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { DataConverterConfig, DataDecorator, DataGridColumn as DataGridColumnNew } from '@dpa/ui-common';

import { Column, COLUMN_NAMES, DataGridColumn } from '@ws1c/intelligence-models/integration-meta';
import { getShortColumnName } from './attributes-utils';

/**
 * Map column metadata into DataGrid Column Settings
 *
 * @export
 * @class ReportColumnMapper
 */
export class ReportColumnMapper {
  public static readonly bitPerSecFormatFieldSet = new Set<string>([
    COLUMN_NAMES.byName.l2_rx_link_speed_bits_ps,
    COLUMN_NAMES.byName.l2_tx_link_speed_bits_ps,
  ]);

  public static readonly bytePerSecFormatFieldSet = new Set<string>([
    COLUMN_NAMES.byName.disk_io_bytes_sec,
    COLUMN_NAMES.byName.network_interface_bytes_sec,
  ]);

  public static readonly linkFormatFieldSet = new Set<string>([COLUMN_NAMES.byName.url]);

  public static readonly stringPercentFormatFieldSet = new Set<string>([
    COLUMN_NAMES.byName.app_average_cpu_usage,
    COLUMN_NAMES.byName.app_average_memory_usage,
    COLUMN_NAMES.byName.app_cpu_usage_percentage,
    COLUMN_NAMES.byName.app_crash_rate,
    COLUMN_NAMES.byName.app_gpu_usage_percentage,
    COLUMN_NAMES.byName.app_memory_usage_percentage,
    COLUMN_NAMES.byName.app_net_error_rate,
    COLUMN_NAMES.byName.battery_level,
    COLUMN_NAMES.byName.cpu_utilization,
    COLUMN_NAMES.byName.cpu_percentage,
    COLUMN_NAMES.byName.dot11_signal_quality,
    COLUMN_NAMES.byName.logicaldisk_free_space_percentage,
    COLUMN_NAMES.byName.device_battery_percent,
    COLUMN_NAMES.byName.gpu_usage0,
    COLUMN_NAMES.byName.gpu_usage1,
    COLUMN_NAMES.byName.gpu_usage2,
    COLUMN_NAMES.byName.memory_usage_percent,
    COLUMN_NAMES.byName.memory_usage_percentage,
    COLUMN_NAMES.byName.memory_utilization_percentage,
    COLUMN_NAMES.byName.memory_commited_bytes_in_use,
    COLUMN_NAMES.byName.paging_file_usage,
    COLUMN_NAMES.byName.processor_usage_percentage,
    COLUMN_NAMES.byName.processor_total_processor_totaltime,
    COLUMN_NAMES.byName.processor_total_c1_totaltime,
    COLUMN_NAMES.byName.processor_total_c2_totaltime,
    COLUMN_NAMES.byName.processor_total_c3_totaltime,
    COLUMN_NAMES.byName.processor_total_dpc_totaltime,
    COLUMN_NAMES.byName.processor_total_idle_totaltime,
    COLUMN_NAMES.byName.processor_total_interrupt_totaltime,
    COLUMN_NAMES.byName.processor_total_privileged_totaltime,
    COLUMN_NAMES.byName.processor_total_user_totaltime,
    COLUMN_NAMES.byName.wifi_signal_strength,
  ]);

  public static readonly percentBarFormatFieldSet = new Set<string>([
    COLUMN_NAMES.byName.impact,
    COLUMN_NAMES.byName.cpu_percent_processor_time,
    COLUMN_NAMES.byName.memory_percent_committed_bytes_in_use,
  ]);

  public static readonly rateFormatFieldSet = new Set<string>([
    COLUMN_NAMES.byName.physicaldisk_avg_disk_sec_transfer,
    COLUMN_NAMES.byName.physicaldisk_avg_disk_sec_read,
    COLUMN_NAMES.byName.physicaldisk_avg_disk_sec_write,
    COLUMN_NAMES.byName.battery_status_charge_rate,
    COLUMN_NAMES.byName.battery_status_discharge_rate,
  ]);

  public static readonly valueDecoratorsByColumnName = new Map([
    ...Array.from(ReportColumnMapper.bitPerSecFormatFieldSet).map((columnName: string) => [columnName, DataDecorator.NETWORK_BPS]),
    ...Array.from(ReportColumnMapper.bytePerSecFormatFieldSet).map((columnName: string) => [columnName, DataDecorator.NETWORK_MBPS]),
    ...Array.from(ReportColumnMapper.linkFormatFieldSet).map((columnName: string) => [columnName, DataDecorator.LINK]),
    ...Array.from(ReportColumnMapper.stringPercentFormatFieldSet).map((columnName: string) => [columnName, DataDecorator.STRING_PERCENT]),
    ...Array.from(ReportColumnMapper.percentBarFormatFieldSet).map((columnName: string) => [columnName, DataDecorator.PERCENTAGE_BAR]),
    ...Array.from(ReportColumnMapper.rateFormatFieldSet).map((columnName: string) => [columnName, DataDecorator.PERCENT]),
  ] as any);

  /**
   * Convert column metadata into column grid setting
   *
   * @static
   * @param {Column[]} columns
   * @returns {DataGridColumn[]}
   * @memberof ReportColumnMapper
   */
  public static getDataGridColumns(columns: Column[]): DataGridColumn[] {
    return columns.filter(Boolean).map((column: Column) => {
      const columnName = column.attributeName || column.name;
      return {
        label: column.label ?? columnName,
        key: columnName,
        value: columnName,
        name: columnName,
        width: 200,
        tooltip: column.tooltip || column.label,
        valueDecorator: this.getValueDecorator(column),
        ...(column.sortingSupported === false ? { isUnsortable: true } : {}),
        ...(column.sortByValue ? { sortByValue: column.sortByValue } : {}),
        ...(column.className ? { className: column.className } : {}),
        ...(column.isNormalized ? { isNormalized: true } : {}),
        ...(column.error ? { error: true } : {}),
      } as DataGridColumn;
    });
  }

  /**
   * Convert column metadata into new column grid setting
   *
   * @static
   * @param {Column[]} columns
   * @returns {DataGridColumnNew[]}
   * @memberof ReportColumnMapper
   */
  public static getDataGridColumnsNew(columns: Column[]): DataGridColumnNew[] {
    return columns.filter(Boolean).map((column: Column) => {
      const columnName = column.attributeName || column.name;
      return {
        category: column.entityLabel,
        title: column.label ?? columnName,
        value: columnName,
        width: 200,
        ...(column.sortingSupported === true ? { sortable: true } : {}),
        ...(column.sortByValue ? { sortBy: column.sortByValue } : {}),
        ...(column.className ? { className: column.className } : {}),
        detail: {
          valueDecorator: this.getValueDecorator(column),
          tooltip: column.tooltip || column.label,
          ...(column.isNormalized ? { isNormalized: true } : {}),
          ...(column.error ? { error: true } : {}),
        },
      };
    });
  }

  /**
   * Get value decorator for column
   *
   * @static
   * @param {Column} column
   * @returns {DataDecorator}
   * @memberof ReportColumnMapper
   */
  private static getValueDecorator(column: Column): DataDecorator {
    // This is the only different between v1 and v2.
    // `column.attributeName` is a getter that will fallback to `column.name` if `column` is constructed with `new Column()`.
    // If column is created as plain object {}, getter `column.attributeName` is undefined so we need fallback to `column.name` here
    const columnName = column.attributeName || column.name;
    const shortenedColName = getShortColumnName(columnName);
    let valueDecorator;
    if (this.stringPercentFormatFieldSet.has(shortenedColName)) {
      valueDecorator = DataDecorator.STRING_PERCENT;
    } else if (this.bitPerSecFormatFieldSet.has(shortenedColName)) {
      valueDecorator = DataDecorator.NETWORK_BPS;
    } else if (this.bytePerSecFormatFieldSet.has(shortenedColName)) {
      valueDecorator = DataDecorator.NETWORK_MBPS;
    } else if (this.percentBarFormatFieldSet.has(shortenedColName)) {
      valueDecorator = DataDecorator.PERCENTAGE_BAR;
    } else if (this.rateFormatFieldSet.has(shortenedColName)) {
      valueDecorator = DataDecorator.PERCENT;
    } else {
      valueDecorator = column?.dataUnit
        ? DataConverterConfig.valueDecoratorsByDataUnit[column.dataUnit]
        : DataConverterConfig.dataTypeToDecoratorMap[column.dataType];
    }
    return valueDecorator;
  }
}
