/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';

import { I18NService } from './i18n.service';

/**
 * converts our keys into the keys used by i18nCommon
 * @export
 * @class I18NService
 */
@Injectable()
export class I18NToCommonService {
  public commonKeysByI18Keys = {
    ADD: 'COMMON_ACTIONS.ADD',
    ADD_RULE: 'COMMON_ACTIONS.ADD_RULE',
    ADD_RULESET: 'COMMON_ACTIONS.ADD_RULESET',
    ADD_TO_BOOKMARKS: 'BOOKMARKS.ADD_TO_BOOKMARKS',
    AND: 'FILTER_VALUE.AND',
    APPLICATIONS: 'COMMON_MESSAGES.APPLICATIONS',
    BACK: 'COMMON_ACTIONS.BACK',
    CANCEL: 'COMMON_ACTIONS.CANCEL',
    CANVAS_CURSOR_ARROW: 'COMMON_ACTIONS.CANVAS_CURSOR_ARROW',
    CANVAS_CURSOR_HAND_OPEN: 'COMMON_ACTIONS.CANVAS_CURSOR_HAND_OPEN',
    CANVAS_ZOOM_IN: 'COMMON_ACTIONS.CANVAS_ZOOM_IN',
    CANVAS_ZOOM_OUT: 'COMMON_ACTIONS.CANVAS_ZOOM_OUT',
    CHANGE_ORGANIZATION: 'CURRENT_USER.CHANGE_ORGANIZATION',
    CHANGE_ROLE: 'CURRENT_USER.CHANGE_ROLE',
    CLEAR_ALL: 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL',
    CLOSE: 'COMMON_ACTIONS.CLOSE',
    CLOUD_SERVICES_CONSOLE: 'CURRENT_USER.CLOUD_SERVICES_CONSOLE',
    COPIED_TO_CLIPBOARD: 'COMMON_MESSAGES.COPIED_TO_CLIPBOARD',
    COPY: 'COMMON_ACTIONS.COPY',
    COUNT_SELECTED: 'COMMON_MESSAGES.COUNT_SELECTED',
    DELETE_RULE: 'COMMON_ACTIONS.DELETE_RULE',
    DELETE_RULESET: 'COMMON_ACTIONS.DELETE_RULESET',
    DISCARD_CHANGES: 'WIZARD.DISCARD_CHANGES',
    DISCARD_UNSAVED_DATA: 'WIZARD.DISCARD_UNSAVED_DATA',
    DISMISS: 'COMMON_ACTIONS.DISMISS',
    DISMISS_ALL: 'COMMON_ACTIONS.DISMISS_ALL',
    ENTER_NUMBER_FOR_CUSTOM_RANGE: 'COMMON_MESSAGES.TYPE_NUMBER_TO_ADD_CUSTOM_VALUE',
    ERROR_LOADING_VALUES: 'COMMON_MESSAGES.ERROR_LOADING_VALUES',
    EXIT_FULLSCREEN: 'FULLSCREEN.EXIT_FULLSCREEN',
    FILTER: 'COMMON_ACTIONS.FILTER',
    FILTERS: 'COMMON_MESSAGES.FILTERS',
    GO_FULLSCREEN: 'FULLSCREEN.GO_FULLSCREEN',
    HIDE: 'COMMON_ACTIONS.HIDE',
    INVALID_DATA: 'COMMON_MESSAGES.INVALID_DATA',
    INVALID_EMAIL: 'CUSTOM_REPORTS_ACTIONS.INVALID_EMAIL',
    INVALID_VALUES: 'COMMON_ERRORS.INVALID_VALUES',
    IN_CATEGORY: 'GLOBAL_SEARCH.IN_CATEGORY',
    LAST_COUNT_DAYS: 'COMMON_MESSAGES.LAST_COUNT_DAYS',
    LAST_COUNT_DAY_S: 'COMMON_MESSAGES.LAST_COUNT_DAY_S',
    LAST_COUNT_HOURS: 'COMMON_MESSAGES.LAST_COUNT_HOURS',
    LAST_COUNT_HOUR_S: 'COMMON_MESSAGES.LAST_COUNT_HOUR_S',
    LAST_COUNT_MINUTES: 'COMMON_MESSAGES.LAST_COUNT_MINUTES',
    LAST_COUNT_MINUTE_S: 'COMMON_MESSAGES.LAST_COUNT_MINUTE_S',
    LAST_COUNT_MONTH_S: 'COMMON_MESSAGES.LAST_COUNT_MONTH_S',
    LAST_COUNT_WEEK_S: 'COMMON_MESSAGES.LAST_COUNT_WEEK_S',
    LAST_COUNT_YEAR_S: 'COMMON_MESSAGES.LAST_COUNT_YEAR_S',
    LOADING: 'COMMON_MESSAGES.LOADING',
    LOGOUT: 'COMMON_ACTIONS.LOGOUT',
    MARK_ALL_AS_READ: 'COMMON_ACTIONS.MARK_ALL_AS_READ',
    MARK_AS_READ: 'GLOBAL_HEADER.MARK_AS_READ',
    MARK_AS_UNREAD: 'GLOBAL_HEADER.MARK_AS_UNREAD',
    MAX: 'COMMON_MESSAGES.MAX',
    MIN: 'COMMON_MESSAGES.MIN',
    MIN_LENGTH: 'ERROR_MESSAGES.MIN_LENGTH',
    MOVE_DOWN: 'COMMON_ACTIONS.MOVE_DOWN',
    MOVE_UP: 'COMMON_ACTIONS.MOVE_UP',
    MY_SERVICES: 'CURRENT_USER.MY_SERVICES',
    NA: 'COMMON_MESSAGES.NA',
    NEXT: 'COMMON_ACTIONS.NEXT',
    NO: 'FILTER_VALUE.NO',
    NOTIFICATIONS: 'NAVIGATION.NOTIFICATIONS',
    NO_ITEMS_FOUND: 'COMMON_MESSAGES.NO_ITEMS_FOUND',
    NO_NOTIFICATIONS_MSG: 'GLOBAL_HEADER.NO_NOTIFICATIONS_MSG',
    NO_RESULTS: 'COMMON_MESSAGES.NO_RESULTS',
    NO_VALUE_FOUND: 'REPORT_CUSTOMIZE.REPORT_FILTERS_NO_VALUE_FOUND',
    N_DAY_ABBREVIATION: 'COMMON_MESSAGES.N_DAY_ABBREVIATION',
    N_DAY_S: 'COMMON_MESSAGES.N_DAY_S',
    N_HOUR_ABBREVIATION: 'COMMON_MESSAGES.N_HOUR_ABBREVIATION',
    N_HOUR_S: 'COMMON_MESSAGES.N_HOUR_S',
    N_MILLISECOND_ABBREVIATION: 'COMMON_MESSAGES.N_MILLISECOND_ABBREVIATION',
    N_MILLISECOND_S: 'COMMON_MESSAGES.N_MILLISECOND_S',
    N_MINUTE_ABBREVIATION: 'COMMON_MESSAGES.N_MINUTE_ABBREVIATION',
    N_MINUTE_S: 'COMMON_MESSAGES.N_MINUTE_S',
    N_MONTH_ABBREVIATION: 'COMMON_MESSAGES.N_MONTH_ABBREVIATION',
    N_MONTH_S: 'COMMON_MESSAGES.N_MONTH_S',
    N_SECOND_ABBREVIATION: 'COMMON_MESSAGES.N_SECOND_ABBREVIATION',
    N_SECOND_S: 'COMMON_MESSAGES.N_SECOND_S',
    N_WEEK_ABBREVIATION: 'COMMON_MESSAGES.N_WEEK_ABBREVIATION',
    N_WEEK_S: 'COMMON_MESSAGES.N_WEEK_S',
    N_YEAR_ABBREVIATION: 'COMMON_MESSAGES.N_YEAR_ABBREVIATION',
    N_YEAR_S: 'COMMON_MESSAGES.N_YEAR_S',
    OR: 'FILTER_VALUE.OR',
    ORGANIZATION_ID: 'GLOBAL_HEADER.ORGANIZATION_ID',
    ORGANIZATION_SETTINGS: 'CURRENT_USER.ORGANIZATION_SETTINGS',
    PAGINATION_INFO: 'LIST_VIEW.PAGINATION_INFO',
    PERSONAL_SETTINGS: 'COMMON_MESSAGES.PERSONAL_SETTINGS',
    POPULAR_TOPICS: 'COMMON_MESSAGES.POPULAR_TOPICS',
    PRESS_ENTER_TO_ADD_CUSTOM_VALUE: 'COMMON_MESSAGES.PRESS_ENTER_TO_ADD_CUSTOM_VALUE',
    REFRESH: 'COMMON_ACTIONS.REFRESH',
    REMOVE: 'COMMON_ACTIONS.REMOVE',
    REMOVE_ALL: 'COMMON_ACTIONS.REMOVE_ALL',
    REMOVE_FROM_BOOKMARKS: 'BOOKMARKS.REMOVE_FROM_BOOKMARKS',
    RESTORE_DEFAULT: 'COMMON_ACTIONS.RESTORE_DEFAULT',
    SAVE: 'COMMON_ACTIONS.SAVE',
    SEARCH: 'COMMON_ACTIONS.SEARCH',
    SEARCH_FOR_ANSWERS: 'COMMON_MESSAGES.SEARCH_FOR_ANSWERS',
    SEARCH_IN_CATEGORY: 'GLOBAL_SEARCH.SEARCH_IN_CATEGORY',
    SEARCH_OTHER_CATEGORIES: 'COMMON_MESSAGES.SEARCH_OTHER_CATEGORIES',
    SEARCH_RESULTS: 'COMMON_MESSAGES.SEARCH_RESULTS',
    SEARCH_RESULTS_FOR: 'COMMON_MESSAGES.SEARCH_RESULTS_FOR',
    SELECT_ALL: 'REPORT_CUSTOMIZE.REPORT_FILTERS_SELECT_ALL',
    SELECTED: 'COMMON_MESSAGES.SELECTED',
    SHARE: 'COMMON_ACTIONS.SHARE',
    SHOW: 'COMMON_ACTIONS.SHOW',
    SHOW_COLUMNS: 'REPORT_CUSTOMIZE.REPORT_FILTERS_SHOW_COLUMNS',
    SORT_BY: 'COMMON_MESSAGES.SORT_BY',
    STILL_LOADING: 'COMMON_MESSAGES.STILL_LOADING',
    SUPPORT: 'COMMON_MESSAGES.SUPPORT',
    TIME_ZONE_ABBR: 'COMMON_MESSAGES.TIME_ZONE_ABBR',
    TIME_ZONE_CURRENT_MESSAGE: 'COMMON_MESSAGES.TIME_ZONE_IS_SET_UP_ACCORDING_TO_CURRENT_LOCATION',
    TOOLTIP_INFO_ARIA_LABEL: 'COMMON_ACTIONS.TOOLTIP_INFO_ARIA_LABEL',
    TOTAL_ITEMS: 'LIST_VIEW.TOTAL_ITEMS',
    TYPE_N_LETTERS_TO_SEE_RESULTS: 'GLOBAL_SEARCH.TYPE_N_LETTERS_TO_SEE_RESULTS',
    UNABLE_LOAD_RESULTS: 'COMMON_MESSAGES.UNABLE_LOAD_RESULTS',
    UNKNOWN_VALUE: 'COMMON_MESSAGES.NA',
    USER_ID: 'GLOBAL_HEADER.USER_ID',
    USER_SETTINGS: 'CURRENT_USER.USER_SETTINGS',
    VIEW_DETAILS: 'COMMON_MESSAGES.VIEW_DETAILS',
    VIEW_MORE_VMW_DOCS: 'COMMON_MESSAGES.VIEW_MORE_VMW_DOCS',
    VIEW_MY_PROFILE: 'CURRENT_USER.VIEW_MY_PROFILE',
    VIEW_ORGANIZATION: 'CURRENT_USER.VIEW_ORGANIZATION',
    VMWARE_CLOUD_SERVICES: 'CURRENT_USER.VMWARE_CLOUD_SERVICES',
    VMWARE_PRIVACY_POLICY: 'CURRENT_USER.VMWARE_PRIVACY_POLICY',
    YES: 'FILTER_VALUE.YES',
  };

  /**
   * constructor
   * @param {I18NService} i18NService
   */
  constructor(private i18NService: I18NService) {}

  /**
   * translate
   * @param {string} key
   * @param {any} interpolateParams
   * @returns {any}
   */
  public translate(key: string, interpolateParams: any = {}) {
    if (!key) {
      return;
    }
    return this.i18NService.translate(this.commonKeysByI18Keys[key] || key, interpolateParams);
  }
}
