/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NonAdminService } from '@dpa-shared-merlot/services/non-admin.service';
import { NonAdminActions } from '@dpa-shared-merlot/store/non-admin';
import { CustomReport, ReportTrackingSearchResponse } from '@ws1c/intelligence-models';

/**
 * Handles side effects for Non admin actions
 * @export
 * @class NonAdminEffects
 */
@Injectable()
export class NonAdminEffects {
  /**
   * getReportShareList$
   * @type {Observable<Action>}
   * @memberof NonAdminEffects
   */
  public getReportShareList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NonAdminActions.getReportShareList),
      switchMap(({ shareData }: ReturnType<typeof NonAdminActions.getReportShareList>) => {
        return this.nonAdminService.getReportShareList(shareData.shareId, shareData.objectId, shareData.searchRequest).pipe(
          map((reportShareList: ReportTrackingSearchResponse) => {
            return NonAdminActions.getReportShareListSuccess({
              reportShareList,
            });
          }),
          catchError(() => of(NonAdminActions.getReportShareListFailure())),
        );
      }),
    ),
  );

  /**
   * getReportShareDetails$
   * @type {Observable<Action>}
   * @memberof NonAdminEffects
   */
  public getReportShareDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NonAdminActions.getReportShareDetails),
      switchMap(({ shareData }: ReturnType<typeof NonAdminActions.getReportShareDetails>) => {
        return this.nonAdminService.getReportShareDetails(shareData.shareId, shareData.objectId).pipe(
          map((reportDetails: CustomReport) => {
            return NonAdminActions.getReportShareDetailsSuccess({
              reportDetails,
            });
          }),
          catchError(() => of(NonAdminActions.getReportShareDetailsFailure())),
        );
      }),
    ),
  );

  /**
   * Creates an instance of NonAdminEffects.
   * @param {Actions} actions$
   * @param {NonAdminService} nonAdminService
   * @memberof NonAdminEffects
   */
  constructor(private actions$: Actions, private nonAdminService: NonAdminService) {}
}
