/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { MerlotState, OsUpdatesDashboardActions, OsUpdatesDashboardSelectors } from '@dpa-shared-merlot/store';
import { DashboardActions, IntegrationMetaActions } from '@ws1c/intelligence-core';
import {
  COLUMN_NAMES,
  Entity,
  Integration,
  StandardDashboardRequest,
  StandardDashboardType,
  SuggestionCriteria,
  SuggestionSearch,
} from '@ws1c/intelligence-models';

/**
 * OsUpdatesDashboardEffects
 * @export
 * @class OsUpdatesDashboardEffects
 */
@Injectable()
export class OsUpdatesDashboardEffects {
  /**
   * setOsUpdateDevicePlatform$
   * @type {Observable<Action>}
   * @memberof OsUpdatesDashboardEffects
   */
  public setOsUpdateDevicePlatform$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OsUpdatesDashboardActions.setOsUpdateDevicePlatform),
      withLatestFrom(
        this.store.select(OsUpdatesDashboardSelectors.getPlatformHasPatchData),
        (action: Action, hasPatchData: boolean) => hasPatchData,
      ),
      map((hasPatchData: boolean) => {
        if (hasPatchData) {
          return OsUpdatesDashboardActions.loadOsMergedDashboard();
        } else {
          return OsUpdatesDashboardActions.loadOsVersionsDashboard();
        }
      }),
    ),
  );

  /**
   * loadOsSummaryDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadOsSummaryDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OsUpdatesDashboardActions.loadOsSummaryDashboard),
      switchMap(({ preferenceDataNeeded }: ReturnType<typeof OsUpdatesDashboardActions.loadOsSummaryDashboard>) => {
        const request = new StandardDashboardRequest(StandardDashboardType.OS_SUMMARY);
        // Need to load available platforms for OS Updates Dashboard
        const suggestionSearch = {
          category: {
            entity: {
              name: Entity.DEVICE,
            },
            integration: {
              name: Integration.AIRWATCH,
            },
          },
          suggestionCriteria: new SuggestionCriteria({
            columnName: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
            query: '',
            size: 50,
          }),
        } as SuggestionSearch;
        return [
          OsUpdatesDashboardActions.setOsUpdateDevicePlatformSuggestionSearch({ suggestionSearch }),
          IntegrationMetaActions.searchFilterValues({
            suggestionSearch,
          }),
          DashboardActions.loadStandardDashboard({ request, preferenceDataNeeded }),
        ];
      }),
    ),
  );

  /**
   * updateOsVersionsDashboardFilters$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateOsVersionsDashboardFilters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OsUpdatesDashboardActions.updateOsVersionsDashboardFilters),
      withLatestFrom(this.store.select(OsUpdatesDashboardSelectors.getPlatformHasPatchData)),
      map(([_action, hasPatchData]: [Action, boolean]) => {
        if (hasPatchData) {
          return OsUpdatesDashboardActions.loadOsMergedDashboard();
        } else {
          return OsUpdatesDashboardActions.loadOsVersionsDashboard();
        }
      }),
    ),
  );

  /**
   * loadOsVersionsDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadOsVersionsDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OsUpdatesDashboardActions.loadOsVersionsDashboard),
      withLatestFrom(this.store.select(OsUpdatesDashboardSelectors.getOsVersionsDashboardRequest)),
      map(([_action, request]: [Action, StandardDashboardRequest]) => DashboardActions.loadStandardDashboard({ request })),
    ),
  );

  /**
   * updateOsPatchesDashboardFilters$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public updateOsPatchesDashboardFilters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OsUpdatesDashboardActions.updateOsPatchesDashboardFilters, OsUpdatesDashboardActions.loadOsPatchesDashboard),
      map(() => OsUpdatesDashboardActions.loadOsMergedDashboard()),
    ),
  );

  /**
   * loadOsMergedDashboard$
   * @type {Observable<Action>}
   * @memberof DashboardEffects
   */
  public loadOsMergedDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OsUpdatesDashboardActions.loadOsMergedDashboard),
      withLatestFrom(
        this.store.select(OsUpdatesDashboardSelectors.getOsPatchesDashboardRequest),
        this.store.select(OsUpdatesDashboardSelectors.getOsVersionsDashboardRequest),
      ),
      switchMap(([_action, patchesRequest, versionsRequest]: [Action, StandardDashboardRequest, StandardDashboardRequest]) => {
        return of(
          DashboardActions.loadMergedStandardDashboard({
            requests: [patchesRequest, versionsRequest],
          }),
        );
      }),
    ),
  );

  /**
   * Creates an instance of OsUpdatesDashboardEffects.
   * @param {Store<MerlotState>} store
   * @param {Actions} actions$
   * @memberof OsUpdatesDashboardEffects
   */
  constructor(private store: Store<MerlotState>, private actions$: Actions) {}
}
