/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { RouterExtensions } from '@ws1c/intelligence-common';
import { DashboardSelectors, NavigationMenuActions, NavigationMenuSelectors } from '@ws1c/intelligence-core';
import { MenuItem, NavigationMenuGroup, ROUTE_NAMES, WidgetDetailDefinition } from '@ws1c/intelligence-models';

/**
 * Common Menu Component
 *
 * @export
 * @class CommonMenuComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-common-menu',
  templateUrl: 'common-menu.component.html',
  styleUrls: ['common-menu.component.scss'],
})
export class CommonMenuComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public defaultMenuItemRoute: string;
  @Input() public navGroup: NavigationMenuGroup;
  @Input() public navGroupHeading: string;
  @Input() public navGroupIconClass?: string = '';
  @Input() public navGroupIconName?: string;
  @Input() public menuItems?: MenuItem[] = [];
  @Input() public navItemsTemplate?: TemplateRef<any>;

  public isSecondaryNavCollapsed: boolean;
  public isGroupCollapsed: boolean = true;
  public isGroupActive: boolean = false;
  public sub: Subscription = new Subscription();
  public widgetDetailPageBreadcrumbUrl: string;

  /**
   * Creates an instance of CommonMenuComponent
   * @param {RouterExtensions} routerExtensions - Router Extensions instance
   * @param {Store} store
   * @param {ChangeDetectorRef} changeRef
   * @memberof CommonMenuComponent
   */
  constructor(private routerExtensions: RouterExtensions, private store: Store, private changeRef: ChangeDetectorRef) {}

  /**
   * ngOnInit
   * @memberof CommonMenuComponent
   */
  public ngOnInit() {
    this.sub.add(
      combineLatest([this.routerExtensions.url$, this.store.select(DashboardSelectors.getWidgetDetailDefinition)]).subscribe(
        ([url, widgetDetailDefinition]: [string, WidgetDetailDefinition]) => {
          this.onRouteChange(url, widgetDetailDefinition);
        },
      ),
    );
    this.sub.add(
      this.store.select(NavigationMenuSelectors.getExpandedNavMenuGroups).subscribe((expandedNavGroups: NavigationMenuGroup[]) => {
        this.isGroupCollapsed = !expandedNavGroups?.includes(this.navGroup);
      }),
    );
    this.sub.add(
      this.store.select(NavigationMenuSelectors.isSecondaryNavigationMenuCollapsed).subscribe((isCollapsed: boolean) => {
        this.isSecondaryNavCollapsed = isCollapsed;
      }),
    );
  }

  /**
   * Checks which side Nav Group is active and side nav menu for Widget detail when route changed
   * onRouteChange
   * @param {string} currentUrl
   * @param {WidgetDetailDefinition} widgetDetailDefinition
   * @memberof CommonMenuComponent
   */
  public onRouteChange(currentUrl: string, widgetDetailDefinition: WidgetDetailDefinition) {
    //Breadcrumb url to get correct active side nav menu when current url is custom widget route
    this.widgetDetailPageBreadcrumbUrl =
      widgetDetailDefinition?.lastBreadcrumbUrl && currentUrl?.includes(ROUTE_NAMES.DASHBOARD.WIDGET_DETAILS)
        ? widgetDetailDefinition.lastBreadcrumbUrl
        : '';
    // gets active side nav group
    this.isGroupActive = this.isNavGroupActive(this.widgetDetailPageBreadcrumbUrl || currentUrl);
    if (this.widgetDetailPageBreadcrumbUrl) {
      this.changeRef.detectChanges();
    }
  }

  /**
   * Navigate to default menu item route when defaultMenuItemRoute input property changes
   * @param {SimpleChanges} changes - Holds map of changed input property to value
   * @memberof CommonMenuComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultMenuItemRoute && this.defaultMenuItemRoute) {
      this.routerExtensions.navigate([this.defaultMenuItemRoute]);
    }
  }

  /**
   * Checks for Active Side Nav Menu only for widget detail page when nested route not followed
   * @param {string} menuRoute
   * @memberof CommonMenuComponent
   * @returns {boolean}
   */
  public isSideNavMenuActive(menuRoute: string): boolean {
    return this.widgetDetailPageBreadcrumbUrl?.includes(menuRoute);
  }

  /**
   * Checks if a navigation group is active based on the current/lastBreadcrumb URL.
   * @param {string} currentUrl
   * @memberof CommonMenuComponent
   * @returns {boolean}
   */
  public isNavGroupActive(currentUrl: string): boolean {
    return this.navGroupHeading && this.menuItems?.some((item: MenuItem) => currentUrl.includes(item.route));
  }

  /**
   * ngOnDestroy
   * @memberof CommonMenuComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * Handler for nav group label click event.
   * Emits expandSecondaryNavigationMenu event.
   * @param {MouseEvent} $event
   * @memberof CommonMenuComponent
   */
  public onNavGroupLabelClick($event: MouseEvent) {
    const node = ($event.target as HTMLElement).closest('.nav-group-content');
    if (!node) {
      return;
    }
    this.store.dispatch(NavigationMenuActions.toggleSecondaryNavigationMenuGroup({ navMenuGroup: this.navGroup }));
    if (this.isSecondaryNavCollapsed) {
      this.store.dispatch(NavigationMenuActions.setSecondaryNavigationCollapsedFlag({ isCollapsed: false }));
    }
  }
}
