/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TypeAheadComponent } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { getColumnKey } from '@ws1c/intelligence-common';
// eslint-disable-next-line max-len
import { KeySelectorSearchComponent } from '@ws1c/intelligence-core/components/query-builder/key-selector-search/key-selector-search.component';
import { CoreAppState, IntegrationMetaSelectors, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store';
import { Category, Column, ColumnIndex, ColumnToggleFilter, FilterRule, Operator, SuggestionFilterBy } from '@ws1c/intelligence-models';

/**
 * FilterGroupRuleComponent
 * @export
 * @class FilterGroupRuleComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-filter-group-rule',
  templateUrl: 'filter-group-rule.component.html',
  styleUrls: ['filter-group-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterGroupRuleComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public rule: FilterRule;
  @Input() public visibleColumnsSortedByName?: Column[] = [];
  @Input() public allColumnsByName?: ColumnIndex = {};
  @Input() public suggestionFilterBys: SuggestionFilterBy[];
  @Input() public suggestionCategory?: Category;
  @Input() public showThreeColumnFilter?: boolean = false;
  @Input() public isCrossCategory?: boolean = false;
  // https://jira-euc.eng.vmware.com/jira/browse/INTEL-10440
  // When filter is used in Dashboard, if there is only one option available, we don't need to show key selector
  // Having this set to true by default to make sure everywhere else we always show the key selector
  // regardless number of visible keys
  @Input() public alwaysShowKeySelector?: boolean = true;
  @Input() public delimiterSupported?: boolean = false;
  @Input() public showColumnsFromInput?: boolean = false;
  @Output() public ruleChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('leadTypeAhead') public leadTypeAhead: TypeAheadComponent;
  @ViewChild('keySearch') public keySearch: KeySelectorSearchComponent;

  public getColumnKey = getColumnKey;
  public columnToggleFilterMap: Record<string, ColumnToggleFilter> = {};
  public isAttributeSelectorV2Enabled: boolean = false;
  public sub: Subscription = new Subscription();

  /**
   * Creates an instance of FilterGroupRuleComponent.
   * @param {Store<CoreAppState>} store
   * @memberof FilterGroupRuleComponent
   */
  constructor(private store: Store<CoreAppState>) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges) {
    // Selects first column if there is only one and alwaysShowKeySelector = false
    const newVisibleColumns = changes.visibleColumnsSortedByName && changes.visibleColumnsSortedByName.currentValue;
    if (
      newVisibleColumns &&
      newVisibleColumns.length === 1 &&
      ![newVisibleColumns[0].name, newVisibleColumns[0].attributeName].includes(this.rule.attribute) && // for FQFN check for attributeName
      !this.alwaysShowKeySelector
    ) {
      this.onColumnChange(newVisibleColumns[0]);
    }
  }

  /**
   * ngOnInit
   * @memberof FilterGroupRuleComponent
   */
  public ngOnInit() {
    this.sub.add(
      this.store
        .select(IntegrationMetaSelectors.getRawAndNormalizedMapping)
        .subscribe((columnToggleFilterMap: Record<string, ColumnToggleFilter>) => {
          this.columnToggleFilterMap = columnToggleFilterMap;
        }),
    );

    this.sub.add(
      this.store
        .select(UserPreferenceFeatureControlsSelectors.isAttributeSelectorV2Enabled)
        .subscribe((isAttributeSelectorV2Enabled: boolean) => {
          this.isAttributeSelectorV2Enabled = isAttributeSelectorV2Enabled;
        }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof FilterGroupRuleComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * onRuleChange
   * @memberof FilterGroupRuleComponent
   * @returns {Column}
   */
  public onRuleChange() {
    this.ruleChange.emit(this.rule);
  }

  /**
   * getSelectedColumn
   * @memberof FilterGroupRuleComponent
   * @returns {Column}
   */
  public getSelectedColumn(): Column {
    return this.allColumnsByName[this.rule.attribute];
  }

  /**
   * onColumnChange - resets rule to initial values
   * @param {any} column
   * @memberof FilterGroupRuleComponent
   */
  public onColumnChange(column: Column) {
    if (column) {
      const blankRule: FilterRule = this.getInitialRuleFromColumn(column);
      Object.assign(this.rule, blankRule);
    } else {
      this.clearRule(this.rule);
    }
    this.onRuleChange();
  }

  /**
   * getInitialRuleFromColumn
   * @param {Column} column
   * @returns {FilterRule}
   * @memberof FilterGroupRuleComponent
   */
  public getInitialRuleFromColumn(column: Column): FilterRule {
    return FilterRule.getInitialRuleFromColumn(column);
  }

  /**
   * clearRule
   * @param {FilterRule} rule
   * @memberof FilterGroupRuleComponent
   */
  public clearRule(rule: FilterRule) {
    Object.assign(rule, {
      condition: undefined,
      attribute: undefined,
      label: undefined,
      data: undefined,
      dataType: undefined,
    });
  }

  /**
   * getSelectedCondition
   * @param {FilterRule} rule
   * @returns {Operator}
   * @memberof FilterGroupRuleComponent
   */
  public getSelectedCondition(rule: FilterRule): Operator {
    return this.getAvailableConditions(rule).find((condition) => condition.value === rule.condition);
  }

  /**
   * getAvailableConditions
   * @param {FilterRule} rule
   * @returns {Operator[]}
   * @memberof FilterGroupRuleComponent
   */
  public getAvailableConditions(rule: FilterRule): Operator[] {
    return (this.allColumnsByName[rule.attribute] || ({} as Column)).supportedOperators || [];
  }

  /**
   * setConditionValue
   * @param {any} condition
   * @memberof FilterGroupRuleComponent
   */
  public setConditionValue(condition: any) {
    const conditionValue = condition && condition.value;
    const isSingle: boolean = condition && condition.single;
    this.rule.valueRequired = condition?.valueRequired;
    if (this.rule.condition !== conditionValue) {
      this.rule.condition = conditionValue;
      this.rule.data = this.getRuleData(isSingle, this.rule.valueRequired);
      this.onRuleChange();
    }
  }

  /**
   * getRuleData
   * @param {boolean} isSingle
   * @param {boolean} valueRequired
   * @memberof FilterGroupRuleComponent
   * @returns {any[]}
   */
  public getRuleData(isSingle: boolean, valueRequired: boolean): any[] {
    if (!valueRequired) {
      return null;
    }
    let data = this.rule.data;
    if (!data || data.length === 0) {
      return [];
    }
    data = Array.isArray(data) ? data : [data];
    return isSingle ? [data[0]] : data;
  }

  /**
   * onRuleDataChange
   * @param {any} data
   * @memberof FilterGroupRuleComponent
   */
  public onRuleDataChange(data: any) {
    this.rule.data = Array.isArray(data) && !data.length ? undefined : data;
    this.onRuleChange();
  }

  /**
   * getConditionKey
   * @param {any} condition
   * @returns {any}
   * @memberof FilterGroupRuleComponent
   */
  public getConditionKey(condition: any): any {
    return condition.name;
  }

  /**
   * typeAheadformatter
   * @param {any} result
   * @returns {string}
   * @memberof FilterGroupRuleComponent
   */
  public typeAheadformatter(result: any): string {
    return result.label || '';
  }

  /**
   * focusLeadTypeAhead - used by parent (FilterGroupComponent)
   * @memberof FilterGroupRuleComponent
   */
  public focusLeadTypeAhead() {
    if (this.leadTypeAhead) {
      this.leadTypeAhead.focusInput();
    } else {
      this.keySearch.focusInput();
    }
  }

  /**
   * shouldShowKeySelector
   *
   * Determines when we need to show Key Selector based on
   * number of  visibleColumnsSortedByName and alwaysShowKeySelector flag
   *
   * @returns {boolean}
   * @memberof FilterGroupRuleComponent
   */
  public shouldShowKeySelector(): boolean {
    return this.visibleColumnsSortedByName.length !== 1 || this.alwaysShowKeySelector;
  }
}
