<div
  *ngIf="trendDefinition$ | async as trendDefinition"
  class="control-bar"
>
  <div
    *ngIf="isGroupByVisible$ | async"
    class="toolbar-input-wrapper medium"
  >
    <dpa-tooltip [tooltipText]="'COMMON_ACTIONS.GROUP_BY' | translate">
      <cds-icon shape="blocks-group"></cds-icon>
    </dpa-tooltip>
    <dpa-column-attribute-selector
      [entity]="trendDefinition.entity"
      [integration]="trendDefinition.integration"
      [integrations]="trendDefinition.integrations"
      [selectedColumnName]="selectedColumnName$ | async"
      [isCrossCategory]="trendDefinition.isCrossEntityOrIntegration || isCrossCategory"
      (selectedColumnChange)="onSelectedAttributeChange($event)"
      class="toolbar-input"
    ></dpa-column-attribute-selector>
  </div>
  <div
    *ngIf="showDateRangePicker && trendDefinition.dateRange"
    class="toolbar-input-wrapper"
  >
    <dpa-tooltip [tooltipText]="'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_DATE_RANGE' | translate">
      <cds-icon shape="calendar"></cds-icon>
    </dpa-tooltip>
    <dpa-trend-date-range
      [trendDateRange]="dateRange"
      [splitCol]="splitCol"
      (trendDateRangeUpdated)="updateTrendDateRange($event)"
      class="toolbar-input"
    ></dpa-trend-date-range>
  </div>
  <div
    *ngIf="showChartTypeSwitcher"
    class="toolbar-input-wrapper small"
  >
    <dpa-tooltip [tooltipText]="'WIDGET_CUSTOMIZE.WIDGET_CHART_TYPE' | translate">
      <cds-icon
        [attr.shape]="selectedChartType$ | async | dpaIconName : '' : '-chart'"
        class="dpa-icon"
      ></cds-icon>
    </dpa-tooltip>
    <dpa-chart-type-ahead-selector
      [chartTypes]="chartTypeOptions$ | async"
      [selectedChartType]="selectedChartType$ | async"
      (chartTypeSelected)="selectChartType($event)"
      class="toolbar-input"
    ></dpa-chart-type-ahead-selector>
  </div>
  <div
    *ngIf="(drilldownEvents$ | async)?.length"
    class="toolbar-input-wrapper small"
  >
    <button
      (click)="clearDrilldown()"
      class="btn btn-link edit"
    >
      {{ 'COMMON_ACTIONS.RESET' | translate }}
    </button>
  </div>
  <div
    *ngIf="showInvertModeToggle"
    class="toolbar-input-wrapper medium toolbar-right"
  >
    <dpa-tooltip [tooltipText]="'WIDGET_CUSTOMIZE.ANOMALY_SCORE_VIEW_TOOLTIP' | translate">
      <clr-toggle-container class="mt-x0">
        <clr-toggle-wrapper>
          <input
            clrToggle
            [checked]="!!isInvertMode"
            (click)="updateInvertMode()"
            type="checkbox"
          />
          <label>{{ 'WIDGET_CUSTOMIZE.ANOMALY_SCORE_VIEW' | translate }}</label>
        </clr-toggle-wrapper>
      </clr-toggle-container>
    </dpa-tooltip>
  </div>
</div>
