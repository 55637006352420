/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, Subscription } from 'rxjs';

import { AppConfig, CookiesService, Endpoint } from '@ws1c/intelligence-common';
import { OrgSelectors, RbacSelectors, UserPreferenceSelectors } from '@ws1c/intelligence-core/store';
import { LOAD_STATE } from '@ws1c/intelligence-models';

/**
 * Handle App Authentication Check
 * @export
 * @class AuthService
 * @implements {OnDestroy}
 */
@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  public isEulaAccepted$: Observable<boolean>;
  public sub: Subscription;
  public isAuthenticated$: Observable<boolean>;
  public isNavigationRestricted$: Observable<boolean>;
  public accessError$: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject<HttpErrorResponse>(undefined);
  public preferenceLoadState$: BehaviorSubject<LOAD_STATE> = new BehaviorSubject<LOAD_STATE>(LOAD_STATE.NONE);

  /**
   * Creates an instance of AuthService.
   * @param {CookiesService} cookiesService
   * @param {Store} store
   * @memberof AuthService
   */
  constructor(private cookiesService: CookiesService, private store: Store) {
    this.isAuthenticated$ = this.preferenceLoadState$.pipe(map((status: LOAD_STATE) => status === LOAD_STATE.SUCCESS));
    this.isEulaAccepted$ = this.store.select(UserPreferenceSelectors.isEulaAccepted);
    this.sub = this.store.select(UserPreferenceSelectors.getUserPreferenceLoadState).subscribe(this.preferenceLoadState$);
    this.isNavigationRestricted$ = combineLatest([
      this.isAuthenticated$,
      this.accessError$.pipe(map((error: HttpErrorResponse) => !!error)),
      this.store.select(UserPreferenceSelectors.isNonAdminMode),
      this.store.select(UserPreferenceSelectors.isRemediationMode),
      this.store.select(UserPreferenceSelectors.isEulaAccepted),
      this.store.select(RbacSelectors.hasUserScopes),
      this.store.select(OrgSelectors.hasOrgDisabledConfig),
    ]).pipe(
      map(
        ([
          isAuthenticated,
          hasThrownAccessError,
          isNonAdminMode,
          isRemediationMode,
          isEulaAccepted,
          hasUserScopes,
          hasOrgDisabledConfig,
        ]: boolean[]) =>
          !isAuthenticated ||
          hasThrownAccessError ||
          isNonAdminMode ||
          isRemediationMode ||
          !isEulaAccepted ||
          !hasUserScopes ||
          hasOrgDisabledConfig,
      ),
    );
  }

  /**
   * @memberof AuthService
   */
  public ngOnDestroy() {
    unsubscribe(this.sub);
  }

  /**
   * Check whether user is authorized or not based on the flag that is set during the app init.
   * @returns {boolean}
   * @memberof AuthService
   */
  public isAuthenticated(): boolean {
    return this.preferenceLoadState$.value === LOAD_STATE.SUCCESS;
  }

  /**
   * Get the full url for Integration Activate API endpoint
   * @param {string} integration ['idm'/'horizon']
   * @param {string} redirectPath
   * @returns {string}
   * @memberof AuthService
   */
  public getIntegrationAuthorizeUrl(integration: string, redirectPath?: string): string {
    return `${this.cookiesService.getCookie(AppConfig.API_URL)}${Endpoint.INTEGRATION_AUTHORIZE(integration, redirectPath)}`;
  }

  /**
   * Get XSRF-TOKEN from cookies
   *
   * @returns {string}
   * @memberof AuthService
   */
  public getXSRFFToken(): string {
    return this.cookiesService.getCookie(AppConfig.XSRF_TOKEN);
  }

  /**
   * setPreferenceLoadState
   *
   * @param {LOAD_STATE} currentState
   * @memberof AuthService
   */
  public setPreferenceLoadState(currentState: LOAD_STATE) {
    this.preferenceLoadState$.next(currentState);
  }

  /**
   * setAccessError
   *
   * @param {HttpErrorResponse} error
   * @memberof AuthService
   */
  public setAccessError(error: HttpErrorResponse) {
    this.accessError$.next(error);
  }
}
