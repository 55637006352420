<div
  *ngIf="show"
  dpaScrollIntoView
  (click)="preventDefault($event)"
  (keyup.enter)="preventDefault($event)"
  class="all-container"
>
  <div
    *ngIf="showSearchTextBox"
    [class.flat]="showFlat"
    [class.empty]="filterKeys.length === 0"
    class="search-box"
  >
    <div class="search-icon">
      <cds-icon shape="search"></cds-icon>
    </div>
    <dpa-local-search
      #localSearch
      [inputValue]="searchString"
      [showIcon]="false"
      [showReset]="showReset"
      [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
      (keydown)="onMenuContainerKeydown($event, true)"
      (onSearch)="onSearchText($event)"
      iconClass="search"
    ></dpa-local-search>
  </div>
  <div
    *ngIf="!isAttributeSelectorV2Enabled"
    class="filters-container"
  >
    <dpa-tooltip [tooltipText]="'COMMON_MESSAGES.NORMALIZED_FILTER_TOOLTIP' | translate">
      <button
        [class.active]="filterNormalized$ | async"
        (click)="toggleFilter(FILTER_NAME.NORMALIZED)"
        (keyup.enter)="toggleFilter(FILTER_NAME.NORMALIZED)"
        (keydown.enter)="preventDefault($event)"
        class="label-filter label label-light-blue clickable"
        type="button"
      >
        {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
      </button>
    </dpa-tooltip>
  </div>
  <div
    (click)="preventDefault($event)"
    (keyup.enter)="preventDefault($event)"
    (mousedown)="preventDefault($event)"
  >
    <div
      *ngIf="filterKeys.length === 0"
      class="empty-container"
    >
      <div class="empty-text">
        {{ 'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate }}
      </div>
    </div>
    <ng-container *ngIf="isAttributeSelectorV2Enabled; else v1View">
      <div
        *ngIf="filterKeys.length > 0"
        (keydown)="onMenuContainerKeydown($event, true)"
        class="flat-view selector-container"
        tabindex="0"
      >
        <ng-container>
          <div
            [ngClass]="{ 'pl-x0': showFlat }"
            class="form-group alpha-col-elem-container"
          >
            <ng-container *ngIf="!baseClassificationNotFound">
              <div
                *ngIf="baseCategoryResultNotFound"
                class="pv-x1 pl-x1"
              >
                {{ 'COLUMN_SELECTOR.NO_RESULT_IN_BASE_CATEGORY' | translate : { categoryLabel: activeCategory.fullLabel } }}
              </div>
              <button
                *ngIf="hideExtraColumns && baseCategoryResultNotFound"
                (click)="toggleHideExtraColumns($event)"
                class="btn btn-link"
              >
                {{ 'COLUMN_SELECTOR.SHOW_ALL' | translate }}
              </button>
            </ng-container>
            <clr-tree>
              <ng-container *ngFor="let entityColumns of filterKeys; trackBy: trackByEntityName; let index = index">
                <clr-tree-node
                  *ngIf="index === (baseCategoryResultNotFound ? -1 : 0) || !hideExtraColumns || baseClassificationNotFound"
                  [clrExpanded]="true"
                  class="entity"
                >
                  <span class="columns-header">
                    {{ entityColumns.label }}
                  </span>
                  <ng-container
                    *dpaDelayRender="1"
                    ngProjectAs="clr-tree-node"
                  >
                    <clr-tree-node
                      *ngFor="let column of entityColumns.columns; trackBy: trackByColumnIdFn"
                      (keyup.enter)="applySelectedKey(column)"
                      class="adjusted-padding"
                    >
                      <div
                        *ngIf="!(column?.attribute?.fullyQualifiedName | isAttributeRaw : columnToggleFilterMap)"
                        class="col-items clr-row filtered-item"
                        role="option"
                      >
                        <ng-container>
                          <ng-template
                            [ngTemplateOutlet]="attributeSelectorV2ColumnLabel"
                            [ngTemplateOutletContext]="{ column: column }"
                          ></ng-template>
                        </ng-container>
                      </div>
                    </clr-tree-node>
                  </ng-container>
                </clr-tree-node>
              </ng-container>
            </clr-tree>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #v1View>
      <div
        *ngIf="filterKeys.length > 0"
        (keydown)="onMenuContainerKeydown($event, true)"
        class="selector-container"
        tabindex="0"
      >
        <ng-container *ngIf="showFlat; else threeColumnView">
          <div
            [ngClass]="{ 'pl-x0': showFlat }"
            class="form-group alpha-col-elem-container"
          >
            <clr-tree>
              <clr-tree-node
                *ngFor="let entityColumn of filterKeys; trackBy: trackByEntityName"
                [clrExpanded]="true"
              >
                <span class="columns-header">
                  {{ entityColumn.label }}
                </span>
                <ng-container
                  *dpaDelayRender="1"
                  ngProjectAs="clr-tree-node"
                >
                  <clr-tree-node
                    *ngFor="let column of entityColumn.columns; trackBy: trackByColumnIdFn"
                    (keyup.enter)="applySelectedKey(column)"
                    class="adjusted-padding"
                  >
                    <div
                      class="col-items clr-row filtered-item"
                      role="option"
                    >
                      <ng-container>
                        <ng-template
                          [ngTemplateOutlet]="columnLabel"
                          [ngTemplateOutletContext]="{ column: column }"
                        ></ng-template>
                      </ng-container>
                    </div>
                  </clr-tree-node>
                </ng-container>
              </clr-tree-node>
            </clr-tree>
          </div>
        </ng-container>
        <ng-template #threeColumnView>
          <div class="three-row-container clr-row">
            <div
              #filterClassificationContainer
              class="list-group-container clr-col-sm-4"
            >
              <div
                *ngFor="let classification of filterKeys"
                [class.active]="isClassificationActive(classification)"
                [class.focused]="isSameItem(classification, filterClassificationFocusedItem)"
                [title]="classification.label"
                (click)="selectClassfication(classification, true, true)"
                (keyup.enter)="selectClassfication(classification, true)"
                class="list-group-item text-ellipsis filtered-item"
                role="option"
              >
                {{ classification.label }}
                <cds-icon
                  class="caret-icon group-type-caret"
                  shape="angle"
                  direction="right"
                ></cds-icon>
              </div>
            </div>
            <div
              *ngIf="currentClassification?.columns"
              #filterKeyContainer
              class="form-group clr-col-sm-8 col-elem-container"
            >
              <div *dpaDelayRender="1">
                <div
                  *ngFor="let column of currentClassification.columns; trackBy: trackByColumnIdFn"
                  [class.focused]="column === filterKeyFocusedItem"
                  class="col-items filtered-item"
                  role="option"
                >
                  <ng-template
                    [ngTemplateOutlet]="columnLabel"
                    [ngTemplateOutletContext]="{ column: column }"
                  ></ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
    <div
      *ngIf="filterKeys.length > 0"
      class="footer-banner"
    >
      <ng-container *ngIf="isAttributeSelectorV2Enabled; else v1Buttons">
        <button
          *ngIf="!baseClassificationNotFound"
          (click)="toggleHideExtraColumns($event)"
          class="btn btn-link footer-link m-x0"
        >
          {{ (hideExtraColumns ? 'COLUMN_SELECTOR.SHOW_ALL' : 'COLUMN_SELECTOR.HIDE') | translate }}
        </button>
      </ng-container>
      <ng-template #v1Buttons>
        <div class="view-option">
          <div class="btn-group btn-link">
            <dpa-tooltip [tooltipText]="'NAVIGATION.CUSTOM_REPORTS_LIST_VIEW' | translate">
              <cds-icon
                [class.is-selected]="showFlat"
                (click)="setViewMode(KEY_SELECTOR_VIEW.LIST)"
                (keyup.enter)="setViewMode(KEY_SELECTOR_VIEW.LIST)"
                class="clickable"
                shape="view-list"
                size="20"
              ></cds-icon>
            </dpa-tooltip>
            <dpa-tooltip [tooltipText]="'COMMON_MESSAGES.COLUMN_VIEW' | translate">
              <cds-icon
                [class.is-selected]="!showFlat"
                (click)="setViewMode(KEY_SELECTOR_VIEW.COLUMN)"
                (keyup.enter)="setViewMode(KEY_SELECTOR_VIEW.COLUMN)"
                class="clickable"
                shape="view-columns"
                size="20"
              ></cds-icon>
            </dpa-tooltip>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="glossary-link">
      <a
        *ngIf="filterDescriptionsUrl$ | async as url"
        (click)="openLink($event, url)"
        (keyup.enter)="openLink($event, url)"
        class="link-normal"
      >
        {{ 'COMMON_MESSAGES.GLOSSARY' | translate }}
        <cds-icon
          class="ml-x1"
          shape="pop-out"
        ></cds-icon>
      </a>
    </div>
  </div>
</div>
<ng-template
  #columnLabel
  let-column="column"
>
  <span
    [title]="column.tooltip"
    [class.active]="column.name === currentKey?.name"
    [dpaInnerHtmlLight]="column.styledString || column.label"
    [ngClass]="column.attribute.isNormalized ? 'clr-col-sm-8' : 'clr-col-sm-12'"
    (click)="applySelectedKey(column)"
    (keyup.enter)="applySelectedKey(column)"
    class="col-item-label text-ellipsis pr-x0"
  ></span>
  <span
    *ngIf="column.attribute.isNormalized"
    [class.active]="column.name === currentKey?.name"
    class="clr-col-sm-4 pr-x0"
  >
    <span class="label">
      {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
    </span>
  </span>
</ng-template>

<ng-template
  #attributeSelectorV2ColumnLabel
  let-column="column"
>
  <span
    [title]="column.tooltip"
    [class.active]="isKeyActiveV2(column)"
    [dpaInnerHtmlLight]="column.styledString || column.label"
    [ngClass]="column.attribute.isNormalized ? 'clr-col-sm-8' : 'clr-col-sm-12'"
    (click)="selectKeyV2(column)"
    (keyup.enter)="selectKeyV2(column)"
    class="col-item-label text-ellipsis pr-x0"
  ></span>
  <div class="clr-col-sm-4 pr-x0">
    <button
      *ngIf="columnToggleFilterMap[column.rawAttributeName]?.rawDataColumn"
      (click)="toggleRawData($event, column.rawAttributeName)"
      class="label"
      role="button"
    >
      <span *ngIf="!rawDataMap[column.rawAttributeName]; else rawDataToggle">
        {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
      </span>
      <cds-icon
        shape="switch"
        class="ml-x1"
      ></cds-icon>
    </button>
    <ng-template #rawDataToggle>
      <span>
        {{ 'COMMON_MESSAGES.RAW' | translate }}
      </span>
    </ng-template>
  </div>
</ng-template>
