<section class="pb-x3">
  <p
    [innerHTML]="
      'SOLUTIONS.DATA_SOURCE.UEM.DESCRIPTION'
        | translate
          : {
              link: learnMoreLink
            }
        | safe : ContentType.HTML
    "
  ></p>
  <div class="mt-x2">
    <ng-container *ngIf="datasource; else datasourceNotFound">
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.STATUS' | translate }}
        </span>
        <span>
          {{ (datasource.active ? 'CONNECTIONS.STATUS_AUTHORIZED' : 'CONNECTIONS.STATUS_NOT_AUTHORIZED') | translate }}
          <cds-icon
            [attr.solid]="datasource.active"
            status="success"
            class="mh-x1"
            shape="success-standard"
          ></cds-icon>
        </span>
      </div>
      <ng-container *ngIf="syncStatus">
        <div class="datasource">
          <span class="title">{{ etlHost?.name }}:</span>
          <span *ngIf="etlHost?.value; else unknownValue">
            {{ etlHost?.value }}
          </span>
        </div>
        <div class="datasource">
          <span class="title">{{ lastCheckin?.name }}:</span>
          <span *ngIf="lastCheckin?.value; else unknownValue">
            {{ lastCheckin?.value | formattedDate : DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT }}
          </span>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #unknownValue>
      <span>
        {{ 'COMMON_MESSAGES.UNKNOWN_VALUE' | translate }}
      </span>
    </ng-template>
    <ng-template #datasourceNotFound>
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.STATUS' | translate }}
        </span>
        <span>
          {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
          <cds-icon
            [attr.solid]="false"
            status="danger"
            class="mb-x1"
            shape="success-standard"
          ></cds-icon>
        </span>
      </div>
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.SERVER_NAME' | translate }}
        </span>
        <span>
          {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
        </span>
      </div>
      <div class="datasource">
        <span class="title">
          {{ 'SOLUTIONS.DATA_SOURCE.UEM.LAST_CHECK_IN_TIME' | translate }}
        </span>
        <span>
          {{ 'COMMON_MESSAGES.NOT_AVAILABLE' | translate }}
        </span>
      </div>
    </ng-template>
  </div>
</section>
<dpa-accordion-wizard-step-actions
  *ngIf="!isStandaloneMode"
  [isReadOnlyMode]="isReadOnlyMode"
  [isFinalStep]="isFinalStep"
  (actionNext)="onButtonClick()"
></dpa-accordion-wizard-step-actions>
