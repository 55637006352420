/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { AlertBannerActions } from '@ws1c/intelligence-core';
import { ALERT_BANNER_TYPE, AlertBannerTarget } from '@ws1c/intelligence-models';
import { NotificationServiceDetails } from '@ws1c/notification/model';
import { NotificationService } from '@ws1c/notification/services/notification.service';
import { NotificationSettingsActions } from '@ws1c/notification/store';

/**
 * NotificationSettingsEffects
 *
 * @export
 * @class NotificationSettingsEffects
 */
@Injectable()
export class NotificationSettingsEffects {
  /**
   * loadNotificationSettings$
   * @type {Observable<Action>}
   * @memberof NotificationSettingsEffects
   */
  public loadNotificationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationSettingsActions.loadNotificationSettings),
      switchMap(() => {
        return this.notificationService.getNotificationSettings().pipe(
          switchMap((notificationServiceDetails: NotificationServiceDetails[]) => [
            NotificationSettingsActions.loadNotificationSettingsSuccess({
              notificationServiceDetails,
            }),
          ]),
          catchError((error: WebError) => [
            NotificationSettingsActions.loadNotificationSettingsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('NOTIFICATION_SETTINGS.LOAD_NOTIFICATION_SETTINGS_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * updateNotificationSettings$
   * @type {Observable<Action>}
   * @memberof NotificationSettingsEffects
   */
  public updateNotificationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationSettingsActions.updateNotificationSettings),
      switchMap(
        ({ notificationServiceDetailsUpdateRequest }: ReturnType<typeof NotificationSettingsActions.updateNotificationSettings>) => {
          return this.notificationService.updateNotificationSettings(notificationServiceDetailsUpdateRequest).pipe(
            switchMap(() => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('NOTIFICATION_SETTINGS.UPDATE_NOTIFICATION_SETTINGS_SUCCESS'),
              }),
            ]),
            catchError((error: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.PAGE,
                message: this.i18nService.translate('NOTIFICATION_SETTINGS.UPDATE_NOTIFICATION_SETTINGS_ERROR_MSG', {
                  reason: error.getFullReason(),
                }),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * Creates an instance of NotificationSettingsEffects.
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {NotificationService} notificationService
   * @memberof NotificationSettingsEffects
   */
  constructor(private actions$: Actions, private i18nService: I18NService, private notificationService: NotificationService) {}
}
