/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import * as Sentry from '@sentry/angular-ivy';

import { HomePageSectionType } from '@ws1c/intelligence-models/home-page-section-type.enum';
import { Integration } from '@ws1c/intelligence-models/integration.model';

/**
 * Define all common configs to be used in the app
 * @export
 * @class AppConfig
 */
export class AppConfig {
  // Global namespaces
  public static PENDO = (window as any).pendo || undefined;
  public static SENTRY = Sentry;
  public static SENTRY_DNS: string = 'https://a4b4bf61c4b44e4ba592fc24cb99da76@o349086.ingest.sentry.io/5257082';
  // eslint-disable-next-line
  public static SENTRY_TRANSACTION_ID: string = (crypto.getRandomValues(new Uint32Array(1))[0] / Math.pow(2, 32))
    .toString(36)
    .substring(2, 9);
  public static SENTRY_TRANSACTION_ID_TAG: string = 'transaction_id';

  // Base Endpoints
  public static readonly API_ROOT: string = '/v1';
  public static readonly API_V2_ROOT: string = '/v2';
  public static readonly API_V3_ROOT: string = '/v3';

  // Cookies: Read Only Values
  public static readonly AJS_ANONYMOUS_ID = 'ajs_anonymous_id';
  public static readonly API_URL = 'API_URL';
  public static readonly ORG_NAME = 'ORG_NAME';
  public static readonly SESSION_IDENTIFIER = 'SESSION_IDENTIFIER';
  public static readonly XSRF_TOKEN = 'XSRF-TOKEN';

  // Headers
  public static readonly XSRF_HEADER_NAME: string = 'X-XSRF-TOKEN';
  public static readonly XSRF_FIELD_NAME: string = '_csrf';
  public static readonly ORG_ID_FIELD_NAME: string = 'org_hint';
  public static readonly ORIGIN_PATH_FIELD_NAME: string = 'origin_path';

  // Connector Global Config Schema id
  public static readonly CONNECTOR_GLOBAL_CONFIG_V1 = 'connector_global_config_v1';

  // Custom Namespace for generating UUID
  public static readonly CUSTOM_UUID_NAMESPACE: string = '04460590-8c5c-11ea-bc55-0242ac130003';

  // Trial Settings
  public static readonly TRIAL_DAYS = 30;

  // Preview Timeout Settings
  public static readonly TIMEOUT_20S: number = 20000; // 20s
  public static readonly TIMEOUT_60S: number = 60000; // 60s

  // IA v2 workspace home page settings
  public static readonly HOME_PAGE_SECTION_PREFERENCE_V1: HomePageSectionType[] = [
    HomePageSectionType.SERVICES,
    HomePageSectionType.DASHBOARDS,
    HomePageSectionType.BOOKMARKS,
    HomePageSectionType.GETTING_STARTED,
  ];

  // In-product trial workspace home page settings
  public static readonly HOME_PAGE_SECTION_PREFERENCE_V2: HomePageSectionType[] = [
    HomePageSectionType.SERVICES,
    HomePageSectionType.TRIALS,
    HomePageSectionType.DASHBOARDS,
    HomePageSectionType.BOOKMARKS,
    HomePageSectionType.GETTING_STARTED,
  ];

  // TRIAL_BANNER
  public static readonly TRIAL_BANNER_SNOOZE_TIME_IN_HOURS = 24;

  // wizard open class
  // example use case: this class will be set on body to control visibility of global search
  public static readonly WIZARD_OPEN_CLASS = 'dpa-wizard-open';
  // new sticky header class - to show new IA sticky header changes
  public static readonly NEW_STICKY_HEADER_CLASS = 'dpa-new-sticky-header';
  // i18n injection token string
  public static readonly ACTIVE_LOCALE = 'ACTIVE_LOCALE';

  // Query Param Constants
  public static readonly QUERY_PARAM_ACTIVE_TAB = 'active_tab';
  public static readonly QUERY_PARAM_APP_ID = 'app_id';
  public static readonly QUERY_PARAM_APP_NAME = 'app_name';
  public static readonly QUERY_PARAM_APPLICATION_NAME = '_application_name';
  public static readonly QUERY_PARAM_BREAD_CRUMBS = 'bread_crumbs';
  public static readonly QUERY_PARAM_CATEGORY = 'category';
  public static readonly QUERY_PARAM_CUSTOM = 'custom';
  public static readonly QUERY_PARAM_DEVICE_GUID = 'device_guid';
  public static readonly QUERY_PARAM_ENABLE_HORIZON = 'enable_horizon';
  public static readonly QUERY_PARAM_ENTITY = 'entity';
  public static readonly QUERY_PARAM_INTEGRATION = 'integration';
  public static readonly QUERY_PARAM_NAME = 'name';
  public static readonly QUERY_PARAM_NIC_TYPE = 'nic_type';
  public static readonly QUERY_PARAM_NIC_FILTER = 'nic_filter';
  public static readonly QUERY_PARAM_PAGE_TITLE = 'page_title';
  public static readonly QUERY_PARAM_PLATFORM = 'platform';
  public static readonly QUERY_PARAM_PREVIEW_ID = 'preview';
  public static readonly QUERY_PARAM_PRODUCTIVITY_APP = 'productivity_app';
  public static readonly QUERY_PARAM_REGISTRATION_CODE = 'registration_code';
  public static readonly QUERY_PARAM_REGISTRATION_SUCCESS = 'registration_success';
  public static readonly QUERY_PARAM_RESOURCE_TAGS = 'resource_tags';
  public static readonly QUERY_PARAM_SELECTED_INDEX = 'selected_index';
  public static readonly QUERY_PARAM_SELECTED_TAG = 'selected_tag';
  public static readonly QUERY_PARAM_SESSION_UUID = 'session_uuid';
  public static readonly QUERY_PARAM_SOURCE_OBJECT_TYPE = 'source_object_type';
  public static readonly QUERY_PARAM_SOURCE_TEMPLATE_ID = 'source_template_id';
  public static readonly QUERY_PARAM_TEMPLATE_ID = 'templateId';
  public static readonly QUERY_PARAM_TEST_ID = 'test_id';
  public static readonly QUERY_PARAM_TYPE = 'type';
  public static readonly QUERY_ORIGIN_PATH = 'origin_path';
  public static readonly QUERY_PARAM_VM_ID = 'vm_id';
  public static readonly QUERY_PARAM_ACTION_PAGE = 'action_page';
  public static readonly QUERY_PARAM_ACTION_URL = 'action_url';
  public static readonly QUERY_PARAM_ORG_ID = 'org_id';
  public static readonly QUERY_PARAM_ORG_HINT = 'org_hint';
  public static readonly QUERY_PARAM_URL_DOMAIN = '_url_domain';

  // Param Constants
  public static readonly PARAM_ID = 'id';

  // Query Builder
  public static readonly MAX_ALLOWED_RULE_NESTING_LEVELS = 3;
  public static readonly MAX_ALLOWED_RULES_PER_FILTER = 10;

  // SessionStorage: Read Only Values
  public static readonly IS_BROWNFIELD_NOTIFICATION_MODAL_DISPLAYED = 'IS_BROWNFIELD_NOTIFICATION_MODAL_DISPLAYED';

  // Not Found Status Code
  public static readonly NOT_FOUND_STATUS_CODE: number = 404;

  // Record Already Exists Status Code
  public static readonly RECORD_ALREADY_EXISTS_STATUS_CODE: number = 409;

  // GEM provisioned integrations supported for opt in
  public static readonly GEM_INTEGRATIONS_SUPPORTED_FOR_OPT_IN: string[] = [Integration.AIRWATCH, Integration.IDM];

  // Report
  public static readonly REPORT_MAX_CUSTOM_DATE_RANGE_LIMIT = 31;
  public static readonly PREDEFINED_REPORT_MAX_CUSTOM_DATE_RANGE_LIMIT = 92;

  // Custom Tag Background Color Constants
  public static readonly DEFAULT_TAG_BG_COLOR_CLASS: string = 'bg-purple';
  public static readonly MARKETPLACE_RESOURCE_TAG_BG_COLOR_CLASS: string = 'bg-blue-gradient';

  // COMMON
  public static readonly PRIVACY_URL = 'https://www.vmware.com/help/privacy.html';

  public static readonly WIDGETS_INCREMENTAL_LOADING_CONFIG = {
    BUFFER_SPAN: 2000, // 2 sec
    POLLING_INTERVAL: 3000, // 3 sec,
    BUFFER_SIZE: 10, // max of 10 items per buffer
  } as const;
}
