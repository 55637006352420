<div
  *ngIf="shouldShowKeySelector(); else selectedColumnLabel"
  class="clr-col-sm"
>
  <ng-container *dpaDelayRender="1">
    <dpa-type-ahead
      *ngIf="!showThreeColumnFilter"
      #leadTypeAhead
      [selectedItem]="getSelectedColumn()"
      [searchableItems]="visibleColumnsSortedByName"
      [customFormatter]="typeAheadformatter"
      [labelFormatter]="typeAheadformatter"
      [placeholderText]="'COMMON_ACTIONS.SELECT_OPERATOR' | translate"
      [keyBy]="getColumnKey"
      (selectedItemChange)="onColumnChange($event)"
    ></dpa-type-ahead>

    <dpa-key-selector-search
      *ngIf="showThreeColumnFilter"
      #keySearch
      [showReset]="false"
      [showColumnsFromInput]="showColumnsFromInput"
      [allColumnsByName]="allColumnsByName"
      [selectedColumn]="getSelectedColumn()"
      [placeholderTextKey]="'COMMON_ACTIONS.SELECT_ATTRIBUTE'"
      [isCrossCategory]="isCrossCategory"
      (columnChange)="onColumnChange($event)"
    ></dpa-key-selector-search>
  </ng-container>
</div>
<ng-template #selectedColumnLabel>
  <div
    *ngIf="visibleColumnsSortedByName.length === 1"
    class="clr-col-sm single-column-label-container"
  >
    <span
      *ngIf="!isAttributeSelectorV2Enabled"
      class="single-column-label"
    >
      {{ visibleColumnsSortedByName[0].label }}
    </span>
    <span
      *ngIf="isAttributeSelectorV2Enabled"
      class="single-column-label"
    >
      {{ visibleColumnsSortedByName[0]?.attribute.fullyQualifiedName | columnLabel : allColumnsByName : columnToggleFilterMap }}
      <span
        *ngIf="
          visibleColumnsSortedByName[0]?.attribute.fullyQualifiedName | isAttributeNormalized : allColumnsByName : columnToggleFilterMap
        "
        class="label"
      >
        {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
      </span>
      <span
        *ngIf="visibleColumnsSortedByName[0]?.attribute.fullyQualifiedName | isAttributeRaw : columnToggleFilterMap"
        class="label"
      >
        {{ 'COMMON_MESSAGES.RAW' | translate }}
      </span>
    </span>
  </div>
</ng-template>
<div class="clr-col-sm">
  <ng-container *dpaDelayRender="1">
    <dpa-type-ahead
      [disabled]="!getSelectedColumn()"
      [selectedItem]="getSelectedCondition(rule)"
      [searchableItems]="getAvailableConditions(rule)"
      [customFormatter]="typeAheadformatter"
      [labelFormatter]="typeAheadformatter"
      [placeholderText]="'COMMON_ACTIONS.SELECT_OPERATOR' | translate"
      [keyBy]="getConditionKey"
      (selectedItemChange)="setConditionValue($event)"
    ></dpa-type-ahead>
  </ng-container>
</div>
<div class="clr-col-sm">
  <ng-container *dpaDelayRender="1">
    <dpa-filter-value
      *ngIf="rule?.valueRequired"
      [disabled]="!getSelectedColumn()"
      [filterRule]="rule"
      [column]="getSelectedColumn()"
      [placeholderText]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
      [selectedValue]="rule.data"
      [suggestionFilterBys]="suggestionFilterBys"
      [suggestionCategory]="suggestionCategory"
      [selectedCondition]="getSelectedCondition(rule)"
      [dropdownAlignRight]="true"
      [delimiterSupported]="delimiterSupported"
      (selectedValueChange)="onRuleDataChange($event)"
    ></dpa-filter-value>
  </ng-container>
</div>
