/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertBannerTarget, ConnectorConfigData, ConnectorSchema } from '@ws1c/intelligence-models';
import { ConnectorCreateParams } from '@ws1c/intelligence-models/connector/connector-request-params.model';

/**
 * ConnectorSetupCustomConnectorComponent
 *
 * migrated from CustomConnectionModalsComponent
 * @export
 * @class ConnectorSetupCustomConnectorComponent
 * @implements {DoCheck}
 */
@Component({
  selector: 'dpa-connector-setup-custom-connector',
  templateUrl: 'connector-setup-custom-connector.component.html',
  styleUrls: ['connector-setup-custom-connector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorSetupCustomConnectorComponent implements DoCheck {
  @Input() public isOpen?: boolean = true;
  @Input() public isLoading: boolean = false;
  @Input() public connectorConfigSchema: ConnectorSchema;
  @Input() public userGuideUrl: string;
  @Output() public create: EventEmitter<ConnectorCreateParams> = new EventEmitter();

  public connectorForm: UntypedFormGroup;
  public configForm: UntypedFormGroup;
  public readonly ALERT_BANNER_TARGET = AlertBannerTarget;

  /**
   * Creates an instance of ConnectorSetupCustomConnectorComponent
   * @param {UntypedFormBuilder} fb
   * @param {ChangeDetectorRef} changeRef
   * @memberof ConnectorSetupCustomConnectorComponent
   */
  constructor(private fb: UntypedFormBuilder, private changeRef: ChangeDetectorRef) {
    this.connectorForm = this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
    });
  }

  /**
   * isRequiredWarningVisible
   * @param {string} fieldName
   * @returns {boolean}
   * @memberof ConnectorSetupCustomConnectorComponent
   */
  public isRequiredWarningVisible(fieldName: string): boolean {
    const formControl = this.connectorForm.get(fieldName);
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  /**
   * addCustomConnector
   * @memberof ConnectorSetupCustomConnectorComponent
   */
  public addCustomConnector() {
    const params = new ConnectorCreateParams({
      ...this.connectorForm.value,
      configData: ConnectorConfigData.getConnectorConfigDataFromConfigForm(this.configForm.getRawValue()),
    });
    this.create.emit(params);
  }

  /**
   * ngDoCheck
   * Workaround for ExpressionChangedAfterItHasBeenCheckedError thrown when you change the Auth type
   * @memberof ConnectorSetupCustomConnectorComponent
   */
  public ngDoCheck() {
    this.changeRef.detectChanges();
  }
}
