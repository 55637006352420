/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BreadCrumb, CustomGlobalEvent, FuzzyItem, FuzzyService, IntersectionChangeEvent, WebError } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { isEditRangeAvailable } from '@ws1c/dashboard-common/utils';
import { DownloadService, FeatureControl } from '@ws1c/intelligence-common';
import { StandardChartComponent } from '@ws1c/intelligence-core/components/dashboard/standard-chart/standard-chart.component';
import {
  AutomationCommonActions,
  BookmarksActions,
  CoreAppState,
  DashboardActions,
  DashboardSelectors,
  FeatureSelectors,
  IntegrationMetaActions,
  IntegrationMetaSelectors,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import {
  AggregationWidget,
  AggregationWidgetChartType,
  Bookmark,
  BookmarkCategory,
  ChartDrilldownEvent,
  CLARITY_TOOLTIP_POSITION,
  DashboardSkinTypeConfig,
  DashboardView,
  FocusedSeries,
  PendoCategory,
  PendoEventCustomDashboardsSelectedSource,
  PendoEventTriggeredBy,
  PendoTrackEventType,
  Tooltip,
  Trend,
  TrendDateRange,
  TrendDateRangeUpdateRequest,
  TrendResult,
  WidgetColorSchema,
  WidgetDataRequest,
  WidgetDetailDefinition,
  WidgetDetailPage,
  WidgetDetailPageSkinType,
  WidgetRangeFilter,
} from '@ws1c/intelligence-models';

/**
 * WidgetComponent
 *
 * @export
 * @class WidgetComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-widget',
  templateUrl: 'widget.component.html',
  styleUrls: ['widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WidgetComponent implements OnChanges, OnDestroy, OnInit {
  public static readonly SKIN_TYPE_BY_CATEGORY_ID = DashboardSkinTypeConfig.SKIN_TYPE_BY_CATEGORY_ID;

  @Input() public activeBookmarkIdsSet: Set<string>;
  @Input() public isUserBookmarksEnabled: boolean;
  @Input() public widget: AggregationWidget;
  @Input() public dashboard?: DashboardView;
  @Input() public customCardBorder?: string = 'solid-border';
  @Input() public detailsReturnCrumbs?: BreadCrumb[];
  @Input() public searchString?: string;
  @Input() public dropdownPosition?: string = CLARITY_TOOLTIP_POSITION.TOP_RIGHT;
  @Input() public showYAxisLabel?: boolean = true;
  @Input() public showDataLabel?: boolean = true;
  @Input() public autoLabelLegend?: boolean = true;
  @Input() public hasFullPerm?: boolean = false;
  @Input() public showOnlyWidget?: boolean = false;
  @ViewChild('standardChart') public standardChartComponent: StandardChartComponent;

  public widgetData$: Observable<WidgetDataRequest>;
  public overlayData$: Observable<WidgetDataRequest>;
  public hasWidgetWritePerm$: Observable<boolean>;
  public hasAutomationWritePerm$: Observable<boolean>;
  public lastRefreshTime$: Observable<number>;
  public focusedSeries$: Observable<FocusedSeries>;
  public drilldownEvents$: Observable<ChartDrilldownEvent[]>;
  public chartType$: Observable<AggregationWidgetChartType>;
  public webError$: Observable<WebError>;
  public widget$: BehaviorSubject<AggregationWidget> = new BehaviorSubject(undefined);
  public rangeFilter$: Observable<WidgetRangeFilter>;
  public colorSchemas$: Observable<WidgetColorSchema[]>;
  public isDeprecated$: Observable<boolean>;
  public isInvertMode$: Observable<boolean>;
  public isEditRangeAvailable$: Observable<boolean>;

  public widgetTrend: Trend;
  public subs: Subscription = new Subscription();
  public tooltip: Tooltip;
  public styledString: string;
  public isControlBarExpanded: boolean = false;
  public isControlBarExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isWidgetEditThemeAvailable: boolean = false;
  public isDeprecated: boolean;
  public widgetChartType: AggregationWidgetChartType;
  public isWidgetJoinEnabled: boolean = false;

  public CHART_TYPE = AggregationWidgetChartType;

  public trackingIds: [string?, string?] = [];

  /**
   * Creates an instance of WidgetComponent.
   * @param {Store<CoreAppState>} store
   * @param {DownloadService} downloadService
   * @param {FuzzyService} fuzzyService
   * @memberof WidgetComponent
   */
  constructor(private store: Store<CoreAppState>, private downloadService: DownloadService, private fuzzyService: FuzzyService) {
    this.drilldownEvents$ = combineLatest([this.store.select(DashboardSelectors.getDrilldownEventsById), this.widget$]).pipe(
      map(([drilldownEventsById, widget]: [Record<string, ChartDrilldownEvent[]>, AggregationWidget]) => {
        return drilldownEventsById[widget && widget.id];
      }),
    );
    this.focusedSeries$ = combineLatest([this.store.select(DashboardSelectors.getFocusedSeriesById), this.widget$]).pipe(
      map(([focusedSeriesById, widget]: [Record<string, FocusedSeries>, AggregationWidget]) => focusedSeriesById[widget && widget.id]),
    );
    this.chartType$ = combineLatest([this.store.select(DashboardSelectors.getChartTypesById), this.widget$]).pipe(
      map(
        ([chartTypesById, widget]: [Record<string, AggregationWidgetChartType>, AggregationWidget]) => chartTypesById[widget && widget.id],
      ),
    );
    this.webError$ = combineLatest([this.store.select(DashboardSelectors.getWebErrorsByWidgetId), this.widget$]).pipe(
      map(([webErrorsByWidgetId, widget]: [Record<string, WebError>, AggregationWidget]) => webErrorsByWidgetId[widget && widget.id]),
    );
    this.rangeFilter$ = combineLatest([this.store.select(DashboardSelectors.getWidgetRangeFiltersById), this.widget$]).pipe(
      map(([rangeFiltersById, widget]: [Record<string, WidgetRangeFilter>, AggregationWidget]) => {
        return rangeFiltersById?.[widget?.id];
      }),
    );
    this.colorSchemas$ = combineLatest([this.store.select(DashboardSelectors.getWidgetColorSchemasById), this.widget$]).pipe(
      map(([colorSchemasById, widget]: [Record<string, WidgetColorSchema[]>, AggregationWidget]) => {
        return colorSchemasById?.[widget?.id];
      }),
    );
    this.isDeprecated$ = combineLatest([this.store.select(IntegrationMetaSelectors.getSupportedTrendModesByCategoryId), this.widget$]).pipe(
      map(([supportedTrendModesByCategoryId, widget]: [Record<string, string[]>, AggregationWidget]) => {
        const trendDefinition = widget.trend?.trendDefinition;
        return !supportedTrendModesByCategoryId[trendDefinition?.categoryId]?.includes(trendDefinition?.trendMode);
      }),
    );
    this.isInvertMode$ = combineLatest([this.store.select(DashboardSelectors.getInvertModeById), this.widget$]).pipe(
      map(([invertModeById, widget]: [Record<string, boolean>, AggregationWidget]) => invertModeById[widget?.id] || false),
    );
  }

  /**
   * ngOnInit
   * @memberof WidgetComponent
   */
  public ngOnInit() {
    this.widgetData$ = this.store.select(DashboardSelectors.getWidgetDataByIdState(this.widget.mainWidgetId));
    this.overlayData$ = this.store.select(DashboardSelectors.getWidgetDataByIdState(this.widget.overlayId));
    this.lastRefreshTime$ = this.store.select(DashboardSelectors.getLastRefreshTimeState);
    this.hasWidgetWritePerm$ = this.store.select(FeatureSelectors.hasDashboardWidgetPerm);
    this.hasAutomationWritePerm$ = this.store.select(FeatureSelectors.hasAutomationPerm);
    this.isEditRangeAvailable$ = combineLatest([
      this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetEditRangeEnabled),
      this.store.select(DashboardSelectors.isWidgetDetailCompositeState),
      this.drilldownEvents$,
      this.widgetData$,
    ]).pipe(
      map(
        ([isEnabled, isWidgetDetailComposite, drilldownEvents, widgetDataRequest]: [
          boolean,
          boolean,
          ChartDrilldownEvent[],
          WidgetDataRequest,
        ]) => {
          return !isWidgetDetailComposite && isEditRangeAvailable(isEnabled, drilldownEvents, widgetDataRequest?.result);
        },
      ),
    );
    this.subs.add(
      this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetJoinEnabled).subscribe((isWidgetJoinEnabled: boolean) => {
        this.isWidgetJoinEnabled = isWidgetJoinEnabled;
      }),
    );
    this.subs.add(
      this.widgetData$.subscribe((widgetDataRequest: WidgetDataRequest) => {
        if (widgetDataRequest) {
          this.widgetTrend = widgetDataRequest.result;
          this.widgetChartType = widgetDataRequest.chartType;
        }
      }),
    );
    this.subs.add(
      // Whenever this time changed, reload widget data
      this.lastRefreshTime$.subscribe((lastRefreshTime: number) => {
        if (lastRefreshTime) {
          this.store.dispatch(DashboardActions.loadWidgetData({ widget: this.widget }));
        }
      }),
    );
    this.subs.add(
      combineLatest([this.store.select(UserPreferenceFeatureControlsSelectors.isWidgetEditThemeEnabled), this.widgetData$]).subscribe(
        ([isWidgetEditThemeEnabled, widgetDataRequest]: [boolean, WidgetDataRequest]) => {
          this.isWidgetEditThemeAvailable = !!(
            isWidgetEditThemeEnabled &&
            widgetDataRequest?.result?.trendDefinition?.bucketingAttributes?.length &&
            widgetDataRequest?.result?.trendResults?.some((trendResult: TrendResult) => trendResult.counters[0].result.value > 0)
          );
        },
      ),
    );
    this.subs.add(
      this.isDeprecated$.subscribe((isDeprecated: boolean) => {
        this.isDeprecated = isDeprecated;
      }),
    );
    if (!this.widget?.trend?.trendDefinition) {
      return;
    }
  }

  /**
   * ngOnDestroy
   * @memberof WidgetComponent
   */
  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * ngOnDestroy
   * @param {SimpleChanges} changes
   * @memberof WidgetComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.searchString && changes.searchString.currentValue) {
      this.getStyledString();
    }
    if (changes.widget) {
      this.widget$.next(this.widget);
      if (!isEqual(changes.widget.currentValue, changes.widget.previousValue) && !changes.widget.firstChange) {
        this.store.dispatch(DashboardActions.loadWidgetData({ widget: changes.widget.currentValue }));
      }
      this.trackingIds = [this.widget.mainWidgetId, this.widget.overlayId];
    }
  }

  /**
   * expandOrCollpaseControlBar
   * @memberof WidgetComponent
   */
  public expandOrCollapseControlBar() {
    this.isControlBarExpanded = !this.isControlBarExpanded;
    // Since the collapsed section is using dpa-fluid-height to add animation, and the chart type select component
    // is using dpa-fluid-height as well, the embeded dpa-fluid-height cannot be hidden successfully when outside
    // dpa-fluid-height tries to hide (Because dpa-fluid-height is using visibility property in css to show/hide
    // the component, the child css property would override the parent ones.) Using this delayed observable to make
    // sure chart type option is hidden when collapsing the control bar section and annimation is visible.
    setTimeout(() => {
      this.isControlBarExpanded$.next(this.isControlBarExpanded);
    }, 200);
  }

  /**
   * onIntersectionChange
   * @param {IntersectionChangeEvent} { isIntersecting, isFirstIntersection }
   * @memberof WidgetComponent
   */
  public onIntersectionChange({ isIntersecting, isFirstIntersection }: IntersectionChangeEvent) {
    if (isIntersecting && isFirstIntersection) {
      this.store.dispatch(
        IntegrationMetaActions.loadColumnsQuietly({
          categoryId: this.widget.trend.trendDefinition.categoryId,
          isCrossCategory: this.isWidgetJoinEnabled,
        }),
      );
      this.store.dispatch(DashboardActions.loadWidgetData({ widget: this.widget }));
    }
  }

  /**
   * requestAutomateWidget
   * @memberof WidgetComponent
   */
  public requestAutomateWidget() {
    this.store.dispatch(
      AutomationCommonActions.automateFromDashboard({
        widget: this.widget,
        activeDashboard: this.dashboard,
      }),
    );
  }

  /**
   * exportAsCsv
   * @memberof WidgetComponent
   */
  public exportAsCsv() {
    this.downloadService.downloadAsCsv(this.standardChartComponent.getCsvData(), this.widget.name);
  }

  /**
   * Trigger Request Refresh Widget Action
   * @param {AggregationWidget} widget
   * @memberof WidgetComponent
   */
  public requestRefreshWidget(widget: AggregationWidget) {
    this.store.dispatch(DashboardActions.loadWidgetData({ widget }));
  }

  /**
   * Trigger Request Duplicate Widget Action
   *
   * @memberof WidgetComponent
   */
  public requestDuplicateWidget() {
    this.store.dispatch(DashboardActions.requestDuplicateWidget({ widget: this.widget }));
  }

  /**
   * Trigger Request Edit Widget Action
   * @param {AggregationWidget} widget
   * @memberof WidgetComponent
   */
  public requestUpdateWidget(widget: AggregationWidget) {
    this.store.dispatch(DashboardActions.requestUpdateWidget({ widget }));
  }

  /**
   * Trigger Request Rename Widget Action
   *
   * @memberof WidgetComponent
   */
  public requestRenameWidget() {
    this.store.dispatch(DashboardActions.requestRenameWidget({ widget: this.widget }));
  }

  /**
   * Trigger setDashboardThumbnail
   *
   * @memberof WidgetComponent
   */
  public setDashboardThumbnail() {
    this.store.dispatch(DashboardActions.setDashboardThumbnail({ widget: this.widget }));
  }

  /**
   * Trigger Request Copy to Widget Action
   *
   * @memberof WidgetComponent
   */
  public requestCopyToWidget() {
    this.store.dispatch(DashboardActions.requestCopyWidget({ widget: this.widget }));
  }

  /**
   * Trigger Request Info Widget Action
   *
   * @memberof WidgetComponent
   */
  public requestInfoWidget() {
    this.store.dispatch(DashboardActions.requestInfoWidget({ widget: this.widget }));
  }

  /**
   * Trigger Request Delete Widget Action
   *
   * @memberof WidgetComponent
   */
  public requestDeleteWidget() {
    this.store.dispatch(DashboardActions.requestDeleteWidget({ widget: this.widget }));
  }

  /**
   * Trigger Request Update TrendDateRange Action
   * @param {AggregationWidget} widget
   * @param {TrendDateRange} trendDateRange
   * @memberof WidgetComponent
   */
  public requestUpdateTrendDateRange(widget: AggregationWidget, trendDateRange: TrendDateRange) {
    this.store.dispatch(DashboardActions.updateTrendDateRange({ request: new TrendDateRangeUpdateRequest(widget, trendDateRange) }));
  }

  /**
   * onEditTheme
   *
   * @memberof WidgetComponent
   */
  public onEditTheme() {
    this.store.dispatch(
      DashboardActions.requestEditThemeWidget({
        widget: this.widget,
        widgetTheme: this.standardChartComponent.getWidgetTheme(),
      }),
    );
  }

  /**
   * goToWidgetDetail
   * @memberof WidgetComponent
   */
  @CustomGlobalEvent.emitBefore(PendoTrackEventType, (self: WidgetComponent) => {
    return {
      category: PendoCategory.CUSTOM_DASHBOARDS,
      data: {
        selectedSource: PendoEventCustomDashboardsSelectedSource.VIEW_WIDGET,
        triggeredBy: PendoEventTriggeredBy.CLICK,
        triggeredAt: Date.now(),
        name: self.widget?.name,
        id: self.widget?.id,
        categoryId: self.widget?.categoryId,
      },
    };
  })
  public goToWidgetDetail() {
    const widgetDetailDefinition = new WidgetDetailDefinition({
      trendDefinition: this.widget.trend.trendDefinition,
      returnCrumbs: this.detailsReturnCrumbs,
      isCrossCategory: this.widget.trend.trendDefinition.isCrossEntityOrIntegration,
    });
    this.store.dispatch(
      DashboardActions.goToWidgetDetailPage({
        widgetId: this.widget.id,
        dashboardId: this.widget.dashboardId,
        widgetDetailDefinition,
        drilldownEvents: [],
        skinType: WidgetComponent.SKIN_TYPE_BY_CATEGORY_ID[this.widgetTrend.trendDefinition.categoryId],
      }),
    );
  }

  /**
   * onTooltipChange
   * @param {Tooltip} tooltip
   * @memberof WidgetComponent
   */
  public onTooltipChange(tooltip: Tooltip) {
    this.tooltip = tooltip;
  }

  /**
   * getStyledString - get styledString from widget name
   * @memberof WidgetComponent
   */
  public getStyledString() {
    const fuzzyItems: FuzzyItem[] = this.fuzzyService.filter(this.searchString, [this.widget], (result: any) => result.name);
    this.styledString = fuzzyItems[0] ? fuzzyItems[0].styledString : '';
  }

  /**
   * getShowYAxisLabel - get showYAxisLabel value from widget height constraint
   * @returns {boolean}
   * @memberof WidgetComponent
   */
  public getShowYAxisLabel(): boolean {
    if (this.showOnlyWidget) {
      return this.showYAxisLabel;
    }
    return this.widget.gridConfig.rows <= this.widget.gridConfig.minItemRows ? false : this.showYAxisLabel;
  }

  /**
   * Handler for add or remove bookmark action.
   * Dispatches addWidgetToBookmarks or removeBookmark action.
   * @memberof WidgetComponent
   */
  public toggleBookmark() {
    const bookmark: Bookmark = new Bookmark({
      category: BookmarkCategory.DASHBOARD_WIDGET,
      featureKey: FeatureControl.DASHBOARDS_ENABLED,
      resourceId: this.widget.id,
      title: this.widget.name,
    });
    if (!this.activeBookmarkIdsSet.has(this.widget.id)) {
      const widgetDetailPage: WidgetDetailPage = {
        widgetId: this.widget.id,
        dashboardId: this.widget.dashboardId,
        drilldownEvents: [],
        skinType: WidgetComponent.SKIN_TYPE_BY_CATEGORY_ID[this.widgetTrend?.trendDefinition.categoryId] ?? WidgetDetailPageSkinType.CUSTOM,
      } as WidgetDetailPage;
      this.store.dispatch(DashboardActions.addWidgetToBookmarks({ widgetDetailPage, bookmark }));
    } else {
      this.store.dispatch(BookmarksActions.removeBookmark({ bookmark }));
    }
  }

  /**
   * pushDrilldownEvent
   * @param {ChartDrilldownEvent} drilldownEvent
   * @memberof WidgetComponent
   */
  public pushDrilldownEvent(drilldownEvent: ChartDrilldownEvent) {
    this.store.dispatch(
      DashboardActions.pushCustomWidgetDrilldownEvent({
        widgetId: this.widget.id,
        drilldownEvent,
      }),
    );
  }

  /**
   * popDrilldown
   * @memberof WidgetComponent
   */
  public popDrilldown() {
    this.store.dispatch(DashboardActions.popCustomDrilldownEvent({ widget: this.widget }));
  }

  /**
   * clearDrilldown
   * @memberof WidgetComponent
   */
  public clearDrilldown() {
    this.store.dispatch(DashboardActions.clearCustomDrilldownEvents({ widget: this.widget }));
  }

  /**
   * isBookmarkEnabled
   * @returns {boolean}
   * @memberof WidgetComponent
   */
  public isBookmarkEnabled(): boolean {
    return this.isUserBookmarksEnabled && !this.isDeprecated;
  }

  /**
   * openEditRangeDialog
   * @param {boolean} isOpen
   * @memberof WidgetControlBarComponent
   */
  public openEditRangeDialog(isOpen: boolean) {
    this.store.dispatch(
      DashboardActions.openEditRangeDialog({
        isOpen,
        groupByLabel: this.standardChartComponent.getYAxisLabelString(),
        editRangeWidgetId: this.widget.mainWidgetId,
      }),
    );
  }
}
