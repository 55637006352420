/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateTimeFormat, GenericObject } from '@dpa/ui-common';
import moment from 'moment';

import { MarkerParams } from '@ws1c/dashboard-common/chart/marker/marker-params';
import { Marker, MarkerFocusEvent } from '@ws1c/dashboard-common/chart/marker/types';
import {
  ChronoUnit,
  DevicesDashboardConfig,
  StandardDashboardCardSize,
  Trend,
  TrendResult,
  WidgetColorSchema,
} from '@ws1c/intelligence-models';

/**
 * ChartMarkerService
 * @export
 * @class ChartMarkerService
 */
@Injectable({
  providedIn: 'root',
})
export class ChartMarkerService {
  public static readonly ARROW_DOWN = 'down';
  public static readonly ARROW_UP = 'up';
  public static readonly GAP_Y_SPLIT = -1;
  public static readonly SCALE_BASE_UNIT = 660;
  public static readonly MAX_DATA_POINTS = 61;
  public static readonly MAX_Y_SCALE = -2;
  public static readonly colorDefaults = DevicesDashboardConfig.TIMELINE_EVENTS_COLOR_SCHEMA;
  public static allEvents: string;

  /**
   * Creates an instance of ChartMarkerService.
   * @param {TitleCasePipe} titleCase
   * @memberof ChartMarkerService
   */
  constructor(private titleCase: TitleCasePipe) {}

  /**
   * getMarkers
   * @param {string} dir
   * @param {Trend} eventTrend
   * @param {MarkerFocusEvent[]} focusEvents
   * @param {MarkerFocusEvent[]} restOfEvents
   * @returns { MarkerParams }
   * @memberOf ChartMarkerService
   */
  public getMarkerParams(dir: string, eventTrend: Trend, focusEvents: MarkerFocusEvent[], restOfEvents: MarkerFocusEvent[]): MarkerParams {
    const colorSchemas = [];
    let yAxisTickFormatting = ChartMarkerService.defaultYTickFormatting;
    let yAxisTicks = [ChartMarkerService.GAP_Y_SPLIT, ChartMarkerService.MAX_Y_SCALE];
    let eventsMap: GenericObject;
    let maxYvalue: number;
    let chartSize;
    const [events, focusEventsUpdated, restOfEventsUpdated] = this.getEvents(eventTrend, focusEvents, restOfEvents);
    if (dir === ChartMarkerService.ARROW_DOWN) {
      [eventsMap, maxYvalue, chartSize] = this.setEvents(dir, events);
      [yAxisTickFormatting, yAxisTicks] = this.getLabelFormatting(eventsMap);
    }

    let markers = [];
    markers = eventTrend.trendResults?.map((result: TrendResult) => {
      const resultValue = result.counters?.[0].result.value;
      const resultName = this.titleCase.transform(result.bucketingAttributes?.[0].value);
      if (!resultValue) {
        return;
      }
      const [xAxisValue, markerSize, date] = this.getMarkerPosition(
        dir,
        eventTrend.trendDefinition.dateRange.samplingFrequency.unit,
        result,
      );
      const [y, heightUpdated, chartSizeUpdated] = this.getYPos(dir, resultName, events, eventsMap, maxYvalue, chartSize);
      maxYvalue = heightUpdated;
      chartSize = chartSizeUpdated;
      const hasColor = colorSchemas.find((schema) => schema.name === y.toString());
      let color = hasColor?.value;
      if (!hasColor) {
        color = ChartMarkerService.colorDefaults[resultName];
        colorSchemas.push(new WidgetColorSchema({ name: y.toString(), value: color }));
      }
      return {
        tooltip: `${this.titleCase.transform(resultName)} (${resultValue})`,
        x: xAxisValue,
        y,
        size: markerSize,
        color,
        date,
        yLabel: resultName,
      } as Marker;
    });
    markers = markers.filter(Boolean);
    const cachedMarkers = markers;
    const customColors = this.setCustomColors(markers);
    markers = this.filterMarkers([...focusEvents, ...restOfEvents], cachedMarkers);
    return {
      yAxisTickFormatting,
      yAxisTicks,
      markers,
      cachedMarkers,
      events,
      focusEvents: focusEventsUpdated,
      restOfEvents: restOfEventsUpdated,
      eventsMap,
      maxYvalue,
      chartSize,
      customColors,
    };
  }

  /**
   * defaultYTickFormatting
   * @param {number} num
   * @returns {string}
   * @memberOf ChartMarkerService
   */
  public static defaultYTickFormatting = (num: number) => {
    if (num === ChartMarkerService.GAP_Y_SPLIT) {
      return ChartMarkerService.allEvents;
    }
  };

  /**
   * getEvents
   * @param {Trend} eventTrend
   * @param {MarkerFocusEvent[]} focusEvents
   * @param {MarkerFocusEvent[]} restOfEvents
   * @returns {[GenericObject[], MarkerFocusEvent[], MarkerFocusEvent[]]} [events, focusEvents, restOfEvents]
   * @memberOf ChartMarkerService
   */
  private getEvents(
    eventTrend: Trend,
    focusEvents: MarkerFocusEvent[],
    restOfEvents: MarkerFocusEvent[],
  ): [GenericObject[], MarkerFocusEvent[], MarkerFocusEvent[]] {
    const eventMap = {};
    eventTrend.trendResults?.forEach((curr: TrendResult) => {
      const currName = this.titleCase.transform(curr.bucketingAttributes?.[0].value);
      const currValue = curr.counters?.[0].result.value;
      if (eventMap[currName]) {
        eventMap[currName].count = eventMap[currName].count + currValue;
      } else {
        eventMap[currName] = { name: currName, count: currValue, selected: false };
      }
    });
    const events = Object.values(eventMap);
    focusEvents.forEach((focusEvent: MarkerFocusEvent, index: number) => {
      if (eventMap[focusEvent.name]) {
        focusEvents[index].count = eventMap[focusEvent.name].count;
        focusEvents[index].selected =
          eventMap[focusEvent.name].count > 0 ? focusEvents[index].selected : eventMap[focusEvent.name].selected;
      }
    });
    restOfEvents.forEach((focusEvent: MarkerFocusEvent, index: number) => {
      if (eventMap[focusEvent.name]) {
        restOfEvents[index].count = eventMap[focusEvent.name].count;
        restOfEvents[index].selected =
          eventMap[focusEvent.name].count > 0 ? restOfEvents[index].selected : eventMap[focusEvent.name].selected;
      }
    });
    return [events, focusEvents, restOfEvents];
  }

  /**
   * setEvents
   * @param {string} dir
   * @param {GenericObject[]} events
   * @returns {[GenericObject, number, StandardDashboardCardSize]} [eventsMap, maxYvalue, chartSize]
   * @memberOf ChartMarkerService
   */
  private setEvents(dir: string, events: GenericObject[]): [GenericObject, number, StandardDashboardCardSize] {
    const eventsMap = {};
    let maxYvalue: number;
    events
      .filter((event: GenericObject) => event.count > 0)
      .forEach((event: GenericObject, index: number) => {
        maxYvalue = (index + 1) * ChartMarkerService.GAP_Y_SPLIT;
        eventsMap[`${event.name} (${event.count})`] = maxYvalue;
      });
    let chartSize = StandardDashboardCardSize.MD;
    if (events.length > 10) {
      chartSize = StandardDashboardCardSize.LG;
    }
    return [eventsMap, maxYvalue, chartSize];
  }

  /**
   * getLabelFormatting
   * @param {GenericObject} eventsMap
   * @returns {[any, any[]]} [yAxisTickFormatting, yAxisTicks]
   * @memberOf ChartMarkerService
   */
  private getLabelFormatting(eventsMap: GenericObject): [any, any[]] {
    const yAxisTickFormatting = (num: string): string => {
      return Object.keys(eventsMap).find((name: string) => {
        if (eventsMap[name] === num) {
          return name;
        }
      });
    };
    yAxisTickFormatting.bind(this);
    const yAxisTicks = Object.values(eventsMap);
    return [yAxisTickFormatting, yAxisTicks];
  }

  /**
   * getMarkerPosition
   * @param {string} dir
   * @param {string} unit
   * @param {TrendResult} result
   * @returns {[string, number, number]} [xAxisValue, markerSize, date]
   * @memberOf ChartMarkerService
   */
  private getMarkerPosition(dir: string, unit: string, result: TrendResult): [string, number, number] {
    let xAxisValue: string;
    const date = result.startMillis;
    switch (unit) {
      case ChronoUnit[ChronoUnit.SECONDS]:
        xAxisValue = moment(result.startMillis).format(DateTimeFormat.MOMENT_TIME_WITH_SECOND_FORMAT);
        break;
      case ChronoUnit[ChronoUnit.MINUTES]:
        xAxisValue = moment(result.startMillis).format(DateTimeFormat.MOMENT_TIME_FORMAT);
        break;
      case ChronoUnit[ChronoUnit.HOURS]:
        xAxisValue = moment(result.startMillis).format(DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT);
        break;
      default:
        xAxisValue = moment(result.startMillis).format(DateTimeFormat.MOMENT_DATE_FORMAT);
    }
    return [xAxisValue, this.scaleSize(result.counters[0].result.value), date];
  }

  /**
   * scaleSize
   * @param {number} counts
   * @returns {number}
   * @memberOf ChartMarkerService
   */
  private scaleSize(counts: number): number {
    return Math.round((ChartMarkerService.SCALE_BASE_UNIT * Math.log10(counts + 1)) / Math.log10(2));
  }

  /**
   * getYPos
   * @param {string} dir
   * @param {string} label
   * @param {GenericObject[]} events
   * @param {GenericObject} eventsMap
   * @param {number} maxYvalue
   * @param {StandardDashboardCardSize} chartSize
   * @returns {[number, number, StandardDashboardCardSize]}
   * @memberOf ChartMarkerService
   */
  private getYPos(
    dir: string,
    label: string,
    events: GenericObject[],
    eventsMap: GenericObject,
    maxYvalue: number,
    chartSize: StandardDashboardCardSize,
  ): [number, number, StandardDashboardCardSize] {
    if (dir === ChartMarkerService.ARROW_UP) {
      maxYvalue = ChartMarkerService.MAX_Y_SCALE;
      chartSize = StandardDashboardCardSize.SM;
      return [ChartMarkerService.GAP_Y_SPLIT, maxYvalue, chartSize];
    }
    const found = events.find((event: GenericObject) => event.name === label);
    return [eventsMap[`${found.name} (${found.count})`], maxYvalue, chartSize];
  }

  /**
   * setCustomColors
   * @param {Markers[]} markers
   * @returns {GenericObject[]}
   * @memberOf ChartMarkerService
   */
  private setCustomColors(markers: Marker[]): GenericObject[] {
    const customColors = markers.reduce((acc: GenericObject[], curr: Marker) => {
      const existingObject = acc.find((obj: GenericObject) => obj.y === curr.y);
      if (!existingObject) {
        acc.push({ name: curr.yLabel, value: curr.color, y: curr.y });
      }
      return acc;
    }, []);
    return customColors;
  }

  /**
   * filterMarkersByName
   * @param {GenericObject[]} focusEvents
   * @param {Markers[]} cachedMarkers
   * @returns {Marker[]}
   * @memberOf ChartMarkerService
   */
  private filterMarkers(focusEvents: GenericObject[], cachedMarkers: Marker[]): Marker[] {
    let filtered: Marker[];
    let selected: number = 0;
    focusEvents.forEach((event: GenericObject) => {
      if (event.selected) {
        const found = cachedMarkers.filter((marker: Marker) => {
          return event.name === marker.yLabel;
        });
        if (found.length) {
          filtered = filtered?.length ? filtered.concat(found) : [].concat(found);
          selected++;
        }
      }
    });
    return selected ? filtered : cachedMarkers;
  }
}
