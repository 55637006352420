/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { I18NService } from '@ws1c/intelligence-common/services';
import { AggregationWidgetChartType, LabelValue } from '@ws1c/intelligence-models';

/**
 * ChartTypeAheadSelectorComponent
 * @implements {OnChanges}
 * @export
 * @class ChartTypeAheadSelectorComponent
 */
@Component({
  selector: 'dpa-chart-type-ahead-selector',
  templateUrl: 'chart-type-ahead-selector.component.html',
  styleUrls: ['chart-type-ahead-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTypeAheadSelectorComponent implements OnChanges {
  @Input() public chartTypes: string[];
  @Input() public selectedChartType: string;
  @Output() public chartTypeSelected = new EventEmitter<string>();

  public chartTypeOptions: LabelValue[] = [];
  public selectedChartTypeOption: LabelValue;
  public readonly labelKeysByChartType = {
    [AggregationWidgetChartType[AggregationWidgetChartType.DONUT]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_DONUT',
    [AggregationWidgetChartType[AggregationWidgetChartType.HORIZONTAL]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_HORIZONTAL',
    [AggregationWidgetChartType[AggregationWidgetChartType.HORIZONTAL_GROUP]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_HORIZONTAL_GROUP',
    [AggregationWidgetChartType[AggregationWidgetChartType.LINE]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_LINE',
    [AggregationWidgetChartType[AggregationWidgetChartType.METRIC]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_METRIC',
    [AggregationWidgetChartType[AggregationWidgetChartType.TABLE]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_TABLE',
    [AggregationWidgetChartType[AggregationWidgetChartType.VERTICAL]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_VERTICAL',
    [AggregationWidgetChartType[AggregationWidgetChartType.VERTICAL_GROUP]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_VERTICAL_GROUP',
    [AggregationWidgetChartType[AggregationWidgetChartType.AREA]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_AREA',
    [AggregationWidgetChartType[AggregationWidgetChartType.HEAT_MAP]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_HEAT_MAP',
    [AggregationWidgetChartType[AggregationWidgetChartType.TREE_MAP]]: 'WIDGET_CUSTOMIZE.WIDGET_CHART_TREE_MAP',
  };

  /**
   * constructor
   * @param {I18NService} i18NService
   * @memberof ChartTypeAheadSelectorComponent
   */
  constructor(public i18NService: I18NService) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ChartTypeAheadSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.chartTypes || changes.selectedChartType) {
      this.chartTypeOptions = (this.chartTypes || []).map((chartType: string) => {
        return {
          value: chartType,
          label: this.i18NService.translate(this.labelKeysByChartType[chartType]),
        };
      });
      this.selectedChartTypeOption = this.chartTypeOptions.find((chartTypeOption: any) => chartTypeOption.value === this.selectedChartType);
    }
  }

  /**
   * selectChartType
   * @param {LabelValue} chartTypeOption
   * @memberof ChartTypeAheadSelectorComponent
   */
  public selectChartType(chartTypeOption: LabelValue) {
    this.chartTypeSelected.emit(chartTypeOption && chartTypeOption.value);
  }

  /**
   * getLabel
   * @param {LabelValue} chartTypeOption
   * @memberof ChartTypeAheadSelectorComponent
   * @returns {string}
   */
  public getLabel(chartTypeOption: LabelValue): string {
    return chartTypeOption.label;
  }
}
