/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { WindowService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UserPreferenceAssetsSelectors } from '@ws1c/intelligence-core';

/**
 * ProductUpgradeModalComponent
 * @export
 * @class ProductUpgradeModalComponent
 */
@Component({
  selector: 'dpa-product-upgrade-modal',
  templateUrl: 'product-upgrade-modal.component.html',
  styleUrls: ['product-upgrade-modal.component.scss'],
})
export class ProductUpgradeModalComponent {
  public overviewReleaseNotesUrl$: Observable<string>;

  /**
   * Creates an instance of ProductUpgradeModalComponent.
   * @param {Store} store
   * @param {WindowService} windowService
   * @memberof ProductUpgradeModalComponent
   */
  constructor(private store: Store, private windowService: WindowService) {
    this.overviewReleaseNotesUrl$ = this.store.select(UserPreferenceAssetsSelectors.getWS1IntelligenceReleaseNotesLink);
  }

  /**
   * onReload
   * @memberof ProductUpgradeModalComponent
   */
  public onReload() {
    this.windowService.location.href = `${this.windowService.location.origin}?q=${Date.now()}`;
  }
}
