/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { AggregationWidget, Category } from '@ws1c/intelligence-models';

/**
 * WidgetTagFilterComponent
 *
 * @export
 * @class WidgetTagFilterComponent
 */
@Component({
  selector: 'dpa-widget-tag-filter',
  templateUrl: 'widget-tag-filter.component.html',
  styleUrls: ['widget-tag-filter.component.scss'],
})
export class WidgetTagFilterComponent implements OnChanges, OnInit {
  @Input() public widgets: AggregationWidget[];
  @Input() public isMinimized: boolean = true;
  @Input() public selectedQuickFilters?: Record<string, boolean>;
  @Input() public selectedFilterCount: number = 0;

  @Output() public onFilterChange: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() public selectedFilterCountChange: EventEmitter<number> = new EventEmitter<number>();

  public filterList = [];
  public tagList = {};
  public trendFilter = {};
  public integrationFilter = {};
  public filter = {};
  public integrationCategories$: Observable<any>;
  public subs: Subscription[];
  public tagLabels = {};

  /**
   * Creates an instance of WidgetTagFilterComponent.
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18nService
   * @memberof WidgetTagFilterComponent
   */
  constructor(private store: Store<CoreAppState>, private i18nService: I18NService) {
    this.integrationCategories$ = this.store.select(IntegrationMetaSelectors.getAvailableCategoriesState);
    this.tagList = {
      HISTORICAL: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_HISTORICAL'),
      SNAPSHOT: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_SNAPSHOT'),
      SNAPSHOT_PERIODICAL: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_SNAPSHOT_PERIODICAL'),
    };
  }

  /**
   * ngOnInit - builds filterList
   * @memberof WidgetTagFilterComponent
   */
  public ngOnInit() {
    this.subs = [
      this.integrationCategories$.subscribe((categories: Category[]) => {
        if (categories) {
          categories.forEach((cat: Category) => {
            this.tagList[cat.categoryId] = cat.fullLabel;
          });
          Object.values(this.filterList).forEach((tagKey: string) => {
            this.tagLabels[tagKey] = this.tagList[tagKey];
          });
        }
      }),
    ];
  }

  /**
   * ngOnChanges - rebuilds filterList & quickFilter
   * @param {SimpleChanges} changes
   * @memberof WidgetTagFilterComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.widgets && changes.widgets.currentValue) {
      this.widgets.forEach((widget: AggregationWidget) => {
        this.integrationFilter[widget.trend.trendDefinition.categoryId] = false;
        this.trendFilter[widget.trend.trendDefinition.trendMode.toUpperCase()] = false;
      });
      this.filterList = Object.keys(this.trendFilter).sort().reverse();
      this.filterList = this.filterList.concat(Object.keys(this.integrationFilter));
      if (changes.widgets.currentValue !== changes.widgets.previousValue) {
        // some action applied, reset filters
        this.filter = {};
      }
      this.updateSelectedFilterCount();
    }
  }

  /**
   * toggleFilter
   * @param {GenericObject} keyChange
   * @memberof WidgetTagFilterComponent
   */
  public toggleFilter(keyChange: GenericObject) {
    const key = Object.keys(keyChange)[0];
    this.filter = {
      ...this.filter,
      ...keyChange,
    };
    this.onFilterChange.emit({
      name: key,
      value: this.filter[key],
    });
    this.updateSelectedFilterCount();
  }

  /**
   * updateSelectedFilterCount
   * @private
   * @memberof WidgetTagFilterComponent
   */
  private updateSelectedFilterCount() {
    this.selectedFilterCount = Object.keys(this.selectedQuickFilters ?? {}).filter(
      (item: string) => this.selectedQuickFilters[item],
    ).length;
    this.selectedFilterCountChange.emit(this.selectedFilterCount);
  }
}
