/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { each } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

// eslint-disable-next-line max-len
import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';
import { AutomationCommonActions, AutomationCommonSelectors } from '@ws1c/intelligence-core/store';
import {
  ConnectorAction,
  ConnectorActionSection,
  JsonSchemaAutomationActionFieldForLookup,
  JsonSchemaAutomationActionFieldLookupRequestPayload,
  JsonSchemaMetadata,
  LabelValue,
  MetaFormField,
  MetaFormFieldPresentationType,
  NameValue,
} from '@ws1c/intelligence-models';

/**
 * LookupFormFieldComponent
 * @export
 * @class LookupFormFieldComponent
 * @extends {BaseFormFieldComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-lookup-form-field',
  templateUrl: 'lookup-form-field.component.html',
  styleUrls: ['lookup-form-field.component.scss'],
})
export class LookupFormFieldComponent extends BaseFormFieldComponent implements OnInit, OnDestroy {
  @Input() public searchableActionsEnabled?: boolean = true;
  @Input() public enumList?: LabelValue[];

  public lookupMap$: Observable<Record<string, NameValue[]>>;
  public lookupMapError$: Observable<WebError>;
  public lookupMapLoading$: Observable<boolean>;
  public fieldsForLookup: JsonSchemaAutomationActionFieldForLookup[] = [];
  public field: MetaFormField;
  public nestedLookups = {};
  public showCustomInput = true;

  public customMetadata;
  public sub: Subscription = new Subscription();
  public availableActionMethodsByKey: Record<string, ConnectorAction>;

  /**
   * Creates an instance of LookupFormFieldComponent.
   * @param {Store} store
   * @memberof LookupFormFieldComponent
   */
  constructor(private store: Store) {
    super();
    this.lookupMap$ = this.store.select(AutomationCommonSelectors.lookupMap);
    this.lookupMapError$ = this.store.select(AutomationCommonSelectors.getLookupMapError);
    this.lookupMapLoading$ = this.store.select(AutomationCommonSelectors.isLookupMapLoading);
  }

  /**
   * ngOnInit
   * @memberof LookupFormFieldComponent
   */
  public ngOnInit() {
    this.field = new MetaFormField({
      lookupConfig: this.metadata.lookupConfig,
      label: this.metadata.label,
      description: this.metadata.description,
      required: this.required,
      name: this.metadata.name,
    });
    // This is to display the field for the Custom value.
    this.customMetadata = {
      ...this.metadata,
      lookupConfig: undefined,
    };
    this.sub.add(
      this.store
        .select(AutomationCommonSelectors.getAllActionsByKey)
        .subscribe((availableActionMethodsByKey: Record<string, ConnectorAction>) => {
          this.availableActionMethodsByKey = availableActionMethodsByKey;
        }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof LookupFormFieldComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * onLookupChoiceClick
   * @param {JsonSchemaMetadata} fieldMetaData
   * @memberof LookupFormFieldComponent
   */
  public onLookupChoiceClick(fieldMetaData: JsonSchemaMetadata) {
    this.showCustomInput = false;
    this.setNestedLookupFields(fieldMetaData);
  }

  /**
   * onCustomValueChoiceClick
   * @memberof LookupFormFieldComponent
   */
  public onCustomValueChoiceClick() {
    this.showCustomInput = true;
  }

  /**
   * onRequestFieldLookup
   * @param {JsonSchemaAutomationActionFieldLookupRequestPayload} payload
   * @memberof LookupFormFieldComponent
   */
  public onRequestFieldLookup(payload: JsonSchemaAutomationActionFieldLookupRequestPayload) {
    this.store.dispatch(AutomationCommonActions.requestFieldLookup({ payload }));
  }

  /**
   * setFieldValueFromLookup
   * @param {string} value
   * @param {string} _fieldName
   * @memberof LookupFormFieldComponent
   */
  public setFieldValueFromLookup(value: string, _fieldName: string) {
    this.formControl.setValue(value);
  }

  /**
   * setNestedLookupFields
   * @param {JsonSchemaMetadata} field
   * @memberof LookupFormFieldComponent
   */
  public setNestedLookupFields(field: JsonSchemaMetadata) {
    const fieldName = field.name;
    if (!this.nestedLookups[fieldName]) {
      this.nestedLookups[fieldName] = [];
    }
    this.getAllFieldsForLookup(field);
    this.nestedLookups[fieldName] = this.fieldsForLookup.length ? this.fieldsForLookup : [];
    this.fieldsForLookup = [];
  }

  /**
   * getAllFieldsForLookup
   * @private
   * @param {JsonSchemaMetadata} lookupField
   * @memberof LookupFormFieldComponent
   */
  private getAllFieldsForLookup(lookupField: JsonSchemaMetadata) {
    const lookupActionMethod: ConnectorAction = this.availableActionMethodsByKey[lookupField.lookupConfig.operationId];
    each(lookupActionMethod?.sections, (sections: ConnectorActionSection[]) => {
      each(sections, (section: ConnectorActionSection) => this.populateFieldsForLookup(lookupField, section));
    });
  }

  /**
   * populateFieldsForLookup
   * @private
   * @param {JsonSchemaMetadata} lookupField
   * @param {ConnectorActionSection} section
   * @memberof LookupFormFieldComponent
   */
  private populateFieldsForLookup(lookupField: JsonSchemaMetadata, section: ConnectorActionSection) {
    const currentField = section.metadata[0];
    if (currentField.lookupConfig) {
      this.getAllFieldsForLookup(currentField);
    }
    if (currentField.presentationType !== MetaFormFieldPresentationType.HIDDEN) {
      this.fieldsForLookup.push(
        new JsonSchemaAutomationActionFieldForLookup({
          field: currentField,
          lookupConfig: lookupField.lookupConfig,
        }),
      );
    }
  }
}
