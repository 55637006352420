/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FullPageElementService } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { AlertBannerActions } from '@ws1c/intelligence-core/store';
import { AlertBannerTarget } from '@ws1c/intelligence-models';

/**
 * Handles side effects for alert banner
 * @export
 * @class AlertBannerEffects
 */
@Injectable({
  providedIn: 'root',
})
export class AlertBannerEffects {
  /**
   * Resize full page container when an app-level banner is dismissed
   * @memberof OrgEffects
   */
  public appLevelAlertBanner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AlertBannerActions.showAlertBanner, AlertBannerActions.dismissAlertBanner),
        tap((action: ReturnType<typeof AlertBannerActions.showAlertBanner | typeof AlertBannerActions.dismissAlertBanner>) => {
          if (action.target === AlertBannerTarget.APP) {
            this.fullPageElementService.resizeContainer();
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of AlertBannerEffects
   * @param {Actions} actions$
   * @param {FullPageElementService} fullPageElementService
   * @memberof AlertBannerEffects
   */
  constructor(private actions$: Actions, private fullPageElementService: FullPageElementService) {}
}
