/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidePanelService, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AlertBannerActions, CoreAppState } from '@ws1c/intelligence-core/store';
import { AlertBannerTarget } from '@ws1c/intelligence-models';

/**
 * SidePanelComponent
 * @export
 * @class SidePanelComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-side-panel',
  templateUrl: 'side-panel.component.html',
  styleUrls: ['side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit, OnDestroy {
  @Input() public header?: string;
  @Input() public showFooter?: boolean = true;
  @Input() public showExpandedSidePanel?: boolean = true;
  @Input() public closable?: boolean = false;
  @Output() public onExpandChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onClose = new EventEmitter();

  public isExpanded: boolean = false;
  private sub: Subscription;

  /**
   * @param {Store<CoreAppState>} store
   * @param {SidePanelService} sidePanelService
   * @memberof SidePanelComponent
   */
  constructor(private store: Store<CoreAppState>, private sidePanelService: SidePanelService) {}

  /**
   * toggleSidePanel
   * @param {boolean} isExpanded
   * @memberof SidePanelComponent
   */
  public toggleSidePanel(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    this.onExpandChange.emit(this.isExpanded);
    if (this.isExpanded) {
      this.store.dispatch(AlertBannerActions.dismissAlertBanner({ target: AlertBannerTarget.SIDEBAR }));
    }
  }

  /**
   * ngOnInit
   * @memberof SidePanelComponent
   */
  public ngOnInit() {
    this.sub = this.sidePanelService.sidePanelToggle$.subscribe(this.toggleSidePanel.bind(this));
  }

  /**
   * ngOnDestroy
   * @memberof SidePanelComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.sub);
  }

  /**
   * onSidePanelClose
   * @memberof SidePanelComponent
   */
  public onSidePanelClose() {
    this.onClose.emit();
  }
}
