<div
  *ngIf="field.name && field.name + '-' + DEFAULT_VALUE_TYPE.LOOKUP as lookupFieldName"
  [formGroup]="formGroup"
  class="clr-form-control radio-group"
>
  <clr-radio-wrapper>
    <input
      clrRadio
      [id]="getFieldId(lookupFieldName, 0)"
      [name]="lookupFieldName"
      [formControlName]="lookupFieldName"
      [value]="DEFAULT_VALUE_TYPE.LOOKUP"
      (click)="onLookupChoiceClick(field)"
      type="radio"
    />
    <label [for]="getFieldId(lookupFieldName, 0)">
      {{ 'CUSTOM_CONNECTION.SEARCH_FOR_EXISTING_VALUES' | translate }}
    </label>
  </clr-radio-wrapper>
  <clr-radio-wrapper>
    <input
      clrRadio
      [id]="getFieldId(lookupFieldName, 1)"
      [name]="lookupFieldName"
      [formControlName]="lookupFieldName"
      [value]="DEFAULT_VALUE_TYPE.CUSTOM"
      (click)="onCustomValueChoiceClick(field)"
      type="radio"
    />
    <label [for]="getFieldId(lookupFieldName, 1)">
      {{ 'CUSTOM_CONNECTION.ENTER_CUSTOM_VALUE' | translate }}
    </label>
  </clr-radio-wrapper>
</div>
<div
  *ngIf="isLookupFormVisible(field.name)"
  class="lookup-form"
>
  <div
    *ngFor="let nestedLookup of nestedLookups[field.name]; let i = index; first as isFirst"
    class="lookup-variable-input-container clr-form-control form-group"
  >
    <label
      [for]="getFieldId(nestedLookup.field.name)"
      [class.required]="nestedLookup.field.required"
      [class.vertical-line]="!isFirst"
      [ngStyle]="getPaddingLeftByIndex(i)"
      class="clr-control-label"
    >
      {{ nestedLookup.field.label }}
    </label>
    <dpa-type-ahead
      [id]="getFieldId(nestedLookup.field.name)"
      [disabled]="i > 0 && !selectedLookupValues[i - 1]"
      [searchableItems]="getSearchableItems(nestedLookup)"
      [isSuggestionLoading]="lookupMapLoading"
      [showError]="!!lookupMapError"
      [errorText]="'WORKFLOWS.LOOKUP_ERROR_MSG' | translate : { reason: lookupMapError?.getFullReason() }"
      [selectedItem]="selectedLookupValues[i]"
      [keyBy]="formatLookupName"
      [labelFormatter]="formatLookupName"
      [customFormatter]="formatLookupName"
      [placeholderText]="'CUSTOM_CONNECTION.SELECT_OPTION' | translate"
      (selectedItemChange)="onSelectLookupValue($event, i, field.name)"
      (onQueryChange)="onRequestFieldLookup($event, nestedLookup, i)"
    ></dpa-type-ahead>
  </div>
</div>
