/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import Quill from 'quill';

// eslint-disable-next-line max-len
import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';
import { LookupVariable } from '@ws1c/intelligence-models';

/**
 * RichTextFormFieldComponent
 * @export
 * @class RichTextFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-rich-text-form-field',
  templateUrl: 'rich-text-form-field.component.html',
  styleUrls: ['rich-text-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextFormFieldComponent extends BaseFormFieldComponent {
  public editorInstance: Quill | undefined;

  /**
   * onEditorCreation
   * @param {Quill} event
   * @memberof RichTextFormFieldComponent
   */
  public onEditorCreation(event: Quill) {
    this.editorInstance = event;
  }

  /**
   * onLookupVariableSelect
   * @param {LookupVariable} lookupVariable
   * @memberof RichTextFormFieldComponent
   */
  public onLookupVariableSelect(lookupVariable: LookupVariable) {
    if (!this.editorInstance) {
      return;
    }
    const range = this.editorInstance.getSelection(true);
    this.editorInstance.insertText(range.index, lookupVariable.name, Quill.sources.USER);
    this.editorInstance.setSelection(range.index + lookupVariable.name.length, Quill.sources.SILENT);
  }
}
