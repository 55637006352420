/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { AppsDashboardType, AppsWidgetSubtype, AppsWidgetType } from './apps-dashboard.enum';
import { AutomationDashboardType, AutomationWidgetSubtype } from './automation-dashboard.enum';
import { CarbonBlackDashboardType, CarbonBlackWidgetSubtype } from './carbon-black-dashboard.enum';
import { CveDashboardType, CveWidgetSubtype } from './cve-dashboard.enum';
import { DataExplorerDashboardType, DataExplorerWidgetSubtype } from './data-explorer-dashboard.enum';
import { DeemDashboardType, DeemWidgetSubtype } from './deem-dashboard.enum';
import { DevicesDashboardType, DevicesWidgetSubtype } from './devices-dashboard.enum';
import { HorizonDashboardType, HorizonWidgetSubtype } from './horizon-dashboard.enum';
import { InsightDashboardType, InsightWidgetSubtype } from './insight-dashboard.enum';
import { LoginRiskDashboardType, LoginRiskWidgetSubtype } from './login-risk-dashboard.enum';
import { NetworkDashboardType, NetworkWidgetSubtype } from './network-dashboard.enum';
import { OsSummaryDashboardType, OsSummaryWidgetSubtype } from './os-summary-dashboard.enum';
import { PerformanceIndicatorsDashboardType, PerformanceIndicatorsWidgetSubtype } from './performance-indicators-dashboard.enum';
import { SecurityDashboardType, SecurityWidgetSubtype, SecurityWidgetType } from './security-dashboard.enum';
import { UagDashboardType, UagWidgetSubtype } from './uag-dashboard.enum';
import { UserFlowDashboardType, UserFlowWidgetSubtype } from './user-flow-dashboard.enum';
import { UserProfileDashboardType, UserProfileWidgetSubtype } from './user-profile-dashboard.enum';
import { UserRiskDashboardType, UserRiskWidgetSubtype } from './user-risk-dashboard.enum';
import { WorkspaceOneMtdDashboardType, WorkspaceOneMtdWidgetSubtype } from './workspace-one-mtd-dashboard.enum';

/**
 * WidgetWizardDialogMode
 *
 * @export
 * @enum {number}
 */
export enum WidgetWizardDialogMode {
  ADD,
  EDIT,
  CLOSE,
}

/**
 * Aggregate functions supported by Janus.
 *
 * @export
 * @enum {string}
 */
export enum AggregationFunction {
  AVG = 'AVG',
  COUNT = 'COUNT',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  COUNT_DISTINCT_BY_MINUTE = 'COUNT_DISTINCT_BY_MINUTE',
  LATEST = 'LATEST',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
}

/**
 * Aggregation widget chart type.
 *
 * @export
 * @enum {number}
 */
export enum AggregationWidgetChartType {
  AREA,
  BUBBLE,
  DONUT,
  HEAT_MAP,
  HORIZONTAL,
  HORIZONTAL_GROUP,
  LINE,
  LINE_SPARK,
  METRIC,
  TABLE,
  TREE_MAP,
  VERTICAL,
  VERTICAL_GROUP,
}

/**
 * standard dashboard card size.
 *
 * @export
 * @enum {number}
 */
export enum StandardDashboardCardSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XG = 'xg',
  AUTO = 'auto',
}

/**
 * Defines Event Data Type
 *
 * @export
 * @enum {number}
 */
export enum EventFieldDataType {
  STRING,
  INTEGER,
  LONG,
  FLOAT,
  DOUBLE,
  BOOLEAN,
  DATETIME,
  BYTE,
  BINARY,
  STRINGSET,
  NUMBERSET,
}

/**
 * Defines the order in which Date Range Buckets be listed.
 *
 * @export
 * @enum {number}
 */
export enum AggregationOrder {
  ASC,
  DESC,
}

/**
 * Defines Dashboard Type
 *
 * @export
 * @enum {string}
 */
export enum DashboardType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
  FRONTLINE = 'FRONTLINE',
}

/**
 * Defines Kendo export types
 *
 * @export
 * @enum {number}
 */
export enum ChartExportType {
  PNG,
  PDF,
}

/**
 * Trend status direction associated with a widget/data point. This indicates if the trend (for this
 * widget) can be interpreted as a positive movement - "moving in the right direction" - or negative.
 *
 * @export
 * @enum {number}
 */
export enum TrendingStatusDirection {
  POSITIVE,
  NEGATIVE,
}

/**
 * ComposeFunction
 * @export
 * @enum ComposeFunction
 */
export enum ComposeFunction {
  SET_TREND_MODE = 'SET_TREND_MODE',
  COUNT_RATIO = 'COUNT_RATIO',
  DIVIDE_BY_COUNT = 'DIVIDE_BY_COUNT',
  RENAME_COUNTERS = 'RENAME_COUNTERS',
  RENAME_COUNTER_LABELS = 'RENAME_COUNTER_LABELS',
  COUNT_CHANGE = 'COUNT_CHANGE',
  SUM_ALL_COUNT = 'SUM_ALL_COUNT',
  RATIO_BY_TIME_AND_BUCKETS = 'RATIO_BY_TIME_AND_BUCKETS',
  SUM_BY_TIME_AND_BUCKETS = 'SUM_BY_TIME_AND_BUCKETS',
  MERGE_PREVIOUS_PERIOD_SERIES = 'MERGE_PREVIOUS_PERIOD_SERIES',
  FLATTEN_BUCKETS = 'FLATTEN_BUCKETS',
  DIFF_BY_BUCKETS = 'DIFF_BY_BUCKETS',
  MERGE_RESULTS = 'MERGE_RESULTS',
  MERGE_SERIES = 'MERGE_SERIES',
  MERGE_COUNTERS = 'MERGE_COUNTERS',
  MERGE_BUCKETING_ATTRS = 'MERGE_BUCKETING_ATTRS',
  COUNT_BY_GROUP = 'COUNT_BY_GROUP',
  REMOVE_ZEROS = 'REMOVE_ZEROS',
  SET_LATEST_BY_COUNTER = 'SET_LATEST_BY_COUNTER',
  NOOP = 'NOOP',
}

/**
 * WidgetRangeType
 * @export
 * @enum {string}
 */
export enum WidgetRangeType {
  COUNT = 'COUNT',
  PERCENTAGE = 'PERCENTAGE',
}

/**
 * ScoreType
 * @export
 * @enum {string}
 */
export enum ScoreType {
  GOOD = 'Good',
  NA = 'NA',
  NEUTRAL = 'Neutral',
  POOR = 'Poor',
  TOTAL = 'Total',
}

export type StandardDashboardType =
  | AppsDashboardType
  | AutomationDashboardType
  | CarbonBlackDashboardType
  | CveDashboardType
  | DevicesDashboardType
  | DeemDashboardType
  | HorizonDashboardType
  | InsightDashboardType
  | LoginRiskDashboardType
  | NetworkDashboardType
  | OsSummaryDashboardType
  | PerformanceIndicatorsDashboardType
  | SecurityDashboardType
  | UagDashboardType
  | UserFlowDashboardType
  | UserProfileDashboardType
  | UserRiskDashboardType
  | WorkspaceOneMtdDashboardType
  | DataExplorerDashboardType;

// eslint-disable-next-line no-redeclare
export const StandardDashboardType = {
  ...AppsDashboardType,
  ...AutomationDashboardType,
  ...CarbonBlackDashboardType,
  ...CveDashboardType,
  ...DeemDashboardType,
  ...DevicesDashboardType,
  ...HorizonDashboardType,
  ...InsightDashboardType,
  ...LoginRiskDashboardType,
  ...NetworkDashboardType,
  ...OsSummaryDashboardType,
  ...PerformanceIndicatorsDashboardType,
  ...SecurityDashboardType,
  ...UagDashboardType,
  ...UserFlowDashboardType,
  ...UserProfileDashboardType,
  ...UserRiskDashboardType,
  ...WorkspaceOneMtdDashboardType,
  ...DataExplorerDashboardType,
};

export const StandardWidgetType = {
  ...AppsWidgetType,
  ...SecurityWidgetType,
};
// eslint-disable-next-line no-redeclare
export type StandardWidgetType = AppsWidgetType | SecurityWidgetType;

export const StandardWidgetSubtype = {
  ...AppsWidgetSubtype,
  ...AutomationWidgetSubtype,
  ...CarbonBlackWidgetSubtype,
  ...CveWidgetSubtype,
  ...DeemWidgetSubtype,
  ...DevicesWidgetSubtype,
  ...HorizonWidgetSubtype,
  ...InsightWidgetSubtype,
  ...LoginRiskWidgetSubtype,
  ...NetworkWidgetSubtype,
  ...OsSummaryWidgetSubtype,
  ...PerformanceIndicatorsWidgetSubtype,
  ...SecurityWidgetSubtype,
  ...UagWidgetSubtype,
  ...UserFlowWidgetSubtype,
  ...UserProfileWidgetSubtype,
  ...UserRiskWidgetSubtype,
  ...WorkspaceOneMtdWidgetSubtype,
  ...DataExplorerWidgetSubtype,
};
// eslint-disable-next-line no-redeclare
export type StandardWidgetSubtype =
  | AppsWidgetSubtype
  | AutomationWidgetSubtype
  | CarbonBlackWidgetSubtype
  | CveWidgetSubtype
  | DeemWidgetSubtype
  | DevicesWidgetSubtype
  | HorizonWidgetSubtype
  | InsightWidgetSubtype
  | LoginRiskWidgetSubtype
  | NetworkWidgetSubtype
  | OsSummaryWidgetSubtype
  | PerformanceIndicatorsWidgetSubtype
  | SecurityWidgetSubtype
  | UagWidgetSubtype
  | UserFlowWidgetSubtype
  | UserProfileWidgetSubtype
  | UserRiskWidgetSubtype
  | WorkspaceOneMtdWidgetSubtype;
