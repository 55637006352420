<div class="card simple-map">
  <div
    *ngIf="cardTitle"
    class="card-header"
  >
    <div class="card-title">
      <div class="card-title-text">
        <span>
          {{ cardTitle }}
        </span>
      </div>
    </div>
  </div>

  <div class="card-block">
    <div
      #map
      class="map"
    ></div>
  </div>
</div>
