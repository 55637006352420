/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FuzzyItem, FuzzyService, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { map as _map, cloneDeep, get, isEmpty, isEqual } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { TrendComposerService } from '@ws1c/intelligence-core/services';
import { CoreAppState, DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core/store';
import {
  AggregationWidgetChartType,
  BucketSelection,
  ChartDrilldownEvent,
  DrilldownTrail,
  DrilldownWidgetConfig,
  FilterRule,
  StandardWidgetSubtype,
  Trend,
  TrendResult,
} from '@ws1c/intelligence-models';

/**
 * TreeMapDrilldownComponent
 * @export
 * @class TreeMapDrilldownComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-tree-map-drilldown',
  templateUrl: 'tree-map-drilldown.component.html',
  styleUrls: ['tree-map-drilldown.component.scss'],
})
export class TreeMapDrilldownComponent implements OnInit, OnDestroy {
  @Input() public initialWidgetConfig: DrilldownTrail;
  @Input() public drilldownWidgetConfigs: DrilldownWidgetConfig[];
  @Input() public searchPlaceholderText?: string;
  @Input() public listItemSize?: number = 37;
  @Input() public listTitle?: string;
  @Input() public treeMapListViewConfig?: DrilldownWidgetConfig[];
  @Input() public customListItemTemplte?: TemplateRef<any>;
  @Input() public customListValuePath?: string;
  @Input() public skipSort?: boolean;
  @Output() public onFilterRulesChanged = new EventEmitter<FilterRule[]>();
  @Output() public onClickListItem = new EventEmitter<TrendResult>();

  public drilldownCurrentWidget$: Observable<DrilldownTrail>;
  public treeMapListConfig: DrilldownWidgetConfig;
  public isLoading$: Observable<boolean>;
  public drilldownList$: Observable<DrilldownTrail[]>;
  public listData$: Observable<Trend>;
  public treeMapData$: Observable<Trend>;
  public subs: Subscription[];
  public showSplitListView?: boolean = false;
  public labelFormatting?: any;
  public valueFormatting?: any;
  public showSameSizeCell?: boolean;
  public isEnd?: boolean = false;
  public visibleWidgetSubtypes = [];
  public prevDrilldownFilterRules: FilterRule[] = [];

  public readonly CHART_TYPE = AggregationWidgetChartType;

  /**
   * Creates an instance of TreeMapDrilldownComponent.
   * @param {FuzzyService} fuzzyService
   * @param {Store<CoreAppState>} store
   * @param {TrendComposerService} tcs
   * @memberof TreeMapDrilldownComponent
   */
  constructor(private fuzzyService: FuzzyService, private store: Store<CoreAppState>, private tcs: TrendComposerService) {
    this.drilldownList$ = this.store.select(DashboardSelectors.getVisibleWidgetDrilldownTrail);
    this.drilldownCurrentWidget$ = this.store
      .select(DashboardSelectors.getLastVisibleWidgetDrilldownTrail)
      .pipe(filter<DrilldownTrail>(Boolean));
  }

  /**
   * ngOnInit
   * @memberof TreeMapDrilldownComponent
   */
  public ngOnInit() {
    this.subs = [
      this.drilldownCurrentWidget$.subscribe((drilldownTrail: DrilldownTrail) => {
        const widgetId = drilldownTrail.widgetId;
        const drilldownConfig = isEmpty(drilldownTrail.drilldownEvent)
          ? this.drilldownWidgetConfigs.find((config) => config.widgetId === widgetId)
          : this.getMatchedDrilldownConfig(this.drilldownWidgetConfigs, drilldownTrail.drilldownEvent);
        this.treeMapData$ = this.tcs.getTrend$(widgetId);
        this.isLoading$ = this.tcs.isLoadingState$(widgetId);

        this.updateConfig(drilldownConfig);
        if (!this.visibleWidgetSubtypes.includes(widgetId)) {
          this.visibleWidgetSubtypes.push(widgetId);
        }

        this.treeMapListConfig = this.getMatchedDrilldownConfig(this.treeMapListViewConfig, drilldownTrail.drilldownEvent);

        this.showSplitListView = !!this.treeMapListConfig;
        if (this.showSplitListView) {
          this.listData$ = this.tcs.getTrend$(this.treeMapListConfig.widgetId);
        }
      }),
      this.drilldownList$.pipe(distinctUntilChanged(isEqual), debounceTime(200)).subscribe((drilldownList: DrilldownTrail[]) => {
        const _filterRules: FilterRule[] = [];
        drilldownList.forEach((drilldown: DrilldownTrail) => {
          drilldown?.drilldownEvent?.selectedBuckets?.forEach((selectBucket: BucketSelection) => {
            _filterRules.push(
              new FilterRule({
                attribute: selectBucket.bucketName,
                condition: FilterRule.FILTER_CONDITION.equals,
                data: selectBucket.selectedValue,
              }),
            );
          });
        });
        this.prevDrilldownFilterRules = _filterRules;
      }),
    ];
    this.store.dispatch(
      DashboardActions.addWidgetDrilldownTrail({
        drilldownTrail: this.initialWidgetConfig,
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof TreeMapDrilldownComponent
   */
  public ngOnDestroy() {
    this.store.dispatch(DashboardActions.clearWidgetDrilldownTrail());
    this.store.dispatch(DashboardActions.removeVisibleWidgetSubtypes({ widgetSubtypes: this.visibleWidgetSubtypes }));
    unsubscribe(this.subs);
  }

  /**
   * pushDrilldownEvent
   * @param {ChartDrilldownEvent} drilldownEvent
   * @memberof TreeMapDrilldownComponent
   */
  public pushDrilldownEvent(drilldownEvent: ChartDrilldownEvent) {
    const drilldownConfig = this.getMatchedDrilldownConfig(this.drilldownWidgetConfigs, drilldownEvent);
    if (this.isEnd || !drilldownConfig) {
      return;
    }

    const filterRules: FilterRule[] = [];
    drilldownEvent.selectedBuckets.forEach((selectBucket: BucketSelection) => {
      filterRules.push(
        new FilterRule({
          attribute: selectBucket.bucketName,
          condition: FilterRule.FILTER_CONDITION.equals,
          data: selectBucket.selectedValue,
        }),
      );
    });
    if (this.prevDrilldownFilterRules.length || filterRules.length) {
      drilldownEvent.addFilters = [...this.prevDrilldownFilterRules, ...filterRules];
    }

    const widgetId: StandardWidgetSubtype = drilldownConfig.widgetId;

    this.store.dispatch(
      DashboardActions.addWidgetDrilldownTrail({
        drilldownTrail: {
          widgetId,
          drilldownKey:
            drilldownConfig.drilldownKey || drilldownEvent?.selectedCounter || drilldownEvent?.selectedBuckets[0]?.selectedValue,
          drilldownEvent,
        },
      }),
    );

    const treeMapListConfig = this.getMatchedDrilldownConfig(this.treeMapListViewConfig, drilldownEvent);
    if (treeMapListConfig) {
      this.store.dispatch(
        DashboardActions.addWidgetDrilldownTrail({
          drilldownTrail: {
            widgetId: treeMapListConfig.widgetId,
            drilldownKey: `${drilldownEvent?.selectedCounter || drilldownEvent?.selectedBuckets[0]?.selectedValue}-List`,
            drilldownEvent,
            hidden: true,
          },
        }),
      );
    }
    this.onFilterRulesChanged.emit();
  }

  /**
   * navigateTo
   * @param {DrilldownTrail} drilldownTrail
   * @memberof TreeMapDrilldownComponent
   */
  public navigateTo(drilldownTrail: DrilldownTrail) {
    this.store.dispatch(
      DashboardActions.popWidgetDrilldownTrail({
        drilldownKey: drilldownTrail.drilldownKey,
      }),
    );
    this.onFilterRulesChanged.emit();
  }

  /**
   * updateConfig
   * @param {DrilldownWidgetConfig} drilldownConfig
   * @memberof TreeMapDrilldownComponent
   */
  public updateConfig(drilldownConfig: DrilldownWidgetConfig) {
    this.labelFormatting = drilldownConfig?.labelFormatting;
    this.valueFormatting = drilldownConfig?.valueFormatting;
    this.showSameSizeCell = drilldownConfig?.showSameSizeCell;
    this.isEnd = drilldownConfig?.isEnd;
  }

  /**
   * searchItem
   * @param {string} searchString
   * @memberof TreeMapDrilldownComponent
   */
  public searchItem(searchString: string) {
    const listValuePath = this.customListValuePath || 'bucketingAttributes[0].value';
    this.listData$ = this.tcs.getTrend$(this.treeMapListConfig.widgetId).pipe(
      map((td: Trend) => {
        const trendData = cloneDeep(td);
        const fuzzyItems: FuzzyItem[] = this.fuzzyService.filter(searchString, trendData.trendResults, (result: TrendResult) =>
          get(result, listValuePath),
        );
        trendData.trendResults = _map(fuzzyItems, 'original');
        return trendData;
      }),
    );
  }

  /**
   * clickListItem
   * @param {TrendResult} item
   * @memberof TreeMapDrilldownComponent
   */
  public clickListItem(item: TrendResult) {
    this.onClickListItem.emit(item);
  }

  /**
   * getMatchedDrilldownConfig
   * @param {DrilldownWidgetConfig[]} configs
   * @param {ChartDrilldownEvent} drilldownEvent
   * @returns {DrilldownWidgetConfig}
   * @memberof TreeMapDrilldownComponent
   */
  public getMatchedDrilldownConfig(configs: DrilldownWidgetConfig[], drilldownEvent: ChartDrilldownEvent): DrilldownWidgetConfig {
    return configs?.find(
      (config: DrilldownWidgetConfig) =>
        config.drilldownEventPath && get(drilldownEvent, config.drilldownEventPath) === config.drilldownEventValue,
    );
  }
}
