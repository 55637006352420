/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  DownloadService,
  Endpoint,
  EndpointV2,
  EndpointV3,
  filterInvalidTrendResults,
  GenericResponse,
  GraphqlService,
  HttpService,
} from '@ws1c/intelligence-common';
import {
  AggregationWidget,
  AppCrashGroupRequest,
  AppCrashGroupResponse,
  AppErrorCrashUploadsTableRequest,
  AppErrorCrashUploadsTableResponse,
  AppHeGroupRequest,
  AppHeGroupResponse,
  CveSearchRequest,
  CveSearchResponse,
  Dashboard,
  DashboardConfig,
  DashboardSearchRequest,
  DashboardSearchResponse,
  DashboardTemplateImportRequest,
  DashboardThresholdSummary,
  DashboardUpdateLayoutRequest,
  DashboardView,
  Device,
  DeviceSearchResponse,
  DeviceUser,
  Entity,
  GenericSearchRequest,
  GlobalSearchType,
  IncrementalLoadingTrendPreviewResponse,
  Integration,
  NetworkInsightsSearchRequest,
  NetworkInsightsSearchResponse,
  NetworkInsightsUrlResponse,
  RuleGroup,
  StandardDashboard,
  StandardDashboardRequest,
  TransferOwnershipRequest,
  Trend,
  TrendDefinition,
  TrendDefinitionIndex,
  TrendPreviewResponse,
  UserAccess,
  UserAdminAccount,
  UserSearchResponse,
  WidgetDatasetPreferenceRequest,
  WidgetPreferences,
} from '@ws1c/intelligence-models';
import { DASHBOARD_SHARED_ACCOUNTS } from './dashboard.graphql';

/**
 * Provides REST Interface for All Dashboard APIs
 *
 * @export
 * @class DashboardService
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  /**
   * Creates an instance of DashboardService.
   * @param {HttpService} httpService
   * @param {DownloadService} downloadService
   * @param {GraphqlService} graphqlService
   * @memberof DashboardService
   */
  constructor(private httpService: HttpService, private downloadService: DownloadService, private graphqlService: GraphqlService) {}

  /**
   * Create an aggregation widget.
   *
   * @param {AggregationWidget} widget
   * @returns {Observable<AggregationWidget>}
   */
  public createWidget(widget: AggregationWidget): Observable<AggregationWidget> {
    return this.httpService.post(EndpointV2.WIDGET_ROOT_V2, widget).pipe(
      map((response: any) => deserialize(AggregationWidget, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Update an aggregation widget.
   *
   * @param {AggregationWidget} widget
   * @returns {Observable<AggregationWidget>}
   */
  public updateWidget(widget: AggregationWidget): Observable<AggregationWidget> {
    return this.httpService.put(EndpointV2.WIDGET_ID(widget.id), widget).pipe(
      map((response: any) => deserialize(AggregationWidget, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Delete an aggregation widget.
   *
   * @param {string} widgetId the widget identifier.
   * @returns {Observable<any>}
   */
  public deleteWidget(widgetId: string): Observable<any> {
    return this.httpService.delete(Endpoint.WIDGET_ID(widgetId)).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get details of an aggregation widget.
   *
   * @param {string} widgetId the widget identifier.
   * @returns {Observable<AggregationWidget>}
   */
  public getWidget(widgetId: string): Observable<AggregationWidget> {
    return this.httpService.get(EndpointV2.WIDGET_ID(widgetId)).pipe(
      map((response: any) => deserialize(AggregationWidget, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Load trend of an aggregation widget.
   *
   * @param {string} widgetId the widget identifier.
   * @returns {Observable<TrendPreviewResponse>}.
   */
  public getWidgetData(widgetId: string): Observable<TrendPreviewResponse> {
    return this.httpService.get(EndpointV2.WIDGET_TRENDS(widgetId)).pipe(
      map((response: any) => deserialize(TrendPreviewResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Preview trend result of an aggregation widget.
   *
   * @param {TrendDefinition} trendDefinition
   * @returns {Observable<Trend>}
   */
  public previewWidgetData(trendDefinition: TrendDefinition): Observable<Trend> {
    return this.httpService.post(Endpoint.WIDGET_TREND_PREVIEW, trendDefinition).pipe(
      map((response: any) => deserialize(Trend, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Preview trend result of an aggregation widget V2.
   *
   * @param {TrendDefinition} trendDefinition
   * @returns {Observable<Trend>}
   */
  public previewWidgetDataV2(trendDefinition: TrendDefinition): Observable<Trend> {
    TrendDefinition.replaceIntegrationEntity(trendDefinition);
    return this.httpService.post(EndpointV2.WIDGET_TREND_PREVIEW_V2, trendDefinition).pipe(
      map((response: GenericObject) => {
        const trend = deserialize(Trend, response.data);
        trend.trendResults = filterInvalidTrendResults(trend.trendResults);
        return trend;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Create a dashboard.
   *
   * @param {Dashboard} dashboard
   * @returns {Observable<DashboardView>}
   */
  public createDashboard(dashboard: Dashboard): Observable<DashboardView> {
    return this.httpService.post(EndpointV2.DASHBOARD_ROOT, dashboard).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Update a dashboard.
   *
   * @param {Dashboard} dashboard
   * @returns {Observable<DashboardView>}
   */
  public updateDashboard(dashboard: Dashboard): Observable<DashboardView> {
    return this.httpService.put(EndpointV2.DASHBOARD_ID(dashboard.id), dashboard).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * clone a dashboard.
   *
   * @param {Dashboard} dashboard
   * @returns {Observable<DashboardView>}
   */
  public cloneDashboard(dashboard: Dashboard): Observable<DashboardView> {
    return this.httpService.post(EndpointV2.DASHBOARD_CLONE(dashboard.id, dashboard.name), {}).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * set a dashboard bookmarked true
   *
   * @param {string} id the dashboard id
   * @returns {Observable<DashboardView>}
   */
  public setDashboardBookmark(id: string): Observable<DashboardView> {
    return this.httpService.put(Endpoint.DASHBOARD_BOOKMARKS(id), {}).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * set a dashboard bookmarked false
   *
   * @param {string} id the dashboard id
   * @returns {Observable<DashboardView>}
   */
  public clearDashboardBookmark(id: string): Observable<DashboardView> {
    return this.httpService.delete(Endpoint.DASHBOARD_BOOKMARKS(id), {}).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Import Dashboards
   *
   * @param  {File[]} files
   * @returns {Observable<boolean>}
   */
  public importDashboards(files: File[]): Observable<boolean> {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append('files', file);
    });
    return this.httpService.post(EndpointV2.TEMPLATE_DASHBOARD_IMPORT, formData).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * importDashboardsByTemplateIds
   *
   * @param {string[]} templateIds
   * @returns {Observable<boolean>}
   */
  public importDashboardsByTemplateIds(templateIds: string[]): Observable<boolean> {
    return this.httpService.post(EndpointV2.TEMPLATE_DASHBOARD_IMPORT, new DashboardTemplateImportRequest({ templateIds })).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Export a dashboard
   *
   * @param {string} dashboardId the dashboard identifier.
   * @returns {Observable<GenericObject>}
   */
  public exportDashboard(dashboardId: string): Observable<GenericObject> {
    return this.httpService.get(EndpointV2.DASHBOARD_EXPORT(dashboardId)).pipe(
      map((response: GenericObject) => response.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Delete a dashboard.
   *
   * @param {string} dashboardId the dashboard identifier.
   * @returns {Observable<any>}
   */
  public deleteDashboard(dashboardId: string): Observable<any> {
    return this.httpService.delete(Endpoint.DASHBOARD_ID(dashboardId)).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get details of a dashboard.
   *
   * @param {string} dashboardId the dashboard identifier.
   * @returns {Observable<DashboardView>}
   */
  public getDashboard(dashboardId: string): Observable<DashboardView> {
    return this.httpService.get(EndpointV2.DASHBOARD_ID(dashboardId)).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getDashboardByIncidentId
   * @param {string} incidentId
   * @returns {Observable<Dashboard>}
   */
  public getDashboardByIncidentId(incidentId: string): Observable<Dashboard> {
    return this.httpService.get(Endpoint.DASHBOARD_BY_INCIDENT_ID(incidentId)).pipe(
      map((response: GenericResponse) => deserialize(Dashboard, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * new dashboard API with full paging, sorting & filtering
   *
   * @param {DashboardSearchRequest} searchRequest
   * @returns {Observable<DashboardSearchResponse>}
   * @memberof DashboardService
   */
  public searchDashboards(searchRequest: DashboardSearchRequest): Observable<DashboardSearchResponse> {
    return this.httpService.post(Endpoint.DASHBOARD_SEARCH, searchRequest).pipe(
      map((response: any) => deserialize(DashboardSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Update dashboard layout.
   *
   * @param {string} dashboardId
   * @param {DashboardUpdateLayoutRequest} newLayout
   * @returns {Observable<DashboardView>}
   */
  public updateDashboardLayout(dashboardId: string, newLayout: DashboardUpdateLayoutRequest): Observable<DashboardView> {
    return this.httpService.put(EndpointV2.DASHBOARD_LAYOUT(dashboardId), newLayout).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Update dashboard thumbnail.
   *
   * @param {AggregationWidget} widget
   * @returns {Observable<DashboardView>}
   */
  public setDashboardThumbnail(widget: AggregationWidget): Observable<DashboardView> {
    return this.httpService.put(EndpointV2.DASHBOARD_THUMBNAIL(widget.dashboardId, widget.id), {}).pipe(
      map((response: any) => deserialize(DashboardView, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Loads standard dashboard defintion by type
   *
   * @param {StandardDashboardRequest} request
   * @param {boolean} maskAttribute
   * @param {boolean} isUemV2Enabled
   * @returns {Observable<StandardDashboard>}
   * @memberof DashboardService
   */
  public getStandardDashboardByType(
    request: StandardDashboardRequest,
    maskAttribute: boolean = true,
    isUemV2Enabled?: boolean,
  ): Observable<StandardDashboard> {
    // Hack for v2. Modify the global filter to replace specific integration and entity
    const ruleGroup = maskAttribute
      ? RuleGroup.modifyRuleForStandardDashboardV2(request.globalFilter?.ruleGroup)
      : request.globalFilter?.ruleGroup;
    request = new StandardDashboardRequest(
      request.standardDashboardType,
      ruleGroup,
      request.globalFilter?.dateRange,
      request.additionalParams,
    );
    const endpoint = isUemV2Enabled ? EndpointV3.STANDARD_DASHBOARD_BY_TYPE : EndpointV2.STANDARD_DASHBOARD_BY_TYPE;
    return this.httpService.post(endpoint(request.standardDashboardType), request).pipe(
      map((response: any) => deserialize(StandardDashboard, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieve data for multiple widgets with single request
   *
   * @param {TrendDefinitionIndex} trendDefinitionIndex
   * @param {boolean} isV2
   * @returns {Observable<Map<string, Trend>>}
   * @memberof DashboardService
   */
  public getStandardWidgetsData(trendDefinitionIndex: TrendDefinitionIndex, isV2: boolean = false): Observable<Map<string, Trend>> {
    // Hack to fix global filter issue until we figure out a proper solution with backend
    const integrationEntityRegex = new RegExp(`${DashboardConfig.Integration}.${DashboardConfig.Entity}.`, 'g');
    Object.keys(trendDefinitionIndex).forEach((widgetName: string) => {
      const trendDefinition = trendDefinitionIndex[widgetName];
      // for v1 (only SECURITY_THREAT widgets) pass only the attributeName
      if (!isV2) {
        trendDefinition.filter = trendDefinition.filter?.replace(integrationEntityRegex, '');
      }
      TrendDefinition.replaceIntegrationEntity(trendDefinition);
    });
    const endpoint = isV2 ? EndpointV2.STANDARD_DASHBOARD_WIDGETS_PREVIEW : Endpoint.STANDARD_DASHBOARD_WIDGETS_PREVIEW;
    return this.httpService.post(endpoint, trendDefinitionIndex).pipe(
      map((response: any) => {
        const results = new Map<string, Trend>();
        const data = response.data;
        Object.keys(data).forEach((key) => {
          // API is returning invalid trendResults (INTEL-14715), this filters those out until a proper backend fix
          const trend = deserialize(Trend, data[key]);
          trend.trendResults = filterInvalidTrendResults(trend.trendResults);
          results.set(key, trend);
        });
        return results;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Loads dashboard widgets by name
   *
   * @param {GenericSearchRequest} request
   * @returns {Observable<StandardDashboard>}
   * @memberof DashboardService
   */
  public getDashboardWidgetsByName(request: GenericSearchRequest): Observable<DashboardView[]> {
    return this.httpService.post(EndpointV2.DASHBOARD_WIDGETS, request).pipe(
      map((response: any) => {
        const dashboards: DashboardView[] = [];
        const data = response.data;
        Object.keys(data).forEach((key) => {
          dashboards.push(deserialize(DashboardView, data[key]));
        });
        return dashboards;
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Loads devices by name
   *
   * @param {GenericSearchRequest} request
   * @returns {Observable<Device[]>}
   * @memberof DashboardService
   */
  public getDevicesByName(request: GenericSearchRequest): Observable<Device[]> {
    return this.httpService.post(Endpoint.GLOBAL_SEARCH(GlobalSearchType.DEVICE, request.searchTerm.value), null).pipe(
      map((response: any) => deserialize(DeviceSearchResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Loads users by name
   *
   * @param {GenericSearchRequest} request
   * @returns {Observable<DeviceUser[]>}
   * @memberof DashboardService
   */
  public getUsersByName(request: GenericSearchRequest): Observable<DeviceUser[]> {
    return this.httpService.post(Endpoint.GLOBAL_SEARCH(GlobalSearchType.USER, request.searchTerm.value), null).pipe(
      map((response: any) => deserialize(UserSearchResponse, response).data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getCveSearchData
   * @param {CveSearchRequest} request
   * @returns {Observable<CveSearchResponse>}
   * @memberof DashboardService
   */
  public getCveSearchData(request: CveSearchRequest = new CveSearchRequest()): Observable<CveSearchResponse> {
    const endpoint = Endpoint.GET_CVE_BY_INTEGRATION_AND_ENTITY(Integration.AIRWATCH, Entity.WINDOWSPATCH.toLowerCase());
    return this.httpService.post(endpoint, request).pipe(
      map((response: any) => deserialize(CveSearchResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getNetworkInsightsData
   * @param {NetworkInsightsSearchRequest} request
   * @returns {Observable<NetworkInsightsSearchResponse>}
   * @memberof DashboardService
   */
  public getNetworkInsightsData(request: NetworkInsightsSearchRequest): Observable<NetworkInsightsSearchResponse> {
    return this.httpService.post(EndpointV2.NETWORK_INSIGHTS_SEARCH, request).pipe(
      map((response: any) => deserialize(NetworkInsightsSearchResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getNetworkInsightsUrls
   * @param {string} appId
   * @param {string} host
   * @returns {Observable<NetworkInsightsUrlResponse>}
   * @memberof DashboardService
   */
  public getNetworkInsightsUrls(appId: string, host: string): Observable<NetworkInsightsUrlResponse> {
    return this.httpService.get(Endpoint.NETWORK_INSIGHTS_URLS(appId, host)).pipe(
      map((response: any) => deserialize(NetworkInsightsUrlResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getThresholdData
   * @param  {string} standardDashboardName
   * @returns {Observable<DashboardThresholdSummary>}
   * @memberof DashboardService
   */
  public getThresholdData(standardDashboardName: string): Observable<DashboardThresholdSummary> {
    return this.httpService.get(Endpoint.DASHBOARD_THRESHOLD_NAME(standardDashboardName)).pipe(
      map((response: any) => deserialize(DashboardThresholdSummary, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * saveDashboardThresholdSummary
   * @param  {DashboardThresholdSummary} dashboardThresholdSummary
   * @returns {Observable<any>}
   * @memberof DashboardService
   */
  public saveDashboardThresholdSummary(dashboardThresholdSummary: DashboardThresholdSummary): Observable<any> {
    return this.httpService
      .put(Endpoint.DASHBOARD_THRESHOLD_NAME(dashboardThresholdSummary.standardDashboardType), dashboardThresholdSummary)
      .pipe(
        map((response: any) => deserialize(DashboardThresholdSummary, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * uploadAndroidCrashMapping
   * @param {File[]} files
   * @param {string} appId
   * @param {string} appVersion
   * @returns {Observable<any>}
   * @memberof DashboardService
   */
  public uploadAndroidCrashMapping(files: File[], appId: string, appVersion: string): Observable<any> {
    const crashEndpoint = Endpoint.GET_CRASH_UPLOAD_ANDROID(appId, appVersion);
    const formData = new FormData();
    formData.append('user-file', files[0]);
    return this.httpService.post(crashEndpoint, formData).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * uploadAppleCrashMapping
   * @param {File[]} files
   * @param {string} appId
   * @param {string} appVersion
   * @returns {Observable<any>}
   * @memberof DashboardService
   */
  public uploadAppleCrashMapping(files: File[], appId: string, appVersion: string): Observable<any> {
    const crashEndpoint = Endpoint.GET_CRASH_UPLOAD_IOS(appId, appVersion);
    const formData = new FormData();
    formData.append('user-file', files[0]);
    return this.httpService.post(crashEndpoint, formData).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAppErrorCrashTable
   * @param {AppCrashGroupRequest} appCrashGroupRequest
   * @returns {Observable<AppCrashGroupResponse>}
   * @memberof DashboardService
   */
  public getAppErrorCrashTable(appCrashGroupRequest: AppCrashGroupRequest): Observable<AppCrashGroupResponse> {
    return this.httpService.post(EndpointV2.GET_CRASH_GROUPS, appCrashGroupRequest).pipe(
      map((response: any) => deserialize(AppCrashGroupResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAppErrorHeTable
   * @param {AppHeGroupResponse} appHeGroupRequest
   * @returns {Observable<AppCrashGroupResponse>}
   * @memberof DashboardService
   */
  public getAppErrorHeTable(appHeGroupRequest: AppHeGroupRequest): Observable<AppHeGroupResponse> {
    return this.httpService.post(EndpointV2.GET_HE_GROUPS, appHeGroupRequest).pipe(
      map((response: any) => deserialize(AppHeGroupResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAppErrorCrashUploadsTable
   * @param {AppErrorCrashUploadsTableRequest} request
   * @returns {Observable<AppErrorCrashUploadsTableResponse>}
   * @memberof DashboardService
   */
  public getAppErrorCrashUploadsTable(request: AppErrorCrashUploadsTableRequest): Observable<AppErrorCrashUploadsTableResponse> {
    return this.httpService.post(Endpoint.CRASH_UPLOAD_SEARCH, request).pipe(
      map((response: any) => deserialize(AppErrorCrashUploadsTableResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * downloadAppErrorCrashUpload
   * @param {string} uploadFileId
   * @returns {Observable<boolean>}
   * @memberof DashboardService
   */
  public downloadAppErrorCrashUpload(uploadFileId: string): Observable<boolean> {
    this.downloadService.download(Endpoint.DOWNLOAD_CRASH_FILE(uploadFileId));
    return of(true);
  }

  /**
   * deleteAppErrorCrashUpload
   * @param {string} uploadId
   * @returns {Observable<any>}
   * @memberof DashboardService
   */
  public deleteAppErrorCrashUpload(uploadId: string): Observable<any> {
    return this.httpService.delete(Endpoint.DELETE_CRASH_UPLOAD(uploadId)).pipe(
      map((response: any) => response),
      catchError(requestErrorHandler),
    );
  }

  /**
   * shareDashboard
   * @param {string} dashboardId
   * @param {UserAccess[]} userShareDetails
   * @returns {Observable<boolean>}
   * @memberof DashboardService
   */
  public shareDashboard(dashboardId: string, userShareDetails: UserAccess[]): Observable<boolean> {
    return this.httpService.put(Endpoint.DASHBOARD_SHARE(dashboardId), userShareDetails).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getDashboardRecipients
   * @param {string} id
   * @returns {Observable<UserAdminAccount[]>}
   * @memberof DashboardService
   */
  public getDashboardRecipients(id: string): Observable<UserAdminAccount[]> {
    return this.graphqlService.query(DASHBOARD_SHARED_ACCOUNTS, { id }).pipe(
      map((response: GenericObject) => {
        return response.dashboardSharedAccounts.details.map((accountAccess: GenericObject) => deserialize(UserAdminAccount, accountAccess));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * transferOwnershipForDashboards
   * @param {TransferOwnershipRequest} transferOwnershipRequest
   * @returns {Observable<any>}
   * @memberof DashboardService
   */
  public transferOwnershipForDashboards(transferOwnershipRequest: TransferOwnershipRequest): Observable<any> {
    return this.httpService.put(Endpoint.DASHBOARDS_TRANSFER_OWNERSHIP, transferOwnershipRequest).pipe(catchError(requestErrorHandler));
  }

  /**
   * getDefaultAttributesForIntegrationEntity
   * @param {string} integration
   * @param {string} entity
   * @param {boolean} ignoreCrossEntity
   * @returns {Observable<string[]>}
   * @memberof DashboardService
   */
  public getDefaultAttributesForIntegrationEntity(integration: string, entity: string, ignoreCrossEntity: boolean): Observable<string[]> {
    return this.httpService.get(EndpointV2.WIDGETS_DEFAULT_ATTRIBUTES(integration, entity, ignoreCrossEntity)).pipe(
      map((response: GenericObject) => response.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * saveWidgetColumns
   * @param {string[]} columns
   * @param {string} widgetId
   * @param {string} dashboardId
   * @returns {Observable<boolean>}
   * @memberof DashboardService
   */
  public saveWidgetColumns(columns: string[], widgetId: string, dashboardId: string): Observable<boolean> {
    return this.httpService
      .post(Endpoint.USER_WIDGET_PREFERENCE(widgetId, dashboardId), {
        data_projection_fields: columns,
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * saveWidgetDatasetPreferences
   * @param {string} widgetId
   * @param {string} dashboardId
   * @param {WidgetDatasetPreferenceRequest[]} widgetDatasetPreferenceRequests
   * @returns {Observable<boolean>}
   * @memberof DashboardService
   */
  public saveWidgetDatasetPreferences(
    widgetId: string,
    dashboardId: string,
    widgetDatasetPreferenceRequests: WidgetDatasetPreferenceRequest[],
  ): Observable<boolean> {
    return this.httpService.post(Endpoint.USER_WIDGETSET_PREFERENCE(widgetId, dashboardId), widgetDatasetPreferenceRequests).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * saveWidgetPreferences
   * @param {WidgetPreferences} widgetPreferences
   * @param {string} widgetId
   * @param {string} dashboardId
   * @returns {Observable<boolean>}
   * @memberof DashboardService
   */
  public saveWidgetPreferences(widgetPreferences: WidgetPreferences, widgetId: string, dashboardId: string): Observable<boolean> {
    return this.httpService.post(Endpoint.USER_WIDGET_PREFERENCE(widgetId, dashboardId), widgetPreferences).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getQueryIdForListOfWidgetIds
   * @param {string[]} widgetIds
   * @returns {Observable<string>}
   * @memberof DashboardService
   */
  public getQueryIdForListOfWidgetIds(widgetIds: string[]): Observable<string> {
    return this.httpService.post(EndpointV2.QUERY_ID_BY_WIDGET_IDS, widgetIds).pipe(
      map((response: GenericObject) => response?.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getQueryIdForTrendDefinitions
   * @param {Record<string, TrendDefinition>} idVsTrendDefinitionMap
   * @returns {Observable<string>}
   * @memberof DashboardService
   */
  public getQueryIdForTrendDefinitions(idVsTrendDefinitionMap: Record<string, TrendDefinition>): Observable<string> {
    return this.httpService.post(EndpointV2.QUERY_ID_BY_TREND_DEFINITIONS, idVsTrendDefinitionMap).pipe(
      map((response: GenericObject) => response?.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getWidgetDataByQueryId
   * @param {string} queryId
   * @returns {Observable<IncrementalLoadingTrendPreviewResponse>}
   * @memberof DashboardService
   */
  public getWidgetDataByQueryId(queryId: string): Observable<IncrementalLoadingTrendPreviewResponse> {
    return this.httpService.get(EndpointV2.WIDGET_DATA_BY_QUERY_ID(queryId)).pipe(
      map((response: GenericObject) => deserialize(IncrementalLoadingTrendPreviewResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * cancelPollingByQueryId
   * @param {string} queryId
   * @returns {Observable<boolean>}
   * @memberof DashboardService
   */
  public cancelPollingByQueryId(queryId: string): Observable<boolean> {
    return this.httpService.post(EndpointV2.CANCEL_POLLING_BY_QUERY_ID(queryId), undefined).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}
