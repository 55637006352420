/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';
import { DeemPlaybookStepItem } from './deem-playbook-step-item.model';

/**
 * DeemPlaybookStep
 * @export
 * @class DeemPlaybookStep
 * @extends {UserTouchDetails}
 */
@Serializable
export class DeemPlaybookStep extends UserTouchDetails {
  @JsonProperty('step_id')
  public id: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('source_object_id')
  public sourceObjectId: string = undefined;

  @JsonProperty('source_object_type')
  public sourceObjectType: string = undefined;

  @JsonProperty({ name: 'components', cls: DeemPlaybookStepItem })
  public components: DeemPlaybookStepItem[] = undefined;

  @JsonProperty('open_duration')
  public openDuration: number = undefined;

  @JsonProperty('average_open_duration_millis')
  public averageOpenDurationMillis: number = undefined;

  @JsonProperty({ name: 'last_visited_at', customConverter: dateFormatConverter })
  public lastVisitedAt: number = undefined;

  /**
   * constructor
   * @param {Partial<DeemPlaybookStep>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStep>>) {
    super();
    Object.assign(this, ...args);
  }
}
