/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  each,
  every,
  get,
  isBoolean,
  isEmpty,
  isEqual,
  isNumber,
  isUndefined,
  keyBy,
  last,
  pickBy,
  remove,
  uniqBy,
  uniqWith,
  without,
} from 'lodash-es';

import {
  AggregationWidget,
  AggregationWidgetChartType,
  BucketAttributeChange,
  ChartDrilldownEvent,
  COLUMN_NAMES,
  DashboardSearchRequest,
  DashboardView,
  Entity,
  getFQN,
  IncrementalLoadingResponseTrendStatus,
  Integration,
  LOAD_STATE,
  LocalDataGridSettings,
  NameValue,
  SEARCH_QUERY_FIELDS,
  SearchTerm,
  Trend,
  TrendComposer,
  TrendDateRange,
  TrendDefinition,
  TrendMode,
  WidgetDataRequest,
  WidgetDataset,
  WidgetUpdateDataRequest,
  WidgetWizardDialogMode,
} from '@ws1c/intelligence-models';
import { getWidgetAttributePreferencesForStandardDashboard, getWidgetAttributePreferencesForWidgets } from './dashboard-reducer-helper';
import { DashboardActions } from './dashboard.actions';
import { ActiveWidgetDialogMode, DashboardDialogMode, DashboardState, initialDashboardState } from './dashboard.state';

/* eslint-disable complexity */
const _reducer: ActionReducer<DashboardState> = createReducer(
  initialDashboardState,
  on(
    DashboardActions.addWidgetDrilldownTrail,
    (state: DashboardState, { drilldownTrail }: ReturnType<typeof DashboardActions.addWidgetDrilldownTrail>): DashboardState => {
      const lastDrilldownEvent: ChartDrilldownEvent = last(state.widgetDrilldownTrails)?.drilldownEvent;
      if (lastDrilldownEvent?.setFilters) {
        drilldownTrail.drilldownEvent.setFilters = uniqBy(
          [...(lastDrilldownEvent.setFilters || []), ...(drilldownTrail.drilldownEvent.setFilters || [])],
          'attribute',
        );
      }
      return {
        ...state,
        widgetDrilldownTrails: [...state.widgetDrilldownTrails, drilldownTrail],
      };
    },
  ),
  on(
    DashboardActions.clearWidgetDrilldownTrailSuccess,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetDrilldownTrails: [],
    }),
  ),
  on(
    DashboardActions.popWidgetDrilldownTrailSuccess,
    (state: DashboardState, { drilldownKey }: ReturnType<typeof DashboardActions.popWidgetDrilldownTrailSuccess>): DashboardState => {
      const currentTrailIndex = state.widgetDrilldownTrails.findIndex((item) => item.drilldownKey === drilldownKey);
      if (isNumber(currentTrailIndex)) {
        state.widgetDrilldownTrails = state.widgetDrilldownTrails.slice(0, currentTrailIndex + 1);
      }
      return {
        ...state,
        widgetDrilldownTrails: [...state.widgetDrilldownTrails],
      };
    },
  ),
  on(
    DashboardActions.setIncidentId,
    (state: DashboardState, { incidentId }: ReturnType<typeof DashboardActions.setIncidentId>): DashboardState => ({
      ...state,
      incident: {
        customDashboard: undefined,
        incidentId,
      },
    }),
  ),
  on(
    DashboardActions.clearIncidentId,
    (state: DashboardState): DashboardState => ({
      ...state,
      incident: {
        ...initialDashboardState.incident,
      },
    }),
  ),
  on(
    DashboardActions.setRootUrl,
    (state: DashboardState, { rootUrl }: ReturnType<typeof DashboardActions.setRootUrl>): DashboardState => ({
      ...state,
      rootUrl,
    }),
  ),
  on(
    DashboardActions.resetRootUrl,
    (state: DashboardState): DashboardState => ({
      ...state,
      rootUrl: initialDashboardState.rootUrl,
    }),
  ),
  on(
    DashboardActions.setIncidentCustomDashboard,
    (state: DashboardState, { dashboard }: ReturnType<typeof DashboardActions.setIncidentCustomDashboard>): DashboardState => ({
      ...state,
      incident: {
        ...state.incident,
        customDashboard: dashboard,
      },
    }),
  ),
  on(
    DashboardActions.resetIncidentCustomDashboard,
    (state: DashboardState): DashboardState => ({
      ...state,
      incident: {
        ...state.incident,
        customDashboard: initialDashboardState.incident.customDashboard,
      },
    }),
  ),
  on(
    DashboardActions.fetchIncidentDashboard,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.CLOSE,
      dashboardDialogModel: undefined,
      dashboardRecipients: [],
    }),
  ),
  on(
    DashboardActions.searchDashboards,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardSearchInProgress: true,
      globalSearchResults: [],
    }),
  ),
  on(
    DashboardActions.requestTransferOwnershipDashboards,
    (
      state: DashboardState,
      { dashboards, actionOrigin }: ReturnType<typeof DashboardActions.requestTransferOwnershipDashboards>,
    ): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.TRANSFER_OWNERSHIP,
      dashboardsDialogModel: dashboards,
      transferOwnershipActionOrigin: actionOrigin,
    }),
  ),
  on(
    DashboardActions.closeDashboardDialog,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.CLOSE,
      dashboardDialogModel: undefined,
      dashboardsDialogModel: undefined,
      transferOwnershipActionOrigin: undefined,
      dashboardRecipients: [],
      dapInfo: {
        selectedDashboardOwnersDAPIds: undefined,
        transferToOwnerDAPId: undefined,
      },
    }),
  ),
  on(
    DashboardActions.selectDashboards,
    (state: DashboardState, { selectedDashboards }: ReturnType<typeof DashboardActions.selectDashboards>): DashboardState => ({
      ...state,
      selectedDashboards,
    }),
  ),
  on(
    DashboardActions.setDefaultSearchRequest,
    (state: DashboardState, { dashboardSearchRequest }: ReturnType<typeof DashboardActions.setDefaultSearchRequest>): DashboardState => ({
      ...state,
      dashboardSearchRequest,
      isDashboardListloading: true,
    }),
  ),
  on(
    DashboardActions.setWidgetDetailTrend,
    (state: DashboardState, { trend }: ReturnType<typeof DashboardActions.setWidgetDetailTrend>): DashboardState => ({
      ...state,
      widgetDetailTrend: trend,
      widgetDetailTrendError: undefined,
    }),
  ),
  on(
    DashboardActions.setWidgetDetailDefinition,
    (state: DashboardState, { widgetDetailDefinition }: ReturnType<typeof DashboardActions.setWidgetDetailDefinition>): DashboardState => ({
      ...state,
      widgetDetailDefinition,
    }),
  ),
  on(
    DashboardActions.setDeemV2IncidentCreationInfo,
    (
      state: DashboardState,
      { widgetDetailPage, trend, widgetDetailTablePreview }: ReturnType<typeof DashboardActions.setDeemV2IncidentCreationInfo>,
    ): DashboardState => ({
      ...state,
      widgetDetailTrend: trend,
      widgetDetailDefinition: widgetDetailPage.widgetDetailDefinition,
      widgetDetailPageSkinType: widgetDetailPage.skinType,
      widgetDetailWidgetId: widgetDetailPage.widgetId,
      activeWidget: undefined,
      widgetDetailTablePreview,
    }),
  ),
  on(
    DashboardActions.setMyDashboardViewFilters,
    (state: DashboardState, props: ReturnType<typeof DashboardActions.setMyDashboardViewFilters>): DashboardState => ({
      ...state,
      widgetData: {},
      myDashboardViewFilterRuleGroup: props.ruleGroup,
      myDashboardViewTrendDateRange: props.trendDateRange,
    }),
  ),
  on(
    DashboardActions.getSelectedDashboardOwnersDAPIdsSuccess,
    (
      state: DashboardState,
      { selectedDashboardOwnersDAPIds }: ReturnType<typeof DashboardActions.getSelectedDashboardOwnersDAPIdsSuccess>,
    ): DashboardState => ({
      ...state,
      dapInfo: {
        ...state.dapInfo,
        selectedDashboardOwnersDAPIds,
      },
    }),
  ),
  on(
    DashboardActions.setTransferToOwnerDAPId,
    (state: DashboardState, { transferToOwnerDAPId }: ReturnType<typeof DashboardActions.setTransferToOwnerDAPId>): DashboardState => ({
      ...state,
      dapInfo: {
        ...state.dapInfo,
        transferToOwnerDAPId,
      },
    }),
  ),
  on(
    DashboardActions.loadRiskIndicatorDetailsSuccess,
    DashboardActions.loadRiskIndicatorDetailsFailure,
    (
      state: DashboardState,
      props: ReturnType<typeof DashboardActions.loadRiskIndicatorDetailsSuccess | typeof DashboardActions.loadRiskIndicatorDetailsFailure>,
    ): DashboardState => ({
      ...state,
      riskIndicatorDetailsByTimestampByGuid: {
        ...state.riskIndicatorDetailsByTimestampByGuid,
        [props.guid]: {
          ...state.riskIndicatorDetailsByTimestampByGuid[props.guid],
          [props.timestamp]: {
            ...get(state, ['riskIndicatorDetailsByTimestampByGuid', props.guid, props.timestamp]),
            ...keyBy(props.results, getFQN(Integration.AIRWATCH, Entity.DEVICE_RISK_SCORE, COLUMN_NAMES.byName.score_type)),
          },
        },
      },
    }),
  ),
  on(
    DashboardActions.setUserFlowsSubFilterRuleGroup,
    (state: DashboardState, { ruleGroup }: ReturnType<typeof DashboardActions.setUserFlowsSubFilterRuleGroup>): DashboardState => ({
      ...state,
      userFlowsSubFilterRuleGroup: ruleGroup,
    }),
  ),
  on(
    DashboardActions.loadNestedTrendDashboard,
    (state: DashboardState, { request }: ReturnType<typeof DashboardActions.loadNestedTrendDashboard>): DashboardState => {
      return {
        ...state,
        standardDashboardRequest: request,
      };
    },
  ),
  on(
    DashboardActions.setDashboardCrossCategory,
    (state: DashboardState, { isCrossCategory }: ReturnType<typeof DashboardActions.setDashboardCrossCategory>): DashboardState => {
      return {
        ...state,
        isCrossCategoryActive: isCrossCategory,
      };
    },
  ),
  on(
    DashboardActions.loadStandardDashboard,
    (
      state: DashboardState,
      { request, isCrossCategory = false }: ReturnType<typeof DashboardActions.loadStandardDashboard>,
    ): DashboardState => {
      return {
        ...state,
        pendingStandardDashboardRequests: state.pendingStandardDashboardRequests + 1,
        standardDashboardRequest: request,
        trendDefinitionsByStandardWidgetSubtype: {},
        currentDashboard: request.standardDashboardType,
        isCrossCategoryActive: isCrossCategory,
      };
    },
  ),
  on(
    DashboardActions.loadStandardDashboardSuccess,
    (
      state: DashboardState,
      { standardDashboard, preferenceDataNeeded = true }: ReturnType<typeof DashboardActions.loadStandardDashboardSuccess>,
    ): DashboardState => ({
      ...state,
      dashboardLastUpdated: Date.now(),
      pendingStandardDashboardRequests: state.pendingStandardDashboardRequests - 1,
      standardWidgetColumnsMapping: standardDashboard.dataProjectionFields,
      trendDefinitionsByStandardWidgetSubtype: {
        ...state.trendDefinitionsByStandardWidgetSubtype,
        ...standardDashboard.trendDefinitionMap,
      },
      widgetAttributePreferencesById: preferenceDataNeeded
        ? getWidgetAttributePreferencesForStandardDashboard(standardDashboard)
        : { ...state.widgetAttributePreferencesById },
    }),
  ),
  on(
    DashboardActions.loadStandardDashboardFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardLastUpdated: Date.now(),
      pendingStandardDashboardRequests: state.pendingStandardDashboardRequests - 1,
    }),
  ),
  on(
    DashboardActions.loadStandardDashboardData,
    DashboardActions.loadStandardDashboardDataV2,
    (state: DashboardState, { trendDefinitionIndex }: ReturnType<typeof DashboardActions.loadStandardDashboardData>): DashboardState => ({
      ...state,
      ...(!isEmpty(trendDefinitionIndex) && {
        pendingPreviewDashboardRequests: state.pendingPreviewDashboardRequests + 1,
        pendingPreviewWidgetSubtypes: [...new Set([...state.pendingPreviewWidgetSubtypes, ...Object.keys(trendDefinitionIndex)])],
      }),
    }),
  ),
  on(
    DashboardActions.loadStandardDashboardDataSuccess,
    (
      state: DashboardState,
      { widgetSubtypes, standardDashboardData }: ReturnType<typeof DashboardActions.loadStandardDashboardDataSuccess>,
    ): DashboardState => {
      const newStandardDashboardData: Map<string, Trend> = new Map([
        ...Array.from(state.standardDashboardData.entries()),
        ...Array.from(standardDashboardData.entries()),
      ]);
      return {
        ...state,
        pendingPreviewDashboardRequests: state.pendingPreviewDashboardRequests - 1,
        standardDashboardData: newStandardDashboardData,
        pendingPreviewWidgetSubtypes: without(state.pendingPreviewWidgetSubtypes, ...widgetSubtypes),
      };
    },
  ),
  on(
    DashboardActions.loadStandardDashboardDataFailure,
    (state: DashboardState, { widgetSubtypes }: ReturnType<typeof DashboardActions.loadStandardDashboardDataFailure>): DashboardState => ({
      ...state,
      pendingPreviewDashboardRequests: state.pendingPreviewDashboardRequests - 1,
      pendingPreviewWidgetSubtypes: without(state.pendingPreviewWidgetSubtypes, ...widgetSubtypes),
    }),
  ),
  on(
    DashboardActions.addVisibleWidgetSubtypes,
    (state: DashboardState, { widgetSubtypes }: ReturnType<typeof DashboardActions.addVisibleWidgetSubtypes>): DashboardState => {
      const visibleWidgetCountBySubtypes = { ...state.visibleWidgetCountBySubtypes };
      const widgetSubtypesToAdd = TrendComposer.getAllTrendDefinitionDependencies(widgetSubtypes);
      widgetSubtypesToAdd.forEach((widgetSubtype: string) => {
        visibleWidgetCountBySubtypes[widgetSubtype] = visibleWidgetCountBySubtypes[widgetSubtype]
          ? visibleWidgetCountBySubtypes[widgetSubtype] + 1
          : 1;
      });
      return {
        ...state,
        visibleWidgetCountBySubtypes,
        pendingPreviewWidgetSubtypes: [...new Set([...state.pendingPreviewWidgetSubtypes, ...Object.keys(visibleWidgetCountBySubtypes)])],
      };
    },
  ),
  on(
    DashboardActions.loadVisibleWidgetSubtypes,
    (state: DashboardState, { widgetSubtypes }: ReturnType<typeof DashboardActions.loadVisibleWidgetSubtypes>): DashboardState => {
      return {
        ...state,
        pendingPreviewWidgetSubtypes: [...new Set([...state.pendingPreviewWidgetSubtypes, ...(widgetSubtypes ?? [])])],
      };
    },
  ),
  on(
    DashboardActions.removeVisibleWidgetSubtypes,
    (state: DashboardState, { widgetSubtypes }: ReturnType<typeof DashboardActions.removeVisibleWidgetSubtypes>): DashboardState => {
      const visibleWidgetCountBySubtypes = { ...state.visibleWidgetCountBySubtypes };
      const widgetSubtypesToRemove = TrendComposer.getAllTrendDefinitionDependencies(widgetSubtypes);
      const standardDashboardData = new Map<string, Trend>(state.standardDashboardData);
      const drilldownEventsById = { ...state.drilldownEventsById };
      widgetSubtypesToRemove.forEach((widgetSubtype: string) => {
        const count = visibleWidgetCountBySubtypes[widgetSubtype] || 0;
        if (count <= 1) {
          standardDashboardData.delete(widgetSubtype);
        }
        visibleWidgetCountBySubtypes[widgetSubtype] = Math.max(0, count - 1);

        if (visibleWidgetCountBySubtypes[widgetSubtype] === 0) {
          drilldownEventsById[widgetSubtype] = undefined;
        }
      });
      return {
        ...state,
        visibleWidgetCountBySubtypes,
        standardDashboardData,
        drilldownEventsById,
      };
    },
  ),
  on(
    DashboardActions.goToWidgetDetailPage,
    (state: DashboardState): DashboardState => ({
      ...state,
      activeDashboard: null,
      widgetList: [],
    }),
  ),
  on(
    DashboardActions.requestAddWidget,
    (state: DashboardState): DashboardState => ({
      ...state,
      activeWidgetTemplate: null,
      activeWidget: null,
      widgetDataPreview: {
        success: false,
        result: null,
      } as WidgetDataRequest,
      overlayDataPreview: {
        success: false,
        result: null,
      } as WidgetDataRequest,
    }),
  ),
  on(
    DashboardActions.addWidgetSuccess,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.addWidgetSuccess>): DashboardState => ({
      ...state,
      activeDashboard: Object.assign(new DashboardView(), state.activeDashboard, {
        widgets: [...(state.activeDashboard?.widgets ?? []), widget],
      }),
      widgetList: [...state.widgetList, widget.gridConfig],
      widgetWizardDialogMode: WidgetWizardDialogMode.CLOSE,
      activeWidgetDialogMode: ActiveWidgetDialogMode.CLOSE,
      activeWidgetTemplate: null,
      addedOrDuplicated: true,
    }),
  ),
  on(
    DashboardActions.requestUpdateWidget,
    (state: DashboardState, { returnCrumbs }: ReturnType<typeof DashboardActions.requestUpdateWidget>): DashboardState => ({
      ...state,
      widgetWizardDialogMode: WidgetWizardDialogMode.EDIT,
      activeWidget: null,
      activeWidgetTemplate: null,
      widgetPreviewColumnSelection: undefined,
      returnCrumbs,
      widgetDataPreview: {
        success: false,
        result: null,
      } as WidgetDataRequest,
      overlayDataPreview: {
        success: false,
        result: null,
      } as WidgetDataRequest,
    }),
  ),
  on(
    DashboardActions.updateWidgetSuccess,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.updateWidgetSuccess>): DashboardState => {
      const updatedWidget: AggregationWidget = Object.assign(new AggregationWidget(), widget);

      let widgetList = [];
      let activeDashboard: DashboardView;

      if (state.activeDashboard) {
        const index: number = state.activeDashboard.widgets.findIndex((item) => item.id === updatedWidget.id);
        const widgets = [
          ...state.activeDashboard.widgets.slice(0, index),
          updatedWidget,
          ...state.activeDashboard.widgets.slice(index + 1),
        ];
        activeDashboard = Object.assign(new DashboardView(), state.activeDashboard, { widgets });
        widgetList = [...state.widgetList.slice(0, index), updatedWidget.gridConfig, ...state.widgetList.slice(index + 1)];
      }
      return {
        ...state,
        activeDashboard,
        activeWidget: state.activeDashboard ? undefined : updatedWidget,
        widgetList,
        widgetWizardDialogMode: WidgetWizardDialogMode.CLOSE,
        returnCrumbs: null,
      };
    },
  ),
  on(
    DashboardActions.setWizardModeToEdit,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetWizardDialogMode: WidgetWizardDialogMode.EDIT,
    }),
  ),
  on(
    DashboardActions.requestRenameWidget,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.requestRenameWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
      activeWidgetDialogMode: ActiveWidgetDialogMode.RENAME,
    }),
  ),
  on(
    DashboardActions.setActiveWidgetDialogMode,
    (state: DashboardState, { mode }: ReturnType<typeof DashboardActions.setActiveWidgetDialogMode>): DashboardState => ({
      ...state,
      activeWidgetDialogMode: mode,
    }),
  ),
  on(
    DashboardActions.requestDuplicateWidget,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.requestDuplicateWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
      activeWidgetDialogMode: ActiveWidgetDialogMode.DUPLICATE,
    }),
  ),
  on(
    DashboardActions.duplicateWidgetSuccess,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.duplicateWidgetSuccess>): DashboardState => ({
      ...state,
      activeDashboard: Object.assign(new DashboardView(), state.activeDashboard, {
        widgets: [...(state.activeDashboard?.widgets ?? []), widget],
      }),
      widgetList: [...state.widgetList, widget.gridConfig],
      widgetWizardDialogMode: WidgetWizardDialogMode.CLOSE,
      activeWidgetDialogMode: ActiveWidgetDialogMode.CLOSE,
      activeWidgetTemplate: null,
      addedOrDuplicated: true,
    }),
  ),
  on(
    DashboardActions.requestDeleteWidget,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.requestDeleteWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
      activeWidgetDialogMode: ActiveWidgetDialogMode.DELETE,
    }),
  ),
  on(DashboardActions.deleteWidgetSuccess, (state: DashboardState): DashboardState => {
    const activeDashboard = Object.assign(new DashboardView(), state.activeDashboard);
    activeDashboard.widgets = activeDashboard.widgets.filter((w) => w.id !== state.activeWidget.id);
    return {
      ...state,
      activeWidget: null,
      widgetList: state.widgetList.filter((w) => w.widget.id !== state.activeWidget.id),
      activeDashboard,
      activeWidgetDialogMode: ActiveWidgetDialogMode.CLOSE,
    };
  }),
  on(
    DashboardActions.requestCopyWidget,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.requestCopyWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
      activeWidgetDialogMode: ActiveWidgetDialogMode.COPY,
    }),
  ),
  on(
    DashboardActions.copyWidgetSuccess,
    (state: DashboardState): DashboardState => ({
      ...state,
      activeWidgetDialogMode: ActiveWidgetDialogMode.CLOSE,
    }),
  ),
  on(
    DashboardActions.requestInfoWidget,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.requestInfoWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
      activeWidgetDialogMode: ActiveWidgetDialogMode.INFO,
    }),
  ),
  on(
    DashboardActions.requestEditThemeWidget,
    (state: DashboardState, { widget, widgetTheme }: ReturnType<typeof DashboardActions.requestEditThemeWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
      activeWidgetTheme: widgetTheme,
      activeWidgetDialogMode: ActiveWidgetDialogMode.EDIT_THEME,
    }),
  ),

  on(
    DashboardActions.setActiveWidget,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.setActiveWidget>): DashboardState => ({
      ...state,
      activeWidget: widget,
    }),
  ),

  on(
    DashboardActions.updateActiveWidgetTrendDefinition,
    (
      state: DashboardState,
      { partialTrendDefinition }: ReturnType<typeof DashboardActions.updateActiveWidgetTrendDefinition>,
    ): DashboardState => {
      const trend = new Trend({
        ...state.activeWidget?.trend,
        trendDefinition: new TrendDefinition({
          ...state.activeWidget?.trend?.trendDefinition,
          ...partialTrendDefinition,
        }),
      });
      const primaryWidgetDataSet = new WidgetDataset({
        ...state.activeWidget?.mainWidget,
        trend,
      });
      const widgetDatasets =
        state.activeWidget.widgetDatasets.length > 1 ? [primaryWidgetDataSet, state.activeWidget.overlayWidget] : [primaryWidgetDataSet];
      return {
        ...state,
        activeWidget: new AggregationWidget({
          ...state.activeWidget,
          trend,
          widgetDatasets,
        }),
      };
    },
  ),

  on(
    DashboardActions.updateActiveWidgetOverlayTrendDefinition,
    (
      state: DashboardState,
      { partialTrendDefinition }: ReturnType<typeof DashboardActions.updateActiveWidgetOverlayTrendDefinition>,
    ): DashboardState => {
      const trend = new Trend({
        ...state.activeWidget?.overlayTrend,
        trendDefinition: new TrendDefinition({
          ...state.activeWidget?.overlayTrend?.trendDefinition,
          ...partialTrendDefinition,
        }),
      });
      const widgetDataset = new WidgetDataset({
        ...state.activeWidget?.overlayWidget,
        trend,
      });
      const widgetDatasets = [state.activeWidget?.mainWidget, widgetDataset];
      return {
        ...state,
        activeWidget: new AggregationWidget({
          ...state.activeWidget,
          widgetDatasets,
        }),
      };
    },
  ),

  on(DashboardActions.addOverlay, (state: DashboardState, { widgetDataset }: ReturnType<typeof DashboardActions.addOverlay>) => {
    return {
      ...state,
      activeWidget: new AggregationWidget({
        ...state.activeWidget,
        widgetDatasets: [state.activeWidget.mainWidget, widgetDataset],
      }),
    };
  }),

  on(DashboardActions.removeOverlay, (state: DashboardState) => {
    return {
      ...state,
      activeWidget: new AggregationWidget({
        ...state.activeWidget,
        widgetDatasets: [state.activeWidget.mainWidget],
      }),
      overlayDataPreview: {
        success: false,
        result: null,
      } as WidgetDataRequest,
    };
  }),

  on(
    DashboardActions.setPrecomputedAggregationId,
    (
      state: DashboardState,
      { precomputedAggregationId, forOverlay = false }: ReturnType<typeof DashboardActions.setPrecomputedAggregationId>,
    ): DashboardState => {
      let activeWidget;
      if (forOverlay) {
        const trend = new Trend({
          ...state.activeWidget?.overlayTrend,
          precomputedAggregationId,
        });
        const widgetDatasets = [
          state.activeWidget?.mainWidget,
          new WidgetDataset({
            ...state.activeWidget?.overlayWidget,
            trend,
          }),
        ];
        activeWidget = new AggregationWidget({
          ...state.activeWidget,
          widgetDatasets,
        });
      } else {
        const trend = new Trend({
          ...state.activeWidget?.trend,
          precomputedAggregationId,
        });
        const primaryWidgetDataSet = new WidgetDataset({
          ...state.activeWidget?.mainWidget,
          trend,
        });
        const widgetDatasets =
          state.activeWidget?.widgetDatasets?.length > 1
            ? [primaryWidgetDataSet, state.activeWidget?.overlayWidget]
            : [primaryWidgetDataSet];
        activeWidget = new AggregationWidget({
          ...state.activeWidget,
          trend,
          widgetDatasets,
        });
      }
      return {
        ...state,
        activeWidget,
      };
    },
  ),

  on(
    DashboardActions.loadWidgetSuccess,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.loadWidgetSuccess>): DashboardState => ({
      ...state,
      activeWidget: widget,
      widgetAttributePreferencesById: {
        ...state.widgetAttributePreferencesById,
        ...getWidgetAttributePreferencesForWidgets([widget]),
      },
    }),
  ),
  on(
    DashboardActions.unsetActiveWidget,
    (state: DashboardState): DashboardState => ({
      ...state,
      activeWidget: undefined,
    }),
  ),
  on(
    DashboardActions.loadWidgetData,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.loadWidgetData>): DashboardState => ({
      ...state,
      webErrorsByWidgetId: {
        ...state.webErrorsByWidgetId,
        [widget?.id]: undefined,
      },
    }),
  ),
  on(
    DashboardActions.loadWidgetDataById,
    (state: DashboardState, { widgetId }: ReturnType<typeof DashboardActions.loadWidgetDataById>): DashboardState => ({
      ...state,
      webErrorsByWidgetId: {
        ...state.webErrorsByWidgetId,
        [widgetId]: undefined,
      },
    }),
  ),
  on(
    DashboardActions.loadWidgetDataByIdSuccess,
    (state: DashboardState, { widget, trendPreview }: ReturnType<typeof DashboardActions.loadWidgetDataByIdSuccess>): DashboardState => {
      const widgetData = Object.assign({}, state.widgetData);
      each(trendPreview?.trendById, (trend: Trend, id: string) => {
        widgetData[id] = {
          success: true,
          result: trend,
        } as WidgetDataRequest;
      });
      return {
        ...state,
        widgetData,
        widgetsById: {
          ...state.widgetsById,
          [widget.id]: widget,
        },
        widgetAttributePreferencesById: {
          ...state.widgetAttributePreferencesById,
          ...getWidgetAttributePreferencesForWidgets([widget]),
        },
      };
    },
  ),
  on(
    DashboardActions.loadWidgetDataSuccess,
    (state: DashboardState, { widget, trend, overlayTrend }: ReturnType<typeof DashboardActions.loadWidgetDataSuccess>): DashboardState => {
      const widgetData = Object.assign({}, state.widgetData);
      if (trend) {
        widgetData[widget.mainWidgetId] = {
          chartTitle: widget.name,
          chartType: AggregationWidgetChartType[widget.chartType],
          success: true,
          result: trend,
        } as WidgetDataRequest;
      }

      if (overlayTrend) {
        widgetData[widget.overlayId] = {
          chartTitle: widget.name,
          chartType: AggregationWidgetChartType.LINE,
          success: true,
          result: overlayTrend,
        } as WidgetDataRequest;
      }
      return {
        ...state,
        widgetData,
        widgetAttributePreferencesById: {
          ...state.widgetAttributePreferencesById,
          ...getWidgetAttributePreferencesForWidgets([widget]),
        },
      };
    },
  ),
  on(
    DashboardActions.loadWidgetDataFailure,
    (state: DashboardState, { widget, error }: ReturnType<typeof DashboardActions.loadWidgetDataFailure>): DashboardState => {
      const widgetData = Object.assign({}, state.widgetData);
      widgetData[widget.id] = {
        chartTitle: widget.name,
        chartType: AggregationWidgetChartType[widget.chartType],
        success: false,
        result: null,
      };
      return {
        ...state,
        widgetData,
        webErrorsByWidgetId: {
          ...state.webErrorsByWidgetId,
          [widget.id]: error,
        },
      };
    },
  ),
  on(
    DashboardActions.updatePreviewChartType,
    (state: DashboardState, { chartType }: ReturnType<typeof DashboardActions.updatePreviewChartType>): DashboardState => {
      const preview = state.widgetDataPreview || ({} as WidgetDataRequest);
      let widgetDatasets = state?.activeWidget?.widgetDatasets;
      if (state?.activeWidget?.widgetDatasets?.length) {
        const updatedWidgetDataset = new WidgetDataset({
          ...state?.activeWidget.mainWidget,
          chartType: AggregationWidgetChartType[chartType],
        });
        widgetDatasets = widgetDatasets.length > 1 ? [updatedWidgetDataset, state.activeWidget.overlayWidget] : [updatedWidgetDataset];
      }
      return {
        ...state,
        widgetDataPreview: {
          ...preview,
          chartType: chartType || preview.chartType,
        },
        activeWidget: new AggregationWidget({
          ...state.activeWidget,
          chartType: AggregationWidgetChartType[chartType],
          widgetDatasets,
        }),
      };
    },
  ),
  on(
    DashboardActions.loadActiveDashboard,
    (state: DashboardState): DashboardState => ({
      ...state,
      activeDashboard: null,
      widgetList: [],
    }),
  ),
  on(
    DashboardActions.loadActiveDashboardSuccess,
    (state: DashboardState, { dashboardView }: ReturnType<typeof DashboardActions.loadActiveDashboardSuccess>): DashboardState => ({
      ...state,
      isDashboardLoading: false,
      activeDashboard: dashboardView,
      widgetList: dashboardView.widgets.map((item: AggregationWidget) => item.gridConfig),
      activeWidget:
        state.activeWidget &&
        new AggregationWidget({
          ...state.activeWidget,
          dashboardName: dashboardView.name,
        }),
      widgetAttributePreferencesById: getWidgetAttributePreferencesForWidgets(dashboardView.widgets),
    }),
  ),
  on(
    DashboardActions.loadActiveDashboardFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardLoading: false,
    }),
  ),
  on(
    DashboardActions.requestRefreshDashboard,
    (state: DashboardState, { refresh }: ReturnType<typeof DashboardActions.requestRefreshDashboard>): DashboardState => ({
      ...state,
      lastRefreshTime: refresh ? new Date().getTime() : null,
    }),
  ),
  on(
    DashboardActions.clearWidgetWizard,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetWizardDialogMode: WidgetWizardDialogMode.CLOSE,
      activeWidgetDialogMode: ActiveWidgetDialogMode.CLOSE,
    }),
  ),
  on(
    DashboardActions.changeDashboardLayout,
    (state: DashboardState, { widgetList }: ReturnType<typeof DashboardActions.changeDashboardLayout>): DashboardState => ({
      ...state,
      widgetList,
    }),
  ),
  on(
    DashboardActions.changeDashboardLayoutSuccess,
    (state: DashboardState): DashboardState => ({
      ...state,
      layoutEditConfirm: false,
    }),
  ),
  on(
    DashboardActions.changeDashboardLayoutFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetList: [],
    }),
  ),
  on(
    DashboardActions.loadDashboardFilter,
    (state: DashboardState, { searchRequest }: ReturnType<typeof DashboardActions.loadDashboardFilter>): DashboardState => ({
      ...state,
      isDashboardListloading: true,
      dashboardSearchRequest: searchRequest,
    }),
  ),
  on(
    DashboardActions.loadDashboardFilterSuccess,
    (state: DashboardState, { searchResponse }: ReturnType<typeof DashboardActions.loadDashboardFilterSuccess>): DashboardState => ({
      ...state,
      isDashboardListloading: false,
      dashboardSearchResponse: searchResponse,
      selectedDashboards: [],
    }),
  ),
  on(
    DashboardActions.loadDashboardList,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardListloading: true,
      activeDashboard: null,
      widgetList: [],
    }),
  ),
  on(
    DashboardActions.loadDashboardListSuccess,
    (state: DashboardState, { searchResponse }: ReturnType<typeof DashboardActions.loadDashboardListSuccess>): DashboardState => ({
      ...state,
      isDashboardListloading: false,
      dashboardSearchResponse: searchResponse,
      selectedDashboards: [],
    }),
  ),
  on(
    DashboardActions.loadDashboardListFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardListloading: false,
      dashboardSearchResponse: undefined,
    }),
  ),
  on(
    DashboardActions.requestCloneDashboard,
    (state: DashboardState, { dashboard }: ReturnType<typeof DashboardActions.requestCloneDashboard>): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.DUPLICATE,
      dashboardDialogModel: dashboard,
    }),
  ),
  on(
    DashboardActions.requestCloneDashboardSuccess,
    DashboardActions.requestCloneDashboardFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.CLOSE,
      dashboardDialogModel: undefined,
    }),
  ),
  on(
    DashboardActions.requestRenameDashboard,
    (state: DashboardState, { dashboard }: ReturnType<typeof DashboardActions.requestRenameDashboard>): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.RENAME,
      dashboardDialogModel: dashboard,
    }),
  ),
  on(
    DashboardActions.requestImportDashboard,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.IMPORT,
    }),
  ),
  on(
    DashboardActions.importDashboards,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardImportInProgress: true,
    }),
  ),
  on(
    DashboardActions.importDashboardsSuccess,
    DashboardActions.importDashboardsFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardImportInProgress: false,
    }),
  ),
  on(
    DashboardActions.requestExportDashboardSuccess,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.EXPORT_SUCCESS,
    }),
  ),
  on(
    DashboardActions.requestDeleteDashboard,
    (state: DashboardState, { dashboard }: ReturnType<typeof DashboardActions.requestDeleteDashboard>): DashboardState => ({
      ...state,
      dashboardDialogMode: DashboardDialogMode.DELETE,
      dashboardDialogModel: dashboard,
    }),
  ),
  on(
    DashboardActions.requestShareDashboard,
    (state: DashboardState, { dashboard, origin }: ReturnType<typeof DashboardActions.requestShareDashboard>): DashboardState => ({
      ...state,
      actionOrigin: origin,
      dashboardDialogMode: DashboardDialogMode.SHARE,
      dashboardDialogModel: dashboard as DashboardView,
    }),
  ),
  on(
    DashboardActions.loadDashboard,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardLoading: true,
      activeDashboard: null,
      widgetList: [],
      widgetListFilter: {},
      dashboardRecipients: [],
    }),
  ),
  on(
    DashboardActions.searchDashboardsSuccess,
    (state: DashboardState, { searchResponse }: ReturnType<typeof DashboardActions.searchDashboardsSuccess>): DashboardState => ({
      ...state,
      isDashboardSearchInProgress: false,
      globalSearchResults: searchResponse.results,
    }),
  ),
  on(
    DashboardActions.searchDashboardsFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardSearchInProgress: false,
    }),
  ),
  on(
    DashboardActions.refreshDashboardList,
    DashboardActions.changePagination,
    (state: DashboardState, { page }: ReturnType<typeof DashboardActions.changePagination>): DashboardState => {
      const searchTerms = [...(state.dashboardSearchRequest.searchTerms || [])];
      const request = new DashboardSearchRequest(state.dashboardSearchRequest, page, {
        searchTerms,
      });

      return {
        ...state,
        dashboardSearchRequest: request,
      };
    },
  ),
  on(
    DashboardActions.sortDashboards,
    (state: DashboardState, { sorts }: ReturnType<typeof DashboardActions.sortDashboards>): DashboardState => {
      const searchTerms = [...(state.dashboardSearchRequest.searchTerms || [])];
      const request = new DashboardSearchRequest(state.dashboardSearchRequest, {
        sortOns: sorts,
        searchTerms,
        from: 0,
      });

      return {
        ...state,
        dashboardSearchRequest: request,
      };
    },
  ),
  on(
    DashboardActions.filterDashboards,
    (state: DashboardState, { query }: ReturnType<typeof DashboardActions.filterDashboards>): DashboardState => {
      const searchTerms = [
        new SearchTerm({
          value: query || undefined,
          fields: SEARCH_QUERY_FIELDS,
        }),
      ];
      const request = new DashboardSearchRequest({
        ...state.dashboardSearchRequest,
        searchTerms,
        from: 0,
      });

      return {
        ...state,
        dashboardSearchRequest: request,
      };
    },
  ),
  on(
    DashboardActions.filterDashboardWidgetList,
    (state: DashboardState, { filterRequest }: ReturnType<typeof DashboardActions.filterDashboardWidgetList>): DashboardState => {
      const quickFilter = filterRequest.searchTerm?.value as unknown as NameValue;
      const matchString = filterRequest.filter && filterRequest.filter.toLowerCase();
      let filterList = state.widgetListFilter;
      let copyWidgets = state.activeDashboard.widgets;
      // quick filter
      if (quickFilter) {
        const filter = {};
        const trendMode = [TrendMode[TrendMode.HISTORICAL], TrendMode[TrendMode.SNAPSHOT]];
        filter[quickFilter.name] = quickFilter.value;
        filterList = Object.assign({}, filterList, filter);
        const checkList = Object.keys(pickBy(filterList));
        const trendModeList = trendMode.filter((key: string) => checkList.includes(key));
        const integrationList = checkList.filter((key) => !trendMode.includes(key));
        if (checkList.length) {
          copyWidgets = copyWidgets.filter((item: AggregationWidget) => {
            return (
              (integrationList.length ? integrationList.includes(item.trend.trendDefinition.categoryId) : true) &&
              (trendModeList.length ? trendModeList.includes(item.trend.trendDefinition.trendMode) : true)
            );
          });
        }
      }
      // search name
      if (matchString) {
        copyWidgets = copyWidgets.filter((item: AggregationWidget) => !!item.name.toLowerCase().match(matchString));
      }
      // apply original gridConfig
      const widgetList = copyWidgets.map((item: AggregationWidget) => {
        return item.gridConfig;
      });

      return {
        ...state,
        widgetListFilter: filterList,
        widgetList,
      };
    },
  ),
  on(
    DashboardActions.resetDashboardWidgetList,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetListFilter: {},
    }),
  ),
  on(
    DashboardActions.updateDashboardListFilter,
    (state: DashboardState, { filter }: ReturnType<typeof DashboardActions.updateDashboardListFilter>): DashboardState => ({
      ...state,
      dashboardListFilter: filter,
      isDashboardListloading: true,
    }),
  ),
  on(
    DashboardActions.updateDashboardListFilterSuccess,
    (state: DashboardState, { request }: ReturnType<typeof DashboardActions.updateDashboardListFilterSuccess>): DashboardState => ({
      ...state,
      dashboardSearchRequest: request,
    }),
  ),
  on(
    DashboardActions.updateWidgetName,
    (state: DashboardState, { name }: ReturnType<typeof DashboardActions.updateWidgetName>): DashboardState => {
      if (!state.widgetUpdateData) {
        state.widgetUpdateData = new WidgetUpdateDataRequest(new AggregationWidget(), undefined);
      }
      return {
        ...state,
        widgetUpdateData: {
          ...state.widgetUpdateData,
          widget: new AggregationWidget({
            ...state.widgetUpdateData.widget,
            name,
          }),
        },
      };
    },
  ),

  on(
    DashboardActions.updateWidgetBasicInfo,
    (state: DashboardState, { name, description }: ReturnType<typeof DashboardActions.updateWidgetBasicInfo>): DashboardState => ({
      ...state,
      activeWidget: new AggregationWidget({
        ...state.activeWidget,
        name,
        description,
      }),
    }),
  ),

  on(
    DashboardActions.previewWidgetData,
    (state: DashboardState, { request, forOverlay = false }: ReturnType<typeof DashboardActions.previewWidgetData>): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          isLoadingOverlayPreviewData: true,
          overlayUpdateData: request,
        };
      }
      return {
        ...state,
        isLoadingWidgetPreviewData: true,
        widgetUpdateData: request,
      };
    },
  ),
  on(
    DashboardActions.previewWidgetDataSuccess,
    (
      state: DashboardState,
      { trend, forOverlay = false }: ReturnType<typeof DashboardActions.previewWidgetDataSuccess>,
    ): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          previewRefreshedAt: undefined,
          isLoadingOverlayPreviewData: false,
          isOverlayDetailTableLoading: true,
          overlayDataPreview: {
            success: true,
            result: trend,
            chartType: AggregationWidgetChartType[state.activeWidget?.overlayChartType] ?? state.widgetDataPreview?.chartType,
          } as WidgetDataRequest,
        };
      }
      return {
        ...state,
        previewRefreshedAt: undefined,
        isLoadingWidgetPreviewData: false,
        widgetDetailLoadingTablePreview: true,
        widgetDataPreview: {
          success: true,
          result: trend,
          chartType: AggregationWidgetChartType[state.activeWidget?.chartType] ?? state.widgetDataPreview?.chartType,
        } as WidgetDataRequest,
      };
    },
  ),
  on(
    DashboardActions.previewWidgetDataFailure,
    (state: DashboardState, { forOverlay = false }: ReturnType<typeof DashboardActions.previewWidgetDataFailure>): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          isLoadingOverlayPreviewData: false,
          overlayDataPreview: {
            success: false,
            result: null,
            chartType: state.widgetDataPreview && state.widgetDataPreview.chartType,
          } as WidgetDataRequest,
        };
      }
      return {
        ...state,
        isLoadingWidgetPreviewData: false,
        widgetDataPreview: {
          success: false,
          result: null,
          chartType: state.widgetDataPreview && state.widgetDataPreview.chartType,
        } as WidgetDataRequest,
      };
    },
  ),
  on(
    DashboardActions.loadPreviewTable,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetDetailLoadingTablePreview: true,
    }),
  ),
  on(
    DashboardActions.loadWidgetPreviewTableSuccess,
    (
      state: DashboardState,
      { response, forOverlay = false }: ReturnType<typeof DashboardActions.loadWidgetPreviewTableSuccess>,
    ): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          previewRefreshedAt: Date.now(),
          isOverlayDetailTableLoading: false,
          widgetDetailLoadingTablePreview: false,
          overlayDetailTablePreview: response,
        };
      }
      return {
        ...state,
        previewRefreshedAt: Date.now(),
        widgetDetailLoadingTablePreview: false,
        widgetDetailTablePreview: response,
      };
    },
  ),
  on(
    DashboardActions.loadWidgetPreviewTableFailure,
    (state: DashboardState, { error }: ReturnType<typeof DashboardActions.loadWidgetPreviewTableFailure>): DashboardState => ({
      ...state,
      widgetDetailLoadingTablePreview: false,
      widgetDetailTablePreviewError: error,
    }),
  ),
  on(
    DashboardActions.selectWidgetTemplate,
    (state: DashboardState, { template }: ReturnType<typeof DashboardActions.selectWidgetTemplate>): DashboardState => ({
      ...state,
      activeWidgetTemplate: template,
    }),
  ),
  on(
    DashboardActions.loadMergedStandardDashboard,
    (
      state: DashboardState,
      { isCrossCategory = false }: ReturnType<typeof DashboardActions.loadMergedStandardDashboard>,
    ): DashboardState => ({
      ...state,
      currentDashboard: undefined,
      trendDefinitionsByStandardWidgetSubtype: {},
      isCrossCategoryActive: isCrossCategory,
    }),
  ),
  on(
    DashboardActions.setWidgetIdToDashboardIdMap,
    (
      state: DashboardState,
      { widgetIdToDashboardIdMap }: ReturnType<typeof DashboardActions.setWidgetIdToDashboardIdMap>,
    ): DashboardState => ({
      ...state,
      widgetIdToDashboardIdMap,
    }),
  ),
  on(
    DashboardActions.initializeWidgetDetail,
    (state: DashboardState, { widgetDetailPage }: ReturnType<typeof DashboardActions.initializeWidgetDetail>): DashboardState => ({
      ...state,
      widgetDetailDefinition: widgetDetailPage.widgetDetailDefinition,
      widgetDetailDrilldownEvents: widgetDetailPage.drilldownEvents,
      widgetDetailWidgetId: widgetDetailPage.widgetId,
      widgetDetailPageSkinType: widgetDetailPage.skinType,
      widgetDetailLoadingTrend: true,
      widgetDetailPagedRequest: undefined,
      activeWidget: undefined,
      widgetDetailDrilldownEventIndex: undefined,
    }),
  ),
  on(
    DashboardActions.clearWidgetDetail,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetDetailDefinition: undefined,
      widgetDetailDrilldownEvents: undefined,
      widgetDetailWidgetId: undefined,
      widgetDetailPageSkinType: undefined,
      widgetDetailLoadingTrend: false,
      widgetDetailTableSelectedColumnNames: undefined,
      widgetDetailPagedRequest: undefined,
      activeWidget: undefined,
      widgetDetailUrl: undefined,
      widgetDetailDrilldownEventIndex: undefined,
      widgetTableSortOns: undefined,
      isOverlayDetailTrendLoading: false,
      widgetDetailOverlayTrend: undefined,
      isOverlayDetailTableLoading: false,
      overlayDetailTablePreview: undefined,
      overlayTableSortOns: undefined,
      overlayTablePagedRequest: undefined,
    }),
  ),
  on(
    DashboardActions.setWidgetDetailDrilldownEventIndex,
    (
      state: DashboardState,
      { drilldownEventIndex }: ReturnType<typeof DashboardActions.setWidgetDetailDrilldownEventIndex>,
    ): DashboardState => ({
      ...state,
      widgetDetailDrilldownEventIndex: drilldownEventIndex,
    }),
  ),
  on(
    DashboardActions.setWidgetDetailUrl,
    (state: DashboardState, { widgetDetailUrl }: ReturnType<typeof DashboardActions.setWidgetDetailUrl>): DashboardState => ({
      ...state,
      widgetDetailUrl,
    }),
  ),
  on(
    DashboardActions.openEditRangeDialog,
    (
      state: DashboardState,
      { isOpen, groupByLabel, editRangeWidgetId }: ReturnType<typeof DashboardActions.openEditRangeDialog>,
    ): DashboardState => ({
      ...state,
      widgetDetailIsEditRangeDialogOpen: isOpen,
      widgetEditRangeGroupByLabel: groupByLabel,
      widgetEditRangeWidgetId: editRangeWidgetId,
    }),
  ),
  on(
    DashboardActions.pushWidgetDetailDrilldownEvent,
    (state: DashboardState, { drilldownEvent }: ReturnType<typeof DashboardActions.pushWidgetDetailDrilldownEvent>): DashboardState => {
      const unfilteredDrilldownEvents = [...(state.widgetDetailDrilldownEvents || []), drilldownEvent];
      const newDrilldownEvents = getFilteredDrilldownEvents(unfilteredDrilldownEvents);
      return {
        ...state,
        widgetDetailDrilldownEvents: newDrilldownEvents,
      };
    },
  ),
  on(
    DashboardActions.setWidgetDetailPagedRequest,
    (
      state: DashboardState,
      { pagedRequest, forOverlay }: ReturnType<typeof DashboardActions.setWidgetDetailPagedRequest>,
    ): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          overlayTablePagedRequest: pagedRequest,
        };
      }
      return {
        ...state,
        widgetDetailPagedRequest: pagedRequest,
      };
    },
  ),
  on(
    DashboardActions.setWidgetPreviewPagedRequest,
    (
      state: DashboardState,
      { pagedRequest, forOverlay }: ReturnType<typeof DashboardActions.setWidgetPreviewPagedRequest>,
    ): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          overlayTablePagedRequest: pagedRequest,
        };
      }
      return {
        ...state,
        widgetPreviewPagedRequest: pagedRequest,
      };
    },
  ),
  on(
    DashboardActions.setWidgetDetailTableColumnNames,
    (
      state: DashboardState,
      { columnNames, fromDetails, widgetDatasetId, forOverlay }: ReturnType<typeof DashboardActions.setWidgetDetailTableColumnNames>,
    ): DashboardState => {
      if (widgetDatasetId) {
        const mainWidgetDataset = state.activeWidget.mainWidget;
        const overlayWidgetDataset = state.activeWidget.overlayWidget;
        const updatedMainWidgetDataset = new WidgetDataset({
          ...mainWidgetDataset,
          widgetColumns: columnNames,
        });
        // If there is overlay, update only the dataset that is modified.
        const mainDataset = forOverlay ? mainWidgetDataset : updatedMainWidgetDataset;
        const overlayDataset = forOverlay
          ? new WidgetDataset({
              ...overlayWidgetDataset,
              widgetColumns: columnNames,
            })
          : overlayWidgetDataset;
        const widgetDatasets = state.activeWidget.hasOverlay ? [mainDataset, overlayDataset] : [updatedMainWidgetDataset];
        return {
          ...state,
          previewRefreshedAt: undefined,
          activeWidget: new AggregationWidget({
            ...state.activeWidget,
            widgetDatasets,
          }),
        };
      }
      return {
        ...state,
        previewRefreshedAt: undefined,
        widgetDetailTableSelectedColumnNames: columnNames,
        activeWidget: fromDetails
          ? state.activeWidget
          : new AggregationWidget({
              ...state.activeWidget,
              widgetColumns: columnNames,
            }),
      };
    },
  ),
  on(
    DashboardActions.setWidgetTableSortOns,
    (state: DashboardState, { sortOns, forOverlay }: ReturnType<typeof DashboardActions.setWidgetTableSortOns>): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          overlayTableSortOns: sortOns,
        };
      }
      return {
        ...state,
        widgetTableSortOns: sortOns,
      };
    },
  ),
  on(
    DashboardActions.setWidgetPreviewTableSortOns,
    (state: DashboardState, { sortOns, forOverlay }: ReturnType<typeof DashboardActions.setWidgetPreviewTableSortOns>): DashboardState => {
      if (forOverlay) {
        return {
          ...state,
          overlayTableSortOns: sortOns,
        };
      }
      return {
        ...state,
        widgetPreviewTableSortOns: sortOns,
      };
    },
  ),
  on(
    DashboardActions.setWidgetRangeFilter,
    DashboardActions.setWidgetAttributePreferences,
    (
      state: DashboardState,
      {
        widgetId,
        widgetPreferences,
      }: ReturnType<typeof DashboardActions.setWidgetRangeFilter | typeof DashboardActions.setWidgetAttributePreferences>,
    ): DashboardState => ({
      ...state,
      widgetAttributePreferencesById: {
        ...state.widgetAttributePreferencesById,
        [widgetId]: [...(widgetPreferences?.widgetAttributePreferences || [])],
      },
    }),
  ),
  on(
    DashboardActions.loadCompositeWidgetDetail,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetDetailLoadingTrend: true,
      widgetDetailSelectedCompositeTableSubtype: undefined,
    }),
  ),
  on(
    DashboardActions.loadCompositeWidgetDetailSuccess,
    (
      state: DashboardState,
      { compositeTrendData }: ReturnType<typeof DashboardActions.loadCompositeWidgetDetailSuccess>,
    ): DashboardState => ({
      ...state,
      widgetDetailCompositeTrendData: compositeTrendData,
      widgetDetailLoadingTrend: false,
      widgetTableSortOns: undefined,
      widgetDetailPagedRequest: undefined,
      widgetDetailTableSelectedColumnNames: undefined,
    }),
  ),
  on(
    DashboardActions.loadCompositeWidgetDetailFailure,
    (state: DashboardState, { webError }: ReturnType<typeof DashboardActions.loadCompositeWidgetDetailFailure>): DashboardState => ({
      ...state,
      widgetDetailLoadingTrend: false,
      widgetDetailTrendError: webError,
    }),
  ),
  on(
    DashboardActions.setWidgetDetailSelectedCompositeTableSubtype,
    (
      state: DashboardState,
      { widgetSubtype, sortOns }: ReturnType<typeof DashboardActions.setWidgetDetailSelectedCompositeTableSubtype>,
    ): DashboardState => ({
      ...state,
      widgetDetailSelectedCompositeTableSubtype: widgetSubtype,
      widgetTableSortOns: sortOns,
      widgetDetailPagedRequest: undefined,
      widgetDetailTableSelectedColumnNames: undefined,
    }),
  ),
  on(DashboardActions.loadWidgetDetailTrend, (state: DashboardState): DashboardState => {
    return {
      ...state,
      widgetDetailLoadingTrend: true,
      widgetDetailTrend: undefined,
      widgetDetailTrendError: undefined,
    };
  }),
  on(
    DashboardActions.loadWidgetDetailTablePreview,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetDetailLoadingTablePreview: true,
      widgetDetailTablePreviewError: undefined,
    }),
  ),
  on(
    DashboardActions.loadWidgetDetailSuccess,
    (
      state: DashboardState,
      { widgetDetailTrend, widgetDetailOverlayTrend }: ReturnType<typeof DashboardActions.loadWidgetDetailSuccess>,
    ): DashboardState => ({
      ...state,
      widgetTableSortOns: undefined,
      widgetPreviewTableSortOns: undefined,
      widgetDetailLoadingTrend: false,
      widgetDetailTrend: widgetDetailTrend ?? state.widgetDetailTrend,
      widgetDetailOverlayTrend: widgetDetailOverlayTrend ?? state.widgetDetailOverlayTrend,
      widgetDetailPagedRequest: undefined,
      isOverlayDetailTrendLoading: false,
      overlayTableSortOns: undefined,
      overlayTablePagedRequest: undefined,
    }),
  ),
  on(
    DashboardActions.loadWidgetDetailFailure,
    (state: DashboardState, { error }: ReturnType<typeof DashboardActions.loadWidgetDetailFailure>): DashboardState => ({
      ...state,
      widgetDetailLoadingTrend: false,
      widgetDetailTrendError: error,
    }),
  ),
  on(
    DashboardActions.loadWidgetDetailTablePreviewSuccess,
    (
      state: DashboardState,
      { response, overlayResponse }: ReturnType<typeof DashboardActions.loadWidgetDetailTablePreviewSuccess>,
    ): DashboardState => ({
      ...state,
      previewRefreshedAt: Date.now(),
      widgetDetailLoadingTablePreview: false,
      isOverlayDetailTableLoading: false,
      widgetDetailTablePreview: response,
      overlayDetailTablePreview: overlayResponse,
    }),
  ),
  on(
    DashboardActions.loadWidgetDetailTablePreviewFailure,
    (state: DashboardState, { error }: ReturnType<typeof DashboardActions.loadWidgetDetailTablePreviewFailure>): DashboardState => ({
      ...state,
      widgetDetailLoadingTablePreview: false,
      widgetDetailTablePreviewError: error,
      widgetDetailTablePreview: undefined,
    }),
  ),
  on(
    DashboardActions.loadUserFlowsCrumbListSuccess,
    (
      state: DashboardState,
      { crumbListLocator, responses }: ReturnType<typeof DashboardActions.loadUserFlowsCrumbListSuccess>,
    ): DashboardState => ({
      ...state,
      userFlowsCrumbListsIndex: crumbListLocator.setCrumbResponses(state.userFlowsCrumbListsIndex, responses),
    }),
  ),
  on(
    DashboardActions.loadUserFlowsCrumbListFailure,
    (state: DashboardState, { crumbListLocator }: ReturnType<typeof DashboardActions.loadUserFlowsCrumbListFailure>): DashboardState => ({
      ...state,
      userFlowsCrumbListsIndex: crumbListLocator.setCrumbResponses(state.userFlowsCrumbListsIndex, []),
    }),
  ),
  on(
    DashboardActions.scrollToBottom,
    (state: DashboardState, { isScroll }: ReturnType<typeof DashboardActions.scrollToBottom>): DashboardState => ({
      ...state,
      scrollToBottom: isScroll,
      addedOrDuplicated: false,
    }),
  ),
  on(
    DashboardActions.layoutEditConfirm,
    (state: DashboardState, { isConfirmed }: ReturnType<typeof DashboardActions.layoutEditConfirm>): DashboardState => ({
      ...state,
      layoutEditConfirm: isConfirmed,
    }),
  ),
  on(
    DashboardActions.resetDashboardLayout,
    (state: DashboardState): DashboardState => ({
      ...state,
      layoutEditConfirm: false,
    }),
  ),
  on(
    DashboardActions.pushDrilldownEvent,
    (state: DashboardState, { widgetId, drilldownEvent }: ReturnType<typeof DashboardActions.pushDrilldownEvent>): DashboardState => {
      const widgetDrilldownEvents = state.drilldownEventsById[widgetId];
      const nextWidgetDrilldownEvents = widgetDrilldownEvents ? [...widgetDrilldownEvents, drilldownEvent] : [drilldownEvent];
      return {
        ...state,
        drilldownEventsById: {
          ...state.drilldownEventsById,
          [widgetId]: nextWidgetDrilldownEvents,
        },
      };
    },
  ),
  on(
    DashboardActions.pushGlobalFilterAsDrilldownEvent,
    (
      state: DashboardState,
      { widgetId, drilldownEvent }: ReturnType<typeof DashboardActions.pushGlobalFilterAsDrilldownEvent>,
    ): DashboardState => {
      const widgetDrilldownEvents = state.drilldownEventsById[widgetId];
      const unfilteredDrilldownEvents = widgetDrilldownEvents ? [...widgetDrilldownEvents, drilldownEvent] : [drilldownEvent];
      const newDrilldownEvents = getFilteredDrilldownEvents(unfilteredDrilldownEvents);
      return {
        ...state,
        drilldownEventsById: {
          ...state.drilldownEventsById,
          [widgetId]: newDrilldownEvents,
        },
      };
    },
  ),
  on(
    DashboardActions.pushCustomWidgetDrilldownEvent,
    (
      state: DashboardState,
      { widgetId, drilldownEvent }: ReturnType<typeof DashboardActions.pushCustomWidgetDrilldownEvent>,
    ): DashboardState => {
      const widgetDrilldownEvents = state.drilldownEventsById[widgetId];
      const nextWidgetDrilldownEvents = widgetDrilldownEvents ? [...widgetDrilldownEvents, drilldownEvent] : [drilldownEvent];
      return {
        ...state,
        drilldownEventsById: {
          ...state.drilldownEventsById,
          [widgetId]: nextWidgetDrilldownEvents,
        },
      };
    },
  ),
  on(
    DashboardActions.clearDrilldownEvents,
    (state: DashboardState, { widgetId }: ReturnType<typeof DashboardActions.clearDrilldownEvents>): DashboardState => ({
      ...state,
      drilldownEventsById: {
        ...state.drilldownEventsById,
        [widgetId]: [],
      },
    }),
  ),
  on(
    DashboardActions.clearCustomDrilldownEvents,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.clearCustomDrilldownEvents>): DashboardState => ({
      ...state,
      drilldownEventsById: {
        ...state.drilldownEventsById,
        [widget.id]: [],
      },
    }),
  ),
  on(
    DashboardActions.popDrilldownEvent,
    DashboardActions.popDrilldownEventWithoutLoad,
    (state: DashboardState, { widgetId }: ReturnType<typeof DashboardActions.popDrilldownEvent>): DashboardState => {
      const nextDrilldownEvents = [...state.drilldownEventsById[widgetId]];
      nextDrilldownEvents.pop();
      return {
        ...state,
        drilldownEventsById: {
          ...state.drilldownEventsById,
          [widgetId]: nextDrilldownEvents,
        },
      };
    },
  ),
  on(
    DashboardActions.popCustomDrilldownEvent,
    (state: DashboardState, { widget }: ReturnType<typeof DashboardActions.popCustomDrilldownEvent>): DashboardState => {
      const widgetId = widget.id;
      const nextDrilldownEvents = [...state.drilldownEventsById[widgetId]];
      nextDrilldownEvents.pop();
      return {
        ...state,
        drilldownEventsById: {
          ...state.drilldownEventsById,
          [widgetId]: nextDrilldownEvents,
        },
      };
    },
  ),
  on(
    DashboardActions.patchLocalDataGridSettings,
    (
      state: DashboardState,
      { settingId, localDataGridSettingsPatch }: ReturnType<typeof DashboardActions.patchLocalDataGridSettings>,
    ): DashboardState => {
      const nextLocalDataGridSettings = Object.assign(
        new LocalDataGridSettings(),
        state.localDataGridSettingsById[settingId],
        localDataGridSettingsPatch,
      );
      return {
        ...state,
        localDataGridSettingsById: {
          ...state.localDataGridSettingsById,
          [settingId]: nextLocalDataGridSettings,
        },
      };
    },
  ),
  on(
    DashboardActions.getDashboardRecipients,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardRecipients: [],
      isDashboardRecipientsLoading: true,
    }),
  ),
  on(
    DashboardActions.getDashboardRecipientsSuccess,
    DashboardActions.getDashboardRecipientsFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      isDashboardRecipientsLoading: false,
    }),
  ),
  on(
    DashboardActions.addDashboardRecipients,
    (state: DashboardState, { userAdminAccounts }: ReturnType<typeof DashboardActions.addDashboardRecipients>): DashboardState => ({
      ...state,
      dashboardRecipients: userAdminAccounts,
    }),
  ),
  on(
    DashboardActions.setTableTrendDefinitionsById,
    (
      state: DashboardState,
      { trendDefinitionsById }: ReturnType<typeof DashboardActions.setTableTrendDefinitionsById>,
    ): DashboardState => ({
      ...state,
      tableTrendDefinitionsById: {
        ...state.tableTrendDefinitionsById,
        ...trendDefinitionsById,
      },
    }),
  ),
  on(
    DashboardActions.setTableTrendsById,
    (state: DashboardState, { tableTrendsById }: ReturnType<typeof DashboardActions.setTableTrendsById>): DashboardState => ({
      ...state,
      tableTrendsById: {
        ...state.tableTrendsById,
        ...tableTrendsById,
      },
    }),
  ),

  on(
    DashboardActions.clearTrendDefinitionByStandardWidgetSubtype,
    (state: DashboardState): DashboardState => ({
      ...state,
      trendDefinitionsByStandardWidgetSubtype: {},
    }),
  ),

  on(
    DashboardActions.getDefaultWidgetColumns,
    (
      state: DashboardState,
      { categoryId, isCrossCategory }: ReturnType<typeof DashboardActions.getDefaultWidgetColumns>,
    ): DashboardState => ({
      ...state,
      activeCategoryId: categoryId,
      isCrossCategoryActive: isCrossCategory,
    }),
  ),

  on(
    DashboardActions.getDefaultWidgetColumnsSuccess,
    (state: DashboardState, { columns }: ReturnType<typeof DashboardActions.getDefaultWidgetColumnsSuccess>): DashboardState => {
      if (state.isCrossCategoryActive) {
        const defaultColumns = {
          ...state.defaultColumns,
          [state.activeCategoryId]: columns,
        };
        return {
          ...state,
          defaultColumns,
        };
      }
      const defaultNonJoinColumns = {
        ...state.defaultNonJoinColumns,
        [state.activeCategoryId]: columns,
      };
      return {
        ...state,
        defaultNonJoinColumns,
      };
    },
  ),

  on(
    DashboardActions.initializeIssueDetail,
    (state: DashboardState, { issueDetailPage, sortOns }: ReturnType<typeof DashboardActions.initializeIssueDetail>): DashboardState => ({
      ...state,
      widgetDetailDefinition: issueDetailPage.widgetDetailDefinition,
      widgetDetailDrilldownEvents: issueDetailPage.drilldownEvents,
      widgetDetailWidgetId: issueDetailPage.widgetId,
      widgetDetailPageSkinType: issueDetailPage.skinType,
      widgetTableSortOns: sortOns,
      widgetDetailLoadingTrend: true,
      widgetDetailPagedRequest: undefined,
    }),
  ),
  on(
    DashboardActions.loadCompositeIssueDetail,
    (state: DashboardState): DashboardState => ({
      ...state,
      widgetDetailLoadingTrend: true,
      widgetDetailSelectedCompositeTableSubtype: undefined,
    }),
  ),
  on(
    DashboardActions.loadCompositeIssueDetailSuccess,
    (
      state: DashboardState,
      { compositeTrendData }: ReturnType<typeof DashboardActions.loadCompositeIssueDetailSuccess>,
    ): DashboardState => ({
      ...state,
      widgetDetailCompositeTrendData: compositeTrendData,
      widgetDetailLoadingTrend: false,
      widgetDetailPagedRequest: undefined,
      widgetDetailTableSelectedColumnNames: undefined,
    }),
  ),
  on(
    DashboardActions.loadCompositeIssueDetailFailure,
    (state: DashboardState, { webError }: ReturnType<typeof DashboardActions.loadCompositeIssueDetailFailure>): DashboardState => ({
      ...state,
      widgetDetailLoadingTrend: false,
      widgetDetailTrendError: webError,
    }),
  ),
  on(
    DashboardActions.getIssueWidgetColumnsSuccess,
    (
      state: DashboardState,
      { columns, categoryId, isCrossCategory }: ReturnType<typeof DashboardActions.getIssueWidgetColumnsSuccess>,
    ): DashboardState => {
      const defaultColumns = {
        ...state.defaultColumns,
        [categoryId]: columns,
      };
      return {
        ...state,
        defaultColumns,
        activeCategoryId: categoryId,
        isCrossCategoryActive: isCrossCategory,
      };
    },
  ),
  on(
    DashboardActions.clearDefaultColumns,
    (state: DashboardState): DashboardState => ({
      ...state,
      defaultColumns: {},
    }),
  ),
  on(
    DashboardActions.setSelectedViewLayout,
    (state: DashboardState, { selectedViewLayout }: ReturnType<typeof DashboardActions.setSelectedViewLayout>): DashboardState => ({
      ...state,
      selectedViewLayout,
    }),
  ),
  on(
    DashboardActions.setUnMergeWidgetSubTypeList,
    (state: DashboardState, { list }: ReturnType<typeof DashboardActions.setUnMergeWidgetSubTypeList>): DashboardState => ({
      ...state,
      unMergeWidgetSubTypeList: list,
    }),
  ),
  on(
    DashboardActions.resetUnMergeWidgetSubTypeList,
    (state: DashboardState): DashboardState => ({
      ...state,
      unMergeWidgetSubTypeList: [],
    }),
  ),
  on(
    DashboardActions.setSearchDashboardType,
    (state: DashboardState, { searchType }: ReturnType<typeof DashboardActions.setSearchDashboardType>): DashboardState => ({
      ...state,
      dashboardSearchType: searchType,
    }),
  ),

  on(
    DashboardActions.importDashboardTemplates,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardTemplateImportLoadStatus: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    DashboardActions.importDashboardTemplatesSuccess,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardTemplateImportLoadStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    DashboardActions.importDashboardTemplatesFailure,
    (state: DashboardState): DashboardState => ({
      ...state,
      dashboardTemplateImportLoadStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    DashboardActions.updateTrackingIdDetailsMap,
    (state: DashboardState, { trackingIdDetailsMap }: ReturnType<typeof DashboardActions.updateTrackingIdDetailsMap>): DashboardState => {
      const newTrackingIdDetailsMap = new Map([...state.trackingIdDetailsMap, ...trackingIdDetailsMap]);

      return {
        ...state,
        trackingIdDetailsMap: newTrackingIdDetailsMap,
      };
    },
  ),

  on(
    DashboardActions.loadWidgetDataByQueryIdSuccess,
    (
      state: DashboardState,
      { trendPreviewResponse }: ReturnType<typeof DashboardActions.loadWidgetDataByQueryIdSuccess>,
    ): DashboardState => {
      const loadingStatusByTrackingIdMap = new Map(state.loadingStatusByTrackingIdMap);
      Object.keys(trendPreviewResponse?.trendById ?? {}).forEach((trackingId: string) => {
        const status = trendPreviewResponse.trendById[trackingId].status;
        loadingStatusByTrackingIdMap.set(trackingId, status);
      });
      return {
        ...state,
        loadingStatusByTrackingIdMap,
      };
    },
  ),

  on(
    DashboardActions.setTrackingIdsStatusAsInProgress,
    (state: DashboardState, { trackingIds }: ReturnType<typeof DashboardActions.setTrackingIdsStatusAsInProgress>): DashboardState => {
      const loadingStatusByTrackingIdMap = new Map(state.loadingStatusByTrackingIdMap);
      (trackingIds ?? []).forEach((trackingId: string) => {
        if (!trackingId) {
          return;
        }
        loadingStatusByTrackingIdMap.set(trackingId, IncrementalLoadingResponseTrendStatus.INPROGRESS);
      });
      return {
        ...state,
        loadingStatusByTrackingIdMap,
      };
    },
  ),
);

/**
 * Dashboard State Reducer
 * @param {DashboardState} state
 * @param {Action} action
 * @returns {DashboardState}
 */
export function dashboardState(state: DashboardState = initialDashboardState, action: Action) {
  return _reducer(state, action);
}

/**
 * isOnlySetFocusedSeriesDrilldown
 * @param  {ChartDrilldownEvent} drilldownEvent
 * @returns {boolean}
 */
function isOnlySetFocusedSeriesDrilldown(drilldownEvent: ChartDrilldownEvent): boolean {
  if (!drilldownEvent.setFocusedSeries) {
    return;
  }
  return isDrilldownEventEmpty({
    ...drilldownEvent,
    setFocusedSeries: undefined,
  });
}

/**
 * isDrilldownEventEmpty
 * @param {ChartDrilldownEvent} drilldownEvent
 * @returns {boolean}
 */
function isDrilldownEventEmpty(drilldownEvent: ChartDrilldownEvent): boolean {
  return every(drilldownEvent, (val: any) => isUndefined(val) || isEmpty(val));
}

/**
 * getFilteredDrilldownEvents
 * @param {ChartDrilldownEvent[]} unfilteredDrilldownEvents
 * @returns {ChartDrilldownEvent[]}
 */
function getFilteredDrilldownEvents(unfilteredDrilldownEvents: ChartDrilldownEvent[]): ChartDrilldownEvent[] {
  // collapses consecutive bucketAttributeChange, trendDateRangeOverride, and setFocusedSeries events
  let previousChange: BucketAttributeChange;
  let previousTrendDateRangeOverride: TrendDateRange;
  let previousInvertModeChange: boolean;
  let isPreviousOnlyFocusedSeries: boolean;
  const filteredDrilldownEvents = [];
  unfilteredDrilldownEvents.forEach((ddEvent: ChartDrilldownEvent) => {
    // removes the previous drilldown event if it's also bucketAttributeChange of the same index
    if (
      previousChange &&
      ddEvent.bucketAttributeChange &&
      previousChange.bucketAttributeIndex === ddEvent.bucketAttributeChange.bucketAttributeIndex
    ) {
      filteredDrilldownEvents.pop();
    }

    // remove all previous events that modify the filter
    if (ddEvent.setFilters || ddEvent.setRuleGroup) {
      remove(filteredDrilldownEvents, (filteredDdEvent: ChartDrilldownEvent) => {
        return Boolean(
          filteredDdEvent.selectedBuckets ||
            filteredDdEvent.addFilters ||
            filteredDdEvent.setFilters ||
            filteredDdEvent.setRuleGroup ||
            filteredDdEvent.bucketAttributeChange ||
            filteredDdEvent.setFocusedSeries,
        );
      });
    }

    // removes the previous drilldown if it's also a trendDateRange override
    if (previousTrendDateRangeOverride && ddEvent.trendDateRangeOverride) {
      filteredDrilldownEvents.pop();
    }

    // removes the previous drilldown if it's a setFocusedSeries drilldown only
    if (isPreviousOnlyFocusedSeries && isOnlySetFocusedSeriesDrilldown(ddEvent)) {
      filteredDrilldownEvents.pop();
    }

    // removes the previous drilldown if it's a invert mode override
    if (previousInvertModeChange && isBoolean(ddEvent.isInvertMode)) {
      filteredDrilldownEvents.pop();
    }

    // adds the next drilldown event, and takes some notes for the next ddEvent
    filteredDrilldownEvents.push(ddEvent);
    previousChange = ddEvent.bucketAttributeChange;
    previousTrendDateRangeOverride = ddEvent.trendDateRangeOverride;
    previousInvertModeChange = isBoolean(ddEvent.isInvertMode);
    isPreviousOnlyFocusedSeries = isOnlySetFocusedSeriesDrilldown(ddEvent);
  });

  return uniqWith(filteredDrilldownEvents, (cde1: ChartDrilldownEvent, cde2: ChartDrilldownEvent) => {
    if (cde1.bucketAttributeChange || cde2.bucketAttributeChange) {
      return false;
    }
    return isEqual(cde1, cde2);
  });
}
