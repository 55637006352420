/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Request size for a selected resource with a previous and next resource
 * @type {number}
 */
export const MARKETPLACE_PREVIEW_REQUEST_SIZE: number = 3;

/**
 * Request size for a selected resource and next resource
 * @type {number}
 */
export const MARKETPLACE_PREVIEW_FIRST_SELECTED_REQUEST_SIZE: number = 2;
