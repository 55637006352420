/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Feature } from '@ws1c/intelligence-models';
import { FeatureControl } from './feature-control.enum';

/**
 * UserScopes
 * @export
 * @class
 */
export class UserScopes {
  // Bira
  public static readonly IAM = 'dpa.bira.iam';
  public static readonly IAM_GROUPS = 'dpa.bira.iam.groups';
  public static readonly IAM_USERS = 'dpa.bira.iam.users';

  // Gangster
  public static readonly IAM_USERS_GANGSTER_READONLY = 'dpa.gangster.iam.users.readonly';

  // Pranqster
  public static readonly ACCOUNT = 'dpa.pranqster.iam.account';
  public static readonly ACCOUNT_READONLY = 'dpa.pranqster.iam.account.readonly';
  public static readonly ACCOUNT_GROUP = 'dpa.pranqster.iam.accountgroup';
  public static readonly ACCOUNT_GROUP_READONLY = 'dpa.pranqster.iam.accountgroup.readonly';
  public static readonly DATA_ACCESS_POLICY = 'dpa.pranqster.dataaccesspolicy';
  public static readonly DATA_ACCESS_POLICY_READONLY = 'dpa.pranqster.dataaccesspolicy.readonly';
  public static readonly IAM_INTEGRATION = 'dpa.pranqster.iam.integration';
  public static readonly IAM_INTEGRATION_READONLY = 'dpa.pranqster.iam.integration.readonly';
  public static readonly OAUTH = 'dpa.pranqster.oauthclient';
  public static readonly OAUTH_READONLY = 'dpa.pranqster.oauthclient.readonly';
  public static readonly PARTNER = 'dpa.pranqster.partner';
  public static readonly PARTNER_READONLY = 'dpa.pranqster.partner.readonly';
  public static readonly ROLE = 'dpa.pranqster.role';
  public static readonly ROLE_READONLY = 'dpa.pranqster.role.readonly';

  // Blantons
  public static readonly EULA = 'dpa.blantons.eula';
  public static readonly FEDERATION_SETTINGS = 'dpa.blantons.federation.settings';
  public static readonly INTEGRATION_REGISTRATION = 'dpa.blantons.integration.registration';
  public static readonly INTEGRATION_REGISTRATION_READONLY = 'dpa.blantons.integration.registration.readonly';
  public static readonly ORG_REGISTRATION = 'dpa.blantons.org.registration';
  public static readonly ORG_REGISTRATION_READONLY = 'dpa.blantons.org.registration.readonly';
  public static readonly SERVICE_LIMIT = 'dpa.blantons.service.limit';
  public static readonly TENANT_PROVISION = 'dpa.blantons.tenant.provision';
  public static readonly SOLUTION_SETTING_STEPS_READONLY = 'dpa.blantons.solution_setting.readonly';

  // Merlot
  public static readonly APP_REGISTRATION = 'dpa.merlot.appregistration';
  public static readonly APP_REGISTRATION_READONLY = 'dpa.merlot.appregistration.readonly';
  public static readonly CONTENT_SEARCH = 'dpa.merlot.integration';
  public static readonly CONTENT_SEARCH_READONLY = 'dpa.merlot.integration.readonly';
  public static readonly DASHBOARD_MERLOT_READONLY = 'dpa.merlot.dashboard.widget.readonly';
  public static readonly SOLUTION_SETTING_METRIC = 'dpa.stonewall.solution.setting.metric';
  public static readonly SOLUTION_SETTING_METRIC_READONLY = 'dpa.stonewall.solution.setting.metric.readonly';
  public static readonly QUICK_FILTER_TAGS_READONLY = 'dpa.merlot.quick.filter.tags.readonly';

  // Daytime
  public static readonly DASHBOARD = 'dpa.daytime.dashboard';
  public static readonly DASHBOARD_READONLY = 'dpa.daytime.dashboard.readonly';
  public static readonly DASHBOARD_STANDARD_READONLY = 'dpa.daytime.standard.dashboard.readonly';
  public static readonly DASHBOARD_SETTINGS = 'dpa.daytime.settings.dashboard';
  public static readonly DASHBOARD_SETTINGS_READONLY = 'dpa.daytime.settings.dashboard.readonly';
  public static readonly WIDGET = 'dpa.daytime.dashboard.widget';
  public static readonly WIDGET_READONLY = 'dpa.daytime.dashboard.widget.readonly';
  public static readonly DASHBOARD_ADMIN = 'dpa.daytime.admin.dashboard';

  // Forceofwill
  public static readonly REPORT = 'dpa.forceofwill.report';
  public static readonly REPORT_READONLY = 'dpa.forceofwill.report.readonly';
  public static readonly REPORT_SCHEDULE = 'dpa.forceofwill.report.schedule';
  public static readonly REPORT_SCHEDULE_READONLY = 'dpa.forceofwill.report.schedule.readonly';
  public static readonly REPORT_TEMPLATE = 'dpa.forceofwill.template.report';
  public static readonly USERS = 'dpa.forceofwill.users';
  public static readonly REPORT_ADMIN = 'dpa.forceofwill.admin.report';

  // Sweetwater
  public static readonly WORKFLOW = 'dpa.sweetwater.workflow';
  public static readonly WORKFLOW_READONLY = 'dpa.sweetwater.workflow.readonly';
  public static readonly SETTINGS = 'dpa.sweetwater.settings';
  public static readonly SETTINGS_READONLY = 'dpa.sweetwater.settings.readonly';
  public static readonly WORKFLOW_ADMIN = 'dpa.sweetwater.admin.workflow';

  // Scofflaw
  public static readonly INCIDENT = 'dpa.scofflaw.incident';
  public static readonly INCIDENT_READONLY = 'dpa.scofflaw.incident.readonly';
  public static readonly INSIGHT = 'dpa.scofflaw.insight';
  public static readonly INSIGHT_READONLY = 'dpa.scofflaw.insight.readonly';
  public static readonly PLAYBOOKS = 'dpa.scofflaw.playbook';

  // Kronenbourg
  public static readonly EVENT_FORWARDER = 'dpa.kronenbourg.partner';
  public static readonly EVENT_FORWARDER_READONLY = 'dpa.kronenbourg.partner.readonly';

  // Buzzkill
  public static readonly SYSTEM_LIMIT = 'dpa.buzzkill.servicelimit';

  // Scripts and Sensors
  public static readonly SCRITPS = 'dpa.morpheus.template.scripts';
  public static readonly SCRIPTS_READONLY = 'dpa.morpheus.template.scripts.readonly';
  public static readonly SENSORS = 'dpa.morpheus.template.sensors';
  public static readonly SENSORS_READONLY = 'dpa.morpheus.template.sensors.readonly';

  // Stonewall
  public static readonly SYNTHETIC_URL_MONITORING = 'dpa.stonewall.synthetic.url.monitoring';
  public static readonly SYNTHETIC_URL_MONITORING_READONLY = 'dpa.stonewall.synthetic.url.monitoring.readonly';

  // UEM Freestyle Workflows
  public static readonly UEM_FREESTYLE_WORKFLOWS = 'dpa.morpheus.template.uem_workflows';
  public static readonly UEM_FREESTYLE_WORKFLOWS_READONLY = 'dpa.morpheus.template.uem_workflows.readonly';

  // Popquiz
  public static readonly SURVEYS = 'dpa.popquiz.survey';
  public static readonly SURVEYS_READONLY = 'dpa.popquiz.survey';

  // Glenfiddich
  public static readonly INTEGRATION_STATISTICS = 'dpa.glenfiddich.statistics';
}

/**
 * Features
 * @export
 * @class
 */
export class Features {
  // Automation

  public static readonly AUTOMATION: Feature = {
    flag: FeatureControl.AUTOMATION_ENABLED,
    scope: UserScopes.WORKFLOW,
  };

  public static readonly AUTOMATION_READONLY: Feature = {
    flag: FeatureControl.AUTOMATION_ENABLED,
    scope: UserScopes.WORKFLOW_READONLY,
  };

  // Settings

  public static readonly ACCOUNT: Feature = {
    flag: FeatureControl.RBAC_ENABLED,
    scope: UserScopes.ACCOUNT,
  };

  public static readonly ACCOUNT_READONLY: Feature = {
    flag: FeatureControl.RBAC_ENABLED,
    scope: UserScopes.ACCOUNT_READONLY,
  };

  public static readonly ACCOUNT_GROUP: Feature = {
    flag: FeatureControl.RBAC_ENABLED,
    scope: UserScopes.ACCOUNT_GROUP,
  };

  public static readonly ACCOUNT_GROUP_READONLY: Feature = {
    flag: FeatureControl.RBAC_ENABLED,
    scope: UserScopes.ACCOUNT_GROUP_READONLY,
  };

  public static readonly IAM_GROUPS: Feature = {
    flag: FeatureControl.WORKSPACE_ONE_RBAC_ENABLED,
    scope: UserScopes.IAM_GROUPS,
  };

  public static readonly IAM_GROUPS_BROWNFIELD_RBAC: Feature = {
    flag: FeatureControl.WORKSPACE_ONE_BROWNFIELD_RBAC_ENABLED,
    scope: UserScopes.IAM_GROUPS,
  };

  public static readonly IAM_INTEGRATION: Feature = {
    flag: FeatureControl.AZURE_AD_ENABLED,
    scope: UserScopes.IAM_INTEGRATION,
  };

  public static readonly IAM_USERS: Feature = {
    flag: FeatureControl.WORKSPACE_ONE_RBAC_ENABLED,
    scope: UserScopes.IAM_USERS,
  };

  public static readonly IAM_USERS_BROWNFIELD_RBAC: Feature = {
    flag: FeatureControl.WORKSPACE_ONE_BROWNFIELD_RBAC_ENABLED,
    scope: UserScopes.IAM_USERS,
  };

  public static readonly ACCOUNT_ROLES: Feature = {
    flag: FeatureControl.CUSTOM_ROLE_ENABLED,
    scope: UserScopes.ROLE,
  };

  public static readonly ACCOUNT_ROLES_READONLY: Feature = {
    flag: FeatureControl.CUSTOM_ROLE_ENABLED,
    scope: UserScopes.ROLE_READONLY,
  };

  public static readonly PARTNER: Feature = {
    flag: FeatureControl.TRUST_NETWORK_ENABLED,
    scope: UserScopes.PARTNER,
  };

  public static readonly PARTNER_READONLY: Feature = {
    flag: FeatureControl.TRUST_NETWORK_ENABLED,
    scope: UserScopes.PARTNER_READONLY,
  };

  public static readonly EVENT_FORWARDER: Feature = {
    flag: FeatureControl.TRUST_NETWORK_ENABLED,
    scope: UserScopes.EVENT_FORWARDER,
  };

  public static readonly EVENT_FORWARDER_READONLY: Feature = {
    flag: FeatureControl.TRUST_NETWORK_ENABLED,
    scope: UserScopes.EVENT_FORWARDER_READONLY,
  };

  public static readonly IAM_INTEGRATION_READONLY: Feature = {
    flag: FeatureControl.AZURE_AD_ENABLED,
    scope: UserScopes.IAM_INTEGRATION_READONLY,
  };

  public static readonly SERVICE_ACCOUNT: Feature = {
    flag: FeatureControl.SERVICE_ACCOUNTS_ENABLED,
    scope: UserScopes.OAUTH,
  };

  public static readonly SERVICE_ACCOUNT_READONLY: Feature = {
    flag: FeatureControl.SERVICE_ACCOUNTS_ENABLED,
    scope: UserScopes.OAUTH_READONLY,
  };

  public static readonly DATA_ACCESS_POLICY: Feature = {
    flag: FeatureControl.DATA_ACCESS_POLICY_ENABLED,
    scope: UserScopes.DATA_ACCESS_POLICY,
  };

  public static readonly WORKFLOW: Feature = {
    flag: '',
    scope: UserScopes.WORKFLOW,
  };

  public static readonly WORKFLOW_READONLY: Feature = {
    flag: '',
    scope: UserScopes.WORKFLOW_READONLY,
  };

  public static readonly WORKFLOW_ADMIN: Feature = {
    flag: '',
    scope: UserScopes.WORKFLOW_ADMIN,
  };

  public static readonly SETTINGS: Feature = {
    flag: '',
    scope: UserScopes.SETTINGS,
  };

  public static readonly SETTINGS_READONLY: Feature = {
    flag: '',
    scope: UserScopes.SETTINGS_READONLY,
  };

  public static readonly APTELIGENT: Feature = {
    flag: FeatureControl.APTELIGENT_ENABLED,
    scope: UserScopes.APP_REGISTRATION,
  };

  public static readonly APTELIGENT_READONLY: Feature = {
    flag: FeatureControl.APTELIGENT_ENABLED,
    scope: UserScopes.APP_REGISTRATION_READONLY,
  };

  public static readonly INTEGRATION_STATISTICS: Feature = {
    flag: '',
    scope: UserScopes.INTEGRATION_STATISTICS,
  };

  public static readonly INTEGRATION_REGISTRATION: Feature = {
    flag: '',
    scope: UserScopes.INTEGRATION_REGISTRATION,
  };

  public static readonly INTEGRATION_REGISTRATION_READONLY: Feature = {
    flag: '',
    scope: UserScopes.INTEGRATION_REGISTRATION_READONLY,
  };

  public static readonly ORG_REGISTRATION: Feature = {
    flag: '',
    scope: UserScopes.ORG_REGISTRATION,
  };

  public static readonly ORG_REGISTRATION_READONLY: Feature = {
    flag: '',
    scope: UserScopes.ORG_REGISTRATION_READONLY,
  };

  public static readonly AIRWATCH_INTEGRATION: Feature = {
    flag: FeatureControl.AIRWATCH_ENABLED,
    scope: UserScopes.INTEGRATION_REGISTRATION_READONLY,
  };

  public static readonly IDM_INTEGRATION: Feature = {
    flag: FeatureControl.IDM_ENABLED,
    scope: UserScopes.INTEGRATION_REGISTRATION_READONLY,
  };

  public static readonly HORIZON_INTEGRATION: Feature = {
    flag: FeatureControl.HORIZON_ENABLED,
    scope: UserScopes.INTEGRATION_REGISTRATION_READONLY,
  };

  public static readonly HUB_INTEGRATION: Feature = {
    flag: FeatureControl.HUB_ENABLED,
    scope: UserScopes.INTEGRATION_REGISTRATION_READONLY,
  };

  public static readonly TRUST_NETWORK: Feature = {
    flag: FeatureControl.TRUST_NETWORK_ENABLED,
    scope: UserScopes.WORKFLOW_READONLY,
  };

  public static readonly ADMINISTRATORS: Feature[] = [Features.ACCOUNT_READONLY, Features.ACCOUNT_GROUP_READONLY];

  public static readonly ADMINISTRATOR_GROUPS_USERS: Feature[] = [Features.IAM_GROUPS, Features.IAM_USERS];

  public static readonly ADMINISTRATOR_GROUPS_USERS_BROWNFIELD_RBAC: Feature[] = [
    Features.IAM_GROUPS_BROWNFIELD_RBAC,
    Features.IAM_USERS_BROWNFIELD_RBAC,
  ];

  public static readonly INTEGRATION_HOME: Feature[] = [
    Features.AIRWATCH_INTEGRATION,
    Features.IDM_INTEGRATION,
    Features.HORIZON_INTEGRATION,
    Features.HUB_INTEGRATION,
    Features.AUTOMATION_READONLY,
    Features.TRUST_NETWORK,
    Features.IAM_INTEGRATION_READONLY,
  ];

  // Custom Reports

  public static readonly REPORT: Feature = {
    flag: FeatureControl.REPORT_ENABLED,
    scope: UserScopes.REPORT,
  };

  public static readonly REPORT_READONLY: Feature = {
    flag: FeatureControl.REPORT_ENABLED,
    scope: UserScopes.REPORT_READONLY,
  };

  public static readonly REPORT_SCHEDULE: Feature = {
    flag: FeatureControl.REPORT_ENABLED,
    scope: UserScopes.REPORT_SCHEDULE,
  };

  public static readonly REPORT_SCHEDULE_READONLY: Feature = {
    flag: FeatureControl.REPORT_ENABLED,
    scope: UserScopes.REPORT_SCHEDULE_READONLY,
  };

  public static readonly REPORT_TEMPLATE: Feature = {
    flag: '',
    scope: UserScopes.REPORT_TEMPLATE,
  };

  public static readonly REPORT_ADMIN: Feature = {
    flag: '',
    scope: UserScopes.REPORT_ADMIN,
  };

  public static readonly REPORT_RETENTION_SUPPORTED: Feature = {
    flag: FeatureControl.REPORT_RETENTION_ENABLED,
    scope: UserScopes.REPORT_ADMIN,
  };

  // Dashboard

  public static readonly DASHBOARD: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.DASHBOARD,
  };

  public static readonly DASHBOARD_READONLY: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.DASHBOARD_READONLY,
  };

  public static readonly DASHBOARD_SETTINGS: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.DASHBOARD_SETTINGS,
  };

  public static readonly DASHBOARD_SETTINGS_READONLY: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.DASHBOARD_SETTINGS_READONLY,
  };

  public static readonly DASHBOARD_WIDGET: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.WIDGET,
  };

  public static readonly DASHBOARD_WIDGET_READONLY: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.WIDGET_READONLY,
  };

  public static readonly DASHBOARD_ADMIN: Feature = {
    flag: FeatureControl.DASHBOARDS_ENABLED,
    scope: UserScopes.DASHBOARD_ADMIN,
  };

  public static readonly CONTENT_SEARCH: Feature = {
    flag: '',
    scope: UserScopes.CONTENT_SEARCH,
  };

  public static readonly CONTENT_SEARCH_READONLY: Feature = {
    flag: '',
    scope: UserScopes.CONTENT_SEARCH_READONLY,
  };

  public static readonly INCIDENT: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_INCIDENT_ENABLED,
    scope: UserScopes.INCIDENT,
  };

  public static readonly INCIDENT_READONLY: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_INCIDENT_ENABLED,
    scope: UserScopes.INCIDENT_READONLY,
  };

  public static readonly INSIGHT: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_INSIGHTS_ENABLED,
    scope: UserScopes.INSIGHT,
  };

  public static readonly PLAYBOOKS: Feature = {
    flag: FeatureControl.SOLUTIONS_PLAYBOOKS_ENABLED,
    scope: UserScopes.PLAYBOOKS,
  };

  public static readonly SOLUTIONS_SETTING_STEPS_READONLY: Feature = {
    flag: '',
    scope: UserScopes.SOLUTION_SETTING_STEPS_READONLY,
  };

  public static readonly SOLUTIONS_SETTING_METRIC: Feature = {
    flag: '',
    scope: UserScopes.SOLUTION_SETTING_METRIC,
  };

  public static readonly SOLUTIONS_SETTING_METRIC_READONLY: Feature = {
    flag: '',
    scope: UserScopes.SOLUTION_SETTING_METRIC_READONLY,
  };

  public static readonly SOLUTION_SETTINGS: Feature[] = [
    Features.INTEGRATION_REGISTRATION_READONLY,
    Features.ORG_REGISTRATION_READONLY,
    Features.SOLUTIONS_SETTING_METRIC,
    Features.DASHBOARD_READONLY,
    Features.DASHBOARD_SETTINGS,
  ];

  public static readonly SOLUTION_SETTINGS_READONLY: Feature[] = [
    Features.INTEGRATION_REGISTRATION_READONLY,
    Features.ORG_REGISTRATION_READONLY,
    Features.SOLUTIONS_SETTING_METRIC_READONLY,
    Features.DASHBOARD_READONLY,
    Features.DASHBOARD_SETTINGS_READONLY,
  ];

  public static readonly SOLUTION_DEEM_PHYSICAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_PHYSICAL_ENABLED,
    scope: '',
  };

  public static readonly SOLUTION_DEEM_VIRTUAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_VIRTUAL_ENABLED,
    scope: '',
  };

  public static readonly SOLUTION_DEEM_DASHBOARD_PHYSICAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_PHYSICAL_ENABLED,
    scope: UserScopes.DASHBOARD_STANDARD_READONLY,
  };

  public static readonly SOLUTION_DEEM_DASHBOARD_VIRTUAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_VIRTUAL_ENABLED,
    scope: UserScopes.DASHBOARD_STANDARD_READONLY,
  };

  public static readonly SOLUTION_DEEM_LEARN_MORE_PHYSICAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_PHYSICAL_ENABLED,
    scope: '',
  };

  public static readonly SOLUTION_DEEM_LEARN_MORE_VIRTUAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_VIRTUAL_ENABLED,
    scope: '',
  };

  public static readonly SOLUTION_DEEM_SETTINGS_PHYSICAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_PHYSICAL_ENABLED,
    scope: UserScopes.DASHBOARD_SETTINGS,
  };

  public static readonly SOLUTION_DEEM_SETTINGS_VIRTUAL: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_VIRTUAL_ENABLED,
    scope: UserScopes.DASHBOARD_SETTINGS,
  };

  public static readonly SOLUTION_DEEM_SYNTHETIC_URL_MONITORING: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_ENABLED,
    scope: UserScopes.SYNTHETIC_URL_MONITORING,
  };

  public static readonly SOLUTION_DEEM_SYNTHETIC_URL_MONITORING_READONLY: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_ENABLED,
    scope: UserScopes.SYNTHETIC_URL_MONITORING_READONLY,
  };

  public static readonly SOLUTION_SURVEY_READONLY: Feature = {
    flag: FeatureControl.SOLUTIONS_DEEM_SURVEYS_ENABLED,
    scope: UserScopes.SURVEYS_READONLY,
  };

  public static readonly SCRIPTS: Feature = {
    flag: FeatureControl.MARKETPLACE_SCRIPTS_ENABLED,
    scope: UserScopes.SCRIPTS_READONLY,
  };

  public static readonly SENSORS: Feature = {
    flag: FeatureControl.MARKETPLACE_SENSORS_ENABLED,
    scope: UserScopes.SENSORS_READONLY,
  };

  public static readonly UEM_FREESTYLE_WORKFLOWS: Feature = {
    flag: FeatureControl.MARKETPLACE_UEM_FREESTYLE_WORKFLOWS_ENABLED,
    scope: UserScopes.UEM_FREESTYLE_WORKFLOWS_READONLY,
  };
}
