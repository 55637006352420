/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { invert } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FeatureSelectors, NavigationMenuActions, NavigationMenuSelectors, SolutionSetupActions } from '@ws1c/intelligence-core';
import { NavigationMenuItem, ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Navigation Menu
 *
 * @export
 * @class NavigationMenuComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-navigation-menu',
  templateUrl: 'navigation-menu.component.html',
  styleUrls: ['navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  public BASE_NAV_URLS: Record<string, NavigationMenuItem> = {
    [ROUTE_NAMES.WORKSPACE.HOME]: NavigationMenuItem.WORKSPACE,
    [ROUTE_NAMES.MARKETPLACE.HOME]: NavigationMenuItem.MARKETPLACE,
    [ROUTE_NAMES.INTEGRATIONS.HOME]: NavigationMenuItem.INTEGRATIONS,
    [ROUTE_NAMES.SETTINGS.HOME]: NavigationMenuItem.SETTINGS,
    [ROUTE_NAMES.NOTIFICATION.HOME]: NavigationMenuItem.NOTIFICATION,
  };
  public labelKeyByNavMenuItem: Record<string, string> = {
    [NavigationMenuItem.INTEGRATIONS]: 'NAVIGATION.INTEGRATIONS',
    [NavigationMenuItem.SETTINGS]: 'NAVIGATION.ACCOUNTS',
    [NavigationMenuItem.WORKSPACE]: 'NAVIGATION.WORKSPACE',
    [NavigationMenuItem.MARKETPLACE]: 'NAVIGATION.MARKETPLACE',
  };
  public navMenuItems$: Observable<NavigationMenuItem[]>;
  public subs: Subscription[];

  /**
   * Creates instance of NavigationMenuComponent
   * @param {Router} router - Router Service instance
   * @param {Store} store - Merlot Store observable instance
   * @memberof NavigationMenuComponent
   */
  constructor(private router: Router, private store: Store) {
    this.navMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleNavigationMenuItems);
  }

  /**
   * Registers listener for router events changes
   * @memberof NavigationMenuComponent
   */
  public ngOnInit() {
    this.subs = [
      this.store
        .select(FeatureSelectors.hasSolutionSettingsStepsReadOnlyPerm)
        .pipe(filter(Boolean))
        .subscribe(() => {
          this.store.dispatch(SolutionSetupActions.loadAllSolutionStepConfigs());
        }),
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          const tree: UrlTree = this.router.parseUrl(this.router.url);
          const urlSegmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
          const urlSegments: UrlSegment[] = urlSegmentGroup.segments;
          const rootRouteSegment: UrlSegment = urlSegmentGroup.segments[0];
          const activatedNavMenuItemIndex: number = this.BASE_NAV_URLS[rootRouteSegment.path];
          this.store.dispatch(
            NavigationMenuActions.activateNavigationMenuItem({
              activatedNavMenuItemIndex,
              loadDefaultRoute: urlSegments.length === 1,
            }),
          );
        }
      }),
    ];
  }

  /**
   * ngOnDestroy
   * @memberof NavigationMenuComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * navUrlByMenuItem
   * @readonly
   * @type {Record<string, string>}
   * @memberof NavigationMenuComponent
   */
  public get navUrlByMenuItem(): Record<string, string> {
    return invert(this.BASE_NAV_URLS);
  }
}
