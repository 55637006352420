/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { RouterExtensions } from '@ws1c/intelligence-common';
import { CoreAppState, NavigationMenuActions, NavigationMenuSelectors, UserPreferenceActions } from '@ws1c/intelligence-core/store';
import { NavigationMenuGroup, ROUTE_NAMES, UIPreference } from '@ws1c/intelligence-models';

/**
 * NavigationMenuEffects
 *
 * @export
 * @class NavigationMenuEffects
 */
@Injectable()
export class NavigationMenuEffects {
  /**
   * toggleSecondaryNavigationMenuGroup$
   * @type {Observable<Action>}
   * @memberof NavigationMenuEffects
   */
  public toggleSecondaryNavigationMenuGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationMenuActions.toggleSecondaryNavigationMenuGroup),
      debounceTime(1000),
      withLatestFrom(this.store.select(NavigationMenuSelectors.getExpandedNavMenuGroups)),
      switchMap(([_action, expandedNavMenuGroups]: [Action, NavigationMenuGroup[]]) => [
        UserPreferenceActions.updateUISettings({
          uiSettings: {
            [UIPreference.SECONDARY_NAVIGATION_GROUPS_EXPANDED]: expandedNavMenuGroups,
          },
        }),
      ]),
    ),
  );

  /**
   * setSecondaryNavigationCollapsedFlag$
   * @type {Observable<Action>}
   * @memberof NavigationMenuEffects
   */
  public setSecondaryNavigationCollapsedFlag$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationMenuActions.setSecondaryNavigationCollapsedFlag),
      debounceTime(1000),
      filter(({ saveToUserPreference = true }: ReturnType<typeof NavigationMenuActions.setSecondaryNavigationCollapsedFlag>) => {
        return saveToUserPreference;
      }),
      switchMap(({ isCollapsed }: ReturnType<typeof NavigationMenuActions.setSecondaryNavigationCollapsedFlag>) => [
        UserPreferenceActions.updateUISettings({
          uiSettings: {
            [UIPreference.SECONDARY_NAVIGATION_COLLAPSED]: isCollapsed,
          },
        }),
      ]),
    ),
  );

  /**
   * navigateOnIntroAcknowledgement$
   * @type {Observable<Action>}
   * @memberof NavigationMenuEffects
   */
  public navigateOnIntroAcknowledgement$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationMenuActions.navigateOnIntroAcknowledgement),
        map(({ preference }: ReturnType<typeof NavigationMenuActions.navigateOnIntroAcknowledgement>) => {
          switch (preference) {
            case UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED:
              this.routerExtensions.navigate([`/${ROUTE_NAMES.AUTOMATION.AUTOMATION_WORKFLOW}`]);
              break;
            case UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED:
              this.routerExtensions.navigate([`/${ROUTE_NAMES.DASHBOARD.MY_DASHBOARDS}`]);
              break;
            case UIPreference.IS_REPORT_INTRO_PAGE_VISITED:
              this.routerExtensions.navigate([`/${ROUTE_NAMES.REPORT.LIST}`]);
              break;
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of NavigationMenuEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {RouterExtensions} routerExtensions - Router Extensions instance
   * @param {Store<CoreAppState>} store Merlot store observable instance
   * @memberof NavigationMenuEffects
   */
  constructor(private actions$: Actions, private routerExtensions: RouterExtensions, private store: Store<CoreAppState>) {}
}
