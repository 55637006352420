/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTimeFormat, DateTimePickerType, GenericObject, TimeOfDay, unsubscribe } from '@dpa/ui-common';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common/services';
import { AppConstants, CronExpressionData, Frequency, LabelKeyValue, LabelValue } from '@ws1c/intelligence-models';

/**
 * Scheduler component
 * @export
 * @class SchedulerComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-scheduler',
  templateUrl: 'scheduler.component.html',
  styleUrls: ['scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public cronExpressionDetail: CronExpressionData;
  @Input() public scheduleFrequencies?: Array<LabelKeyValue<number>> = AppConstants.SCHEDULE_FREQUENCIES;
  @Output() public onCronExpressionDetailChange: EventEmitter<CronExpressionData> = new EventEmitter<CronExpressionData>();

  public DATE_TIME_PICKER_TYPE = DateTimePickerType;
  public frequencyEnum = Frequency;
  public showDayOfMonthDropdownForOutOfRange = false;

  public weekdays: any[] = [
    { abbr: 'mon', label: 'MONDAY' },
    { abbr: 'tue', label: 'TUESDAY' },
    { abbr: 'wed', label: 'WEDNESDAY' },
    { abbr: 'thu', label: 'THURSDAY' },
    { abbr: 'fri', label: 'FRIDAY' },
    { abbr: 'sat', label: 'SATURDAY' },
    { abbr: 'sun', label: 'SUNDAY' },
  ];
  public endDate: Date;
  public startTime: TimeOfDay = new TimeOfDay({
    hour: 0,
    minute: 0,
    isAm: true,
  });
  public endByText: string;

  public scheduleFrequencyDropdown: Array<LabelValue<number>>;

  public intervalDropdown: Array<LabelValue<number>> = this.range(4, 13).map((i: number) => {
    return { value: i, label: this.i18nService.translate('SCHEDULE_CUSTOMIZE.N_HOURS', { hours: i }) };
  });

  public dayOfMonthDropdown: Array<LabelValue<number>> = this.range(1, 29).map((i: number) => {
    return { value: i, label: '' + i };
  });

  public schedulerForm: UntypedFormGroup;
  public subs: Subscription[];

  /**
   * Creates an instance of SchedulerComponent.
   * @param {UntypedFormBuilder} fb
   * @param {I18NService} i18nService
   * @memberof SchedulerComponent
   */
  constructor(private fb: UntypedFormBuilder, private i18nService: I18NService) {
    this.schedulerForm = this.fb.group({
      cronExpressionDetail: this.fb.group({
        frequency: [null, Validators.required],
        startTimeOfDay: [this.startTime, Validators.required],
        end: [null, Validators.required],
        requiredEnDate: [false, Validators.required],
        hourly: this.fb.group({
          interval: [4, Validators.required],
        }),
        weekly: this.fb.group({
          sun: true,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
        }),
        monthly: this.fb.group({
          dayOfMonth: [1, Validators.required],
        }),
      }),
    });
  }

  /**
   * ngOnChanges
   *
   * @param {SimpleChanges} changes
   * @memberof SchedulerComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.cronExpressionDetail && changes.cronExpressionDetail.currentValue) {
      this.patchCronForm(changes.cronExpressionDetail.currentValue);
    }
    this.getEndByText();
  }

  /**
   * Init form values based on add/edit state
   *
   * @memberof SchedulerComponent
   */
  public ngOnInit() {
    this.subs = [
      this.schedulerForm.valueChanges.pipe(distinctUntilChanged(isEqual)).subscribe((newValue: GenericObject) => {
        this.onCronExpressionDetailChange.emit(newValue.cronExpressionDetail);
      }),
    ];
    this.scheduleFrequencyDropdown = this.scheduleFrequencies.map((frequency: LabelKeyValue<number>) => {
      return {
        value: frequency.value,
        label: this.i18nService.translate(frequency.labelKey),
      };
    });
  }

  /**
   * ngOnDestroy
   *
   * @memberof SchedulerComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * patchCronForm
   *
   * @param {CronExpressionData} cronExpressionDetail
   * @memberof SchedulerComponent
   */
  public patchCronForm(cronExpressionDetail: CronExpressionData) {
    this.schedulerForm.patchValue({
      cronExpressionDetail,
    });
  }

  /**
   * Update the end-by date when the day-of-week changes
   *
   * @param {string} weekdayAbbr
   * @param {boolean} isDayChecked
   * @memberof SchedulerComponent
   */
  public onWeeklyIntervalChange(weekdayAbbr: string, isDayChecked: boolean) {
    const selectedWeekdays = this.schedulerForm.get('cronExpressionDetail.weekly').value;
    selectedWeekdays[weekdayAbbr] = isDayChecked;
    this.onFrequencyIntervalChange({
      selectedWeekdays,
    });
    this.onCronExpressionDetailChange.emit(this.schedulerForm.value.cronExpressionDetail);
  }

  /**
   * Update `frequency` in `cronExpressionDetail` form group
   *
   * @param {LabelValue<number>} frequency
   * @memberof SchedulerComponent
   */
  public onSelectFrequencyAttribute(frequency: LabelValue<number>) {
    if (frequency) {
      this.schedulerForm.get('cronExpressionDetail.frequency').patchValue(frequency.value);
    }
  }

  /**
   * Update `hourly.interval` in `cronExpressionDetail` form
   *
   * @param {LabelValue<number>} interval
   * @memberof SchedulerComponent
   */
  public onSelectIntervalAttribute(interval: LabelValue<number>) {
    if (interval) {
      this.schedulerForm.get('cronExpressionDetail.hourly.interval').patchValue(interval.value);
    }
  }

  /**
   * Update `monthly.dayOfMonth` in `cronExpressionDetail` form
   *
   * @param {LabelValue<number>} dayOfMonth
   * @memberof SchedulerComponent
   */
  public onSelectDayOfMonthAttribute(dayOfMonth: LabelValue<number>) {
    if (dayOfMonth) {
      this.onFrequencyIntervalChange({
        dayOfMonth: dayOfMonth.value,
      });
      this.schedulerForm.get('cronExpressionDetail.monthly.dayOfMonth').patchValue(dayOfMonth.value);
    }
  }

  /**
   * Determine the minimum end-by date, based on the selected day-of-week or day-of-month
   *
   * @param {GenericObject} options
   * @returns {Date}
   * @memberof SchedulerComponent
   */
  public getMinEndBy(options: GenericObject = {}): Date {
    const rightNow = new Date();
    rightNow.setHours(0, 0, 0, 0);
    let endBy = new Date(rightNow.getTime());
    // endBy date should round up to the next day to prevent the end date from being before the start date
    endBy.setDate(endBy.getDate() + 1);

    const frequency = parseInt(this.schedulerForm.get('cronExpressionDetail.frequency').value, 10);
    switch (frequency) {
      case this.frequencyEnum.WEEKLY:
        const selectedEnd = this.schedulerForm.get('cronExpressionDetail.end').value;
        const weeklongMilliseconds = 1000 * 60 * 60 * 24 * 7;
        const weekFromNow = new Date(rightNow.getTime() + weeklongMilliseconds);
        if (!selectedEnd || selectedEnd < weekFromNow) {
          let weekdayAbbr;
          if (!options.selectedWeekdays) {
            options.selectedWeekdays = this.schedulerForm.get('cronExpressionDetail.weekly').value;
          }
          const weekdayMap = this.weekdays.map((item) => item.abbr);
          weekdayMap.unshift(weekdayMap.pop()); // Move Sunday to the front
          for (endBy = new Date(rightNow.getTime()); endBy < weekFromNow; endBy.setDate(endBy.getDate() + 1)) {
            weekdayAbbr = weekdayMap[endBy.getDay()];
            if (options.selectedWeekdays[weekdayAbbr]) {
              break;
            }
          }
        }
        break;
      case this.frequencyEnum.MONTHLY:
        if (!options.dayOfMonth) {
          options.dayOfMonth = parseInt(this.schedulerForm.get('cronExpressionDetail.monthly.dayOfMonth').value, 10);
        }
        endBy.setDate(options.dayOfMonth);
        if (endBy < rightNow) {
          endBy.setMonth(rightNow.getMonth() + 1);
        }
        break;
    }
    return endBy;
  }

  /**
   * Generate a range from start to end
   *
   * @param {number} start
   * @param {number} end
   * @returns {number[]}
   * @memberof SchedulerComponent
   */
  public range(start: number, end: number): number[] {
    return Array.from({ length: end - start }, (_, i) => start + i);
  }

  /**
   * getStartsAtLabel
   * @memberof SchedulerComponent
   * @returns {string}
   */
  public getStartsAtLabel() {
    const i18nKey =
      parseInt(this.schedulerForm.get('cronExpressionDetail.frequency').value, 10) === this.frequencyEnum.DAILY
        ? 'SCHEDULE_CUSTOMIZE.RECURRENCE_TIME_OF_THE_DAY'
        : 'SCHEDULE_CUSTOMIZE.RECURRENCE_STARTS_AT';
    return this.i18nService.translate(i18nKey);
  }

  /**
   * getEndByText
   * @memberof SchedulerComponent
   */
  public getEndByText() {
    let endByText: string;
    const endDateReq = this.schedulerForm.get('cronExpressionDetail.requiredEnDate').value;
    if (endDateReq) {
      this.endDate = this.schedulerForm.get('cronExpressionDetail.end').value;
      endByText = this.i18nService.translate('SCHEDULE_CUSTOMIZE.END_BY_DATE', {
        date: moment(this.endDate).format(DateTimeFormat.MOMENT_DATE_FORMAT),
      });
    } else {
      endByText = this.i18nService.translate('SCHEDULE_CUSTOMIZE.END_BY');
    }
    this.endByText = endByText;
  }

  /**
   * onEndByChange
   * @param {any} event
   * @memberof SchedulerComponent
   */
  public onEndByChange(event) {
    if (!event) {
      return;
    }
    this.schedulerForm.get('cronExpressionDetail').patchValue({
      end: event,
      requiredEnDate: true,
    });
    this.getEndByText();
  }

  /**
   * onTimeChange
   * @param {any} event
   * @memberof SchedulerComponent
   */
  public onTimeChange(event) {
    this.schedulerForm.get('cronExpressionDetail.startTimeOfDay').patchValue(TimeOfDay.createFromTimestamp(event));
  }

  /**
   * onClearEnd
   * @memberof SchedulerComponent
   */
  public onClearEnd() {
    this.schedulerForm.get('cronExpressionDetail.requiredEnDate').patchValue(false);
    this.endDate = null;
    this.getEndByText();
  }

  /**
   * formatter for type ahead
   *
   * @param {LabelValue<number>} result
   * @returns {string}
   * @memberof SchedulerComponent
   */
  public formatter(result: LabelValue<number>): string {
    return result.label || '';
  }

  /**
   * Update end-by date when weekly or monthly interval changes
   *
   * @param {GenericObject} options
   * @memberof SchedulerComponent
   */
  public onFrequencyIntervalChange(options: GenericObject) {
    const selectedEnd = this.schedulerForm.get('cronExpressionDetail.end').value;
    if (!selectedEnd) {
      return;
    }
    const endBy = this.getMinEndBy(options);
    if (selectedEnd < endBy) {
      this.schedulerForm.get('cronExpressionDetail.end').patchValue(endBy);
    }
  }

  /**
   * Gets the selected object from the dropdown; used to update the typeahead fields in the form
   *
   * @param {string} formKey
   * @param {Array<LabelValue<number>>} dropdown
   * @returns {LabelValue<number>}
   * @memberof SchedulerComponent
   */
  public getTypeaheadSelectedItem(formKey: string, dropdown: Array<LabelValue<number>>): LabelValue<number> {
    const value = this.schedulerForm.get(formKey).value;
    // check if the form has a value set, if not then return the first item in the dropdown
    const selectedItem = value ? dropdown.find((dropdownItem) => dropdownItem.value === value) : dropdown[0];
    if (!value) {
      this.schedulerForm.get(formKey).patchValue(selectedItem.value);
    }
    return value ? dropdown.find((dropdownItem) => dropdownItem.value === value) : dropdown[0];
  }

  /**
   * keyBy
   * @param {LabelValue<number>} item
   * @returns {string}
   * @memberof SchedulerComponent
   */
  public keyBy(item: LabelValue<number>): string {
    return `${item.value} - ${item.label}`;
  }

  /**
   * showDayOfMonthDropdown
   * @memberof SchedulerComponent
   */
  public showDayOfMonthDropdown() {
    this.showDayOfMonthDropdownForOutOfRange = true;
  }
}
