/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

// eslint-disable-next-line max-len
import { StandardDashboardUemV2ToggleComponent } from '@dpa-components/standard-dashboard-uem-v2-toggle/standard-dashboard-uem-v2-toggle.component';
import { MerlotModule } from '@dpa-shared-merlot/merlot.module';
import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { IntelligenceCoreComponentsModule } from '@ws1c/intelligence-core';
import { NAVIGATION_MENU_COMPONENTS } from './index';

/**
 * NavigationMenuModule contains navigation menu components
 * @class NavigationMenuModule
 */
@NgModule({
  imports: [MerlotModule, IntelligenceCoreComponentsModule, IntelligenceCommonModule, StandardDashboardUemV2ToggleComponent],
  declarations: [...NAVIGATION_MENU_COMPONENTS],
  exports: [...NAVIGATION_MENU_COMPONENTS],
})
export class NavigationMenuModule {}
