<clr-alerts>
  <clr-alert
    *ngFor="let notification of notifications$ | async"
    [clrAlertClosable]="notification.dismissable"
    [clrAlertAppLevel]="true"
    [clrAlertType]="SEVERITY_ALERT_TYPE_MAPPING[notification.severity]"
    (clrAlertClosedChange)="dismissAlert(notification.id)"
  >
    <div
      [ngClass]="SEVERITY_ALERT_TYPE_MAPPING[notification.severity]"
      class="service-icon-wrpr"
    >
      <div
        [attr.data-service-type]="notification?.serviceType | uppercase"
        class="notification-service-icon white"
      ></div>
    </div>
    <clr-alert-item>
      {{ notification.body }}
      <div
        *ngIf="notification.actionTitle && (notification.actionUrl || notification.actionPage)"
        class="ml-x2"
      >
        <button
          (click)="onActionClick(notification)"
          class="btn btn-sm"
        >
          {{ notification.actionTitle }}
          <cds-icon
            *ngIf="notification.actionUrl"
            shape="pop-out"
            size="12"
            class="ml-x1"
          ></cds-icon>
        </button>
      </div>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>
