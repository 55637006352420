/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { breadthFirstBy, Color, GenericObject } from '@dpa/ui-common';
import { cloneDeep, each, forEach, groupBy, isUndefined, keyBy, last, mapValues, size, sumBy, values } from 'lodash-es';

import { WidgetColorSchema } from '@ws1c/intelligence-models/dashboard//widget-color-schema.model';
import { FocusedSeries } from '@ws1c/intelligence-models/dashboard/chart-drilldown-event.interface';
import { CounterDefinition } from '@ws1c/intelligence-models/dashboard/counter-definition.model';
import { DashboardConfig } from '@ws1c/intelligence-models/dashboard/dashboard.config';
import { AggregationFunction, WidgetRangeType } from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { TrendResult } from '@ws1c/intelligence-models/dashboard/trend-result.model';
import { Trend } from '@ws1c/intelligence-models/dashboard/trend.model';
import { WidgetRangeFilter } from '@ws1c/intelligence-models/dashboard/widget-range-filter.model';
import { ColumnIndex, DataType } from '@ws1c/intelligence-models/integration-meta';
import { Tooltip, TooltipSeverity } from '@ws1c/intelligence-models/tool-tip.interface';
import { TrendMode } from '@ws1c/intelligence-models/trend-mode.enum';
import { getCSVCellValue } from '@ws1c/intelligence-models/utils';
import { NgxChartDataBuilder, NgxSingleData } from './ngx-chart-data-builder.model';
import { NgxChartLabels } from './ngx-chart-labels.model';
import { NgxChartOtherGrouper } from './ngx-chart-other-grouper.model';
import { NgxDatasetIndices } from './ngx-dataset-indices.model';
import { NgxDrilldownEventBuilder } from './ngx-drilldown-event-builder.model';
import { NgxTooltipBuilder, TableTooltip } from './ngx-tooltip-builder.model';
import { NgxTrendResultFlattener } from './ngx-trend-result-flattener.model';

/**
 * NgxChart
 * @export
 * @class NgxChart
 */
export class NgxChart {
  public groupBys: string[];

  public trendResultFlattener: NgxTrendResultFlattener;
  public rootNode: NgxSingleData;
  public chartData: NgxSingleData[];

  public bubbleData: any[];
  public rangedData: NgxSingleData[];

  public colorizedEntriesByAttributeName: any;
  public colorizedAttributeValues: any[];
  public indices: NgxDatasetIndices;
  public chartDataBuilder: NgxChartDataBuilder;
  public labels: NgxChartLabels;
  public tooltipBuilder: NgxTooltipBuilder;
  public drilldownEventBuilder: NgxDrilldownEventBuilder;
  public otherGrouper: NgxChartOtherGrouper;

  public isMultiDataSet: boolean;
  public usingFakeGroupBy: boolean;
  public infoTooltip: Tooltip;

  public customColors: any[];
  public colorizedAttributeLabel: string;
  public colorsByAttribute: GenericObject;
  public defaultColorScheme: Color;

  public bucketAttributeIndexByGroupByIndex: any[];
  public dateFormatter: (millis: number) => string;
  public dateFormatterAxis: (millis: number) => string;

  /**
   * Creates an instance of NgxChart.
   * @param {Trend} trend
   * @param {any} translators
   * @param {boolean} [useDateAsSecondGroupBy=false]
   * @param {number} [minimumGroupBys=0]
   * @param {string} [_noBucketingColor=DashboardConfig.DEFAULT_COLORS[0]]
   * @param {number} [colorizedLevel=0]
   * @param {string} currentLocale
   * @param {any} customColorOverride
   * @param {WidgetColorSchema[]} colorSchemas
   * @param {boolean} [isRanged=false]
   * @param {boolean} isInvertMode
   * @param {boolean} skipSort
   * @param {WidgetRangeFilter} rangeFilter
   * @param {number} xAxisLabelFactor
   * @param {string[]} defaultColors
   * @param {ColumnIndex} [allColumnsByName={}]
   * @memberof NgxChart
   */
  public constructor(
    private trend: Trend,
    private translators: any,
    private useDateAsSecondGroupBy: boolean = false,
    private minimumGroupBys: number = 0,
    private _noBucketingColor: string = DashboardConfig.DEFAULT_COLORS[0],
    private colorizedLevel: number = 0,
    private currentLocale: string,
    private customColorOverride?: any,
    private colorSchemas?: WidgetColorSchema[],
    private isRanged: boolean = false,
    private isInvertMode?: boolean,
    private skipSort?: boolean,
    private rangeFilter?: WidgetRangeFilter,
    private xAxisLabelFactor?: number,
    private defaultColors?: string[],
    private allColumnsByName: ColumnIndex = {},
  ) {
    if (this.isInvertMode) {
      // It needs to filter out all the zero values as it cannot be render with 1/x
      this.trend.trendResults = trend.trendResults?.filter((trendResult: TrendResult) => {
        return trendResult.counters.every((counter) => counter.result.value !== 0);
      });
    }

    if (this.rangeFilter) {
      this.trend.trendResults = this.filterTrendResultsByRange(this.trend.trendResults, this.rangeFilter);
    }

    if (!this.hasTrendResults()) {
      return;
    }
    this.groupBys = this.getGroupBys();

    // This normalizes trendResults
    // It sets some date strings and gets values out of bucketing attributes
    this.trendResultFlattener = new NgxTrendResultFlattener(this.trend);

    // These indices are used to create the tooltips and determine the drilldown date values
    this.indices = new NgxDatasetIndices(
      this.trend.trendResults,
      this.trendResultFlattener.results,
      this.getIsHistorical(),
      this.allColumnsByName,
    );

    // The localized labels are spread throughout trendResults
    // This goes through and indexes them for use in tooltips and axis labels
    this.labels = new NgxChartLabels(
      this.groupBys,
      this.trend.trendResults,
      this.translators,
      this.indices.dataTypesByBucketingAttributeKey,
      this.trend.trendDefinition.dateRange,
      this.currentLocale,
      this.isInvertMode,
      this.indices.dataUnitsByBucketingAttributeKey,
      this.xAxisLabelFactor,
    );

    // This builds the nested data format used by NgxCharts
    this.chartDataBuilder = new NgxChartDataBuilder(
      this.groupBys,
      this.trendResultFlattener.results,
      this.trend.trendDefinition.counterDefinitions[0].aggregationFunction,
      this.indices.dataTypesByBucketingAttributeKey,
      this.trendResultFlattener.timestampsByFormattedTimes,
      this.labels.formattersByBucketingAttributeKey[this.groupBys[0]],
      this.isInvertMode,
    );
    this.rootNode = this.chartDataBuilder.buildRootNode(this.skipSort);
    this.bubbleData = this.chartDataBuilder.buildNestedBubbleDataset();

    // This is the format accepted by NgxChart components
    this.chartData = this.rootNode.series;
    if (this.isRanged) {
      this.chartData = this.chartDataBuilder.buildRangedDataset();
    }

    this.tooltipBuilder = new NgxTooltipBuilder(
      this.groupBys,
      this.trend.trendDefinition.getIsRolling(),
      this.labels,
      this.indices.startEndDateStrByDateValue,
      this.rootNode,
    );

    this.colorizedAttributeLabel = this.labels.byFlatKey[this.groupBys[this.colorizedLevel]];
    this.setCustomColors();

    this.isMultiDataSet = this.getIsMultiDataSet();
    this.infoTooltip = this.getInfoTooltip();
    this.defaultColorScheme = {
      domain: [this.noBucketingColor],
    } as Color;

    // bubble charts will assume x-axis is a date
    this.dateFormatter = (millis: number) => this.trendResultFlattener.formatDate(millis);
    this.dateFormatterAxis = (millis: number) => this.trendResultFlattener.formatDateAxis(millis);
  }

  /**
   * @readonly
   * @type {string}
   * @readonly
   * @memberof NgxChart
   */
  public get noBucketingColor() {
    return this._noBucketingColor;
  }

  /**
   * setter for _noBucketingColor
   * noBucketingColor
   * @memberof NgxChart
   */
  public set noBucketingColor(noBucketingColor: string) {
    this._noBucketingColor = noBucketingColor;
  }

  /**
   * @readonly
   * @type {string}
   * @readonly
   * @memberof NgxChart
   */
  public get trendMode() {
    return this.trend?.trendDefinition?.trendMode;
  }

  /**
   * getColorizedEntriesByAttributeName
   * @returns {any}
   * @memberof NgxChart
   */
  public getColorizedEntriesByAttributeName(): any {
    const colorizedSeriesByAttribute = {};
    breadthFirstBy(this.rootNode, 'series', (node: NgxSingleData, depth: number) => {
      if (depth === this.colorizedLevel) {
        each(node.series, (colorizedNode: NgxSingleData) => {
          colorizedSeriesByAttribute[colorizedNode.name] = colorizedSeriesByAttribute[colorizedNode.name] || new Set<string>();
          colorizedSeriesByAttribute[colorizedNode.name].add(node.name);
        });
      }
    });
    const colorizedEntriesByAttributeName = mapValues(colorizedSeriesByAttribute, (seriesNames: Set<string>, attributeName: string) => {
      return Array.from(seriesNames).map((seriesName: string) => {
        return {
          name: attributeName,
          series: seriesName,
        };
      });
    });
    return this.usingFakeGroupBy ? {} : colorizedEntriesByAttributeName;
  }

  /**
   * getGroupBys
   * @returns {string[]}
   * @memberof NgxChart
   */
  public getGroupBys(): string[] {
    const bucketingAttributes = this.trend.trendDefinition.bucketingAttributes || [];

    // records positions using object references for setting bucketAttributeIndexByGroupByIndex
    const wrappedAttributes = bucketingAttributes.map((attribute: string) => {
      return { attribute };
    });

    const dateKey = this.getIsHistorical() ? [NgxTrendResultFlattener.DATE_KEY] : [];
    let groupBys;
    if (this.getUseDateAsSecondGroupBy()) {
      groupBys = [...wrappedAttributes.slice(0, 1), ...dateKey, ...wrappedAttributes.slice(1)];
    } else {
      groupBys = [...dateKey, ...wrappedAttributes];
    }

    // The data for NgxChart's Line chart must have at least two groupBys
    // The data for NgxChart's Pie chart must have at least one groupBy
    // This inserts a "fake group by" that I will hide when encountered in the tooltip and legend
    while (this.minimumGroupBys > groupBys.length) {
      groupBys.unshift(NgxTrendResultFlattener.FAKE_GROUP_BY_KEY);
      this.usingFakeGroupBy = true;
    }

    this.bucketAttributeIndexByGroupByIndex = groupBys.map((groupByItem: any | string) => {
      return wrappedAttributes.findIndex((attrString: any) => attrString === groupByItem);
    });

    return groupBys.map((groupByItem: any | string) => groupByItem.attribute ?? groupByItem);
  }

  /**
   * getTooltip
   * @param {any} model
   * @param {string} radiusLabel
   * @returns {TableTooltip}
   * @memberof NgxChart
   */
  public getTooltip(model: any, radiusLabel?: string): TableTooltip {
    if (radiusLabel) {
      const extraFilters = [
        {
          keyLabel: radiusLabel,
          value: model.radius.toLocaleString(),
        },
      ];
      return this.tooltipBuilder.getTooltip(model.value, extraFilters, model.series, this.dateFormatter(model.x));
    }
    return this.tooltipBuilder.getTooltip(model.value, [], model.series, model.name);
  }

  /**
   * getConvertedMultiDataSet
   *
   * workaround for historical ngx-charts that only support multi series data e.g. heat-map
   * @param {string} [name='']
   * @returns {NgxSingleData[]}
   * @memberof NgxChart
   */
  public getConvertedMultiDataSet(name: string = ''): NgxSingleData[] {
    return this.isMultiDataSet
      ? this.chartData
      : this.chartData.map((singleData: NgxSingleData) => ({
          ...singleData,
          series: [
            {
              name,
              value: singleData.value,
            },
          ],
        }));
  }

  /**
   * getIsHistorical
   * @returns {boolean}
   * @memberof NgxChart
   */
  public getIsHistorical(): boolean {
    return [TrendMode[TrendMode.HISTORICAL], TrendMode[TrendMode.SNAPSHOT_PERIODICAL]].includes(this.trend.trendDefinition.trendMode);
  }

  /**
   * hasTrendResults
   * @returns {boolean}
   * @memberof NgxChart
   */
  public hasTrendResults(): boolean {
    return size(this.trend.trendResults) > 0;
  }

  /**
   * getIsMultiDataSet
   * @returns {boolean}
   * @memberof NgxChart
   */
  public getIsMultiDataSet(): boolean {
    return this.groupBys.length > 1;
  }

  /**
   * getUseDateAsSecondGroupBy
   * @returns {boolean}
   * @memberof NgxChart
   */
  public getUseDateAsSecondGroupBy(): boolean {
    return this.useDateAsSecondGroupBy;
  }

  /**
   * setCustomColors
   * @memberof NgxChart
   */
  public setCustomColors() {
    // These are the series shown in the legend
    this.colorizedEntriesByAttributeName = this.getColorizedEntriesByAttributeName();
    this.colorizedAttributeValues = Object.keys(this.colorizedEntriesByAttributeName);

    // The presence of a customColors array makes NgxCharts stop using any colors from the color scheme
    // This adds in colors from the color scheme, then applies overrides
    // The colorSchemas which can be saved as part of widget theme will be applied over default colors or custom colors
    const customColorsByName = {
      ...keyBy(this.getCustomColors(), 'name'),
      ...keyBy(this.customColorOverride, 'name'),
      ...keyBy(this.colorSchemas, 'name'),
    };
    this.customColors = values(customColorsByName);
    this.colorsByAttribute = mapValues(customColorsByName, 'value');

    this.setDrilldownEventBuilder();
  }

  /**
   * getCustomColors
   * @returns {any[]}
   * @memberof NgxChart
   */
  public getCustomColors(): any[] {
    // if colorized level has no data (single color chart)
    if (this.colorizedLevel >= this.groupBys.length || this.usingFakeGroupBy) {
      return undefined;
    }
    const defaultColors = this.defaultColors ?? DashboardConfig.DEFAULT_COLORS;
    return this.colorizedAttributeValues.map((attribute: string, index: number) => {
      const colorIndex = index % defaultColors.length;
      return {
        name: attribute,
        value: defaultColors[colorIndex],
      };
    });
  }

  /**
   * getInfoTooltip
   * @returns {Tooltip}
   * @memberof NgxChart
   */
  public getInfoTooltip(): Tooltip {
    const hasDateTimeGroup = this.groupBys
      .map((groupByAttr: string) => this.indices.dataTypesByBucketingAttributeKey[groupByAttr])
      .includes(DataType[DataType.DATETIME]);

    const isUsingUiBucketing = this.groupBys.length > 2;

    const aggregationFunction = this.trend.trendDefinition.counterDefinitions[0].aggregationFunction as AggregationFunction;
    const aggregationFunctionIsRisky = [AggregationFunction.AVG, AggregationFunction.COUNT_DISTINCT].includes(aggregationFunction);

    if (aggregationFunctionIsRisky && (isUsingUiBucketing || hasDateTimeGroup)) {
      return {
        textKey: this.translators.DANGEROUS_GROUP_BY_INFO(),
        severity: TooltipSeverity.WARNING,
      } as Tooltip;
    }
  }

  /**
   * getColorizedLevel
   * @returns {number}
   * @memberof NgxChart
   */
  public getColorizedLevel(): number {
    return this.colorizedLevel;
  }

  /**
   * getColorizedBucketingAttributeIndex
   * @returns {number}
   * @memberof NgxChart
   */
  public getColorizedBucketingAttributeIndex(): number {
    const bucketingAttributeCount = this.trend.trendDefinition.bucketingAttributes.length;
    const attributeIndex = this.bucketAttributeIndexByGroupByIndex[this.colorizedLevel];
    return Math.max(isUndefined(attributeIndex) ? bucketingAttributeCount : attributeIndex, 0);
  }

  /**
   * getColorizedAttributeName
   * @returns {string}
   * @memberof NgxChart
   */
  public getColorizedAttributeName(): string {
    return this.groupBys[this.colorizedLevel];
  }

  /**
   * applyOtherGrouping
   * @memberof NgxChart
   */
  public applyOtherGrouping() {
    if (!this.rootNode) {
      return;
    }

    this.otherGrouper = new NgxChartOtherGrouper(this.rootNode, this.translators);

    this.otherGrouper.applyOtherGrouping();
    this.setCustomColors();
    this.drilldownEventBuilder.setOtherSeries(this.otherGrouper.otherSeries);
  }

  /**
   * setFocusedSeries
   * @param {any} focusedSeries
   * @memberof NgxChart
   */
  public setFocusedSeries(focusedSeries: FocusedSeries) {
    if (!this.rootNode) {
      return;
    }

    const rootNodeClone = cloneDeep(this.rootNode);
    this.chartDataBuilder.setValuesDeep(rootNodeClone, !!this.otherGrouper, focusedSeries);
    this.chartData = rootNodeClone.series;
    this.rootNode = rootNodeClone;
  }

  /**
   * getCsvData
   * @returns {string[][]}
   * @memberof NgxChart
   */
  public getCsvData(): string[][] {
    if (!this.hasTrendResults()) {
      return [];
    }
    const visibleGroupBys = this.groupBys.filter((groupByItem: string) => groupByItem !== NgxTrendResultFlattener.FAKE_GROUP_BY_KEY);
    const groupByLabels = visibleGroupBys.map((groupByItem: string) => this.labels.byFlatKey[groupByItem]);
    const headerRow = [...groupByLabels, this.labels.byFlatKey[NgxTrendResultFlattener.COUNTER_KEY]];

    const dataRows = this.trendResultFlattener.results.map((flatTrendResult: any) => {
      const bucketValues = visibleGroupBys.map((groupByItem: string) => {
        const trendResultGroupBy = flatTrendResult[groupByItem];
        return getCSVCellValue(trendResultGroupBy);
      });
      const counterValue = String(flatTrendResult[NgxTrendResultFlattener.COUNTER_KEY]);
      return [...bucketValues, counterValue];
    });
    return [headerRow, ...dataRows];
  }

  /**
   * getCounterKeys
   * @returns {string[]}
   * @memberof NgxChart
   */
  public getCounterKeys(): string[] {
    return this.trend.trendDefinition.counterDefinitions.map((counterDefinition: CounterDefinition, index: number) => {
      return NgxTrendResultFlattener.getCounterKey(index);
    });
  }

  /**
   * getCounterKeysMap - map counter key to its aggregate attribute name
   * @returns {Record<string, string>}
   */
  public getCounterKeysMap(): Record<string, string> {
    return this.trend.trendDefinition.counterDefinitions.reduce(
      (acc: GenericObject, counterDefinition: CounterDefinition, index: number) => {
        acc[counterDefinition.aggregateAttribute] = NgxTrendResultFlattener.getCounterKey(index);
        return acc;
      },
      {},
    );
  }

  /**
   * filterTrendResultsByRange
   * @param {TrendResult[]} trendResults
   * @param {WidgetRangeFilter} rangeFilter
   * @returns {TrendResult[]}
   * @memberof NgxChart
   */
  public filterTrendResultsByRange(trendResults: TrendResult[], rangeFilter: WidgetRangeFilter): TrendResult[] {
    if (rangeFilter.type === WidgetRangeType.COUNT) {
      return trendResults?.filter((trendResult: TrendResult) => {
        return trendResult.counters[0].result.value >= rangeFilter.min && trendResult.counters[0].result.value <= rangeFilter.max;
      });
    }

    if (rangeFilter.type === WidgetRangeType.PERCENTAGE) {
      return this.filterTrendResultsByPercentageRange(trendResults, rangeFilter);
    }

    return trendResults;
  }

  /**
   * setDrilldownEventBuilder
   * @memberof NgxChart
   */
  private setDrilldownEventBuilder() {
    this.drilldownEventBuilder = new NgxDrilldownEventBuilder(
      this.groupBys,
      this.indices.startEndDrilldownByDateValue,
      this.labels.byFlatKey,
      this.indices.dataTypesByBucketingAttributeKey,
      this.trendResultFlattener.timestampsByFormattedTimes,
      this.colorsByAttribute,
      this.colorizedLevel,
      this.translators,
      this.isInvertMode,
    );
  }

  /**
   * filterTrendResultsByPercentageRange
   * @private
   * @param {TrendResult[]} trendResults
   * @param {WidgetRangeFilter} rangeFilter
   * @returns {TrendResult[]}
   * @memberof NgxChart
   */
  private filterTrendResultsByPercentageRange(trendResults: TrendResult[], rangeFilter: WidgetRangeFilter): TrendResult[] {
    if (!trendResults?.[0]?.bucketingAttributes?.length) {
      return trendResults;
    }
    const trendResultByGroup: Record<string, TrendResult[]> = groupBy(
      trendResults,
      (trendResult: TrendResult) => last(trendResult.bucketingAttributes)?.value,
    );

    const sumValueByGroup: Map<string, number> = new Map<string, number>();
    let sum: number = 0;
    forEach(trendResultByGroup, (resultsOfGroup: TrendResult[], key: string) => {
      const sumOfGroup = sumBy(resultsOfGroup, (trendResult: TrendResult) => {
        return trendResult.counters[0].result.value;
      });
      sumValueByGroup.set(key, sumOfGroup);
      sum += sumOfGroup;
    });

    const filteredBucketingValues: string[] = [];
    sumValueByGroup.forEach((sumOfGroup: number, key: string) => {
      const percentageOfGroup = sumOfGroup / sum;
      if (percentageOfGroup >= rangeFilter.min / 100 && percentageOfGroup <= rangeFilter.max / 100) {
        filteredBucketingValues.push(key);
      }
    });

    return trendResults.filter((trendResult: TrendResult) => {
      return filteredBucketingValues.includes(last(trendResult.bucketingAttributes)?.value?.toString());
    });
  }
}
