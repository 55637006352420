/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { AggregationWidgetChartType } from './dashboard.enum';

/**
 * WidgetConfig
 * @export
 * @class WidgetConfig
 */
export class WidgetConfig {
  public static readonly CHART_TYPES = [
    AggregationWidgetChartType[AggregationWidgetChartType.HORIZONTAL],
    AggregationWidgetChartType[AggregationWidgetChartType.HORIZONTAL_GROUP],
    AggregationWidgetChartType[AggregationWidgetChartType.VERTICAL],
    AggregationWidgetChartType[AggregationWidgetChartType.VERTICAL_GROUP],
    AggregationWidgetChartType[AggregationWidgetChartType.LINE],
    AggregationWidgetChartType[AggregationWidgetChartType.AREA],
    AggregationWidgetChartType[AggregationWidgetChartType.DONUT],
    AggregationWidgetChartType[AggregationWidgetChartType.METRIC],
    AggregationWidgetChartType[AggregationWidgetChartType.TABLE],
    AggregationWidgetChartType[AggregationWidgetChartType.HEAT_MAP],
    AggregationWidgetChartType[AggregationWidgetChartType.TREE_MAP],
  ];

  public static OVERLAY_ALLOWED_CHART_TYPES = [
    AggregationWidgetChartType[AggregationWidgetChartType.VERTICAL],
    AggregationWidgetChartType[AggregationWidgetChartType.LINE],
  ];

  public static readonly OUT_OF_FOCUS_OPACITY = 0.3;
}
