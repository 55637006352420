/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNumber } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavigationMenuActions, NavigationMenuSelectors } from '@ws1c/intelligence-core';
import { NavigationMenuItem } from '@ws1c/intelligence-models';
import { IntegrationsMenuComponent } from './integrations-menu/integrations-menu.component';
import { MarketplaceMenuComponent } from './marketplace-menu/marketplace-menu.component';
import { NotificationMenuComponent } from './notification-menu/notification-menu.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { WorkspaceMenuComponent } from './workspace-menu/workspace-menu.component';

/**
 * Secondary Navigation Menu
 *
 * @export
 * @class SecondaryNavigationMenuComponent
 * @implements {AfterViewInit}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-secondary-navigation-menu',
  templateUrl: 'secondary-navigation-menu.component.html',
  styleUrls: ['secondary-navigation-menu.component.scss'],
})
export class SecondaryNavigationMenuComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) public containerRef: ViewContainerRef;

  public isSecondaryNavigationMenuCollapsed$: Observable<boolean>;
  public activatedNavMenuItemIndex$: Observable<number>;
  public saveToUserPreference: boolean;
  public sub: Subscription = new Subscription();

  public readonly SECONDARY_NAV_DETAILS_MAP: { [key: string]: Type<any> } = {
    [NavigationMenuItem.INTEGRATIONS]: IntegrationsMenuComponent,
    [NavigationMenuItem.SETTINGS]: SettingsMenuComponent,
    [NavigationMenuItem.NOTIFICATION]: NotificationMenuComponent,
    [NavigationMenuItem.WORKSPACE]: WorkspaceMenuComponent,
    [NavigationMenuItem.MARKETPLACE]: MarketplaceMenuComponent,
  };

  /**
   * Creates an instance of SecondaryNavigationMenuComponent.
   * @param {Store<MerlotState>} store
   * @param {ChangeDetectorRef} changeRef
   * @memberof SecondaryNavigationMenuComponent
   */
  constructor(private store: Store, private changeRef: ChangeDetectorRef) {
    this.isSecondaryNavigationMenuCollapsed$ = this.store.select(NavigationMenuSelectors.isSecondaryNavigationMenuCollapsed);
    this.activatedNavMenuItemIndex$ = this.store.select(NavigationMenuSelectors.activatedNavMenuItemIndex);
  }

  /**
   * ngAfterViewInit
   * @memberof SecondaryNavigationMenuComponent
   */
  public ngAfterViewInit() {
    this.sub.add(
      this.store
        .select(NavigationMenuSelectors.activatedNavMenuItemIndex)
        .pipe(filter(isNumber))
        .subscribe((activatedNavMenuItemIndex: number) => {
          const navComponent = this.SECONDARY_NAV_DETAILS_MAP[activatedNavMenuItemIndex];
          if (!navComponent || !this.containerRef) {
            return;
          }
          this.containerRef.clear();
          this.containerRef.createComponent(navComponent);
          this.changeRef.detectChanges();
        }),
    );
  }

  /**
   * ngOnInit
   */
  public ngOnInit() {
    this.sub.add(
      this.store.select(NavigationMenuSelectors.saveToUserPreference).subscribe((saveToUserPreference: boolean) => {
        this.saveToUserPreference = saveToUserPreference;
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof SecondaryNavigationMenuComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * Handler for secondary nav toggle button click.
   * Emits secondaryNavigationCollapseStateChange event.
   * @param {boolean} isCollapsed
   * @memberof SecondaryNavigationMenuComponent
   */
  public onSecondaryNavToggleBtnClick(isCollapsed: boolean) {
    this.store.dispatch(
      NavigationMenuActions.setSecondaryNavigationCollapsedFlag({ isCollapsed, saveToUserPreference: this.saveToUserPreference }),
    );
  }
}
