/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { ColumnLabelPipe, getColumnTooltipBadgeText, I18NService } from '@ws1c/intelligence-common';
import {
  CoreAppState,
  IntegrationMetaSelectors,
  OrgSelectors,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import { ColumnIndex, ColumnToggleFilter, FilterRule, OrgTreeNode } from '@ws1c/intelligence-models';

/**
 * KeyInfoTooltip
 * @interface
 */
interface KeyInfoTooltip {
  entity?: string;
  label: string;
}

/**
 * RuleTextComponent
 * @export
 * implements {OnInit}
 * implements {OnChanges}
 * implements {OnDestroy}
 * @class RuleTextComponent
 */
@Component({
  selector: 'dpa-rule-text',
  templateUrl: 'rule-text.component.html',
  styleUrls: ['rule-text.component.scss'],
})
export class RuleTextComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public rule: FilterRule;
  @Input() public allColumnsByName: ColumnIndex = {};

  @Output() public valuesSelect = new EventEmitter();

  public key: string;
  public keyTooltipInfo: KeyInfoTooltip;
  public condition: string;
  public value: string;
  public valueCount: number;
  public isKeyNormalized: boolean;
  public orgHierarchy: OrgTreeNode;
  public sub = new Subscription();
  public columnToggleFilterMap: Record<string, ColumnToggleFilter> = {};
  public isAttributeSelectorV2Enabled: boolean = false;
  public readonly columnLabelPipe: ColumnLabelPipe = new ColumnLabelPipe();

  /**
   * Creates an instance of RuleTextComponent.
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18nService
   * @memberof RuleTextComponent
   */
  constructor(private store: Store<CoreAppState>, private i18nService: I18NService) {}

  /**
   * ngOnInit
   * @memberof RuleTextComponent
   */
  public ngOnInit() {
    this.sub.add(
      combineLatest([
        this.store.select(OrgSelectors.getOrgHierarchy),
        this.store.select(IntegrationMetaSelectors.getRawAndNormalizedMapping),
        this.store.select(UserPreferenceFeatureControlsSelectors.isAttributeSelectorV2Enabled),
      ]).subscribe(
        ([orgHierarchy, columnToggleFilterMap, isAttributeSelectorV2Enabled]: [
          OrgTreeNode,
          Record<string, ColumnToggleFilter>,
          boolean,
        ]) => {
          this.orgHierarchy = orgHierarchy;
          this.columnToggleFilterMap = columnToggleFilterMap;
          this.isAttributeSelectorV2Enabled = isAttributeSelectorV2Enabled;
          this.updateDisplayFields();
        },
      ),
    );
  }

  /**
   * ngOnChanges
   * @memberof RuleTextComponent
   */
  public ngOnChanges() {
    if (!this.rule || !this.isRuleValid()) {
      return;
    }

    this.updateDisplayFields();
  }

  /**
   * ngOnDestroy
   * @memberof RuleTextComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * isRuleValid
   * @returns {boolean}
   * @memberof RuleTextComponent
   */
  public isRuleValid(): boolean {
    return this.rule.isValid(this.allColumnsByName);
  }

  /**
   * handleValuesClick
   * @memberof RuleTextComponent
   */
  public handleValuesClick() {
    this.valuesSelect.emit();
  }

  /**
   * updateKeyTooltipInfo
   * @memberof RuleTextComponent
   */
  private updateKeyTooltipInfo() {
    this.isKeyNormalized = undefined;
    const column = this.allColumnsByName[this.rule.attribute];

    if (column) {
      this.keyTooltipInfo = {
        entity: column.entityLabel ?? column.classification?.label,
        label: !this.isAttributeSelectorV2Enabled
          ? column.label
          : `${this.columnLabelPipe.transform(
              column.attribute.fullyQualifiedName,
              this.allColumnsByName,
              this.columnToggleFilterMap,
            )} ${getColumnTooltipBadgeText(column, this.allColumnsByName, this.columnToggleFilterMap, this.i18nService)}`,
      };
      this.isKeyNormalized = column.attribute.isNormalized;
    } else {
      this.keyTooltipInfo = {
        label: this.rule.label,
      };
    }
  }

  /**
   * updateDisplayFields
   * @memberof RuleTextComponent
   */
  private updateDisplayFields() {
    const { name, condition, value } = this.rule.getNormalizedRule(this.allColumnsByName, this.orgHierarchy);
    const translatedValue = typeof value === 'string' ? value : this.i18nService.translate(value.resource, value.data);
    this.key = this.isAttributeSelectorV2Enabled
      ? this.columnLabelPipe.transform(this.rule.attribute, this.allColumnsByName, this.columnToggleFilterMap)
      : name;
    this.condition = condition;
    this.value = translatedValue;

    this.updateKeyTooltipInfo();

    this.valueCount = Array.isArray(this.rule.data) ? this.rule.data.length : 1;
  }
}
