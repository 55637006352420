/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { AdminManagementState } from '@ws1c/admin-management/models';
import { RouterExtensions } from '@ws1c/intelligence-common';
import { NavigationSelectors } from '@ws1c/intelligence-core';
import { AdminManagementCommonActions } from './admin-management-common.actions';

/**
 * AdminManagementCommonEffects
 *
 * @export
 * @class AdminManagementCommonEffects
 */
@Injectable()
export class AdminManagementCommonEffects {
  /**
   * exitAdminsGroupsAddEditFlow$
   * @type {Observable<Action>}
   * @memberof AdminManagementCommonEffects
   */
  public exitAdminsGroupsAddEditFlow$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminManagementCommonActions.exitAdminsGroupsAddEditFlow),
        withLatestFrom(this.store.select(NavigationSelectors.getNavigationReturnPath), (action: Action, returnPath: string) => {
          if (returnPath) {
            this.routerExtensions.navigate([returnPath]);
          } else {
            this.routerExtensions.back();
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * Initializes instance of AdminManagementCommonEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {RouterExtensions} routerExtensions - Router Extensions Service instance
   * @param {Store<AdminManagementState>} store
   * @memberof AdminManagementCommonEffects
   */
  constructor(private actions$: Actions, private routerExtensions: RouterExtensions, private store: Store<AdminManagementState>) {}
}
