/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { AvailableService } from './available-service.model';

/**
 * @exports
 * @enum {number}
 */
export enum ConnectionActionType {
  ADD,
  CREATE,
  DEAUTHORIZE,
  DELETE,
  EDIT,
  RENAME,
  SET_UP,
  VIEW,
  VIEW_ACTIONS,
  VIEW_ERRORS,
}

/**
 * Emitted event that contains the service and the type of action
 * @exports
 * @interface ConnectionActionEvent
 */
export interface ConnectionActionEvent {
  action: ConnectionActionType;
  data: AvailableService;
}
