/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { BaseTemplateSearchComponent } from '@ws1c/intelligence-core/components/template-search/base-template-search.component';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store';
import { Column, COLUMN_NAMES, DataType, Tag, TAGS_TYPE, TEMPLATE_TYPE } from '@ws1c/intelligence-models';

/**
 * WidgetTemplateSearchComponent
 * @export
 * @class WidgetTemplateSearchComponent
 * @extends {BaseTemplateSearchComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-widget-template-search',
  templateUrl: 'widget-template-search.component.html',
  styleUrls: ['widget-template-search.component.scss'],
})
export class WidgetTemplateSearchComponent extends BaseTemplateSearchComponent implements OnInit {
  public isDashboardsEnabled$: Observable<boolean>;

  /**
   * Creates an instance of WidgetTemplateSearchComponent.
   * @param {Store} store
   * @param {I18NService} i18nService
   * @memberof WidgetTemplateSearchComponent
   */
  constructor(public store: Store, public i18nService: I18NService) {
    super(store, i18nService, TEMPLATE_TYPE.WIDGETS);
    this.isDashboardsEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isDashboardsEnabled);
    this.tableColumns = [
      new Column({
        name: COLUMN_NAMES.byName.name,
        label: this.i18nService.translate('COMMON_MESSAGES.WIDGET_NAME'),
        dataType: DataType[DataType.STRING],
        className: 'dpa-dg-col-lg',
      }),
      new Column({
        name: COLUMN_NAMES.byName.description,
        label: this.i18nService.translate('DASHBOARD_ACTIONS.WIDGET_DESCRIPTION'),
        dataType: DataType[DataType.STRING],
        className: 'dpa-dg-col-lg',
      }),
      new Column({
        name: COLUMN_NAMES.byField.chartType,
        label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TYPE'),
        dataType: DataType[DataType.INTEGER],
        className: 'dpa-dg-col-lg',
      }),
      new Column({
        name: COLUMN_NAMES.byName.actions,
        label: this.i18nService.translate('COMMON_MESSAGES.TAGS'),
        className: 'clr-justify-content-end',
      }),
    ];
    this.additionalTags = [
      new Tag({
        name: 'bookmarked',
        label: this.i18nService.translate('COMMON_ACTIONS.BOOKMARKS'),
        type: TAGS_TYPE.BOOLEAN,
      }),
    ];
  }

  /**
   * ngOnInit
   * @memberof WidgetTemplateSearchComponent
   */
  public ngOnInit() {
    super.ngOnInit();
    this.sub.add(
      this.isDashboardsEnabled$.subscribe((isDashboardsEnabled: boolean) => {
        this.actions = this.customActions ?? [
          {
            label: this.i18nService.translate('COMMON_ACTIONS.PREVIEW'),
            onClick: this.onPreviewTemplate.bind(this),
          },
          {
            label: this.i18nService.translate('COMMON_ACTIONS.START'),
            isHidden: () => !isDashboardsEnabled,
            onClick: this.onSelectTemplate.bind(this),
          },
        ];
      }),
    );
  }
}
