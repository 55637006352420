/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { SettingsFilterTag } from '@ws1c/intelligence-models/common/';
import { OAuthPartnerType } from './oauth-connection.enum';
import { TrustNetworkPartnerType } from './trust-network-partner-connection.enum';

/**
 * PartnerConnectionConfig
 * @export
 * @class PartnerConnectionConfig
 */
export class PartnerConnectionConfig {
  public static readonly oAuthPartnerTagLabels: Record<OAuthPartnerType, string> = {
    [OAuthPartnerType.ZOOM]: 'SETTINGS.EMPLOYEE_EXPERIENCE',
  };
  public static readonly oAuthPartnerSettingsTag: Record<OAuthPartnerType, SettingsFilterTag> = {
    [OAuthPartnerType.ZOOM]: SettingsFilterTag.EMPLOYEE_EXPERIENCE,
  };

  public static readonly isPartnerAcknowledgeFormMap: Record<TrustNetworkPartnerType, boolean> = {
    [TrustNetworkPartnerType.BETTERMOBILE]: false,
    [TrustNetworkPartnerType.CHECKPOINT]: false,
    [TrustNetworkPartnerType.CROWDSTRIKE]: false,
    [TrustNetworkPartnerType.EXTREME_NETWORKS]: true,
    [TrustNetworkPartnerType.PRADEO]: false,
    [TrustNetworkPartnerType.WANDERA]: false,
    [TrustNetworkPartnerType.ZIMPERIUM]: false,
  };

  public static readonly employeeExperiencePartners: string[] = [TrustNetworkPartnerType.EXTREME_NETWORKS, OAuthPartnerType.ZOOM];
}
