/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * EdiType
 * @export
 * @enum {string}
 */
export enum EdiType {
  AVAILABLE_LOGICAL_DISK_SPACE = 'Available_Logical_Disk_Space',
  AVAILABLE_LOGICAL_DISK_SPACE_PERCENT = 'Available_Logical_Disk_Space_Percent',
  BIOS = 'Bios',
  BIOS_DATE = 'Bios_Date',
  BITLOCKER_ENCRYPTION = 'BitLocker_Encryption',
  COMPROMISED = 'Compromised',
  CPU_USAGE = 'CPU_Usage',
  DAYS_SINCE_LAST_LOGON = 'Days_Since_Last_Logon',
  DAYS_SINCE_LAST_OS_UPDATE = 'Days_Since_Last_OS_Update',
  DEVICE_FRIENDLY_NAME = 'Device_Friendly_Name',
  DEVICE_MAKE = 'Device_Make',
  DEVICE_SERIAL_NUMBER = 'Device_Serial_Number',
  DEVICE_TAGS = 'Device_Tags',
  ENCRYPTION_METHOD = 'Encryption_Method',
  ENROLLED_USER = 'Enrolled_User',
  ENROLLMENT_AGE = 'Enrollment_Age',
  ENROLLMENT_DATE = 'Enrollment_Date',
  ENROLLMENT_STATUS = 'Enrollment_Status',
  FRIENDLY_VERSION_NAME = 'Friendly_Version_Name',
  DEEM_AGENT_VERSION = 'DEEM_Agent_Version',
  LAST_BOOT_EVENT = 'Last_Boot_Event',
  LAST_LOGON_DATE = 'Last_logon_Date',
  LAST_LOGON_USER = 'Last_Logon_User',
  LAST_OS_UPDATE_DATE = 'Last_OS_Update_Date',
  MEMORY = 'Memory',
  OS_VERSION = 'OS_Version',
  PENDING_OS_UPDATE = 'Pending_OS_Update',
  PROCESSOR = 'Processor',
  RECOVERY_KEY_ESCROWED = 'Recovery_Key_Escrowed',
  SYSTEM = 'System',
  TIME_SINCE_LAST_BOOT = 'Time_Since_Last_Boot',
  TIMEZONE = 'Timezone',
  TPM_ENABLED = 'TPM_Enabled',
  TOTAL_PHYSICAL_MEMORY = 'Total_Physical_Memory',
}

/**
 * DeviceInfoType
 * @export
 * @enum {string}
 */
export enum DeviceInfoType {
  //device
  ENROLLED_USER = 'ENROLLED_USER',
  PROCESS_CPU_TIME = 'PROCESS_CPU_TIME',
  ENROLLMENT_STATUS = 'ENROLLMENT_STATUS',
  LOGICAL_CPU_CORES = 'LOGICAL_CPU_CORES',
  DEVICE_FRIENDLY_NAME = 'DEVICE_FRIENDLY_NAME',
  PHYSICAL_CPU_CORES = 'PHYSICAL_CPU_CORES',
  CPU_USAGE = 'CPU_USAGE',
  AVAILABLE_PHYSICAL_MEMORY = 'AVAILABLE_PHYSICAL_MEMORY',
  TOTAL_PHYSICAL_MEMORY = 'TOTAL_PHYSICAL_MEMORY',
  MEMORY_USAGE = 'MEMORY_USAGE',
  OS_VERSION = 'OS_VERSION',
  MODEL = 'MODEL',
  SERIAL_NUMBER = 'SERIAL_NUMBER',
  AVAILABLE_DISK_SPACE = 'AVAILABLE_DISK_SPACE',
  TOTAL_DISK_SPACE = 'TOTAL_DISK_SPACE',
  MANUFACTURER = 'MANUFACTURER',
  AVAILABLE_SD_CARD_SPACE = 'AVAILABLE_SD_CARD_SPACE',
  OWNERSHIP = 'OWNERSHIP',
  TIMEZONE = 'TIMEZONE',
  SHARED_DEVICE = 'SHARED_DEVICE',
  MAC_ADDRESS = 'MAC_ADDRESS',
  LOCATION_GROUP_NAME = 'LOCATION_GROUP_NAME',
  PUBLIC_IP_ADDRESS = 'PUBLIC_IP_ADDRESS',
  LOCATION_GROUP_GUID = 'LOCATION_GROUP_GUID',
  DEVICE_TAGS = 'DEVICE_TAGS',
  //preferences
  LOCATION_SERVICE = 'LOCATION_SERVICE',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  SCREEN_BRIGHTNESS = 'SCREEN_BRIGHTNESS',
  //battery
  BATTERY_STATUS = 'BATTERY_STATUS',
  BATTERY_CHARGING_STATUS = 'BATTERY_CHARGING_STATUS',
  BATTERY_LEVEL = 'BATTERY_LEVEL',
  BATTERY_CHARGING_RATE = 'BATTERY_CHARGING_RATE',
  BATTERY_TYPE = 'BATTERY_TYPE',
  TIME_SPENT_CHARGING = 'TIME_SPENT_CHARGING',
  BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
  BATTERY_PLUGGED_IN_TYPE = 'BATTERY_PLUGGED_IN_TYPE',
  BATTERY_TEMPERATURE = 'BATTERY_TEMPERATURE',
  TIME_SINCE_UNPLUGGED = 'TIME_SINCE_UNPLUGGED',
  MANUFACTURER_DATE = 'MANUFACTURER_DATE',
  BATTERY_CHARGE_CYCLE = 'BATTERY_CHARGE_CYCLE',
  //network
  NETWORK_CONNECTION_TYPE = 'NETWORK_CONNECTION_TYPE',
  MOBILE_SIGNAL_STRENGTH = 'MOBILE_SIGNAL_STRENGTH',
  WIFI_STATUS = 'WIFI_STATUS',
  MOBILE_NETWORK_TYPE = 'MOBILE_NETWORK_TYPE',
  SSID = 'SSID',
  MOBILE_RADIO_STATUS = 'MOBILE_RADIO_STATUS',
  BSSID = 'BSSID',
  LATENCY = 'LATENCY',
  WIFI_FREQUENCY = 'WIFI_FREQUENCY',
  JITTER = 'JITTER',
  WIFI_CONFIGURATION_STATUS = 'WIFI_CONFIGURATION_STATUS',
  ROAMING_STATUS = 'ROAMING_STATUS',
  NETWORK_DOWNLOAD_SPEED = 'NETWORK_DOWNLOAD_SPEED',
  MULTICAST = 'MULTICAST',
  NETWORK_UPLOAD_SPEED = 'NETWORK_UPLOAD_SPEED',
  VPN_CONNECTION = 'VPN_CONNECTION',
  //history
  LAST_BOOT_DATE = 'LAST_BOOT_DATE',
  TIME_SINCE_LAST_BOOT = 'TIME_SINCE_LAST_BOOT',
  SLEEP_DURATION = 'SLEEP_DURATION',
  ENROLLMENT_DATE = 'ENROLLMENT_DATE',
  WAKE_DURATION = 'WAKE_DURATION',
  ENCRYPTION_STATUS = 'ENCRYPTION_STATUS',
  LOST_MODE = 'LOST_MODE',
  SAFE_MODE = 'SAFE_MODE',
}
