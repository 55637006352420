/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { FormControl, FormRecord } from '@angular/forms';

import { DeemPlaybookStepItemType } from '@ws1c/intelligence-models';

/**
 * DeemPlaybooksConfig
 * @export
 * @class DeemPlaybooksConfig
 */
export class DeemPlaybooksConfig {
  public static readonly MAX_PLAYBOOK_STEPS = 20;
  public static readonly MAX_PLAYBOOK_STEP_ITEMS = 20;

  public static readonly iconShapeByStepItem: Record<DeemPlaybookStepItemType, string> = {
    [DeemPlaybookStepItemType.WIDGET]: 'layers',
    [DeemPlaybookStepItemType.REPORT]: 'tasks',
    [DeemPlaybookStepItemType.LINK]: 'link',
    [DeemPlaybookStepItemType.IMAGE]: 'image',
    [DeemPlaybookStepItemType.VIDEO]: 'play',
    [DeemPlaybookStepItemType.QUICK_ACTION]: 'dpa-workflow',
    [DeemPlaybookStepItemType.RCA]: 'target',
    [DeemPlaybookStepItemType.DEVICE_TIMELINE]: 'devices',
  } as const;

  /**
   * getEmptyStepItemForm
   * @static
   * @param {DeemPlaybookStepItemType} stepItemType
   * @returns {FormRecord}
   * @memberof DeemPlaybooksConfig
   */
  public static getEmptyStepItemForm = (stepItemType: DeemPlaybookStepItemType): FormRecord =>
    new FormRecord({
      type: new FormControl<DeemPlaybookStepItemType>(stepItemType),
    });
}
