/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { breadthFirstBy, GenericObject, reverseBreadthFirstBy } from '@dpa/ui-common';
import { groupBy, map } from 'lodash-es';

import { FocusedSeries } from '@ws1c/intelligence-models/dashboard/chart-drilldown-event.interface';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { NgxTrendResultFlattener } from './ngx-trend-result-flattener.model';

export interface NgxSingleData {
  name: string;
  value: any;
  series?: NgxSingleData[];
  extra?: GenericObject;

  // line chart range
  min?: number;
  max?: number;

  // bubble chart format
  x?: any;
  y?: number;
  r?: number;
}

/**
 * NgxChartDataBuilder
 * @export
 * @class NgxChartDataBuilder
 */
export class NgxChartDataBuilder {
  // Match data ranges such as '30-50', '<=30', etc.
  public static readonly RANGE_FORMAT_REGEX = /(((>|<)=?\s*\d+)|(\d+%?\s*\-\s*\d+%?))/;
  // Match integers and decimals.
  public static readonly NUMBER_REGEX = /\d+(\.\d+)?/g;
  public static readonly LEAF_VALUE = '__LEAF_VALUE';
  public static readonly aggregationFunctionByName: any = {
    COUNT: (memo: number, value: number) => memo + value,
    MIN: (memo: number, value: number) => Math.min(memo, value),
    MAX: (memo: number, value: number) => Math.max(memo, value),
  };

  /**
   * constructor
   * @param {string[]} groupBys
   * @param {any[]} flatTrendResults
   * @param {string} aggregationFunctionName
   * @param {any} dataTypesByBucketingAttributeKey
   * @param {any} timestampsByFormattedTimes
   * @param {any} formatter
   * @param {boolean} isInvertMode
   * @memberof NgxChartDataBuilder
   */
  constructor(
    private groupBys: string[],
    private flatTrendResults: any[],
    // @ts-ignore
    private aggregationFunctionName: string,
    private dataTypesByBucketingAttributeKey: any,
    private timestampsByFormattedTimes: any,
    private formatter: any,
    private isInvertMode: boolean,
  ) {}

  /**
   * buildRootNode
   * @param {boolean} [skipSort]
   * @returns {NgxSingleData}
   * @memberof NgxChartDataBuilder
   */
  public buildRootNode(skipSort?: boolean): NgxSingleData {
    // formatter should only be for orginal groupBys size = 1
    const nestedDataset = this.buildNestedDataset(this.groupBys, this.flatTrendResults, this.groupBys.length === 1);
    const rootNode = {
      name: 'root',
      series: nestedDataset,
    } as NgxSingleData;
    this.setValuesDeep(rootNode, skipSort);
    return rootNode;
  }

  /**
   * buildRangedDataset
   * @param {any[]} flatTrendResults
   * @returns {{series: NgxSingleData[], name: string}[]}
   * @memberof NgxChartDataBuilder
   */
  public buildRangedDataset(flatTrendResults: any[] = this.flatTrendResults) {
    const grouped = groupBy(flatTrendResults, this.groupBys[0]);
    const series = map(grouped, (childTrendResults: any[]) => {
      return map(childTrendResults, (trendResults) => ({
        name: trendResults[NgxTrendResultFlattener.START_STR],
        series: [],
        ...trendResults[NgxTrendResultFlattener.RANGE_FLATTENER],
      }));
    }).flat();

    return [
      {
        name: flatTrendResults?.[0]?.[NgxTrendResultFlattener.FAKE_GROUP_BY_KEY] ?? '',
        series,
        value: undefined,
      },
    ];
  }

  /**
   * buildNestedDataset
   * @param {string[]} groupBys
   * @param {any[]} flatTrendResults
   * @param {boolean} applyFormatter
   * @returns {NgxSingleData[]}
   * @memberof NgxChartDataBuilder
   */
  public buildNestedDataset(groupBys: string[], flatTrendResults: any[], applyFormatter: boolean = false): NgxSingleData[] {
    if (!groupBys.length) {
      return this.buildFlatSeries(flatTrendResults);
    }
    const groupedTrendResults = groupBy(flatTrendResults, groupBys[0]);
    return map(groupedTrendResults, (childFlatTrendResults: any[], groupedValue: string) => {
      return {
        name: this.formatter && applyFormatter ? this.formatter(groupedValue) : groupedValue,
        series: this.buildNestedDataset(groupBys.slice(1), childFlatTrendResults, applyFormatter),
      } as NgxSingleData;
    });
  }

  /**
   * buildFlatSeries
   * @param {any[]} flatTrendResults
   * @returns {NgxSingleData[]}
   * @memberof NgxChartDataBuilder
   */
  public buildFlatSeries(flatTrendResults: any[]): NgxSingleData[] {
    // This extra level is in case there are multiple trendResults, and nothing left to group by
    // If that happens, flatTrendResults will contain more than 1 result
    // The extra level, makes sure these values will be aggregated along with the other data
    return flatTrendResults?.map((flatTrendResult: any): NgxSingleData => {
      return {
        name: NgxChartDataBuilder.LEAF_VALUE,
        // All aggregation functions except LATEST would return 0 when the data is NULL. But LATEST
        // can't use 0 to represent NULL due to it breaks data type when the data type is not
        // number (Integer/Long/Float/Double) or DateTime. LATEST will responses NULL when the
        // result is NULL. When value is NULL meaning trendResult returns without "value" property,
        // value needs to convert to 0 to make sure data is processed correctly to build chart data.
        value: flatTrendResult[NgxTrendResultFlattener.COUNTER_KEY] || 0,
      };
    });
  }

  /**
   * setValuesDeep
   * @param {NgxSingleData[]} node
   * @param {boolean} skipSort
   * @param {FocusedSeries} focusedSeries
   * @memberof NgxChartDataBuilder
   */
  public setValuesDeep(node: NgxSingleData, skipSort: boolean = false, focusedSeries?: FocusedSeries) {
    const aggregationFunction = NgxChartDataBuilder.aggregationFunctionByName.COUNT;

    // Avoid filtering out 0s and sorting by value for series grouped by date
    // Series grouped by date are for an axis
    const dateDepth = this.groupBys.findIndex((groupByAttr: string) => {
      return groupByAttr === NgxTrendResultFlattener.DATE_KEY;
    });

    const dateTimeDepths = new Set([dateDepth]);
    this.groupBys.forEach((groupByAttribute: string, index: number) => {
      if (this.dataTypesByBucketingAttributeKey[groupByAttribute] === DataType[DataType.DATETIME]) {
        dateTimeDepths.add(index);
      }
    });

    const focusedSeriesDepth = this.groupBys.findIndex((groupByAttribute: string) => {
      return focusedSeries && groupByAttribute === focusedSeries.colorizedAttributeName;
    });

    reverseBreadthFirstBy(node, 'series', (data: NgxSingleData, depth: number) => {
      if (data.series && data.series.length) {
        // remove unfocused series before calculating aggregation
        const isFocusedSeriesDepth: boolean = focusedSeriesDepth === depth;
        if (isFocusedSeriesDepth && focusedSeries.seriesNames.length) {
          const focusedSeriesNames = new Set(focusedSeries.seriesNames);
          data.series = data.series.filter((datum: NgxSingleData) => {
            return focusedSeriesNames.has(datum.name);
          });
        }

        data.value = data.series.map((singleData: NgxSingleData) => singleData.value).reduce(aggregationFunction, 0);

        const isDateTimeDepth: boolean = dateTimeDepths.has(depth);
        if (!isDateTimeDepth) {
          data.series = data.series.filter((datum: NgxSingleData) => {
            if (datum.name === NgxChartDataBuilder.LEAF_VALUE) {
              return;
            }
            return datum.value;
          });
        }

        // Donut chart sorts groups by their value instead of by name if an "others" grouping is applied
        // This allows keeping that sort order instead of resorting the series by name
        if (!skipSort) {
          data.series.sort((a: NgxSingleData, b: NgxSingleData) => {
            if (isDateTimeDepth) {
              return this.timestampsByFormattedTimes[a.name] - this.timestampsByFormattedTimes[b.name];
            }
            if (!isNaN(Number(a.name)) && !isNaN(Number(b.name))) {
              return Number(a.name) - Number(b.name);
            }
            if (this.isRangeFormat(a.name) || this.isRangeFormat(b.name)) {
              return this.compareDataRange(a.name, b.name);
            }
            return a.name.localeCompare(b.name);
          });
        }
      }
    });

    if (this.isInvertMode) {
      breadthFirstBy(node, 'series', (data: NgxSingleData, _depth: number) => {
        if (data.value) {
          data.value = 1 / data.value;
        }
      });
    }
  }

  /**
   * buildNestedBubbleDataset
   * @param  {string[]} groupBys
   * @param  {any[]} flatTrendResults
   * @returns {NgxSingleData[]}
   * @memberof NgxChartDataBuilder
   */
  public buildNestedBubbleDataset(groupBys: string[] = this.groupBys, flatTrendResults: any[] = this.flatTrendResults): NgxSingleData[] {
    if (!groupBys.length) {
      return this.buildFlatBubbleSeries(flatTrendResults);
    }

    const grouped = groupBy(flatTrendResults, groupBys[0]);
    return map(grouped, (childFlatTrendResults: any[], groupedValue: string) => {
      return {
        name: groupedValue,
        series: this.buildFlatBubbleSeries(childFlatTrendResults),
        value: undefined,
      } as NgxSingleData;
    });
  }

  /**
   * buildFlatBubbleSeries
   * @param  {any[]} flatTrendResults
   * @returns {NgxSingleData[]}
   * @memberof NgxChartDataBuilder
   */
  public buildFlatBubbleSeries(flatTrendResults: any[]): NgxSingleData[] {
    return flatTrendResults?.map((flatTrendResult: any) => {
      return {
        name: '',
        x: flatTrendResult[NgxTrendResultFlattener.START_DRILLDOWN_KEY],
        y: flatTrendResult[NgxTrendResultFlattener.COUNTER_KEY_MULTI][0],
        r: flatTrendResult[NgxTrendResultFlattener.COUNTER_KEY_MULTI][1],
        value: undefined,
      } as NgxSingleData;
    });
  }

  /**
   * isRangeFormat
   * @param {string} inputString
   * @returns {boolean}
   * @memberof NgxChartDataBuilder
   */
  public isRangeFormat(inputString: string) {
    return NgxChartDataBuilder.RANGE_FORMAT_REGEX.test(inputString);
  }

  /**
   * compareStringAsNumber
   *
   * Return a negative value if first argument is less than second argument, zero if they're equal and a positive value otherwise.
   * @param {string} a
   * @param {string} b
   * @returns {0 | 1 | -1}
   * @memberof NgxChartDataBuilder
   */
  private compareStringAsNumber(a: string, b: string): 0 | 1 | -1 {
    const integerA = parseInt(a, 10);
    const integerB = parseInt(b, 10);

    if (integerA > integerB) {
      return 1;
    }
    return integerA === integerB ? 0 : -1;
  }

  /**
   * compareDataRange
   *
   * Customized compare function, based on compareStringAsNumber.
   * Only positive numbers can be processed
   * @param {string} a
   * @param {string} b
   * @returns {0 | 1 | -1}
   * @memberof NgxChartDataBuilder
   */
  private compareDataRange(a: string, b: string): 0 | 1 | -1 {
    // matches any digit (global search)
    const matchedA = a.match(NgxChartDataBuilder.NUMBER_REGEX);
    const matchedB = b.match(NgxChartDataBuilder.NUMBER_REGEX);

    // handle null case
    if (matchedA === null) {
      return -1;
    }
    if (matchedB === null) {
      return 1;
    }

    const result = this.compareStringAsNumber(matchedA[0], matchedB[0]);

    // if the result is zero, continue to compare the second one
    if (result === 0) {
      return this.compareStringAsNumber(matchedA[1] ?? matchedA[0], matchedB[1] ?? matchedB[0]);
    } else {
      return result;
    }
  }
}
