/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, PagedResponse } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { find, get, isEmpty } from 'lodash-es';

import { SuggestedApp } from '@dpa-shared-merlot/model';
import { getAppErrorDetailsGroupEntity } from '@dpa-shared-merlot/store/dashboard/apps/app-deployment-dashboard-selector-helpers';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { FeatureControl } from '@ws1c/intelligence-common';
import { IntegrationMetaSelectors } from '@ws1c/intelligence-core/store/integration-meta';
import { UserPreferenceCommonSelectors, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference';
import {
  AirwatchAppSearchItem,
  App,
  AppDetailTabType,
  AppErrorDetailTabType,
  AppErrorType,
  AppRegistration,
  AppSearchRequest,
  Category,
  CategoryIndex,
  Column,
  COLUMN_NAMES,
  ComparisonQueryResponse,
  Entity,
  FeatureControls,
  getUniqueId,
  Integration,
  RegisteredAppSearchResponse,
  SuggestionFilterBy,
  WizardDialogMode,
} from '@ws1c/intelligence-models';
import { getSuggestionFilterBys } from './app-loads-selector-helpers';
import { AppLoadsState } from './app-loads.state';

/**
 * AppLoadsSelectors
 * @exports
 * @class AppLoadsSelectors
 */
export class AppLoadsSelectors {
  /**
   * appLoadsState
   * @type {MemoizedSelector<MerlotState, AppLoadsState>}
   * @memberOf AppLoadsSelectors
   */
  public static appLoadsState: Selector<MerlotState, AppLoadsState> = (state: MerlotState) => state.appLoadsState;

  /**
   * hasPluginExceptionEventCount
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static hasPluginExceptionEventCount: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => !!state.selectedAppPluginExceptionEventCount,
  );

  /**
   * getAppSearchList
   * @static
   * @type {MemoizedSelector<MerlotState, AirwatchAppSearchItem[]>}
   * @memberof AppLoadsSelectors
   */
  public static getAppSearchList: MemoizedSelector<MerlotState, AirwatchAppSearchItem[]> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.appSearchList,
  );

  /**
   * getAppLoadsSuggestedApps
   * @static
   * @type {MemoizedSelector<MerlotState, SuggestedApp[]>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsSuggestedApps: MemoizedSelector<MerlotState, SuggestedApp[]> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.suggestedApps,
  );

  /**
   * getAppLoadsSearchRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppSearchRequest>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsSearchRequest: MemoizedSelector<MerlotState, AppSearchRequest> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.request,
  );

  /**
   * isLoadingAppList
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isLoadingAppList: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.loading,
  );

  /**
   * getNonDeployedApps
   * @static
   * @type {MemoizedSelector<MerlotState, PagedResponse>}
   * @memberof AppLoadsSelectors
   */
  public static getNonDeployedApps: MemoizedSelector<MerlotState, PagedResponse> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => ({ results: state.nonDeployedApps } as PagedResponse),
  );

  /**
   * hasNonDeployedApps
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static hasNonDeployedApps: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => !!(state.nonDeployedApps && state.nonDeployedApps.length),
  );

  /**
   * getAppLoadsWizardMode
   * @static
   * @type {MemoizedSelector<MerlotState, WizardDialogMode>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsWizardMode: MemoizedSelector<MerlotState, WizardDialogMode> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.wizardDialogMode,
  );

  /**
   * getSelectedApteligentApp
   * @static
   * @type {MemoizedSelector<MerlotState, AppRegistration>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedApteligentApp: MemoizedSelector<MerlotState, AppRegistration> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.selectedApteligentApp,
  );

  /**
   * getSelectedApteligentApps
   * @static
   * @type {MemoizedSelector<MerlotState, AppRegistration[]>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedApteligentApps: MemoizedSelector<MerlotState, AppRegistration[]> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.selectedApteligentApps,
  );

  /**
   * hasSelectedApteligentApps
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static hasSelectedApteligentApps: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => !!(state.selectedApteligentApps && state.selectedApteligentApps.length),
  );

  /**
   * getAppErrorCrashId
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppLoadsSelectors
   */
  public static getAppErrorCrashId: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.appErrorCrashId,
  );

  /**
   * getAppErrorHeParams
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof AppLoadsSelectors
   */
  public static getAppErrorHeParams: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.appErrorHeParams,
  );

  /**
   * getSelectedApps
   * @static
   * @type {MemoizedSelector<MerlotState, App[]>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedApps: MemoizedSelector<MerlotState, App[]> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.selectedApps,
  );

  /**
   * isSelectedAppIdmOnly
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isSelectedAppIdmOnly: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.selectedApp?.integration === Integration.IDM,
  );

  /**
   * getRegisteredApps
   * @static
   * @type {MemoizedSelector<MerlotState, AppRegistration[]>}
   * @memberof AppLoadsSelectors
   */
  public static getRegisteredApps: MemoizedSelector<MerlotState, AppRegistration[]> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.registeredApps,
  );

  /**
   * getAppLoadsAddAppShowModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsAddAppShowModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.showAddAppModal,
  );

  /**
   * getAppLoadsSendEmailModalState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsSendEmailModalState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.showSendEmailModal,
  );

  /**
   * isSendingEmail
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isSendingEmail: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.sendingEmail,
  );

  /**
   * getAllRegisteredApps
   * @static
   * @type {MemoizedSelector<MerlotState, AppRegistration[]>}
   * @memberof AppLoadsSelectors
   */
  public static getAllRegisteredApps: MemoizedSelector<MerlotState, AppRegistration[]> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.allRegisteredApps,
  );

  /**
   * getRegisteredAppRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppSearchRequest>}
   * @memberof AppLoadsSelectors
   */
  public static getRegisteredAppRequest: MemoizedSelector<MerlotState, AppSearchRequest> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.registeredAppRequest,
  );

  /**
   * getAppLoadsRegisteredAppResponse
   * @static
   * @type {MemoizedSelector<MerlotState, RegisteredAppSearchResponse>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsRegisteredAppResponse: MemoizedSelector<MerlotState, RegisteredAppSearchResponse> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.registeredAppResponse,
  );

  /**
   * isLoadingOrHasRegisteredApps
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isLoadingOrHasRegisteredApps: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.isLoadingAppList,
    AppLoadsSelectors.getAppLoadsRegisteredAppResponse,
    AppLoadsSelectors.getRegisteredAppRequest,
    (isLoading: boolean, searchResponse: RegisteredAppSearchResponse, searchRequest: AppSearchRequest) => {
      const hasResults = searchResponse && searchResponse.total > 0;
      const hasSearchTerm = searchRequest && searchRequest.searchTerm && !isEmpty(searchRequest.searchTerm.value);
      return isLoading || hasResults || hasSearchTerm;
    },
  );

  /**
   * getAptTrendCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppLoadsSelectors
   */
  public static getAptTrendCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    AppLoadsSelectors.getAppLoadsRegisteredAppResponse,
    (response: RegisteredAppSearchResponse) => {
      return {
        firstCount: undefined,
        lastCount: response ? response.total : 0,
        ratio: undefined,
      } as ComparisonQueryResponse;
    },
  );

  /**
   * getAppLoadsUserAndAppRegistrationDone
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsUserAndAppRegistrationDone: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.userAndAppRegistrationDone,
  );

  /**
   * isDeleteModalOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isDeleteModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.isDeleteModalOpen,
  );

  /**
   * isMultiDeleteModalOpen
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isMultiDeleteModalOpen: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.isMultiDeleteModalOpen,
  );

  /**
   * isDeletingApps
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isDeletingApps: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.isDeletingApps,
  );

  /**
   * getProductivityAppRequest
   * @static
   * @type {MemoizedSelector<MerlotState, AppSearchRequest>}
   * @memberof AppLoadsSelectors
   */
  public static getProductivityAppRequest: MemoizedSelector<MerlotState, AppSearchRequest> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.productivityAppRequest,
  );

  /**
   * getAppLoadsProductivityAppResponse
   * @static
   * @type {MemoizedSelector<MerlotState, RegisteredAppSearchResponse>}
   * @memberof AppLoadsSelectors
   */
  public static getAppLoadsProductivityAppResponse: MemoizedSelector<MerlotState, RegisteredAppSearchResponse> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.productivityAppResponse,
  );

  /**
   * isLoadingOrHasProductivityApps
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isLoadingOrHasProductivityApps: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.isLoadingAppList,
    AppLoadsSelectors.getAppLoadsProductivityAppResponse,
    AppLoadsSelectors.getProductivityAppRequest,
    (isLoading: boolean, searchResponse: RegisteredAppSearchResponse, searchRequest: AppSearchRequest) => {
      const hasResults = searchResponse && searchResponse.total > 0;
      const hasSearchTerm = searchRequest && searchRequest.searchTerm && !isEmpty(searchRequest.searchTerm.value);
      return isLoading || hasResults || hasSearchTerm;
    },
  );

  /**
   * getMobileProductivityTrendCounter
   * @static
   * @type {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof AppLoadsSelectors
   */
  public static getMobileProductivityTrendCounter: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    AppLoadsSelectors.getAppLoadsProductivityAppResponse,
    (response: RegisteredAppSearchResponse) => {
      return {
        firstCount: undefined,
        lastCount: response ? response.total : 0,
        ratio: undefined,
      } as ComparisonQueryResponse;
    },
  );

  /**
   * getSelectedApp
   * @static
   * @type {MemoizedSelector<MerlotState, App>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedApp: MemoizedSelector<MerlotState, App> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.selectedApp,
  );

  /**
   * getSelectedAppName
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedAppName: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    (selectedApp: App) => selectedApp?.name,
  );

  /**
   * isProductivityAppSelector
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isProductivityAppSelector: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    (selectedApp: App) => !!selectedApp?.isProductivityApp,
  );

  /**
   * isPluginExceptionAvailableState
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isPluginExceptionAvailableState: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.hasPluginExceptionEventCount,
    (hasEventCount: boolean) => hasEventCount,
  );

  /**
   * getSelectedAppId
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedAppId: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    (selectedApp: App) => selectedApp?.id,
  );

  /**
   * getSelectedApteligentId
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedApteligentId: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    (selectedApp: App) => selectedApp?.apteligentAppId,
  );

  /**
   * getSelectedAppPlatform
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppLoadsSelectors
   */
  public static getSelectedAppPlatform: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    (selectedApp: App) => selectedApp?.platform,
  );

  /**
   * getIsApteligentAppSelected
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static getIsApteligentAppSelected: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.getSelectedApteligentId,
    (apteligentId: string) => !!apteligentId,
  );

  /**
   * getIsApteligentBannerVisible
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static getIsApteligentBannerVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    AppLoadsSelectors.getSelectedApp,
    AppLoadsSelectors.getIsApteligentAppSelected,
    (app: App, isApteligentApp: boolean) => !!app?.isInternalApp && !isApteligentApp,
  );

  /**
   * getCrashAppVersionSuggestionFilterBys
   * @static
   * @type {MemoizedSelector<MerlotState, SuggestionFilterBy[]>}
   * @memberof AppLoadsSelectors
   */
  public static getCrashAppVersionSuggestionFilterBys: MemoizedSelector<MerlotState, SuggestionFilterBy[]> = createSelector(
    AppLoadsSelectors.getSelectedApteligentId,
    (appteligentId: string) =>
      appteligentId
        ? [
            new SuggestionFilterBy({
              fieldName: COLUMN_NAMES.byFullyQualifiedName.apteligent_appload_app_id,
              values: [appteligentId],
            }),
          ]
        : [],
  );

  /**
   * getAppDetailTabType
   * @static
   * @type {MemoizedSelector<MerlotState, AppDetailTabType>}
   * @memberof AppLoadsSelectors
   */
  public static getAppDetailTabType: MemoizedSelector<MerlotState, AppDetailTabType> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.appDetailTabType,
  );

  /**
   * isApteligentUserFlowsDashboardVisibleState
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isApteligentUserFlowsDashboardVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isApteligentUserFlowsDashboardEnabled,
    AppLoadsSelectors.getIsApteligentAppSelected,
    (isEnabled: boolean, isApteligentApp: boolean) => isEnabled && isApteligentApp,
  );

  /**
   * isApteligentNetworkInsightsDashboardVisibleState
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isApteligentNetworkInsightsDashboardVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isApteligentNetworkInsightsDashboardEnabled,
    AppLoadsSelectors.getIsApteligentAppSelected,
    (isEnabled: boolean, isApteligentApp: boolean) => isEnabled && isApteligentApp,
  );

  /**
   * isApteligentErrorsDashboardVisible
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isApteligentErrorsDashboardVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isApteligentErrorsDashboardEnabled,
    AppLoadsSelectors.getIsApteligentAppSelected,
    (isEnabled: boolean, isApteligentApp: boolean) => isEnabled && isApteligentApp,
  );

  /**
   * isProductivityAppFeatureAvailable
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isProductivityAppFeatureAvailable: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled,
    AppLoadsSelectors.isProductivityAppSelector,
    (isStandaloneApteligentEnabled: boolean, isProductivityApp: boolean) => {
      // Should be hidden for productivity apps in the Enterprise env
      if (isProductivityApp && !isStandaloneApteligentEnabled) {
        return false;
      }
      return true;
    },
  );

  /**
   * getAppErrorDetailType
   * @static
   * @type {MemoizedSelector<MerlotState, AppErrorType>}
   * @memberof AppLoadsSelectors
   */
  public static getAppErrorDetailType: MemoizedSelector<MerlotState, AppErrorType> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.appErrorDetailType,
  );

  /**
   * getAppErrorDetailsGroupEntity
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof AppLoadsSelectors
   */
  public static getAppErrorDetailsGroupEntity: MemoizedSelector<MerlotState, string> = createSelector(
    AppLoadsSelectors.getAppErrorDetailType,
    AppLoadsSelectors.getSelectedAppPlatform,
    getAppErrorDetailsGroupEntity,
  );

  /**
   * getAppErrorDetailTabType
   *
   * @static
   * @type {MemoizedSelector<MerlotState, AppErrorDetailTabType>}
   * @memberof AppLoadsSelectors
   */
  public static getAppErrorDetailTabType: MemoizedSelector<MerlotState, AppErrorDetailTabType> = createSelector(
    AppLoadsSelectors.appLoadsState,
    (state: AppLoadsState) => state.appErrorDetailTabType,
  );

  /**
   * getAppDashboardFilterCategory
   *
   * @static
   * @type {MemoizedSelector<MerlotState, Category>}
   * @memberof AppLoadsSelectors
   */
  public static getAppDashboardFilterCategory: MemoizedSelector<MerlotState, Category> = createSelector(
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled,
    (categoriesById: CategoryIndex, isStandalone: boolean) => {
      return isStandalone
        ? categoriesById[getUniqueId(Integration.APTELIGENT, Entity.APPLOAD)]
        : categoriesById[getUniqueId(Integration.AIRWATCH, Entity.APPLICATION)];
    },
  );

  /**
   * getAppDashboardColumns
   *
   * @static
   * @type {MemoizedSelector<MerlotState, Column[]>}
   * @memberof AppLoadsSelectors
   */
  public static getAppDashboardColumns: MemoizedSelector<MerlotState, Column[]> = createSelector(
    AppLoadsSelectors.getAppDashboardFilterCategory,
    IntegrationMetaSelectors.getColumnsByCategory,
    UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled,
    (category: Category, columnsByCategory: Map<Category, Column[]>, isStandalone: boolean) => {
      const columns = columnsByCategory.get(category);
      const defaultColumns = new Array(COLUMN_NAMES.byName._app_version);
      if (isStandalone) {
        defaultColumns.push(COLUMN_NAMES.byName._consumer_org_id);
      }
      return columns ? columns.filter((column: Column) => defaultColumns.includes(column.name)) : [];
    },
  );

  /**
   * getSuggestionFilterBys
   *
   * @static
   * @type {MemoizedSelector<MerlotState, SuggestionFilterBy[]>}
   * @memberof AppLoadsSelectors
   */
  public static getSuggestionFilterBys: MemoizedSelector<MerlotState, SuggestionFilterBy[]> = createSelector(
    AppLoadsSelectors.getAppDashboardFilterCategory,
    AppLoadsSelectors.getSelectedApp,
    (category: Category, selectedApp: App) => {
      return getSuggestionFilterBys(selectedApp, category?.integration.name);
    },
  );

  /**
   * isAppErrorsCategoryAvailable
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isAppErrorsCategoryAvailable: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationMetaSelectors.getCategoriesByIntegrationByName,
    (categoriesByIntegrationByName: GenericObject) => {
      return Boolean(get(categoriesByIntegrationByName, [Integration.EMPLOYEE_EXPERIENCE, Entity.DEVICES]));
    },
  );

  /**
   * isUserFlowsCategoryAvailable
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isUserFlowsCategoryAvailable: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationMetaSelectors.getCategoriesByIntegrationByName,
    (categoriesByIntegrationByName: GenericObject) => {
      return Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.USER_FLOW]));
    },
  );

  /**
   * isNetworkInsightsCategoryAvailable
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isNetworkInsightsCategoryAvailable: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationMetaSelectors.getCategoriesByIntegrationByName,
    (categoriesByIntegrationByName: GenericObject) => {
      return (
        Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.NET_EVENT])) &&
        Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.NET_ERROR]))
      );
    },
  );

  /**
   * isCrashesCategoryAvailable
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isCrashesCategoryAvailable: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationMetaSelectors.getCategoriesByIntegrationByName,
    (categoriesByIntegrationByName: GenericObject) => {
      return (
        Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.CRASH_IOS])) &&
        Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.CRASH_ANDROID]))
      );
    },
  );

  /**
   * isHandledExceptionCategoryAvailable
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isHandledExceptionCategoryAvailable: MemoizedSelector<MerlotState, boolean> = createSelector(
    IntegrationMetaSelectors.getCategoriesByIntegrationByName,
    (categoriesByIntegrationByName: GenericObject) => {
      return (
        Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.HANDLED_EXCEPTION_IOS])) &&
        Boolean(get(categoriesByIntegrationByName, [Integration.APTELIGENT, Entity.HANDLED_EXCEPTION_ANDROID]))
      );
    },
  );

  /**
   * getAppUploadCategory
   *
   * @static
   * @type {MemoizedSelector<MerlotState, Category>}
   * @memberof AppLoadsSelectors
   */
  public static getAppUploadCategory: MemoizedSelector<MerlotState, Category> = createSelector(
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    (categoriesById: Record<string, Category>) => {
      const categoryId = getUniqueId(Integration.APTELIGENT, Entity.APPLOAD);
      return categoriesById[categoryId];
    },
  );

  /**
   * getAppUploadVersionColumn
   *
   * @static
   * @type {MemoizedSelector<MerlotState, Column>}
   * @memberof AppLoadsSelectors
   */
  public static getAppUploadVersionColumn: MemoizedSelector<MerlotState, Column> = createSelector(
    IntegrationMetaSelectors.getColumnsByCategory,
    AppLoadsSelectors.getAppUploadCategory,
    (columnsByCategory: Map<Category, Column[]>, appUploadCategory: Category) => {
      return find(columnsByCategory.get(appUploadCategory), (column: Column) => column.name === COLUMN_NAMES.byName._app_version);
    },
  );

  /**
   * getAirwatchAppCategory
   *
   * @static
   * @type {MemoizedSelector<MerlotState, Category>}
   * @memberof AppLoadsSelectors
   */
  public static getAirwatchAppCategory: MemoizedSelector<MerlotState, Category> = createSelector(
    IntegrationMetaSelectors.getCategoriesByCategoryId,
    (categoryIndex: CategoryIndex) => {
      const appCategoryId = getUniqueId(Integration.AIRWATCH, Entity.APPLICATION);
      return categoryIndex[appCategoryId];
    },
  );

  /**
   * isExperienceVisible
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static isExperienceVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isEmployeeExperienceAppsUxScoreDashboardEnabled,
    UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled,
    (isEmployeeExperienceAppsUxScoreDashboardEnabledState: boolean, isStandaloneApteligentEnabled: boolean) =>
      isEmployeeExperienceAppsUxScoreDashboardEnabledState && !isStandaloneApteligentEnabled,
  );

  /**
   * shouldShowPluginException
   *
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof AppLoadsSelectors
   */
  public static shouldShowPluginException: MemoizedSelector<MerlotState, boolean> = createSelector(
    UserPreferenceFeatureControlsSelectors.isApteligentPluginExceptionDashboardEnabled,
    AppLoadsSelectors.hasPluginExceptionEventCount,
    (isPluginExceptionEnabled: boolean, hasPluginExceptionEventCount: boolean) => isPluginExceptionEnabled && hasPluginExceptionEventCount,
  );

  /**
   * getVisibleAppDetailTabTypes
   * For Apteligent tabs, show the tab even if there is no data/metadata
   * After the user clicks on the tab,
   * determine whether to show widgets using isUserFlowsCategoryAvailable and isNetworkInsightsCategoryAvailable
   * @static
   * @type {MemoizedSelector<MerlotState, AppDetailTabType[]>}
   * @memberof AppLoadsSelectors
   */
  public static getVisibleAppDetailTabTypes: MemoizedSelector<MerlotState, AppDetailTabType[]> = createSelector(
    AppLoadsSelectors.getAppDetailTabType,
    AppLoadsSelectors.isApteligentUserFlowsDashboardVisible,
    AppLoadsSelectors.isApteligentNetworkInsightsDashboardVisible,
    AppLoadsSelectors.isApteligentErrorsDashboardVisible,
    AppLoadsSelectors.shouldShowPluginException,
    AppLoadsSelectors.isProductivityAppFeatureAvailable,
    AppLoadsSelectors.isExperienceVisible,
    // Workaround for max 8 selectors https://github.com/ngrx/platform/issues/1192
    UserPreferenceCommonSelectors.getFeatureControls,
    (
      appDetailTabType: AppDetailTabType,
      isUserFlowsVisible: boolean,
      isNetworkInsightsVisible: boolean,
      isErrorsVisible: boolean,
      shouldShowPluginException: boolean,
      isProductivityAppFeatureAvailable: boolean,
      isExperienceVisible: boolean,
      featureControls: FeatureControls,
    ) => {
      if ([AppDetailTabType.NETWORK_INSIGHTS_DETAILS, AppDetailTabType.USER_PROFILE].includes(appDetailTabType)) {
        return [];
      }
      // If only overview is visible, just select it and don't show any tabs
      if (!isUserFlowsVisible && !isNetworkInsightsVisible && !isErrorsVisible) {
        return [];
      }
      if (!isProductivityAppFeatureAvailable) {
        // INTEL-20366 Hide HANDLED_EXCEPTION and Settings tab for consumer region tenant on UI
        return [
          AppDetailTabType.OVERVIEW,
          ...(isExperienceVisible ? [AppDetailTabType.EXPERIENCE] : []),
          ...(isUserFlowsVisible ? [AppDetailTabType.USER_FLOWS] : []),
          ...(isNetworkInsightsVisible ? [AppDetailTabType.NETWORK_INSIGHTS] : []),
          ...(isErrorsVisible ? [AppDetailTabType.ERRORS] : []),
        ];
      }
      const isUsersVisible = !!featureControls[FeatureControl.APTELIGENT_USERS_ENABLED];
      const isHandledExceptionVisible = !!featureControls[FeatureControl.APTELIGENT_HE_DASHBOARD_ENABLED];
      return [
        AppDetailTabType.OVERVIEW,
        ...(isExperienceVisible ? [AppDetailTabType.EXPERIENCE] : []),
        ...(isUserFlowsVisible ? [AppDetailTabType.USER_FLOWS] : []),
        ...(isNetworkInsightsVisible ? [AppDetailTabType.NETWORK_INSIGHTS] : []),
        ...(isErrorsVisible ? [AppDetailTabType.ERRORS] : []),
        ...(isHandledExceptionVisible ? [AppDetailTabType.HANDLED_EXCEPTION] : []),
        ...(shouldShowPluginException ? [AppDetailTabType.PLUGIN_EXCEPTION] : []),
        ...(isUsersVisible ? [AppDetailTabType.USERS] : []),
        ...(isErrorsVisible ? [AppDetailTabType.SETTINGS] : []),
      ];
    },
  );
}
