<div [ngClass]="isInlineFilter ? 'inline-filter-text-view' : 'filter-group-text-view'">
  <div class="rules-text">
    <ng-container *ngIf="isInlineFilter">
      <div
        *ngIf="filteredRules?.length"
        class="rule-number"
      >
        <cds-icon
          solid
          shape="filter"
          size="12"
        ></cds-icon>
      </div>

      <!-- Shows complete filter explicitly "App Version includes (all)" -->
      <div *ngFor="let column of columnsToShowIncludesAll; let columnIndex = index">
        <strong
          *ngIf="columnIndex > 0"
          class="mh-x1"
        >
          {{ 'FILTER_VALUE.AND' | translate }}
        </strong>
        <span *ngIf="!isAttributeSelectorV2Enabled">
          {{ column.label }}
          <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: column?.attribute?.fullyQualifiedName }"></ng-container>
          <span class="rule-condition">{{ 'COMMON_MESSAGES.INCLUDES' | translate | uppercase }}</span>
          <span>{{ 'COMMON_MESSAGES.ALL' | translate }}</span>
        </span>
        <div
          *ngIf="isAttributeSelectorV2Enabled"
          class="includes-all-text"
        >
          {{ column?.attribute.fullyQualifiedName | columnLabel : allColumnsByName : columnToggleFilterMap }}
          <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: column?.attribute?.fullyQualifiedName }"></ng-container>
          <span class="rule-condition">{{ 'COMMON_MESSAGES.INCLUDES' | translate | uppercase }}</span>
          <span>{{ 'COMMON_MESSAGES.ALL' | translate }}</span>
        </div>
      </div>
    </ng-container>

    <ul class="rule-set root">
      <ng-container *ngIf="!isInlineFilter">
        <ng-container *ngIf="columnsToShowIncludesAll.length > 0 && ruleGroup?.getFilterCount(allColumnsByName) === 0">
          <li *ngFor="let column of columnsToShowIncludesAll; let columnIndex = index">
            <div class="rule-number">
              {{ columnIndex + 1 }}
            </div>

            <div class="rule-text-container-wrapper">
              <span
                *ngIf="columnIndex > 0"
                class="rule-operator and"
              >
                {{ 'FILTER_VALUE.AND' | translate }}
              </span>
              <span *ngIf="!isAttributeSelectorV2Enabled">
                {{ column.label }}
                <ng-container
                  *ngTemplateOutlet="attributeBadge; context: { attribute: column?.attribute?.fullyQualifiedName }"
                ></ng-container>
                <span class="rule-condition">{{ 'COMMON_MESSAGES.INCLUDES' | translate | uppercase }}</span>
                <span>{{ 'COMMON_MESSAGES.ALL' | translate }}</span>
              </span>
              <div
                *ngIf="isAttributeSelectorV2Enabled"
                class="includes-all-text"
              >
                {{ column?.attribute.fullyQualifiedName | columnLabel : allColumnsByName : columnToggleFilterMap }}
                <ng-container
                  *ngTemplateOutlet="attributeBadge; context: { attribute: column?.attribute?.fullyQualifiedName }"
                ></ng-container>
                <span class="rule-condition">{{ 'COMMON_MESSAGES.INCLUDES' | translate | uppercase }}</span>
                <span>{{ 'COMMON_MESSAGES.ALL' | translate }}</span>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          ruleGroupTmpl;
          context: {
            ruleGroup: ruleGroup,
            isRoot: true
          }
        "
      ></ng-container>
    </ul>
  </div>
  <div class="filter-text-actions">
    <dpa-tooltip
      *ngIf="showEdit"
      [attr.aria-label]="'COMMON_ACTIONS.EDIT' | translate"
      [tooltipText]="'COMMON_ACTIONS.EDIT' | translate"
      [focusEnabled]="true"
      (click)="toggleEdit()"
      (keyup.enter)="toggleEdit()"
      role="tooltip"
    >
      <a class="action-icon-container circle">
        <cds-icon shape="pencil"></cds-icon>
      </a>
    </dpa-tooltip>
    <dpa-tooltip
      *ngIf="hasRules()"
      [attr.aria-label]="'COMMON_ACTIONS.COPY' | translate"
      [tooltipText]="'COMMON_ACTIONS.COPY' | translate"
      [focusEnabled]="true"
      (click)="copyRules()"
      (keyup.enter)="copyRules()"
      role="tooltip"
    >
      <a class="action-icon-container circle">
        <cds-icon shape="copy"></cds-icon>
      </a>
    </dpa-tooltip>
    <dpa-tooltip
      [attr.aria-label]="showLess ? SHOW_VALUES : HIDE_VALUES"
      [tooltipText]="showLess ? SHOW_VALUES : HIDE_VALUES"
      [focusEnabled]="true"
      (click)="toggleRuleView()"
      (keyup.enter)="toggleRuleView()"
      role="tooltip"
    >
      <a class="action-icon-container circle">
        <cds-icon
          *ngIf="!showLess"
          shape="eye-hide"
          class="is-solid eye-icon"
        ></cds-icon>
        <cds-icon
          *ngIf="showLess"
          shape="eye"
          class="is-solid eye-icon"
        ></cds-icon>
      </a>
    </dpa-tooltip>
  </div>
</div>

<ng-template
  #ruleTmpl
  let-rule="rule"
>
  <div
    *dpaDelayRender="1"
    class="rule-text"
  >
    <div
      *ngLet="{
        ruleStatus: getRuleStatus(rule),
        ruleInfo: getRuleInfo(rule)
      } as details"
      class="rule-value pr-x1"
    >
      <a
        *ngIf="details.ruleStatus === RULE_STATUS.EMPTY"
        [class.clickable]="!readOnly"
        (click)="onFilterRuleClick(rule, $event)"
        (keyup.enter)="onFilterRuleClick(rule, $event)"
        class="empty-rule"
      >
        {{ 'COMMON_ERRORS.EMPTY_RULE' | translate }}
      </a>

      <a
        *ngIf="details.ruleStatus === RULE_STATUS.INVALID"
        [class.clickable]="!readOnly"
        (click)="onFilterRuleClick(rule, $event)"
        (keyup.enter)="onFilterRuleClick(rule, $event)"
        class="text-danger"
      >
        <span *ngIf="!isAttributeSelectorV2Enabled">{{ details.ruleInfo.ruleText }}</span>
        <span *ngIf="isAttributeSelectorV2Enabled">
          {{ rule.attribute | columnLabel : allColumnsByName : columnToggleFilterMap }}
          <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: rule?.attribute }"></ng-container>
          {{ details.ruleInfo.condition }}
        </span>
      </a>

      <ng-container *ngIf="[RULE_STATUS.DRAFT, RULE_STATUS.VALID].includes(details.ruleStatus)">
        <div class="rule-value-text">
          <span
            *ngIf="isAttributeSelectorV2Enabled"
            class="attribute-name"
          >
            {{ rule.attribute | columnLabel : allColumnsByName : columnToggleFilterMap }}
            <ng-container *ngTemplateOutlet="attributeBadge; context: { attribute: rule?.attribute }"></ng-container>
          </span>
          <span *ngIf="!isAttributeSelectorV2Enabled">
            {{ details.ruleInfo.name }}
          </span>
          <span class="rule-condition">
            {{ details.ruleInfo.condition | uppercase }}
          </span>
          <span *ngIf="!showLess || details.ruleInfo.valueCount === 1; else countTmpl">
            {{ details.ruleInfo.translatedValue }}
          </span>
          <ng-template #countTmpl>
            <span class="rule-value-count">{{ details.ruleInfo.valueCount }} {{ 'COMMON_MESSAGES.VALUES' | translate }}</span>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template
  #ruleGroupTmpl
  let-ruleGroup="ruleGroup"
  let-isRoot="isRoot"
>
  <ng-container *ngIf="showRuleSet()">
    <!-- linthtml-configure indent-width="false" -->
    <!-- prettier-ignore -->
    <li *ngFor="let rule of ruleGroup?.rules; let ruleIndex = index"><!-- //NOSONAR -->
      <ng-container *ngIf="isRoot">
        <div class="rule-number">
          {{ ruleIndex + 1 }}
        </div>
      </ng-container>
      <div
        *ngIf="ruleIndex >= 0 || columnsToShowIncludesAll?.length"
        class="rule-text-container-wrapper"
      >
        <span
          *ngIf="displayRuleOperator(rule) && ruleIndex > 0"
          [class.and]="ruleGroup.operator === RuleGroupOperator.AND"
          [class.or]="ruleGroup.operator === RuleGroupOperator.OR"
          class="rule-operator"
        >
          {{ 'FILTER_VALUE.' + ruleGroup.operator | translate }}
        </span>
        <ng-container *ngIf="isRuleGroup(rule)">
          <span class="open-bracket">(</span>
          <div class="ml-x3">
            <ul class="rule-set">
              <ng-container *ngTemplateOutlet="ruleGroupTmpl; context: { ruleGroup: rule }"></ng-container>
            </ul>
          </div>
          <span class="close-bracket">)</span>
        </ng-container>
      </div>
      <div
        *ngIf="!isRuleGroup(rule) && displayRule(rule)"
        class="rule-text-container"
      >
        <ng-container *ngTemplateOutlet="ruleTmpl; context: { rule: rule }"></ng-container>
      </div>
    </li>
  </ng-container>
</ng-template>

<ng-template
  #attributeBadge
  let-attribute="attribute"
>
  <span
    *ngIf="attribute | isAttributeNormalized : allColumnsByName : columnToggleFilterMap"
    class="label mr-0"
  >
    {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
  </span>
  <span
    *ngIf="attribute | isAttributeRaw : columnToggleFilterMap"
    class="label mr-0"
  >
    {{ 'COMMON_MESSAGES.RAW' | translate }}
  </span>
</ng-template>
