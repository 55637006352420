/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// eslint-disable-next-line max-len
import { BaseFormFieldComponent } from '@ws1c/intelligence-core/components/dynamic-form/components/form-field/components/base-form-field.component';
import { LabelValue } from '@ws1c/intelligence-models';

/**
 * ListFormFieldComponent
 * @export
 * @class ListFormFieldComponent
 * @extends {BaseFormFieldComponent}
 */
@Component({
  selector: 'dpa-list-form-field',
  templateUrl: 'list-form-field.component.html',
  styleUrls: ['list-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFormFieldComponent extends BaseFormFieldComponent {
  @Input() public selectedEnum?: LabelValue;
  @Input() public enumList?: LabelValue[] = [];

  /**
   * typeAheadFormatter
   * @param {LabelValue} result
   * @returns {string}
   * @memberof ListFormFieldComponent
   */
  public typeAheadFormatter(result: LabelValue): string {
    return result.label || '';
  }

  /**
   * keyBy
   * @param {LabelValue} item
   * @returns {string}
   * @memberof ListFormFieldComponent
   */
  public keyBy(item: LabelValue): string {
    return item.label;
  }

  /**
   * setTypeaheadControlValue
   * @param {LabelValue} item
   * @memberof ListFormFieldComponent
   */
  public setTypeaheadControlValue(item: LabelValue) {
    if (!item) {
      return;
    }
    this.formControl.patchValue(item.value);
  }

  /**
   * selectedItem
   * @readonly
   * @type {LabelValue}
   * @memberof ListFormFieldComponent
   */
  public get selectedItem(): LabelValue {
    return this.enumList.find((item: LabelValue) => item.value === (this.formControl.value ?? this.defaultValue));
  }
}
