<div class="card standard-widget">
  <div class="card-header">
    <div class="card-title">
      <div
        *ngLet="isControlBarVisible$ | async as isControlBarVisible"
        [class.pr-x4]="isControlBarVisible"
        class="clr-row"
      >
        <div class="clr-col-sm card-title-text">
          <span>
            {{ chartTitle }}
          </span>
          <dpa-tooltip-viewer
            *ngFor="let tooltipModel of tooltips"
            [tooltip]="tooltipModel"
            class="title-tooltip"
          ></dpa-tooltip-viewer>
        </div>
        <div class="group-by-template-container">
          <ng-template
            *ngIf="groupByTemplate"
            [ngTemplateOutlet]="groupByTemplate"
          ></ng-template>
        </div>
        <dpa-simple-drilldown-navigator
          *ngIf="(drilldownEvents$ | async)?.length"
          (clearDrilldown)="clearDrilldown()"
          (popDrilldown)="popDrilldown()"
          class="simple-drilldown"
        ></dpa-simple-drilldown-navigator>
        <button
          *ngIf="isControlBarVisible"
          (click)="expandOrCollapseControlBar()"
          class="btn btn-link btn-sm btn-icon m-x0 widget-dropdown-indicator-wrapper"
        >
          <cds-icon
            size="20"
            shape="filter-2"
            class="dropdown-indicator"
          ></cds-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="showSubHeader"
      class="card-sub-header"
    >
      <ng-template
        *ngIf="subheaderTemplate"
        [ngTemplateOutlet]="subheaderTemplate"
      ></ng-template>
      <dpa-fluid-height
        [isExpanded]="isControlBarExpanded"
        [showOverflowWhenNotAnimating]="true"
      >
        <div
          *ngIf="isControlBarExpanded$ | async"
          class="card-control-bar"
        >
          <dpa-widget-control-bar
            [showDateRangePicker]="showDateRangePicker"
            [widgetId]="widgetId"
            [trend]="chartData"
            [selectedChartTypeOverride]="selectedChartType$ | async"
            [showChartTypeSwitcher]="showChartTypeSwitcher"
            [isCrossCategory]="isWidgetJoinEnabled"
          ></dpa-widget-control-bar>
        </div>
      </dpa-fluid-height>
    </div>
  </div>

  <div class="card-block">
    <div class="card-block-header ph-x3">
      <ng-template
        *ngIf="cardBlockHeaderTemplate"
        [ngTemplateOutlet]="cardBlockHeaderTemplate"
      ></ng-template>
    </div>
    <dpa-web-error
      *ngIf="chartData?.errorMessage"
      [webError]="getWebError()"
      class="web-error m-x4 ph-x4"
    ></dpa-web-error>
    <dpa-standard-chart
      #standardChart
      [showDetailsLink]="true"
      [isRangedData]="isRangedData"
      [loading]="!chartData?.trendDefinition"
      [chartData]="chartData"
      [focusedSeries]="focusedSeries$ | async"
      [activeChartType]="selectedChartType$ | async"
      [showSeriesNames]="showSeriesNames"
      [isPercentageOverride]="isPercentageOverride"
      [stackedTooltip]="stackedTooltip"
      [xAxisLabelOverride]="xAxisLabelOverride"
      [yAxisLabelOverride]="yAxisLabelOverride"
      [customColors]="customColors"
      [noBucketingColor]="noBucketingColor"
      [showAllAvailableLabels]="showAllAvailableLabels"
      [noDataMessage]="noDataMessage"
      [noDataTooltip]="noDataTooltip"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [xAxisLabelFactor]="xAxisLabelFactor"
      [showLabels]="showLabels"
      [showXAxis]="showXAxis"
      [showYAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showDataLabel]="showDataLabel"
      [referenceLines]="referenceLines"
      [showRefLines]="showRefLines"
      [showRefLabels]="showRefLabels"
      [showTimeline]="showTimeline"
      [chartCurveType]="chartCurveType"
      [tableColumnNames]="tableColumnNames"
      [tableColumnLabelsByName]="tableColumnLabelsByName"
      [tableCellTemplatesByName]="tableCellTemplatesByName"
      [tableSettings]="tableSettings"
      [yScaleMax]="yScaleMax"
      [yScaleMin]="yScaleMin"
      [isInvertMode]="isInvertMode$ | async"
      [rangeFilter]="rangeFilter$ | async"
      [colorSchemas]="colorSchemas$ | async"
      [trimXAxisTicks]="trimXAxisTicks"
      [isCountersClickable]="isCountersClickable"
      [markerTooltipTemplate]="markerTooltipTemplate"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yAxisTicks]="yAxisTicks"
      [ngClass]="size"
      [markers]="markers"
      [skipSort]="skipSort"
      (drilldown)="pushDrilldownEvent($event)"
      (onMarkerClick)="handleMarkerClick($event)"
      class="standard-chart"
    ></dpa-standard-chart>
    <ng-template
      *ngIf="cardBlockFooter"
      [ngTemplateOutlet]="cardBlockFooter"
    ></ng-template>
  </div>
  <div
    *ngIf="customFooterTemplate"
    class="custom-footer"
  >
    <ng-template [ngTemplateOutlet]="customFooterTemplate"></ng-template>
  </div>
  <div
    *ngIf="showCardFooter"
    class="card-footer"
  >
    <div class="view-button-container">
      <button
        *ngIf="!hideViewLink"
        [attr.aria-label]="'DASHBOARD_ACTIONS.VIEW_DAHSBOARD_DETAILS_ARIA_LABEL' | translate : { name: chartTitle }"
        [disabled]="!chartData || !chartData.trendDefinition || disableViewLink"
        (click)="goToWidgetDetail()"
        type="button"
        class="btn btn-link"
      >
        {{ 'COMMON_ACTIONS.VIEW' | translate }}
      </button>
      <ng-template
        [ngTemplateOutlet]="outerActionsTemplate"
        [ngTemplateOutletContext]="{ widgetId: widgetId }"
      ></ng-template>
    </div>
    <dpa-widget-tag
      [trend]="chartData"
      [maxNumTags]="2"
    ></dpa-widget-tag>
    <clr-dropdown>
      <button
        clrDropdownTrigger
        [disabled]="!chartData || !chartData.trendDefinition"
        type="button"
        class="btn-sm btn-link ph-0"
      >
        <cds-icon
          [class.disabled]="!chartData || !chartData.trendDefinition"
          shape="ellipsis-horizontal"
          size="24"
        ></cds-icon>
      </button>
      <clr-dropdown-menu
        *clrIfOpen
        [clrPosition]="dropdownPosition"
      >
        <button
          *ngIf="chartData?.trendDefinition?.automationEnabled && (hasAutomationWritePerm$ | async)"
          clrDropdownItem
          [disabled]="!chartData?.trendDefinition?.isCategoryAutomationSupported"
          (click)="automateWidget()"
          data-action-type="AUTOMATE"
          type="button"
        >
          {{ 'COMMON_ACTIONS.AUTOMATE' | translate }}
        </button>
        <button
          *ngIf="chartData?.trendDefinition"
          clrDropdownItem
          (click)="exportAsCsv()"
          data-action-type="EXPORT_AS_CSV"
          type="button"
        >
          {{ 'COMMON_ACTIONS.EXPORT_AS_CSV' | translate }}
        </button>
        <button
          *ngIf="isCopyVisible()"
          clrDropdownItem
          (click)="requestCopyToWidget()"
          data-action-type="COPY_TO"
          type="button"
        >
          {{ 'COMMON_ACTIONS.COPY_TO' | translate }}
        </button>
        <button
          *ngIf="isWidgetEditThemeAvailable"
          clrDropdownItem
          (click)="onEditTheme()"
          data-action-type="EDIT_THEME"
          type="button"
        >
          {{ 'COMMON_ACTIONS.EDIT_THEME' | translate }}
        </button>
        <button
          *ngIf="isEditRangeAvailable$ | async"
          clrDropdownItem
          (click)="openEditRangeDialog(true)"
          data-action-type="EDIT_RANGES"
          type="button"
        >
          {{ 'WIDGET_CUSTOMIZE.EDIT_RANGES' | translate }}
        </button>
        <ng-template [ngTemplateOutlet]="extraActionsTemplate"></ng-template>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</div>

<dpa-widget-edit-range-dialog
  *ngIf="isEditRangeDialogOpen$ | async as isEditRangeDialogOpen"
  [dashboardId]="dashboardId"
  [isModalOpen]="isEditRangeDialogOpen"
  [chartData]="chartData"
></dpa-widget-edit-range-dialog>
