/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { AppComponent } from './app.component';
import { BrownfieldNotificationModalComponent } from './brownfield-notification-modal/brownfield-notification-modal.component';
import { IntroModalComponent } from './intro-modal/intro-modal.component';
import { StartTrialComponent } from './intro-modal/start-trial/start-trial.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NavigationConfirmModalComponent } from './navigation/navigation-confirm-modal.component';
import { NavigationPreviewModalComponent } from './navigation-preview-modal/navigation-preview-modal.component';
import { ProductUpgradeModalComponent } from './pages/product-upgrade-modal/product-upgrade-modal.component';
import { RequestCloudAccountComponent } from './request-cloud-account/request-cloud-account.component';
import { RequestCloudAccountModalComponent } from './request-cloud-account-modal/request-cloud-account-modal.component';

export const APP_COMPONENTS: any[] = [
  AppComponent,
  BrownfieldNotificationModalComponent,
  MaintenanceComponent,
  IntroModalComponent,
  StartTrialComponent,
  NavigationConfirmModalComponent,
  NavigationPreviewModalComponent,
  ProductUpgradeModalComponent,
  RequestCloudAccountComponent,
  RequestCloudAccountModalComponent,
];

export * from './app.component';
export * from './brownfield-notification-modal/brownfield-notification-modal.component';
export * from './maintenance/maintenance.component';
export * from './intro-modal/intro-modal.component';
export * from './intro-modal/start-trial/start-trial.component';
export * from './navigation/navigation-confirm-modal.component';
export * from './navigation-preview-modal/navigation-preview-modal.component';
export * from './request-cloud-account/request-cloud-account.component';
export * from './request-cloud-account-modal/request-cloud-account-modal.component';
