/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { PagedRequest, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { EntityProp } from '@ws1c/deem-solution/models';
import {
  CustomReportPreviewSearchResponse,
  Device,
  DeviceCrumbListLocator,
  EntityThresholds,
  PreviewReportContentRequest,
  RuleGroup,
  StandardDashboardType,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * DeviceProfileActions
 *
 * @export
 * @class DeviceProfileActions
 */
@Injectable()
export class DeviceProfileActions {
  public static readonly CATEGORY = '[DEVICE_PROFILE]';

  public static initDevice = createAction(`${DeviceProfileActions.CATEGORY} INIT_DEVICE`, props<{ device: Device }>());

  public static initDeviceSuccess = createAction(`${DeviceProfileActions.CATEGORY} INIT_DEVICE_SUCCESS`, props<{ device: Device }>());

  public static initDeviceFailure = createAction(`${DeviceProfileActions.CATEGORY} INIT_DEVICE_FAILURE`, props<{ error: WebError }>());

  public static loadDeviceProfileDashboard = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_DASHBOARD`,
    props<{
      deviceId: string;
      trendDateRange?: TrendDateRange;
    }>(),
  );

  public static loadDeviceProfileCompareAppsDashboard = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_COMPARE_APPS_DASHBOARD`,
    props<{
      deviceId: string;
      appNames: string[];
      trendDateRange: TrendDateRange;
    }>(),
  );

  public static loadDeviceProfileExperienceDashboard = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_EXPERIENCE_DASHBOARD`,
    props<{ deviceId: string }>(),
  );

  public static loadDeviceEdiData = createAction(`${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EDI_DATA`, props<{ deviceId: string }>());

  public static loadDeviceEdiDataSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EDI_DATA_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse }>(),
  );

  public static loadDeviceEdiDataFailure = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EDI_DATA_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadDeviceProfileNetworkPerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_NETWORK_PERFORMANCE`,
    props<{
      deviceId: string;
      nicType: string;
      nicDescription: string;
    }>(),
  );

  public static loadDeviceProfileEnergyPerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_ENERGY_PERFORMANCE`,
    props<{ deviceId: string }>(),
  );

  public static loadDeviceProfileUrlMonitoringPerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_URL_MONITORING_PERFORMANCE`,
    props<{
      deviceId: string;
      testId: string;
    }>(),
  );

  public static loadDeviceProfileAppPerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_APP_PERFORMANCE`,
    props<{
      deviceId: string;
      appName: string;
    }>(),
  );

  public static loadDeviceProfileDestinationPerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_DESTINATION_PERFORMANCE`,
    props<{
      deviceId: string;
      url: string;
    }>(),
  );

  public static loadDeviceProfileMobileNetworkDestinationBreakdownPerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_MOBILE_NETWORK_DESTINATION_BREAKDOWN_PERFORMANCE`,
    props<{
      deviceId: string;
      appName: string;
      hostName: string;
    }>(),
  );

  public static saveDeviceProfilePerformance = createAction(
    `${DeviceProfileActions.CATEGORY} SAVE_DEVICE_PROFILE_PERFORMANCE`,
    props<{
      id: string;
      subType: string;
      trend: Trend;
    }>(),
  );

  public static setDeviceProfileFilters = createAction(
    `${DeviceProfileActions.CATEGORY} SET_DEVICE_PROFILE_FILTERS`,
    props<{
      ruleGroup: RuleGroup;
      trendDateRange: TrendDateRange;
      deviceId?: string;
    }>(),
  );

  public static loadDeviceRiskScore = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_RISK_SCORE`,
    props<{ deviceId: string }>(),
  );

  public static loadDeviceRiskScoreSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_RISK_SCORE_SUCCESS`,
    props<{ riskScore: string }>(),
  );

  public static loadDeviceRiskScoreFailure = createAction(`${DeviceProfileActions.CATEGORY} LOAD_DEVICE_RISK_SCORE_FAILURE`);

  public static loadDeviceHealthThresholdsV2 = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_THRESHOLDS_V2`,
    props<EntityProp>(),
  );

  public static loadDeviceHealthThresholdsV2Success = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_THRESHOLDS_V2_SUCCESS`,
    props<{ data: EntityThresholds }>(),
  );

  public static loadDeviceHealthThresholdsV2Failure = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_THRESHOLDS_V2_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadDeviceHealthStatus = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_STATUS`,
    props<{ deviceId: string }>(),
  );

  public static loadDeviceHealthStatusSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_STATUS_SUCCESS`,
    props<{ deviceHealth: string }>(),
  );

  public static loadDeviceHealthStatusFailure = createAction(`${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_STATUS_FAILURE`);

  public static loadDeviceEvents = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EVENTS`,
    props<{
      id: string;
      entity: string;
      event: string;
      startDateMillis: number;
      endDateMillis: number;
    }>(),
  );

  public static loadDeviceEventsPaged = createAction(`${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EVENTS_PAGED`);

  public static setloadDeviceEventsPagedRequest = createAction(
    `${DeviceProfileActions.CATEGORY} SET_LOAD_DEVICE_EVENTS_PAGED_REQUEST`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static loadDeviceEventsSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EVENTS_SUCCESS`,
    props<{
      request: PreviewReportContentRequest;
      response: CustomReportPreviewSearchResponse;
    }>(),
  );

  public static loadDeviceEventsFailure = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_EVENTS_FAILURE`,
    props<{
      error?: WebError;
    }>(),
  );

  public static loadDevicesProfileSystemTrend = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICES_PROFILE_SYSTEM_TREND`,
    props<{ deviceId: string }>(),
  );

  public static loadDeviceHealth = createAction(`${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH`, props<{ deviceId: string }>());

  public static loadDeviceHealthSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_SUCCESS`,
    props<{
      deviceId: string;
      response: any;
    }>(),
  );

  public static loadDeviceHealthFailure = createAction(`${DeviceProfileActions.CATEGORY} LOAD_DEVICE_HEALTH_FAILURE`);

  public static loadDevicePropertiesDashboard = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROPERTIES_DASHBOARD`,
    props<{
      deviceId: string;
    }>(),
  );

  public static loadDeviceProperties = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROPERTIES`,
    props<{
      deviceId: string;
    }>(),
  );
  public static loadDevicePropertiesSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROPERTIES_SUCCESS`,
    props<{
      response: CustomReportPreviewSearchResponse;
    }>(),
  );
  public static loadDevicePropertiesFailure = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROPERTIES_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadDeviceProfilePerformance = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_PROFILE_PERFORMANCE`,
    props<{
      deviceId: string;
      dashboardType: StandardDashboardType;
    }>(),
  );

  public static loadDeviceCrumbList = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_CRUMB_LIST`,
    props<{ crumbListLocator: DeviceCrumbListLocator }>(),
  );

  public static loadDeviceCrumbListSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_CRUMB_LIST_SUCCESS`,
    props<{
      crumbListLocator: DeviceCrumbListLocator;
      responses: CustomReportPreviewSearchResponse[];
    }>(),
  );

  public static loadDeviceCrumbListFailure = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_CRUMB_LIST_FAILURE`,
    props<{
      crumbListLocator: DeviceCrumbListLocator;
      error: WebError;
    }>(),
  );

  public static loadDeviceDetailsDashboard = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_DEVICE_DETAILS_DASHBOARD`,
    props<{ deviceId: string }>(),
  );

  public static showDeviceProfileSlideOver = createAction(
    `${DeviceProfileActions.CATEGORY} SHOW_DEVICE_PROFILE_SLIDE_OVER`,
    props<{ device: Device }>(),
  );

  public static hideDeviceProfileSlideOver = createAction(`${DeviceProfileActions.CATEGORY} HIDE_DEVICE_PROFILE_SLIDE_OVER`);

  public static loadZoomSummaryData = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_ZOOM_SUMMARY_DATA`,
    props<{ request: PreviewReportContentRequest }>(),
  );

  public static loadZoomSummaryDataSuccess = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_ZOOM_SUMMARY_DATA_SUCCESS`,
    props<{ response: CustomReportPreviewSearchResponse }>(),
  );

  public static loadZoomSummaryDataFailure = createAction(
    `${DeviceProfileActions.CATEGORY} LOAD_ZOOM_SUMMARY_DATA_FAILURE`,
    props<{ error: WebError }>(),
  );
}
