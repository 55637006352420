/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * ServiceIdSearchData
 * @export
 * @class ServiceIdSearchData
 */
@Serializable
export class ServiceIdSearchData {
  @JsonProperty('service_ids')
  public serviceIds: string[] = [];

  /**
   * Creates an instance of ServiceIdSearchData
   * @param {any} args
   * @memberof ServiceIdSearchData
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * AutomationsByServiceIdSearchRequest Model Object
 * @export
 * @class AutomationsByServiceIdSearchRequest
 */
@Serializable
export class AutomationsByServiceIdSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'search_data', cls: ServiceIdSearchData })
  public searchData: ServiceIdSearchData = undefined;

  /**
   * Creates an instance of AutomationsByServiceIdSearchRequest
   * @param {any} args
   * @memberof AutomationsByServiceIdSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}
