/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, RelativeTime, RelativeTimeUnit, RuleGroupOperator } from '@dpa/ui-common';
import { cloneDeep, isEmpty, isNil, isString } from 'lodash-es';

import { ColumnIndex } from '@ws1c/intelligence-models/integration-meta/column.model';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { FilterRule } from './filter-rule.model';
import { RuleGroup } from './rule-group.model';
import { RuleType } from './rule-type.enum';

export const initialRuleGroup: RuleGroup = new RuleGroup();

/**
 * QueryBuilder
 * @export
 * @class QueryBuilder
 */
export class QueryBuilder {
  public static readonly TIME_UNIT_TO_FILTER_STRING_UNIT = {
    [RelativeTimeUnit[RelativeTimeUnit.MINUTES]]: 'minutes',
    [RelativeTimeUnit[RelativeTimeUnit.HOURS]]: 'hours',
    [RelativeTimeUnit[RelativeTimeUnit.DAYS]]: 'days',
    [RelativeTimeUnit[RelativeTimeUnit.WEEKS]]: 'weeks',
    [RelativeTimeUnit[RelativeTimeUnit.MONTHS]]: 'months',
    [RelativeTimeUnit[RelativeTimeUnit.YEARS]]: 'years',
  };

  /**
   * queryStringFromKeyValue
   * @param {Record<string, any>} keyValues
   * @param {any} [dataTypesByBucket]
   * @param {RuleGroupOperator | string} operator
   * @returns {string}
   */
  public static queryStringFromKeyValue(
    keyValues: Record<string, any>,
    dataTypesByBucket?: any,
    operator?: RuleGroupOperator | string,
  ): string {
    const filterRules = FilterRule.listFromKeyValue(keyValues, dataTypesByBucket);
    return new QueryBuilder(new RuleGroup(filterRules, operator ?? RuleGroupOperator.AND)).getQueryString();
  }

  /**
   * queryStringFromFilterRules
   * @static
   * @param {FilterRule[]} filterRules
   * @returns {string}
   * @memberof QueryBuilder
   */
  public static queryStringFromFilterRules(filterRules: FilterRule[]): string {
    return new QueryBuilder(new RuleGroup(filterRules.filter(Boolean))).getQueryString();
  }

  public columnsByName: ColumnIndex;
  public group: RuleGroup;

  /**
   * constructor
   * @param {RuleGroup} group
   * @param {ColumnIndex} [columnsByName]
   * @memberof QueryBuilder
   */
  constructor(group: RuleGroup = cloneDeep(initialRuleGroup), columnsByName?: ColumnIndex) {
    this.columnsByName = columnsByName;
    this.group = group;
  }

  /**
   * Convert rules to query string
   * @returns {string}
   * @memberof QueryBuilder
   */
  public getQueryString(): string {
    return QueryBuilder.buildQueryString(this.group, this.columnsByName);
  }

  /**
   * Convert single rule object to query string
   * @static
   * @param {FilterRule} rule
   * @param {ColumnIndex} [columnsByName]
   * @returns {string}
   * @memberof QueryBuilder
   */
  public static getRuleString(rule: FilterRule, columnsByName?: ColumnIndex): string {
    let data = rule && rule.data;
    if (!rule?.valueRequired) {
      return `${rule.attribute} ${rule.condition}`;
    }

    // currently needs to be isNil instead of isEmpty
    // I think this is because of "let data = rule && rule.data" above
    // isEmpty(false) is true, but isNil(false) is false
    if (isNil(data)) {
      return '';
    }

    if (QueryBuilder.isRelativeRange(rule.condition)) {
      const relativeTime: RelativeTime = data[0];
      if (!relativeTime || !relativeTime.interval || !relativeTime.unit) {
        return '';
      }
      const unitString = QueryBuilder.TIME_UNIT_TO_FILTER_STRING_UNIT[relativeTime.unit];
      return ` ${rule.attribute} ${rule.condition} ${relativeTime.interval} ${unitString} `;
    }

    const isStringData = isString(data);
    if (Array.isArray(data)) {
      data = QueryBuilder.getDataForArrayType(data, rule);
    } else {
      if (isStringData && !rule.isBoolean && !rule.isIPv4) {
        data = `'${data.replace(/\'/g, "\\'")}'`;
      }
    }
    data = QueryBuilder.checkIfMultiAndWrapData(rule, data, columnsByName);
    return ` ${rule.attribute} ${rule.condition} ${data} `;
  }

  /**
   * Check whether value is a range or not based on operator
   *
   * @static
   * @param {string} operator
   * @returns {boolean}
   * @memberof QueryBuilder
   */
  public static isRange(operator: string): boolean {
    return ['BETWEEN', 'NOT BETWEEN'].includes(operator);
  }

  /**
   * Check whether value is a relative range or not based on operator
   *
   * @static
   * @param {string} operator
   * @returns {boolean}
   * @memberof QueryBuilder
   */
  public static isRelativeRange(operator: string): boolean {
    return ['WITHIN', 'NOT WITHIN'].includes(operator);
  }

  /**
   * Check whether value is a multiselect or not based on operator
   *
   * @static
   * @param {string} operator
   * @returns {boolean}
   * @memberof QueryBuilder
   */
  public static isMultiSelect(operator: string): boolean {
    return ['IN', 'NOT IN', 'CONTAINS ANY OF', 'CONTAINS ALL OF', 'CONTAINS NONE OF'].indexOf(operator) >= 0;
  }

  /**
   * Check the dataType is a LIST
   *
   * @static
   * @param {string} dataType
   * @returns {boolean}
   * @memberof QueryBuilder
   */
  public static isListDataType(dataType: string): boolean {
    return [DataType[DataType.NUMBERLIST], DataType[DataType.STRINGLIST]].includes(dataType);
  }

  /**
   * parseRuleDefinitionTree
   * @static
   * @param {any} rootTreeNode
   * @returns {RuleGroup}
   * @memberof QueryBuilder
   */
  public static parseRuleDefinitionTree(rootTreeNode: any): RuleGroup {
    if (!rootTreeNode || isEmpty(rootTreeNode)) {
      return new RuleGroup();
    }
    if (rootTreeNode.type === RuleType.RuleSet) {
      // version V2
      return (QueryBuilder.buildRuleSet(rootTreeNode) ?? new RuleGroup()) as RuleGroup;
    }

    // Below statement should never be executed.
    // This error will be printed to the console when the API doesn't return `filter_condition_nested_rules`
    // eslint-disable-next-line no-console
    console.error('rule definition tree(filter_condition_nested_rules) is invalid.');
  }

  /**
   * treeNodeToFilterRule
   * @static
   * @param {any} treeNode
   * @returns {FilterRule}
   * @memberof QueryBuilder
   */
  public static treeNodeToFilterRule(treeNode: any): FilterRule {
    return new FilterRule({
      condition: treeNode.operator,
      attribute: treeNode.attribute,
      data: QueryBuilder.getData(treeNode),
      dataType: treeNode.operands[0]?.data_type,
      valueRequired: !!treeNode.operands[0],
      label: treeNode.attribute,
    });
  }

  /**
   * getData
   * @param {any} treeNode
   * @returns {any}
   * @memberof QueryBuilder
   */
  private static getData(treeNode: any) {
    // For WITHIN, NOT WITHIN, we need to return entire operand object (value is undefined)
    if (QueryBuilder.isRelativeRange(treeNode.operator)) {
      return treeNode.operands;
    }
    if (FilterRule.isArrayOperator(treeNode.operator) || treeNode.operands?.length > 1) {
      return treeNode.operands.map((operand: any) => operand.value);
    }
    return treeNode.operands[0]?.value;
  }

  /**
   * buildRuleSet
   * @param {any} tree
   * @returns {RuleGroup | FilterRule}
   * @memberof QueryBuilder
   */
  public static buildRuleSet(tree: any): RuleGroup | FilterRule {
    if (!tree) {
      return;
    }

    if (tree.type === RuleType.RuleSet) {
      const rules = tree.rules.map((rule) => QueryBuilder.buildRuleSet(rule));
      const operator = tree.logical_operator;
      return new RuleGroup(rules, operator);
    }
    return QueryBuilder.treeNodeToFilterRule(tree);
  }

  /**
   * filterConditionToQueryString
   *
   * @static
   * @param {any} filterCondition
   * @returns {string}
   * @memberof QueryBuilder
   */
  public static filterConditionToQueryString(filterCondition: any): string {
    const parsedRuleGroup = QueryBuilder.parseRuleDefinitionTree(filterCondition);
    return new QueryBuilder(parsedRuleGroup).getQueryString();
  }

  /**
   * buildQueryString
   * @static
   * @param {RuleGroup} ruleGroup
   * @param {any} columnsByName
   * @param {boolean} encloseInParens
   * @returns {string}
   * @memberof QueryBuilder
   */
  public static buildQueryString(ruleGroup: RuleGroup, columnsByName: any, encloseInParens: boolean = false): string {
    const queries: string[] = [];
    for (const rule of ruleGroup.rules) {
      if (RuleGroup.isRuleGroup(rule)) {
        const query = QueryBuilder.buildQueryString(rule, columnsByName, true);
        if (query) {
          queries.push(query);
        }
      } else {
        const isValidRule = !isEmpty(columnsByName) ? rule.isValid(columnsByName) : rule;
        if (isValidRule) {
          const query = QueryBuilder.getRuleString(rule, columnsByName);
          if (query) {
            queries.push(query);
          }
        }
      }
    }
    if (queries.length) {
      const prefix = encloseInParens ? '( ' : '';
      const suffix = encloseInParens ? ' )' : '';
      return `${prefix}${queries.join(` ${ruleGroup.operator} `)}${suffix}`;
    }
    return '';
  }

  /**
   * areSameQueries
   * @static
   * @param {RuleGroup} first
   * @param {RuleGroup} second
   * @param {GenericObject} columnsByName
   * @returns {boolean}
   * @memberof QueryBuilder
   */
  public static areSameQueries(first: RuleGroup, second: RuleGroup, columnsByName: GenericObject): boolean {
    if (first === second) {
      return true;
    }
    return QueryBuilder.buildQueryString(first, columnsByName) === QueryBuilder.buildQueryString(second, columnsByName);
  }

  /**
   * getDataForArrayType
   *
   * @private
   * @static
   * @param {any[]} data
   * @param {FilterRule} rule
   * @returns {string}
   * @memberof QueryBuilder
   */
  private static getDataForArrayType(data: any[], rule: FilterRule): string {
    const isStringData = isString(data[0]);
    if (isStringData && !rule.isIPv4 && !rule.isNumberArray) {
      const escapedData = data.map((str) => str.replace(/\'/g, "\\'"));
      return `'${escapedData.join("' , '")}'`;
    }
    return data.join(' , ');
  }

  /**
   * checkIfMultiAndWrapData
   * @param {FilterRule} rule
   * @param {any} data
   * @param {ColumnIndex} [columnsByName]
   * @returns {string}
   * @memberof QueryBuilder
   */
  private static checkIfMultiAndWrapData(rule: FilterRule, data: any, columnsByName?: ColumnIndex): string {
    if (
      QueryBuilder.isRange(rule.condition) ||
      QueryBuilder.isMultiSelect(rule.condition) ||
      QueryBuilder.isListDataType(columnsByName?.[rule.attribute]?.dataType)
    ) {
      return `( ${data} )`;
    }
    return data;
  }
}
