/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GenericObject } from '@dpa/ui-common';
import { uniqueId } from 'lodash-es';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { originalOrder } from '@ws1c/intelligence-common';
import { MetaFormUtils } from '@ws1c/intelligence-core/components/dynamic-form/metaform.utils';
import { JsonSchemaMetadata, JsonSchemaNode, JsonSchemaType, LabelValue, LookupVariable } from '@ws1c/intelligence-models';

/**
 * DynamicFormNodeComponent
 * @export
 * @class DynamicFormNodeComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-dynamic-form-node',
  templateUrl: 'dynamic-form-node.component.html',
  styleUrls: ['dynamic-form-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormNodeComponent implements OnInit, OnDestroy {
  @Input() public node: JsonSchemaNode;
  @Input() public metadataByAnchor: Record<string, JsonSchemaMetadata>;
  @Input() public parentForm: UntypedFormGroup | AbstractControl;
  @Input() public parentFormArray: UntypedFormArray | AbstractControl;
  @Input() public dependentNodes?: JsonSchemaNode[];
  @Input() public nodeKey?: string;
  @Input() public required?: boolean;
  @Input() public columnLookupVariables?: LookupVariable[];
  @Input() public formValues?: GenericObject;
  @Input() public readonly?: boolean = false;
  @Input() public value: any;
  @Input() public searchableActionsEnabled?: boolean = true;

  public sub: Subscription = new Subscription();
  public dependentNode: JsonSchemaNode;
  public nodeControl: AbstractControl;
  public readonly NODE_TYPE = JsonSchemaType;
  public readonly originalOrder: () => number = originalOrder;
  public iterateArray: boolean;
  public id: string = uniqueId('dynamic-form-node-component');

  /**
   * ngOnInit
   * @memberof DynamicFormNodeComponent
   */
  public ngOnInit() {
    if (this.node?.type === JsonSchemaType.ARRAY) {
      this.iterateArray = Array.isArray(this.node.items);
    }
    // if node is a property, add form control or re-enable existing control
    // For object type, if the parent is array, nodeKey will not be available for each object inside
    if (this.nodeKey || this.parentFormArray) {
      this.setNodeControl();
    }
    // when node value changes, propagate change to any dependent nodes
    if (this.dependentNodes) {
      this.sub.add(
        this.parentForm.valueChanges.pipe(startWith({ [this.nodeKey]: this.initialNodeValue })).subscribe((formValues: GenericObject) => {
          this.collapseEnumDependencies(formValues[this.nodeKey]);
        }),
      );
    }
  }

  /**
   * ngOnDestroy
   * @memberof DynamicFormNodeComponent
   */
  public ngOnDestroy() {
    if (this.parentFormArray) {
      const index: number = (this.parentFormArray as UntypedFormArray).controls.indexOf(this.nodeControl);
      (this.parentFormArray as UntypedFormArray).removeAt(index);
    } else if (this.parentForm) {
      (this.parentForm as UntypedFormGroup).removeControl(this.nodeKey);
    }
    this.sub.unsubscribe();
  }

  /**
   * initialNodeValue
   * @readonly
   * @type {string}
   * @memberof DynamicFormNodeComponent
   */
  public get initialNodeValue(): string {
    if (Array.isArray(this.formValues)) {
      // When the value is an array take the first element and the value by the nodeKey or default node value
      // until we have the Array support implemented with https://jira-euc.eng.vmware.com/jira/browse/INTEL-47637
      return this.formValues?.[0][this.nodeKey] ?? this.node?.default;
    }
    return this.formValues?.[this.nodeKey] ?? this.node?.default;
  }

  /**
   * itemsArray
   * @readonly
   * @type {JsonSchemaNode[]}
   * @memberof DynamicFormNodeComponent
   */
  public get itemsArray(): JsonSchemaNode[] {
    return this.node.items as JsonSchemaNode[];
  }

  /**
   * nodeMetadata
   * @readonly
   * @type {JsonSchemaMetadata}
   * @memberof DynamicFormNodeComponent
   */
  public get nodeMetadata(): JsonSchemaMetadata {
    return this.metadataByAnchor?.[this.node?.$anchor] ?? new JsonSchemaMetadata();
  }

  /**
   * nodeEnumList
   * @readonly
   * @type {LabelValue[]}
   * @memberof DynamicFormNodeComponent
   */
  public get nodeEnumList(): LabelValue[] {
    return (
      this.node.enum?.map((enumVal: string) => {
        const enumNode = this.dependentNodes?.find((node: JsonSchemaNode) => node.properties[this.nodeKey].enum.includes(enumVal));
        const label =
          this.metadataByAnchor[enumNode?.properties[this.nodeKey].$anchor]?.label ??
          this.metadataByAnchor[`${this.node.$anchor}/${enumVal}`]?.label ??
          enumVal;
        return {
          label,
          value: enumVal,
        };
      }) ?? []
    );
  }

  /**
   * setNodeControl
   * @memberof DynamicFormNodeComponent
   */
  public setNodeControl() {
    const validators = [this.required && Validators.required, MetaFormUtils.getValidator(this.nodeMetadata.presentationType)].filter(
      Boolean,
    );
    switch (this.node.type) {
      case JsonSchemaType.OBJECT:
        this.nodeControl = new UntypedFormGroup({});
        break;
      case JsonSchemaType.ARRAY:
        this.nodeControl = this.iterateArray ? new UntypedFormArray([]) : new UntypedFormControl(this.initialNodeValue, validators);
        break;
      default:
        this.nodeControl = new UntypedFormControl(this.initialNodeValue, validators);
        break;
    }
    if (this.parentFormArray) {
      (this.parentFormArray as UntypedFormArray).push(this.nodeControl);
    } else {
      (this.parentForm as UntypedFormGroup).addControl(this.nodeKey, this.nodeControl);
    }
  }

  /**
   * collapseEnumDependencies
   * @param {string} formValue
   * @memberof DynamicFormNodeComponent
   */
  public collapseEnumDependencies(formValue: string) {
    let value: string | boolean = formValue ?? this.node.default;
    value = value === 'true' || value === 'false' ? JSON.parse(value) : value;
    const rawDependentNode = this.dependentNodes?.find((node: JsonSchemaNode) => {
      return node.properties?.[this.nodeKey].enum.includes(value);
    });
    if (rawDependentNode) {
      this.dependentNode = {
        ...rawDependentNode,
        properties: Object.fromEntries(
          Object.entries(rawDependentNode.properties).filter(([key]: [string, JsonSchemaNode]) => key !== this.nodeKey),
        ),
      };
      return;
    }
    this.dependentNode = undefined;
  }

  /**
   * trackByForArray
   * @param {number} _index
   * @param {GenericObject} field
   * @returns {string}
   * @memberof DynamicFormNodeComponent
   */
  public trackByForArray(_index: number, field: GenericObject): string {
    return field.$anchor;
  }
}
